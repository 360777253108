import { json } from "node:stream/consumers";
import {
  CanvasAssignment,
  FinancialAidToDo,
  ToDoItem,
  NintexTasksResponse,
  PeoplesoftStudentClassApiResponse,
  NintexTaskStep,
} from "types/integrations";
import { ToDoTypes } from "types/tasks";

export const populateNintexTasksPriority = (
  nintexData: NintexTasksResponse
) => {
  nintexData.CompletedTasks.forEach((task) => {
    task.CardType = task.Priority;
    task.IsNintexTask = true;
    task.DataType = ToDoTypes.Onboarding;
    if (task.Steps) {
      task.NintexTaskStep = getNintexStepsFromRawString(task.Steps);
    }
  });
  nintexData.InProgressTasks.forEach((task) => {
    task.CardType = task.Priority;
    task.IsNintexTask = true;
    task.DataType = ToDoTypes.Onboarding;
    if (task.Steps) {
      task.NintexTaskStep = getNintexStepsFromRawString(task.Steps);
    }
  });
  nintexData.UpcomingTasks.forEach((task) => {
    task.CardType = task.Priority;
    task.IsNintexTask = true;
    task.DataType = ToDoTypes.Onboarding;
    if (task.Steps) {
      task.NintexTaskStep = getNintexStepsFromRawString(task.Steps);
    }
  });
  nintexData.ToDoTasks.forEach((task) => {
    task.CardType = task.Priority;
    task.IsNintexTask = true;
    task.DataType = ToDoTypes.Onboarding;
    if (task.Steps) {
      task.NintexTaskStep = getNintexStepsFromRawString(task.Steps);
    }
  });
  return nintexData;
};
// The "Steps" field is a JSON string with the fields missing double quotes,
// so we have to add it to parse it correctly
const getNintexStepsFromRawString = (rawString: string) => {
  const regex = new RegExp(/([a-zA-Z0-9-]+):/g);
  let modifiedString = rawString.replaceAll(regex, '"$1":');
  modifiedString = modifiedString.replaceAll("\\", "");
  return JSON.parse(modifiedString) as NintexTaskStep;
};

export const mutateFinancialAidToDoToNintexTask = (
  financialAidToDo: FinancialAidToDo
) => {
  let todoItem = {} as ToDoItem;
  todoItem.ShortDescription = financialAidToDo.ItemDescription;
  todoItem.TaskDate = financialAidToDo.ItemDueDate;
  todoItem.DataType = ToDoTypes.Financial;
  return todoItem;
};

export const mutateCanvasAssignmentsToNintexTask = (
  canvasData: PeoplesoftStudentClassApiResponse,
  today: Date
) => {
  let assignments = Array<CanvasAssignment>();
  canvasData.Classes.map((item) => {
    if (item.Assignments) {
      assignments.push(...item.Assignments);
    }
  });

  let dataToReturn = {
    ToDoTasks: Array<ToDoItem>(),
    UpcomingTasks: Array<ToDoItem>(),
    CompletedTasks: Array<ToDoItem>(),
  };
  if (!assignments) return dataToReturn;

  assignments.map((canvasAssignment) => {
    let todoItem = {} as ToDoItem;
    todoItem.Id = canvasAssignment.id;
    todoItem.Name = canvasAssignment.name;
    todoItem.DataType = ToDoTypes.Assignment;
    if (canvasAssignment.html_url) {
      todoItem.ButtonText = "Go To Assignment";
      todoItem.ButtonUrl = canvasAssignment.html_url;
    }

    if (canvasAssignment.has_submitted_submissions === true) {
      todoItem.IsCompleted = true;
      todoItem.CardType = "recommended";
      dataToReturn.CompletedTasks.push(todoItem);
    } else {
      if (canvasAssignment.due_at) {
        let oneWeekAway = new Date(today);
        oneWeekAway.setDate(today.getDate() + 7);

        let twoDaysAway = new Date(today);
        twoDaysAway.setDate(today.getDate() + 3);

        const assignmentDate = new Date(canvasAssignment.due_at);

        if (assignmentDate.toISOString() >= oneWeekAway.toISOString()) {
          todoItem.CardType = "recommended";
          dataToReturn.UpcomingTasks.push(todoItem);
        } else if (assignmentDate.toISOString() >= twoDaysAway.toISOString()) {
          todoItem.CardType = "upcoming";
          dataToReturn.ToDoTasks.push(todoItem);
        } else if (assignmentDate.toISOString() <= today.toISOString()) {
          todoItem.CardType = "";
          dataToReturn.ToDoTasks.push(todoItem);
        }
      }
      todoItem.IsCompleted = false;
    }
  });

  return dataToReturn;
};
