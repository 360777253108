import ReactFocusLock from "react-focus-lock";
import React, { useEffect, useRef, useState } from "react";
import { NintexTaskStep } from "types/integrations";

interface Props {
  NintexTaskStep: NintexTaskStep;
}

export default function TaskModal({ NintexTaskStep }: Props) {
  const [modalStatus, setModalStatus] = useState(false);
  const [dialogStyles, setDialogStyles] = useState({});
  const closeRef = useRef(null);
  const toggleRef = useRef(null) as
    | React.MutableRefObject<HTMLButtonElement>
    | React.MutableRefObject<null>;
  const modalRef = useRef(null) as
    | React.MutableRefObject<HTMLDivElement>
    | React.MutableRefObject<null>;

  const onEscapeKey = (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      const target = e.target;
      if (target === closeRef.current) {
        setModalStatus(false);
      }
    }
  };

  // position the dialog arrow below the 3 dots toggle button
  const showModalDialog = (e: any) => {
    const bounds = (
      toggleRef as React.MutableRefObject<HTMLButtonElement>
    ).current.getBoundingClientRect();
    const topBounds = bounds.top;
    const leftBounds = bounds.left;
    const height = (toggleRef as React.MutableRefObject<HTMLButtonElement>)
      .current.offsetHeight;
    const dialogWidth = parseInt(
      getComputedStyle(
        (modalRef as React.MutableRefObject<HTMLDivElement>).current
      ).getPropertyValue("width")
    );
    setDialogStyles({
      top: topBounds + height + 20 + "px",
      left: leftBounds - dialogWidth + 30 + "px",
    });
    setModalStatus(!modalStatus);
  };

  useEffect(() => {
    if (!modalStatus) {
      (toggleRef as React.MutableRefObject<HTMLButtonElement>).current.focus();
    }
  }, [modalStatus]);

  useEffect(() => {
    document.addEventListener("keyup", onEscapeKey);
    return () => {
      document.removeEventListener("keyup", onEscapeKey);
    };
  }, []);
  return (
    <div className="task-modal">
      <button
        type="button"
        className="task-modal__toggle"
        onClick={showModalDialog}
        aria-expanded={modalStatus}
        ref={toggleRef}
      >
        <span></span>
      </button>
      <ReactFocusLock disabled={!modalStatus}>
        <div
          role="dialog"
          className={`task-modal__dialog ${modalStatus ? "active" : ""}`}
          style={dialogStyles}
          ref={modalRef}
        >
          <button
            type="button"
            className="task-modal__close"
            aria-label="close modal"
            onClick={() => setModalStatus(false)}
            ref={closeRef}
          ></button>
          <h3 className="task-modal__heading">Steps:</h3>
          <ul className="task-modal__list">
            {NintexTaskStep.steps.map((item, index) => (
              <li className="task-modal__item task-modal__item--active">
                {index + 1}: {item}
                {NintexTaskStep.current && (
                  <svg
                    className={
                      index + 1 < NintexTaskStep.current
                        ? "task-modal__icon-complete"
                        : "task-modal__icon-incomplete"
                    }
                    aria-hidden="true"
                  >
                    <use href="#check-circle"></use>
                  </svg>
                )}
              </li>
            ))}
          </ul>
          {NintexTaskStep.text && (
            <>
              <h3 className="task-modal__heading">What's Next:</h3>
              <p>{NintexTaskStep.text}</p>
            </>
          )}
        </div>
      </ReactFocusLock>
    </div>
  );
}
