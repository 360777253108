import { useHttp } from "hooks/useHttp";
import React, { useContext, useEffect, useState } from "react";
import { HttpResponse } from "types/http";
import {
  PeoplesoftOverallBalanceResponse,
  PeopleSoftStudentProfile as PeopleSoftStudentProfileApiResponse,
  PeoplesoftStudentFinancialAidApiResponse,
  PeoplesoftStudentFinancialsApiResponse,
  PeoplesoftStudentTermsApiResponse,
} from "types/integrations";
import { getCurrentTermFromActiveTerms } from "utilities/student";
import {
  getPeopleSoftUserTermsUrl,
  getPeopleSoftStudentFinancials,
  getPeopleSoftFinancialAidUrl,
  getPeopleSoftUserProfileWithAvatarUrl,
  getPeopleSoftStudentOverallBalance,
} from "utilities/urls";

interface Props {
  children: React.ReactNode;
}

export interface IStudentInfo {
  studentTerms: HttpResponse<PeoplesoftStudentTermsApiResponse>;
  studentFinancials: HttpResponse<PeoplesoftStudentFinancialsApiResponse>;
  studentFinancialAid: HttpResponse<PeoplesoftStudentFinancialAidApiResponse>;
  overallBalance: HttpResponse<PeoplesoftOverallBalanceResponse>;
  currentTermNumber: string | undefined;
  currentTermName: string;
  setSelectedTermNumber: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  setSelectedTermName: React.Dispatch<React.SetStateAction<string | undefined>>;
  selectedTermName: string;
  studentProfile: HttpResponse<PeopleSoftStudentProfileApiResponse>;
  financialAidDisbursementDate: Date | undefined;
}

export interface IStudentInfoContext extends IStudentInfo {}

const defaultState: IStudentInfo = {
  currentTermNumber: undefined,
  currentTermName: "",
  financialAidDisbursementDate: undefined,
  setSelectedTermNumber: {} as React.Dispatch<
    React.SetStateAction<string | undefined>
  >,
  setSelectedTermName: {} as React.Dispatch<
    React.SetStateAction<string | undefined>
  >,
  selectedTermName: "",
  studentTerms: {} as HttpResponse<PeoplesoftStudentTermsApiResponse>,
  studentFinancials: {} as HttpResponse<PeoplesoftStudentFinancialsApiResponse>,
  overallBalance: {} as HttpResponse<PeoplesoftOverallBalanceResponse>,
  studentFinancialAid:
    {} as HttpResponse<PeoplesoftStudentFinancialAidApiResponse>,
  studentProfile: {} as HttpResponse<PeopleSoftStudentProfileApiResponse>,
};

const defaultContext: IStudentInfoContext = {
  ...defaultState,
};

export const StudentContext =
  React.createContext<IStudentInfoContext>(defaultContext);

export const StudentProvider: React.FC<Props> = ({ children }) => {
  const [currentTermNumber, setCurrentTermNumber] = useState(
    defaultState.currentTermNumber
  );
  const [currentTermName, setCurrentTermName] = useState(
    defaultState.currentTermName
  );
  const [selectedTermNumber, setSelectedTermNumber] = useState<
    string | undefined
  >(undefined);
  const [selectedTermName, setSelectedTermName] = useState<string | undefined>(
    undefined
  );
  const [financialAidDisbursementDate, setFinancialAidDisbursementDate] =
    useState(defaultState.financialAidDisbursementDate);

  const studentTermsResponse = useHttp<PeoplesoftStudentTermsApiResponse>(
    "get",
    getPeopleSoftUserTermsUrl()
  );

  useEffect(() => {
    if (studentTermsResponse.data && studentTermsResponse.data.ActiveTerms) {
      const termData = getCurrentTermFromActiveTerms(
        studentTermsResponse.data.ActiveTerms
      );
      setCurrentTermNumber(termData.value);
      setCurrentTermName(termData.currentTerm);
    }
  }, [studentTermsResponse]);

  const studentFinancialsResponse =
    useHttp<PeoplesoftStudentFinancialsApiResponse>(
      "get",
      getPeopleSoftStudentFinancials(selectedTermNumber)
    );

  const overallBalanceResponse = useHttp<PeoplesoftOverallBalanceResponse>(
    "get",
    getPeopleSoftStudentOverallBalance()
  );
  const studentProfileResponse = useHttp<PeopleSoftStudentProfileApiResponse>(
    "get",
    getPeopleSoftUserProfileWithAvatarUrl()
  );
  const studentFinancialAidResponse =
    useHttp<PeoplesoftStudentFinancialAidApiResponse>(
      "get",
      getPeopleSoftFinancialAidUrl()
    );

  useEffect(() => {
    if (studentFinancialAidResponse.data && currentTermNumber !== "0000") {
      // const activeTermDisbursementString =
      //   studentFinancialAidResponse.data?.DisbursementDates?.find(
      //     (aid) => aid.Term === currentTermNumber
      //   )?.["Grant-Scholarship"];
      // if (activeTermDisbursementString) {
      //   const newActiveDisbursementDate = new Date(
      //     activeTermDisbursementString.replace("-", "/")
      //   );
      //   if (
      //     !financialAidDisbursementDate ||
      //     newActiveDisbursementDate.toISOString() !==
      //       new Date(financialAidDisbursementDate).toISOString()
      //   ) {
      //     setFinancialAidDisbursementDate(newActiveDisbursementDate);
      //   }
      // } else {
      //   setFinancialAidDisbursementDate(undefined);
      // }
    }
  }, [studentFinancialAidResponse.data, currentTermNumber]);

  return (
    <StudentContext.Provider
      value={{
        setSelectedTermNumber:
          setSelectedTermNumber ?? defaultContext.setSelectedTermNumber,
        setSelectedTermName:
          setSelectedTermName ?? defaultContext.setSelectedTermName,
        selectedTermName: selectedTermName ?? defaultContext.selectedTermName,
        currentTermNumber:
          currentTermNumber ?? defaultContext.currentTermNumber,
        currentTermName: currentTermName ?? defaultContext.currentTermName,
        studentTerms: studentTermsResponse ?? defaultContext.studentTerms,
        overallBalance: overallBalanceResponse ?? defaultContext.overallBalance,
        studentFinancials:
          studentFinancialsResponse ?? defaultContext.studentFinancials,
        studentFinancialAid:
          studentFinancialAidResponse ?? defaultContext.studentFinancialAid,
        financialAidDisbursementDate:
          financialAidDisbursementDate ??
          defaultContext.financialAidDisbursementDate,
        studentProfile: studentProfileResponse ?? defaultContext.studentProfile,
      }}
    >
      {children}
    </StudentContext.Provider>
  );
};

export const useStudentContext = () => useContext(StudentContext);
