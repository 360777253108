export const parseDate = (date: Date, type: string) => {
  date = new Date(date);
  const time = date.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  switch (type) {
    case "dayNumber":
      const dayNumber = date.getDate();
      return dayNumber;
    case "day":
      const dayName = date.toLocaleString("en-US", { weekday: "short" });
      return dayName;
    case "monthAndDay":
      const monthAndDay = date.getMonth() + 1 + "/" + date.getDate();
      return monthAndDay;
    case "month":
      const month = date.toLocaleString("en-US", { month: "long" });
      return month;
    case "monthShort":
      const monthShort = date.toLocaleString("en-US", { month: "short" });
      return monthShort;
    case "startTime":
      return time;
    case "endTime":
      return time;
    case "fullDate":
      const formattedDate = date.toLocaleString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      });
      return formattedDate;
    default:
      return "";
  }
};

export const formatDate = (date: Date) => {
  return Intl.DateTimeFormat("en-US").format(date);
};

export const parseDateForGmail = (today: Date, date?: Date) => {
  let textToReturn = "";
  if (!date) return textToReturn;

  const todayFormatted = new Date(today);
  const dateFormatted = new Date(date);

  // Same year
  if (todayFormatted.getFullYear() === dateFormatted.getFullYear()) {
    // Same month
    if (todayFormatted.getMonth() === dateFormatted.getMonth()) {
      // Same day
      if (todayFormatted.getDate() === dateFormatted.getDate()) {
        textToReturn = parseDate(dateFormatted, "startTime").toString();
        // Yesterday
      } else if (isYesterday(todayFormatted, dateFormatted)) {
        textToReturn = "Yesterday";
        // Further than yesterday
      } else {
        textToReturn = formatDate(dateFormatted);
      }
      // Yesterday from Last month
    } else {
      if (
        isLastMonth(todayFormatted, dateFormatted) &&
        isYesterday(todayFormatted, dateFormatted)
      ) {
        textToReturn = "Yesterday";
      } else {
        textToReturn = formatDate(dateFormatted);
      }
    }
  } else {
    // Yesterday from Last month
    if (
      isLastMonth(todayFormatted, dateFormatted) &&
      isYesterday(todayFormatted, dateFormatted)
    ) {
      textToReturn = "Yesterday";
      // Further than yesterday
    } else {
      textToReturn = formatDate(dateFormatted);
    }
  }

  return textToReturn;
};

const isLastMonth = (today: Date, date: Date) => {
  let dateFormatted = new Date(date);
  let todayFormatted = new Date(today);
  if (
    dateFormatted.getMonth() ===
    new Date(todayFormatted.setMonth(todayFormatted.getMonth() - 1)).getMonth()
  )
    return true;

  return false;
};
const isYesterday = (today: Date, date: Date) => {
  let dateFormatted = new Date(date);
  let todayFormatted = new Date(today);
  if (
    dateFormatted.getDate() ===
    new Date(todayFormatted.setDate(todayFormatted.getDate() - 1)).getDate()
  )
    return true;

  return false;
};
