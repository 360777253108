import ReactDOM from "react-dom";
import { useMemo } from "react";
import { useStudentContext } from "context/student/student-context";
import { useDivInjector } from "hooks/useDivInjector";
import { getPrimaryAndAliasNames } from "components/studentProfile/utilities";
import ErrorComponent from "components/common/errorComponent";
import { BusyIndicator } from "components/busyIndicator/busy-indicator";
import { useUserRoles } from "context/user-roles/user-roles-context";
import { FUNCTIONALITY_AVAILABLE_TO_STUDENTS_ONLY } from "context/common/constant";

export default function ProfileSidebar() {
  useDivInjector("portal-profile-sidebar");
  const { studentProfile } = useStudentContext();
  const { data, error, isLoading } = studentProfile;
  const { isStudent } = useUserRoles();
  const container = document.getElementById(
    "portal-profile-sidebar"
  ) as HTMLElement;
  const { firstName } = useMemo(
    () => getPrimaryAndAliasNames(data?.Names),
    [data?.Names]
  );
  return (
    container &&
    ReactDOM.createPortal(
      <div className="profile">
        {!isStudent ? (
          <>{FUNCTIONALITY_AVAILABLE_TO_STUDENTS_ONLY}</>
        ) : isLoading ? (
          <BusyIndicator />
        ) : error ? (
          <div className="profile__content">
            <ErrorComponent
              message={error.message ?? "An unknown error has occured"}
            />
          </div>
        ) : (
          data && (
            <>
              <div className="profile__media">
                <img
                  src={
                    data.AvatarUrl
                      ? data.AvatarUrl
                      : `/images/default-source/default-album/profile-pic.png`
                  }
                  className="profile__img"
                  alt={"Student Image"}
                />
              </div>
              <div className="profile__content">
                <h2 className="profile__title">Hello, {firstName}</h2>
                {data.Programs && data.Programs.length > 0 && (
                  <>
                    <div className="profile__program">
                      Program: {data.Programs[0].Description}
                    </div>
                    <div className="profile__program">
                      Plan: {data.Programs[0].Plan}
                    </div>
                    {data.Programs[0].SubPlan && (
                      <div className="profile__program">
                        Sub Plan: {data?.Programs[0].SubPlan}
                      </div>
                    )}
                  </>
                )}
                <a href="/profile" className="profile__view">
                  View Profile
                </a>
              </div>
            </>
          )
        )}
      </div>,
      container
    )
  );
}
