import React from "react";
import ReactDOM from "react-dom";
import CardContainer from "../../components/containers/card-container";
import { useUserRoles } from "context/user-roles/user-roles-context";
import { ImpersonationUnavailable } from "components/impersonation-unavailable/impersonation-unavailable";
import { useStudentContext } from "context/student/student-context";
import ErrorComponent from "components/common/errorComponent";
import { BusyIndicator } from "components/busyIndicator/busy-indicator";
import { CalendarItemDisplayTypes } from "types/calendar";
import { mutateFinancialAidToDo } from "utilities/integrations";
import ToDoItemCard from "components/todos/todo-card";
import { FUNCTIONALITY_AVAILABLE_TO_STUDENTS_ONLY } from "context/common/constant";

export default function FinancialTasks() {
  const container = document.getElementById("financial-tasks") as HTMLElement;

  const { studentFinancialAid } = useStudentContext();

  const componentHeading = container?.dataset?.heading;
  const { isStudent } = useUserRoles();

  return (
    container &&
    ReactDOM.createPortal(
      <CardContainer title={componentHeading || "Financial To-do's"}>
        {!isStudent ? (
          <>{FUNCTIONALITY_AVAILABLE_TO_STUDENTS_ONLY}</>
        ) : studentFinancialAid.error ? (
          <ErrorComponent message={studentFinancialAid.error.message} />
        ) : studentFinancialAid.isLoading ? (
          <BusyIndicator />
        ) : (
          studentFinancialAid.data && (
            <>
              {studentFinancialAid.data.ToDos.length === 0 ? (
                <div>No to-do's to complete at this time.</div>
              ) : (
                <ul className="list-unstyled">
                  {studentFinancialAid.data.ToDos.slice(0, 3).map((todo) => {
                    return (
                      <ToDoItemCard
                        displayType={CalendarItemDisplayTypes.Date}
                        todoItem={mutateFinancialAidToDo(todo)}
                      />
                    );
                  })}
                </ul>
              )}
              {/* <div className="card-container__section">
                <Link
                  url={"/dashboard/calendar"}
                  label={"View More"}
                  type="arrow"
                ></Link>
              </div> */}
            </>
          )
        )}
      </CardContainer>,
      container
    )
  );
}
