import { useHttp } from "hooks/useHttp";
import React, { useContext, useState } from "react";
import { HttpResponseWithMutator } from "types/http";
import {
  TwentyFiveLiveCalendarTag,
  TwentyFiveLiveEvent,
} from "types/integrations";
import { getCalendarActiveDateRange } from "utilities/calendar";
import {
  get25LiveCalendarTagsFromSitefinityUrl,
  get25LiveEventsFromSitefinityUrl,
} from "utilities/urls";

interface Props {
  children: React.ReactNode;
}

export interface IEvents {
  eventTagResponse: HttpResponseWithMutator<TwentyFiveLiveCalendarTag>;
  twentyFiveEventsResponse: HttpResponseWithMutator<TwentyFiveLiveEvent[]>;
  setRequestedEventTagId: React.Dispatch<React.SetStateAction<string>>;
}

export interface IEventsContext extends IEvents {}

const defaultState: IEvents = {
  eventTagResponse: {} as HttpResponseWithMutator<TwentyFiveLiveCalendarTag>,
  twentyFiveEventsResponse: {} as HttpResponseWithMutator<
    TwentyFiveLiveEvent[]
  >,
  setRequestedEventTagId: () => {},
};

const defaultContext: IEventsContext = {
  ...defaultState,
};

export const EventsContext =
  React.createContext<IEventsContext>(defaultContext);

export const EventsProvider: React.FC<Props> = ({ children }) => {
  const [requestedCalendarTag, setRequestedEventTagId] = useState("null");
  const today = new Date();
  const eventDateRange = getCalendarActiveDateRange(today);
  const eventsTagResponse = useHttp<TwentyFiveLiveCalendarTag>(
    "get",
    get25LiveCalendarTagsFromSitefinityUrl(requestedCalendarTag)
  );
  const twentyFiveEventsResponse = useHttp<TwentyFiveLiveEvent[]>(
    "get",
    get25LiveEventsFromSitefinityUrl(
      // TODO: Verify that this date range is correct (-6 Months and + 12 Months)
      eventDateRange.startDate,
      eventDateRange.endDate
    ),
    true
  );

  return (
    <EventsContext.Provider
      value={{
        eventTagResponse: eventsTagResponse ?? defaultContext.eventTagResponse,
        twentyFiveEventsResponse:
          twentyFiveEventsResponse ?? defaultContext.twentyFiveEventsResponse,
        setRequestedEventTagId:
          setRequestedEventTagId ?? defaultContext.setRequestedEventTagId,
      }}
    >
      {children}
    </EventsContext.Provider>
  );
};

export const useEventsContext = () => useContext(EventsContext);
