import { StudentOrganization } from "../../types/student";
import { StudentOrganizationListingCard } from "./student-organization-listing-card";
interface Props {
  data: StudentOrganization[];
  type: string;
  displayType: string;
  url: string;
}

export default function StudentOrganizationsListing({
  data,
  displayType,
  type,
  url,
}: Props) {
  return (
    <div className="tools-listing__main">
      <ul className="tools-listing__list">
        {data.map((org) => {
          return (
            <StudentOrganizationListingCard
              data={org}
              type={type}
              displayType={displayType}
              url={url}
              key={org.Id}
            />
          );
        })}
      </ul>
    </div>
  );
}
