import { ActionLinkDataModel, Term } from "types/data-models";
import {
  Hold,
  StudentClass,
  StudentClassShoppingCartItem,
} from "types/integrations";

interface Props {
  selectedTerm: Term;
  classesInShoppingCart: StudentClassShoppingCartItem[];
  classesEnrolled: StudentClass[];
  priorityRegistrationDate: string;
  studentHolds: Hold[];
  openEnrollmentDate: string;
  staticActionLinks: { [key: string]: string };
  today: Date;
}

export default function ClassesScheduleAlert({
  selectedTerm,
  classesInShoppingCart,
  classesEnrolled,
  priorityRegistrationDate,
  studentHolds,
  openEnrollmentDate,
  staticActionLinks,
  today,
}: Props) {
  const holdsForSelectedTerm = studentHolds.filter(
    (hold) => hold.Term === selectedTerm.value
  );
  const areAnyHolds = holdsForSelectedTerm.length > 0;
  const areAnyClassesInCart = classesInShoppingCart.length > 0;
  const areAnyClassesEnrolled = classesEnrolled.length > 0;
  const todayDateIsoString = today.toISOString();
  const registrationDateIsoString = priorityRegistrationDate
    ? new Date(priorityRegistrationDate).toISOString()
    : openEnrollmentDate
    ? new Date(openEnrollmentDate).toISOString()
    : undefined; // Open Enrollment is fallback if Priority registration is undefined
  const isRegistrationDateInThePast = registrationDateIsoString
    ? todayDateIsoString > registrationDateIsoString
    : true;

  return (
    <>
      {areAnyHolds === false &&
      areAnyClassesEnrolled === true &&
      areAnyClassesInCart === false ? (
        <></>
      ) : (
        <div className="schedule-alert">
          <h3 className="schedule-alert__heading">
            {areAnyHolds
              ? "Holds"
              : areAnyClassesInCart
              ? "Registration Incomplete"
              : ""}
          </h3>
          <p className="schedule-alert__message">
            {areAnyHolds
              ? holdsForSelectedTerm.map((hold) => (
                  <>
                    <b> {hold.Reason.Description} </b>
                    <br /> {hold.Description} <br />
                    <br />
                  </>
                ))
              : areAnyClassesInCart
              ? "You need to complete the enrollment process to complete registration."
              : ""}
          </p>
          {areAnyHolds ? (
            <>
              <a
                href={staticActionLinks["viewHoldsActionLink"]}
                className="schedule-alert__link button button--solid"
                target="_blank"
              >
                View Holds List
              </a>
              <br /> <br />
            </>
          ) : (
            areAnyClassesInCart &&
            isRegistrationDateInThePast && (
              <>
                <a
                  href={staticActionLinks["enrollActionLink"]}
                  className="schedule-alert__link button button--solid"
                  target="_blank"
                >
                  Enroll
                </a>
                <br /> <br />
              </>
            )
          )}
          {!isRegistrationDateInThePast &&
            (priorityRegistrationDate !== "" ? (
              <p className="schedule-alert__message">
                Your Priority Registration Date is{" "}
                <b>{priorityRegistrationDate}</b>. Register on or after that
                date.
              </p>
            ) : (
              openEnrollmentDate !== "" && (
                <p className="schedule-alert__message">
                  Open Enrollment starts on <b>{openEnrollmentDate}</b>
                </p>
              )
            ))}
          {!areAnyClassesInCart && !areAnyClassesEnrolled && (
            <>
              <br />
              <b className="schedule-alert__message">
                You have not selected any classes for this term.
              </b>
              <br />
              <a
                href={staticActionLinks["searchClassesActionLink"]}
                className="button class-schedule__button"
                target="_blank"
              >
                Search For Classes
              </a>
            </>
          )}
        </div>
      )}
    </>
  );
}
