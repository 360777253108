import PortalTools from "./portals/tools/tools-listing";
import Svgs from "./components/svgs/svg";
import FavoriteTools from "./portals/tools/tools-favorites";
import InstructorDetail from "./portals/instructor/instructor-detail";
import ClassSchedule from "./portals/classes/schedule";
import ResourceListing from "./portals/resources/resource-listing";
import Todos from "./portals/tasks/todo";
import CalendarFeatured from "./portals/calendar/calendar-featured";
import CalendarListing from "./portals/calendar/calendar-view";
import FacultyTabs from "./portals/faculty/faculty-tabs";
import FinancialTasks from "./portals/financials/financial-tasks";
import ClassCards from "./portals/classes/card-listing";
import FeaturedEvents from "./portals/events/featured-events";
import EventsListing from "portals/events/event-list";
import ImpersonateRoles from "portals/impersonate/impersonate-role";
import EventDetail from "./portals/events/event-detail";
import OrganizationEvents from "./portals/events/organization-events";
import StudentOrganizations from "./portals/student-organizations/student-organization-list";
import StudentOrganizationDetail from "./portals/student-organizations/student-organization-detail";
import StudentOrganizationFavorites from "./portals/student-organizations/student-organization-favorites";
import { FavoriteResources } from "portals/resources/favorite-resources";
import { StudentOrganizationProvider } from "context/student-organizations/student-organizations-context";
import { StudentProvider } from "context/student/student-context";
import { useUserRoles } from "context/user-roles/user-roles-context";
import { ResourcesContextProvider } from "context/resources/resources-context";
import { ToolsContextProvider } from "context/tools/tools-context";
import { StudentAcademicPlanProvider } from "context/student/student-academic-plan-context";
import ResourceFavoriteButton from "portals/resources/resource-favorite-button";
import { EventsProvider } from "context/events/events-context";
import { WindowLocationObserver } from "components/windowLocationObserver/windowLocationObserver";
import GoogleInbox from "portals/inbox/google-inbox";
import ProfileSidebar from "portals/profile/sidebar";
import ProfileDetailAddress from "portals/profile/detail/address";
import ProfileDetailPersonal from "portals/profile/detail/personal";
import ProfileDetailTuition from "portals/profile/detail/tuition";
import ProfileDetailContact from "portals/profile/detail/contact";
import FinancialCardAmountDue from "portals/financials/financial-card-amount-due";
import FinancialCardFinancialAid from "portals/financials/financial-card-financial-aid";

const organizationEvents = document.getElementById("organization-events");
const studentOrganizationsList = document.getElementById(
  "student-organization-list"
);
const studentOrganizationDetail = document.getElementById(
  "student-organization-detail"
);
const studentOrganizationFavorites = document.getElementById(
  "student-organization-favorites"
);
const impersonatorRoles = document.getElementById("impersonate-roles");
const eventDetail = document.getElementById("event-detail");
const featuredEvents = document.getElementById("featured-events");
const eventsList = document.getElementById("events-list");
const facultyTabs = document.getElementById("faculty-tabs");
const calendarView = document.getElementById("calendar-view");
const calendarFeatured = document.getElementById("calendar-featured");
const instructorDetail = document.getElementById("instructor-details");
const googleInbox = document.getElementById("google-inbox");
const resourceListing = document.getElementById("resource-listing");
const todoApp = document.getElementById("todo-tabs");
const portalTools = document.getElementById("portal-tools");
const resourceFavoriteButton = document.getElementById(
  "resource-favorite-button"
);
const favoriteTools = document.getElementById("favorite-tools");
const favoriteResources = document.getElementById("favorite-resources");
const profileSidebar = document.getElementById("portal-profile-sidebar");
const profileDetailAddress = document.getElementById("portal-detail-address");
const profileDetailContact = document.getElementById("portal-detail-contact");
const profileDetailPersonal = document.getElementById("portal-detail-personal");
const profileDetailTuition = document.getElementById("portal-detail-tuition");

const classSchedule = document.getElementById("class-schedule");
const classCards = document.getElementById("class-cards");
const financialTasks = document.getElementById("financial-tasks");
const financialCardFinancialAid = document.getElementById(
  "financial-card-financial-aid"
);
const financialCardAmountDue = document.getElementById(
  "financial-card-amount-due"
);
if (process.env.NODE_ENV === "development") {
  require("talon-sitefinity/assets/dist/css/critical.min.css");
  require("talon-sitefinity/assets/dist/css/main.min.css");
}

const App = () => {
  const { impersonator } = useUserRoles();

  return (
    <>
      <Svgs />
      <WindowLocationObserver />
      {impersonatorRoles && impersonator && <ImpersonateRoles />}
      {calendarFeatured && <CalendarFeatured />}
      {calendarView && <CalendarListing />}
      {googleInbox && <GoogleInbox />}
      {classSchedule || classCards ? (
        <StudentProvider>
          {classSchedule && <ClassSchedule />}

          {classCards && (
            <StudentAcademicPlanProvider>
              <ClassCards />
            </StudentAcademicPlanProvider>
          )}
        </StudentProvider>
      ) : (
        <></>
      )}

      {facultyTabs && (
        <StudentProvider>
          <FacultyTabs />
        </StudentProvider>
      )}
      {favoriteTools && (
        <ToolsContextProvider>
          <FavoriteTools />
        </ToolsContextProvider>
      )}
      {resourceFavoriteButton && (
        <ResourcesContextProvider>
          <ResourceFavoriteButton />
        </ResourcesContextProvider>
      )}
      {favoriteResources && (
        <ResourcesContextProvider>
          <FavoriteResources />
        </ResourcesContextProvider>
      )}
      {instructorDetail && <InstructorDetail />}
      {portalTools && (
        <ToolsContextProvider>
          <PortalTools />
        </ToolsContextProvider>
      )}
      {profileDetailAddress && (
        <StudentProvider>
          <ProfileDetailAddress />
        </StudentProvider>
      )}
      {profileDetailContact && (
        <StudentProvider>
          <ProfileDetailContact />
        </StudentProvider>
      )}
      {profileDetailPersonal && (
        <StudentProvider>
          <ProfileDetailPersonal />
        </StudentProvider>
      )}
      {profileDetailTuition && (
        <StudentProvider>
          <ProfileDetailTuition />
        </StudentProvider>
      )}
      {featuredEvents && (
        <EventsProvider>
          <FeaturedEvents />
        </EventsProvider>
      )}
      {eventsList && (
        <EventsProvider>
          <EventsListing />
        </EventsProvider>
      )}
      {eventDetail && (
        <EventsProvider>
          <EventDetail />
        </EventsProvider>
      )}
      {organizationEvents && (
        <StudentOrganizationProvider>
          <EventsProvider>
            <OrganizationEvents />
          </EventsProvider>
        </StudentOrganizationProvider>
      )}
      {studentOrganizationsList && (
        <StudentOrganizationProvider>
          <StudentOrganizations />
        </StudentOrganizationProvider>
      )}
      {studentOrganizationDetail && (
        <StudentOrganizationProvider>
          <StudentOrganizationDetail />
        </StudentOrganizationProvider>
      )}
      {studentOrganizationFavorites && (
        <StudentOrganizationProvider>
          <StudentOrganizationFavorites />
        </StudentOrganizationProvider>
      )}
      {profileSidebar && (
        <StudentProvider>
          <ProfileSidebar />
        </StudentProvider>
      )}
      {resourceListing && (
        <ResourcesContextProvider>
          <ResourceListing />
        </ResourcesContextProvider>
      )}
      {todoApp && (
        <StudentProvider>
          <Todos />
        </StudentProvider>
      )}
      {
        <StudentProvider>
          {financialTasks && <FinancialTasks />}
        </StudentProvider>
      }
      {financialCardAmountDue && (
        <StudentProvider>
          <FinancialCardAmountDue />
        </StudentProvider>
      )}
      {financialCardFinancialAid && (
        <StudentProvider>
          <FinancialCardFinancialAid />
        </StudentProvider>
      )}
    </>
  );
};

export default App;
