import React from "react";
import App from "./App";
import ReactDOM from "react-dom/client";
import { SWRConfig } from "swr";
import { sessionStorageProvider } from "sessionStorage/session-storage-provider";
import { worker } from "mocks/browser";
import { UserRolesProvider } from "context/user-roles/user-roles-context";

const container = document.getElementById("root") as HTMLElement;
const root = ReactDOM.createRoot(container);
if (process.env.NODE_ENV === "development") {
  worker.start();
}
root.render(
  <React.StrictMode>
    <SWRConfig value={{ provider: sessionStorageProvider }}>
      <UserRolesProvider>
        <App />
      </UserRolesProvider>
    </SWRConfig>
  </React.StrictMode>
);
