import { EmailMessage } from "types/integrations";

export const userProfileData = {
  Names: [
    {
      Name: "Tester,Tom T",
      Type: "ALI",
    },
    {
      Name: "Teststudent,Thomas L",
      Type: "PRI",
    },
  ],
  Addresses: [
    {
      Address: "316 56TH ST",
      City: "KENOSHA",
      State: "WI",
      Postal: "53140",
      Type: "HOME",
    },
  ],
  Contacts: {
    Emails: [
      {
        Email: "thomasteststudent1@stu.clcillinois.edu",
        Type: "HOME",
      },
      {
        Email: "thomasteststudent1@stu.clcillinois.edu",
        Type: "STDT",
      },
    ],
    Phones: [
      {
        Phone: "847/543-2060",
        Type: "HOME",
      },
    ],
  },
  TuitionRates: [
    {
      TuitionRate: "OUT-DIST",
      Description: "Out-of-Distict Student",
    },
  ],
  Programs: [
    {
      Program: "TRANS",
      Plan: "11AB",
      Description: "Associate in Science",
    },
  ],
  StudentId: "0052604",
  Success: true,
  errors: {
    validation_errors: {},
  },
};

export const userTermsData = {
  ActiveTerms: [
    {
      Term: "1233",
      Description: "Fall 2023",
      StartDate: "2023-08-21",
      EndDate: "2023-12-15",
      IsCurrentTerm: "N",
    },
    {
      Term: "1252",
      Description: "Summer 2025 - DATES NOT FINAL",
      StartDate: "2025-06-09",
      EndDate: "2025-08-01",
      IsCurrentTerm: "N",
    },
    {
      Term: "1241",
      Description: "Spring 2024",
      StartDate: "2024-01-16",
      EndDate: "2024-05-10",
      IsCurrentTerm: "N",
    },
    {
      Term: "1243",
      Description: "Fall 2024 - DATES NOT FINAL",
      StartDate: "2024-08-19",
      EndDate: "2024-12-15",
      IsCurrentTerm: "N",
    },
    {
      Term: "1232",
      Description: "Summer 2023",
      StartDate: "2023-06-05",
      EndDate: "2023-07-27",
      IsCurrentTerm: "N",
    },
    {
      Term: "1251",
      Description: "Spring 2025 - DATES NOT FINAL",
      StartDate: "2025-01-21",
      EndDate: "2025-05-16",
      IsCurrentTerm: "N",
    },
    {
      Term: "1242",
      Description: "Summer 2024",
      StartDate: "2024-06-03",
      EndDate: "2024-07-25",
      IsCurrentTerm: "N",
    },
    {
      Term: "1231",
      Description: "Spring 2023",
      StartDate: "2023-01-17",
      EndDate: "2023-05-12",
      IsCurrentTerm: "Y",
    },
  ],
  PastTerms: [
    {
      Term: "1122",
      Description: "Summer 2012",
      StartDate: "2012-06-04",
      EndDate: "2012-07-28",
    },
    {
      Term: "1123",
      Description: "Fall 2012",
      StartDate: "2012-08-20",
      EndDate: "2012-12-14",
    },
    {
      Term: "1141",
      Description: "Spring 2014",
      StartDate: "2014-01-21",
      EndDate: "2014-05-16",
    },
    {
      Term: "1143",
      Description: "Fall 2014",
      StartDate: "2014-08-25",
      EndDate: "2014-12-19",
    },
    {
      Term: "1153",
      Description: "Fall 2015",
      StartDate: "2015-08-24",
      EndDate: "2015-12-18",
    },
    {
      Term: "1201",
      Description: "Spring 2020",
      StartDate: "2020-01-21",
      EndDate: "2020-05-15",
    },
  ],
  Success: true,
  errors: {
    validation_errors: {},
  },
};

export const favoriteTools = [
  {
    id: "10d1c254-328d-4912-b744-5e3cfc150ac8",
    type: "Tool",
  },
  {
    id: "5992b0bd-a335-4bce-bbe8-40ce89b7554d",
    type: "Tool",
  },
  {
    id: "0b813b1b-93f0-4fea-b211-20f2697c7627",
    type: "Tool",
  },
  {
    id: "e1d33015-ffed-4500-9139-690660f9cd50",
    type: "Tool",
  },
  {
    id: "8d3e8c8c-ebb4-47cc-a27f-42cd1bf34386",
    type: "Tool",
  },
  {
    id: "9c500593-3365-4e51-b3c9-92d745101f24",
    type: "Tool",
  },
  {
    id: "aabca489-df81-4736-b4bf-64efd28bf62f",
    type: "Tool",
  },
];

export const allTools = [
  {
    Id: "8d3e8c8c-ebb4-47cc-a27f-42cd1bf34386",
    LastModified: "2023-02-01T20:41:08Z",
    Summary:
      '<span style="color:#4d5156;font-family:Roboto, arial, sans-serif;font-size:14px;">Use&nbsp;</span>Google Sheets<span style="color:#4d5156;font-family:Roboto, arial, sans-serif;font-size:14px;">&nbsp;to create and edit online spreadsheets.&nbsp;</span> ',
    Link: "https://docs.google.com/spreadsheets",
    Title: "Google Sheets",
    Image: [
      {
        AlternativeText: "",
        Url: "https://clc-dev.idevdesign.net/images/default-source/callouts/googlesheetslogo.png?sfvrsn=467eed1b_1",
      },
    ],
    tooltypes: [
      {
        Id: "65a04bb8-4a00-4074-9d41-498be469db65",
        Title: "Communication",
      },
    ],
  },
  {
    Id: "10d1c254-328d-4912-b744-5e3cfc150ac8",
    LastModified: "2023-02-01T20:36:55Z",
    Summary:
      '<span style="color:#4d5156;font-family:Roboto, arial, sans-serif;font-size:14px;">Use Google Docs to create, and collaborate on online documents.</span> ',
    Link: "https://docs.google.com\n",
    Title: "Google Docs",
    Image: [
      {
        AlternativeText: "",
        Url: "https://clc-dev.idevdesign.net/images/default-source/callouts/googledocslogo.png?sfvrsn=7cdefdd7_1",
      },
    ],
    tooltypes: [
      {
        Id: "65a04bb8-4a00-4074-9d41-498be469db65",
        Title: "Communication",
      },
    ],
  },
  {
    Id: "9c500593-3365-4e51-b3c9-92d745101f24",
    LastModified: "2023-02-01T20:35:35Z",
    Summary:
      "<span style=\"color:#000000;font-family:'Segoe UI', SegoeUI, 'Helvetica Neue', Helvetica, Arial, sans-serif;font-size:16px;\">Microsoft 365 is home to all your favorite productivity apps and content. </span> ",
    Link: "https://www.office.com/",
    Title: "Microsoft Office 365",
    Image: [
      {
        AlternativeText: "",
        Url: "https://clc-dev.idevdesign.net/images/default-source/callouts/o365logo.png?sfvrsn=e89dbf77_1",
      },
    ],
    tooltypes: [
      {
        Id: "65a04bb8-4a00-4074-9d41-498be469db65",
        Title: "Communication",
      },
    ],
  },
  {
    Id: "aabca489-df81-4736-b4bf-64efd28bf62f",
    LastModified: "2022-06-29T12:17:14Z",
    Summary:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor incididunt ",
    Link: "",
    Title: "Google Forms",
    Image: [
      {
        AlternativeText: "",
        Url: "https://clc-dev.idevdesign.net/images/default-source/callouts/student-government.png?sfvrsn=c1e9aa77_1",
      },
    ],
    tooltypes: [
      {
        Id: "0696daf9-6f32-43c7-b7ff-3f485bb0b9b7",
        Title: "Google Forms",
      },
    ],
  },
  {
    Id: "0b813b1b-93f0-4fea-b211-20f2697c7627",
    LastModified: "2023-01-12T14:05:19Z",
    Summary: "",
    Link: "www.google.com",
    Title: "Close me",
    Image: [],
    tooltypes: [],
  },
  {
    Id: "c225bbd4-3cfe-4d8b-a122-39985add04df",
    LastModified: "2023-02-01T20:42:18Z",
    Summary:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor incididunt ",
    Link: "https://docs.google.com/presentation",
    Title: "Google Slides",
    Image: [
      {
        AlternativeText: "",
        Url: "https://clc-dev.idevdesign.net/images/default-source/callouts/googleslideslogo.png?sfvrsn=9a6156a2_1",
      },
    ],
    tooltypes: [
      {
        Id: "58046016-67e2-4cf7-b3c4-6ef0a7d038af",
        Title: "Google Slides",
      },
    ],
  },
  {
    Id: "5992b0bd-a335-4bce-bbe8-40ce89b7554d",
    LastModified: "2023-02-01T20:38:30Z",
    Summary: "Access your student email account via Gmail.",
    Link: "https://mail.google.com",
    Title: "Gmail",
    Image: [
      {
        AlternativeText: "",
        Url: "https://clc-dev.idevdesign.net/images/default-source/callouts/gmail-logo.png?sfvrsn=6f59fe49_1",
      },
    ],
    tooltypes: [
      {
        Id: "65a04bb8-4a00-4074-9d41-498be469db65",
        Title: "Communication",
      },
    ],
  },
  {
    Id: "e6439664-16d1-435f-94f8-75cd6a399167",
    LastModified: "2023-01-12T20:33:01Z",
    Summary:
      "Straight from 1997 get the latest and greatest from the new internet sensation, Yahoo!",
    Link: "www.yahoo.com",
    Title: "Yahoo!",
    Image: [
      {
        AlternativeText: "",
        Url: "https://clc-dev.idevdesign.net/images/default-source/callouts/color-yahoo-logo.jpg?sfvrsn=f07ddc87_1",
      },
    ],
    tooltypes: [
      {
        Id: "65a04bb8-4a00-4074-9d41-498be469db65",
        Title: "Communication",
      },
    ],
  },
  {
    Id: "1a1892df-9541-4507-bd3c-af62088c1b54",
    LastModified: "2023-01-09T15:23:10Z",
    Summary: "",
    Link: "Test LInk",
    Title: "Test Name",
    Image: [],
    tooltypes: [],
  },
  {
    Id: "e1d33015-ffed-4500-9139-690660f9cd50",
    LastModified: "2023-01-12T14:11:18Z",
    Summary: "",
    Link: "te",
    Title: "Close me 2",
    Image: [],
    tooltypes: [],
  },
];

export const studentOrganizationListings = {
  "@odata.context":
    "https://clc-dev.idevdesign.net/sfapi/default/$metadata#organizations(Image(Url,AlternativeText),Instructors(Id,WorkPhone,Name,Email))",
  value: [
    {
      Id: "dda37620-27c7-486b-8a0a-442d860c40c4",
      LastModified: "2023-03-08T14:00:03Z",
      PublicationDate: "2022-12-15T14:25:31Z",
      DateCreated: "2022-12-15T14:25:31Z",
      IncludeInSitemap: true,
      UrlName: "test-organization-1",
      ItemDefaultUrl: "/test-organization-1",
      InstagramURL: "https://www.instagram.com/",
      FacebookURL: "https://www.facebook.com/",
      Officers:
        '[{"Id":"19eb9f98-92bf-4624-994f-d192bec182dc","JobTitle":"Officer Job Title","Name":"Officer Name","Email":"Officer Email"}]',
      Title: "Test Organization 1",
      Content:
        "<span style=\"color:#000000;font-family:'Open Sans', Arial, sans-serif;font-size:14px;text-align:justify;\">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum bibendum lacus nibh, venenatis volutpat metus ullamcorper nec. Donec ac odio facilisis, malesuada lorem eu, fermentum dui. Quisque dictum arcu a metus porttitor, eu venenatis augue laoreet. Curabitur id sem pharetra, commodo ante sit amet, facilisis felis. Nam quam enim, viverra nec commodo non, rhoncus id ex. Mauris lacinia tempor erat vel blandit. Fusce vel aliquam dui. Nulla volutpat arcu id lectus viverra, non feugiat justo vehicula. Aenean sodales, sem non porta imperdiet, ipsum est mattis ante, non commodo nulla leo accumsan dolor. Sed consectetur risus vitae risus posuere, vitae lacinia est bibendum. In at lectus ipsum. Sed iaculis commodo orci ac pharetra. Aenean sed malesuada mauris, sed vehicula nisi. Nullam ac gravida urna, eu viverra quam.</span>",
      TwitterURL: "https://twitter.com/",
      Provider: "OpenAccessProvider",
      Image: [
        {
          AlternativeText: "thumbnail",
          Url: "https://clc-dev.idevdesign.net/images/default-source/callouts/thumbnail.png?sfvrsn=c87430d4_1",
        },
      ],
      Staff: [
        {
          Id: "2ce3cbb5-6cef-4cc7-8e02-5b9104efa5af",
          Email: "fakeemail@gmail.com",
          WorkPhone: "3131313131",
          Name: "Erickson, Joyce",
        },
        {
          Id: "0117c307-dd0c-4d8a-afa3-769de45e4003",
          Email: "mshallcross@clcillinois.edu",
          WorkPhone: "",
          Name: "Shallcross, Michael",
        },
        {
          Id: "2186000f-82f3-49da-929a-e55dd60aeaa7",
          Email: "mbonine@clcillinois.edu",
          WorkPhone: "847/543-2934",
          Name: "Bonine, Mary",
        },
      ],
    },
    {
      Id: "eee19504-6d4f-46ef-ad0f-779bdea554cb",
      LastModified: "2023-02-27T01:51:33Z",
      PublicationDate: "2023-02-22T16:18:20Z",
      DateCreated: "2023-02-22T16:18:20Z",
      IncludeInSitemap: true,
      UrlName: "student-programming",
      ItemDefaultUrl: "/student-programming",
      InstagramURL: "dum",
      FacebookURL: "dum",
      Officers: "",
      Title: "Student Programming",
      Content:
        "<span style=\"color:#262729;font-family:'Open Sans', Arial, sans-serif;font-size:16px;\">Shares knowledge and expertise of computer programming and provides professional networking opportunities for students and information about career opportunities.</span>",
      TwitterURL: "dumur",
      Provider: "OpenAccessProvider",
      Image: [
        {
          AlternativeText: "",
          Url: "https://clc-dev.idevdesign.net/images/default-source/callouts/color-yahoo-logo.jpg?sfvrsn=f07ddc87_1",
        },
      ],
      Instructors: [],
    },
    {
      Id: "6960913f-9005-40d2-b78d-a6888a7ba303",
      LastModified: "2023-02-22T16:19:25Z",
      PublicationDate: "2023-02-22T16:19:25Z",
      DateCreated: "2023-02-22T16:19:25Z",
      IncludeInSitemap: true,
      UrlName: "student-interview-prep",
      ItemDefaultUrl: "/student-interview-prep",
      InstagramURL: "",
      FacebookURL: "",
      Officers: "",
      Title: "Student Interview Prep",
      Content:
        "Club for Mock interviews with other students to prepare for the industry",
      TwitterURL: "",
      Provider: "OpenAccessProvider",
      Image: [
        {
          AlternativeText: "",
          Url: "https://clc-dev.idevdesign.net/images/default-source/callouts/gmail-logo.png?sfvrsn=6f59fe49_1",
        },
      ],
      Instructors: [],
    },
    {
      Id: "ecc391f1-a16c-4749-a69b-1c1d6419dbbe",
      LastModified: "2023-02-21T16:30:04Z",
      PublicationDate: "2023-02-21T16:30:04Z",
      DateCreated: "2023-02-21T16:30:04Z",
      IncludeInSitemap: true,
      UrlName: "test-organization-2",
      ItemDefaultUrl: "/test-organization-2",
      InstagramURL: "",
      FacebookURL: "",
      Officers: "",
      Title: "Test Organization 2",
      Content: "This is just a test organization 2",
      TwitterURL: "",
      Provider: "OpenAccessProvider",
      Image: [
        {
          AlternativeText: "Organization Image",
          Url: "https://clc-dev.idevdesign.net/images/default-source/callouts/family-organization.jpg?sfvrsn=8b7f557a_1",
        },
      ],
      Instructors: [],
    },
  ],
};

export const studentOrganizationFavrites = [
  {
    id: "6960913f-9005-40d2-b78d-a6888a7ba303",
    type: "Organization",
  },
  {
    id: "eee19504-6d4f-46ef-ad0f-779bdea554cb",
    type: "Organization",
  },
];

export const instructorDetails = {
  "@odata.context":
    "https://clc-dev.idevdesign.net/sfapi/default/$metadata#instructors(Photo(),PrimaryVideo(),RelatedVideos())/$entity",
  Id: "2ce3cbb5-6cef-4cc7-8e02-5b9104efa5af",
  LastModified: "2023-03-02T16:47:53Z",
  PublicationDate: "2023-02-28T01:29:40Z",
  DateCreated: "2023-02-28T01:29:40Z",
  IncludeInSitemap: true,
  UrlName: "Instructorjerickson",
  ItemDefaultUrl: "/Instructorjerickson",
  CellPhone: "3131313131",
  JobTitle: "Department Chair | Instructor, Engineering",
  Links: "https://www.google.com",
  Question5Answer: "",
  Question4Answer: "",
  subjects: [
    "1b40953e-f950-46cd-994c-96febff117fe",
    "036346bf-a906-4ac3-8aeb-5927e9159c38",
    "1c9c70dc-d97b-4353-8adf-3969510f87c4",
  ],
  OfficeLocation: "Chicago",
  Question2Question: "",
  Question1Question: "",
  Question2Answer: "",
  StaffId: "0603651",
  Question3Answer: "",
  departments: [
    "9cd6f14b-ee83-41b6-beef-e5c63d646944",
    "e3a2901c-697b-4599-8061-e7f99b3069a6",
    "59c4335a-322b-4dd3-b5ab-cf7cbe23438b",
    "5cf0c0b9-1722-4ab2-8015-b893bd4b1b5d",
  ],
  Email: "fakeemail@gmail.com",
  WorkPhone: "3131313131",
  Affiliations: "American Society of Civil Engineers",
  InstructorId: "jerickson",
  Title: "Erickson Joyce",
  Question4Question: "",
  Education:
    "<p>B.S. in Civil&nbsp; Engineering, University of Illinois at Urbana-Champaign;</p><p>M.S. in Civil Engineering, University of Illinois at Urbana-Chamaign;</p><p>Illinois Registered Professional Engineer.</p>",
  Question5Question: "",
  SchedulingLink:
    "https://support.zoom.us/hc/en-us/articles/201362413-Scheduling-meetings",
  Question3Question: "",
  Name: "Erickson, Joyce",
  MeetWithMe: "Schedule Appointment",
  Question1Answer: "",
  Bio: "<p style=\"margin-bottom:0.5em;color:#292a2c;font-family:'Fira Sans', sans-serif;font-size:16px;text-align:left;text-transform:none;white-space:normal;word-spacing:0px;background-color:#f6f6f6;\">Interested in transferring?&nbsp; Come speak to a UW Parkside representative about your transfer options. This on-campus table visit offers transfer information to help you plan out your path to a four year college or university.&nbsp; Look for the table in the CLC Student Commons area near the IT Help Desk window.</p><p style=\"margin-bottom:0.5em;color:#292a2c;font-family:'Fira Sans', sans-serif;font-size:16px;text-align:left;text-transform:none;white-space:normal;word-spacing:0px;background-color:#f6f6f6;\">When you KNOW YOUR SCHOOL and KNOW YOUR MAJOR, THE SMOOTHER YOUR TRANSFER WILL BE!</p>",
  Provider: "OpenAccessProvider",
  Photo: [
    {
      Id: "70cd7e88-f3b9-4ee0-9241-4b6f496916bc",
      LastModified: "2022-07-13T17:50:21Z",
      PublicationDate: "2022-07-13T17:50:21Z",
      Title: "thumbnail",
      Description: "",
      DateCreated: "2022-07-13T17:50:21Z",
      IncludeInSitemap: true,
      Ordinal: -13,
      UrlName: "thumbnail",
      ItemDefaultUrl: "/images/default-source/callouts/thumbnail",
      Author: "",
      Extension: ".png",
      MimeType: "image/png",
      TotalSize: 558145,
      Category: [],
      Tags: [],
      Width: 752,
      Height: 482,
      AlternativeText: "thumbnail",
      FolderId: null,
      ParentId: "80c7aecd-0c36-47b0-a216-89f2a8c0d778",
      Provider: "OpenAccessDataProvider",
      Url: "https://clc-dev.idevdesign.net/images/default-source/callouts/thumbnail.png?sfvrsn=c87430d4_1",
      ThumbnailUrl:
        "https://clc-dev.idevdesign.net/images/default-source/callouts/thumbnail.tmb-thumbnail.png?sfvrsn=c87430d4_1",
      IsDamMedia: false,
      Thumbnails: [
        {
          Title: "medium",
          Url: "https://clc-dev.idevdesign.net/images/default-source/callouts/thumbnail.tmb-medium.png?sfvrsn=c87430d4_1",
          Width: 240,
          Height: 153,
          MimeType: "image/png",
        },
        {
          Title: "0",
          Url: "https://clc-dev.idevdesign.net/images/default-source/callouts/thumbnail.tmb-0.png?sfvrsn=c87430d4_1",
          Width: 249,
          Height: 160,
          MimeType: "image/png",
        },
        {
          Title: "small",
          Url: "https://clc-dev.idevdesign.net/images/default-source/callouts/thumbnail.tmb-small.png?sfvrsn=c87430d4_1",
          Width: 240,
          Height: 153,
          MimeType: "image/png",
        },
      ],
    },
  ],
  PrimaryVideo: [
    {
      Id: "ed7a0ca0-d44e-4430-8812-d51b1bc7fef8",
      LastModified: "2022-07-13T17:50:25Z",
      PublicationDate: "2022-07-13T17:50:25Z",
      DateCreated: "2022-07-13T17:50:25Z",
      IncludeInSitemap: true,
      UrlName: "video-1",
      ItemDefaultUrl: "/video-1",
      Source:
        "https://clc.hosted.panopto.com/Panopto/Pages/Embed.aspx?id=3def78ad-bc50-499e-adf8-adbb01128b79&autoplay=false&offerviewer=false&showtitle=true&showbrand=true&captions=true&interactivity=all",
      Title: "Video 1",
      Provider: "OpenAccessProvider",
    },
  ],
  RelatedVideos: [
    {
      Id: "e2351eb8-ce18-48f1-809f-5558acbb6019",
      LastModified: "2022-07-13T17:55:20Z",
      PublicationDate: "2022-07-13T17:55:20Z",
      DateCreated: "2022-07-13T17:55:20Z",
      IncludeInSitemap: true,
      UrlName: "video-2",
      ItemDefaultUrl: "/video-2",
      Source:
        "https://clc.hosted.panopto.com/Panopto/Pages/Embed.aspx?id=3def78ad-bc50-499e-adf8-adbb01128b79&autoplay=false&offerviewer=false&showtitle=true&showbrand=true&captions=true&interactivity=all",
      Title: "Video 2",
      Provider: "OpenAccessProvider",
    },
    {
      Id: "1533b62a-0b8f-4319-9c3a-e20f99949555",
      LastModified: "2022-07-13T17:55:27Z",
      PublicationDate: "2022-07-13T17:55:27Z",
      DateCreated: "2022-07-13T17:55:27Z",
      IncludeInSitemap: true,
      UrlName: "video-3",
      ItemDefaultUrl: "/video-3",
      Source:
        "https://clc.hosted.panopto.com/Panopto/Pages/Embed.aspx?id=3def78ad-bc50-499e-adf8-adbb01128b79&autoplay=false&offerviewer=false&showtitle=true&showbrand=true&captions=true&interactivity=all",
      Title: "Video 3",
      Provider: "OpenAccessProvider",
    },
    {
      Id: "fcbe42f8-1da7-4e4c-a0f5-843128cdf6d5",
      LastModified: "2022-07-13T17:55:34Z",
      PublicationDate: "2022-07-13T17:55:34Z",
      DateCreated: "2022-07-13T17:55:34Z",
      IncludeInSitemap: true,
      UrlName: "video-4",
      ItemDefaultUrl: "/video-4",
      Source:
        "https://clc.hosted.panopto.com/Panopto/Pages/Embed.aspx?id=3def78ad-bc50-499e-adf8-adbb01128b79&autoplay=false&offerviewer=false&showtitle=true&showbrand=true&captions=true&interactivity=all",
      Title: "Video 4",
      Provider: "OpenAccessProvider",
    },
    {
      Id: "ed7a0ca0-d44e-4430-8812-d51b1bc7fef8",
      LastModified: "2022-07-13T17:50:25Z",
      PublicationDate: "2022-07-13T17:50:25Z",
      DateCreated: "2022-07-13T17:50:25Z",
      IncludeInSitemap: true,
      UrlName: "video-1",
      ItemDefaultUrl: "/video-1",
      Source:
        "https://clc.hosted.panopto.com/Panopto/Pages/Embed.aspx?id=3def78ad-bc50-499e-adf8-adbb01128b79&autoplay=false&offerviewer=false&showtitle=true&showbrand=true&captions=true&interactivity=all",
      Title: "Video 1",
      Provider: "OpenAccessProvider",
    },
  ],
};

export const subjects = {
  "@odata.context":
    "https://clc-dev.idevdesign.net/sfapi/default/$metadata#flat-taxa(Title,Id)",
  value: [
    {
      Id: "1c9c70dc-d97b-4353-8adf-3969510f87c4",
      Title: "Physical Sciences Division",
    },
    {
      Id: "036346bf-a906-4ac3-8aeb-5927e9159c38",
      Title: "Math",
    },
    {
      Id: "1b40953e-f950-46cd-994c-96febff117fe",
      Title: "Engineering",
    },
  ],
};

export const departments = {
  "@odata.context":
    "https://clc-dev.idevdesign.net/sfapi/default/$metadata#hierarchy-taxa(Id,Title)",
  value: [
    {
      Id: "5cf0c0b9-1722-4ab2-8015-b893bd4b1b5d",
      Title: "Community Programming",
    },
    {
      Id: "59c4335a-322b-4dd3-b5ab-cf7cbe23438b",
      Title: "Career and Job Placement Cntr",
    },
    {
      Id: "9cd6f14b-ee83-41b6-beef-e5c63d646944",
      Title: "Professional Development",
    },
    {
      Id: "e3a2901c-697b-4599-8061-e7f99b3069a6",
      Title: "Information Technology",
    },
  ],
};

export const studentFinancialAid = {
  ToDos: [
    {
      Code: "SAP23S",
      Description: "SAP Appeal Spring 2023",
      Status: "Initiated",
      ItemCode: "SPA23S",
      ItemDescription: "SAP Appeal Spring 2023",
      ItemStatus: "Initiated",
    },
  ],
  Holds: [],
  AnticipatedAid: [
    { Term: "1223", Type: "LEAF Aid LLH DR 4", Amount: "6.66" },
    { Term: "1231", Type: "LEAF Aid LLH DR 4", Amount: "6.67" },
    { Term: "1232", Type: "LEAF Aid LLH DR 4", Amount: "6.67" },
  ],
  FAFSAStatus: "Official",
  Awards: [],
  DisbursementDates: [],
  Success: true,
  status: null,
  errors: {
    code: null,
    message: null,
    raw_message: null,
    validation_errors: {},
  },
};
export const studentFinancials = {
  AccountBalance: "3,564.00",
  AccountDetails: [
    {
      Description: "Tuition Charges",
      Balance: "3,295.00",
      DueDate: "03/13/2023",
    },
    {
      Description: "Comprehensive Fee",
      Balance: "220.00",
      DueDate: "03/13/2023",
    },
    {
      Description: "CourseFees/Biology & Chemistry",
      Balance: "25.00",
      DueDate: "03/13/2023",
    },
    {
      Description: "Online Course Fee",
      Balance: "24.00",
      DueDate: "03/13/2023",
    },
  ],
  PaymentPlan: { EligibleForDeferred: "N", Enrolled: "N" },
  Success: true,
  status: null,
  errors: {
    code: null,
    message: null,
    raw_message: null,
    validation_errors: {},
  },
};

export const instructorData = {
  "@odata.context":
    "https://clc-dev.idevdesign.net/sfapi/default/$metadata#instructors/$entity",
  Id: "49932eb2-b391-410e-b3ce-9f6fcbb5c7ad",
  LastModified: "2023-03-23T18:00:21Z",
  PublicationDate: "2023-02-27T15:48:18Z",
  DateCreated: "2023-02-27T15:48:23Z",
  IncludeInSitemap: true,
  UrlName: "Instructorchenderson",
  ItemDefaultUrl: "/Instructorchenderson",
  CellPhone: "",
  Links: "",
  Question5Answer: "",
  Question4Answer: "",
  subjects: [],
  Question2Answer: "",
  Question3Answer: "",
  Temp: "1",
  IsDocument: false,
  IsDepartmentChair: false,
  Affiliations: "",
  InstructorId: "chenderson",
  Title: "Henderson Cynthia",
  Education:
    '{"Degrees":[{"DegreeType":"Msc","Subject":"Subject 1","School":"School 1","Location":"Location 1"},{"DegreeType":"Bsc","Subject":"Subject 2","School":"School 2","Location":"Location 2"}],"Certificates":[{"Description":"Some Certificate Numba 1"},{"Description":"Some Certificate Numba 2"}]}',
  SchedulingLink: null,
  MeetWithMe: "",
  Question1Answer: "",
  Bio: "",
  Provider: "OpenAccessProvider",
};

export const gmailData = [
  {
    Id: "18898b60764002b6",
    Date: "2023-06-08T01:54:05",
    IsRead: true,
    SenderName: '"Harris,Barbara" ',
    Subject:
      "Harris,Barbara (Low Intermediate Mathematics 2) just sent you a message in Canvas.",
  },
  {
    Id: "1889630c37dac9fc",
    Date: "2023-06-07T14:09:17",
    IsRead: false,
    SenderName: '"Anderson,Melissa" ',
    Subject:
      "Anderson,Melissa (History of Western Civ to 1500) just sent you a message in Canvas.",
  },
  {
    Id: "18895cf7f36b98d6",
    Date: "2023-06-07T12:22:50",
    IsRead: false,
    SenderName: "finaid@clcillinois.edu",
    Subject: "Financial Aid Status 2023 - 2024",
  },
  {
    Id: "1888be48a1535836",
    Date: "2023-06-05T14:09:50",
    IsRead: false,
    SenderName: "Introduction to Business ",
    Subject: "Final Exam Review: Introduction to Business",
  },
  {
    Id: "1888be3ed0998256",
    Date: "2023-06-05T14:09:09",
    IsRead: false,
    SenderName: "Introduction to Business ",
    Subject: "Test 3 Review Notes: Introduction to Business",
  },
  {
    Id: "1888be37ed3d9396",
    Date: "2023-06-05T14:08:41",
    IsRead: false,
    SenderName: "Introduction to Business ",
    Subject: "Test 2 Review Notes: Introduction to Business",
  },
  {
    Id: "1888bdd27197d0a2",
    Date: "2023-06-05T14:01:44",
    IsRead: false,
    SenderName: "Introduction to Business ",
    Subject: "Test 1 Review: Introduction to Business",
  },
  {
    Id: "188817438c81351a",
    Date: "2023-06-03T13:30:56",
    IsRead: true,
    SenderName: "Instructure Canvas ",
    Subject: "Recent Canvas Notifications",
  },
  {
    Id: "188774ac95da8af3",
    Date: "2023-06-01T14:09:28",
    IsRead: false,
    SenderName: '"Anderson,Melissa" ',
    Subject:
      "Anderson,Melissa (History of Western Civ to 1500) just sent you a message in Canvas.",
  },
  {
    Id: "1886307194aa9aae",
    Date: "2023-05-28T15:43:08",
    IsRead: true,
    SenderName: "History of Western Civ to 1500 ",
    Subject:
      "A few words about the first primary source paper: History of Western Civ to 1500",
  },
] as unknown as EmailMessage[];
