import ReactDOM from "react-dom";
import { Types } from "types/tools-resources-callouts";
import { useResourcesContext } from "context/resources/resources-context";
import { SitefinityItemList } from "components/common/tools-resources-callouts/sitefinity-item-list";
import ErrorComponent from "components/common/errorComponent";
import { BusyIndicator } from "components/busyIndicator/busy-indicator";
import { useUserRoles } from "context/user-roles/user-roles-context";
import { FUNCTIONALITY_AVAILABLE_TO_STUDENTS_ONLY } from "context/common/constant";

const ResourceListing = () => {
  const containerEl = document.getElementById("resource-listing");

  const { favoriteResources, isLoading, error } = useResourcesContext();
  const { impersonating, isStudent } = useUserRoles();

  return ReactDOM.createPortal(
    <>
      <section className="tools-listing">
        {!isStudent ? (
          <>{FUNCTIONALITY_AVAILABLE_TO_STUDENTS_ONLY}</>
        ) : impersonating ? (
          <div>
            <span>
              Warning: Proxy does not include favorite resources access
            </span>
          </div>
        ) : error ? (
          <ErrorComponent message={error.message} />
        ) : isLoading ? (
          <BusyIndicator />
        ) : (
          <>
            <div className="tools-listing__header">
              <h2 className="tools-listing__title">On Dashboard</h2>
            </div>
            <SitefinityItemList
              data={favoriteResources}
              type={Types.Resource}
              displayType="favorite"
            />
          </>
        )}
      </section>
    </>,
    containerEl as HTMLElement
  );
};

export default ResourceListing;
