import { ActiveTerm, PastTerm } from "types/integrations";
import { FilterDropdownOption } from "types/sort-and-filter-options";

export const getCurrentTermFromActiveTerms = (activeTerms: ActiveTerm[]) => {
  const currentTerm = activeTerms.find((term) => term.IsCurrentTerm === "Y");
  let currentTermData = {
    label: "",
    value: "0000",
    timeState: "N/A",
    currentTerm: "",
  };
  if (currentTerm) {
    currentTermData.label = currentTerm.Description + " - Current Term";
    currentTermData.value = currentTerm.Term;
    currentTermData.timeState = "Current";
    currentTermData.currentTerm = currentTerm.Description;
  } else {
    // If no current term, then set the current term to the first one chronologically
    const sortedList = sortTermsByDate(activeTerms);
    const firstTermChronologically = sortedList.at(0);
    if (firstTermChronologically) {
      currentTermData.label = firstTermChronologically.Description;
      currentTermData.value = firstTermChronologically.Term;
      currentTermData.timeState = "Future";
      currentTermData.currentTerm = firstTermChronologically.Description;
    }
  }

  return currentTermData;
};

// Sorts from closest to furthest dates
export const sortTermsByDate = (
  terms: ActiveTerm[] | PastTerm[],
  reverseOrder?: boolean
) => {
  const sortedList = terms.sort((item1, item2) => {
    const item1Date = new Date(item1.StartDate).toISOString();
    const item2Date = new Date(item2.StartDate).toISOString();
    if (reverseOrder) {
      return item1Date > item2Date ? -1 : 1;
    } else {
      return item1Date < item2Date ? -1 : 1;
    }
  }) as ActiveTerm[] | PastTerm[];
  return sortedList;
};

export const getTermTimeState = (
  term: string,
  terms: FilterDropdownOption[]
) => {
  const termMatch = terms.find((studentTerm) => studentTerm.Value === term);
  return termMatch?.SecondaryValue;
};
