import React from "react";
import { CanvasAssignment } from "types/integrations";
import { parseDate } from "../../utilities/date-parser";
interface Props {
  assignment: CanvasAssignment;
}
export default function StudentClassAssignmentWidget({ assignment }: Props) {
  return (
    <li className="course-assignment">
      {assignment.due_at && (
        <div className="course-assignment__date">
          <span className="course-assignment__time">
            {parseDate(new Date(assignment.due_at), "endTime")}
          </span>
          <span className="course-assignment__label">Due</span>
        </div>
      )}
      <div className="course-assignment__content">
        <svg aria-hidden="true" className="course-assignment__icon">
          <use href="#pencil-ruler"></use>
        </svg>
        <span className="course-assignment__type">Assignment</span>

        <h4 className="course-assignment__title">
          <a
            href={assignment.html_url}
            className="calendar-card__link"
            target="_blank"
          >
            {assignment.name}
          </a>
        </h4>

        {/* <div className="course-assignment__summary">
          {assignment.description}
        </div> */}
      </div>
    </li>
  );
}
