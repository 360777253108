import { Address, AddressTypes } from "types/integrations";
import CardContainer from "components/containers/card-container";
import { useMemo } from "react";
import Link from "components/links/link";
import { useStudentContext } from "context/student/student-context";
import ErrorComponent from "components/common/errorComponent";
import { BusyIndicator } from "components/busyIndicator/busy-indicator";
interface Props {
  componentHeading: string | undefined;
  linkHtml: string | undefined;
}
export const StudentAddress = ({ componentHeading, linkHtml }: Props) => {
  const { studentProfile } = useStudentContext();

  const { homeAddress, mailingAddress } = useMemo(
    () => getMailingAndHomeAddress(studentProfile.data?.Addresses),
    [studentProfile.data?.Addresses]
  );

  return (
    <CardContainer title={componentHeading || "Addresses"}>
      {studentProfile.error ? (
        <ErrorComponent message={studentProfile.error.message} />
      ) : studentProfile.isLoading ? (
        <BusyIndicator />
      ) : (
        studentProfile.data && (
          <>
            <div className="card-container__section">
              <h3>Home Address:</h3>
              <p>{homeAddress}</p>
            </div>
            <div className="card-container__section">
              <h3>Mailing Address:</h3>
              <p>{mailingAddress}</p>
            </div>
            <div className="card-container__section">
              <div dangerouslySetInnerHTML={{ __html: linkHtml ?? "" }} />
            </div>
          </>
        )
      )}
    </CardContainer>
  );
};

const getMailingAndHomeAddress = (addresses: Address[] | undefined) => {
  if (!addresses) return { homeAddress: "", mailingAddress: "" };
  let homeAddress = "",
    mailingAddress = "";
  for (let i = 0; i < addresses.length; i++) {
    if (addresses[i].Type === AddressTypes.Home) {
      homeAddress = fromatAddress(addresses[i]);
    } else {
      mailingAddress = fromatAddress(addresses[i]);
    }
  }
  return { homeAddress, mailingAddress };
};

const fromatAddress = (address: Address) => {
  return `${address.Address} ${address.City}, ${address.State} ${address.Postal}`;
};
