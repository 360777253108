import {
  Callout,
  SitefinityPage,
  SitefinityTool,
} from "types/tools-resources-callouts";
import { SitefinityImageItem } from "./sitefinity-image";

interface Props {
  favorites: SitefinityTool[] | Callout[] | SitefinityPage[];
}
export const SitefinityFavoritesCard = ({ favorites }: Props) => {
  return (
    <div className="callout-listing">
      <ul className="callout-listing__list">
        {favorites.map((item) => {
          return (
            <li className="callout-item" key={item.Id}>
              <SitefinityImageItem item={item} />
            </li>
          );
        })}
      </ul>
    </div>
  );
};
