import {
  Instructor,
  InstructorQuestions,
  Photo,
} from "types/staff-and-instructors";
import { InstructorVideoCard } from "./instructor-video-card";

interface Props {
  instructor: Instructor;
}
export const InstructorDetailMainCard = ({ instructor }: Props) => {
  return (
    <div className="instructor-detail__content">
      <h2>Bio</h2>
      <InstructorVideoCard
        photo={
          instructor.Photo && instructor.Photo.length > 0
            ? instructor.Photo[0]
            : ({} as Photo)
        }
        video={instructor.PrimaryVideo[0]}
      />
      <div
        className="instructor-detail__description"
        dangerouslySetInnerHTML={{ __html: instructor.Bio }}
      />
      {instructor.Question1Answer && (
        <>
          <h3 className="h2">{InstructorQuestions.one}</h3>
          <div
            className="insturctor-detail__description"
            dangerouslySetInnerHTML={{ __html: instructor.Question1Answer }}
          />
        </>
      )}
      {instructor.Question2Answer && (
        <>
          <h3 className="h2">{InstructorQuestions.two}</h3>
          <div
            className="insturctor-detail__description"
            dangerouslySetInnerHTML={{ __html: instructor.Question2Answer }}
          />
        </>
      )}
      {instructor.Question3Answer && (
        <>
          <h3 className="h2">{InstructorQuestions.three}</h3>
          <div
            className="insturctor-detail__description"
            dangerouslySetInnerHTML={{ __html: instructor.Question3Answer }}
          />
        </>
      )}
      {instructor.Question4Answer && (
        <>
          <h3 className="h2">{InstructorQuestions.four}</h3>
          <div
            className="insturctor-detail__description"
            dangerouslySetInnerHTML={{ __html: instructor.Question4Answer }}
          />
        </>
      )}
      {instructor.Question5Answer && (
        <>
          <h3 className="h2">{InstructorQuestions.five}</h3>
          <div
            className="insturctor-detail__description"
            dangerouslySetInnerHTML={{ __html: instructor.Question5Answer }}
          />
        </>
      )}
      {instructor.RelatedVideos && instructor.RelatedVideos.length > 0 && (
        <InstructorVideoCard
          video={instructor.RelatedVideos}
          photo={instructor.Photo[0]}
        />
      )}
    </div>
  );
};
