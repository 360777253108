import { useReducer } from "react";
interface State {
  loading: boolean;
}
interface Actions {
  type: string;
}

export const useBusyIndicator = () => {
  const actions = {
    LOADING: "LOADING",
  };
  // mutate busy indicator state
  const reducer = (state: State, action: Actions): State => {
    if (action.type === actions.LOADING) {
      return { ...state, loading: true };
    }
    return { ...state, loading: false };
  };

  const [{ loading }, dispatch] = useReducer(reducer, { loading: false });
  // Call to show busy indicator
  const load = () => {
    dispatch({ type: actions.LOADING });
  };
  // Call to hide busy indicator
  const unload = () => {
    dispatch({ type: "UNLOAD" });
  };

  return { loading, load, unload };
};
