import { FilterDropdownOption } from "types/sort-and-filter-options";

interface Props {
  options: FilterDropdownOption[];
  handleFilterClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  activeFacet: string;
}
export default function FilterOptions({
  options,
  handleFilterClick,
  activeFacet,
}: Props) {
  return (
    <>
      {options.map((option, index) => {
        return (
          <li
            className={`select-dropdown__item ${
              activeFacet === option.Value ? "active" : ""
            }`}
            key={option.Label + "-" + index}
          >
            <button
              type="button"
              className="select-dropdown__option"
              value={option.Value}
              onClick={handleFilterClick}
            >
              {option.Label}
            </button>
          </li>
        );
      })}
    </>
  );
}
