import {
  Callout,
  SitefinityPage,
  SitefinityTool,
} from "types/tools-resources-callouts";
import { isCallout, isResource } from "utilities/tools-resources-callouts";

interface Props {
  item: SitefinityTool | Callout | SitefinityPage;
}
export const SitefinityImageItem = ({ item }: Props) => {
  item = isCallout(item)
    ? { ...item, Link: item.ItemDefaultUrl }
    : isResource(item)
    ? { ...item, Link: item.ViewUrl }
    : item;

  return (
    <>
      {item.Link ? (
        <a
          href={item.Link}
          className="callout-item__link"
          target="_blank"
          aria-label={`${item.Title} will open in a new window`}
        >
          {item.Image.length > 0 ? (
            <div className="callout-item__wrapper">
              <img
                className="callout-item__image"
                src={
                  item.Image[0].Url
                    ? item.Image[0].Url
                    : `/images/default-source/default-album/profile-pic.png`
                }
                alt={item.Image[0].AlternativeText}
              />
            </div>
          ) : (
            ""
          )}
          <h3 className="callout-item__title">{item.Title}</h3>
        </a>
      ) : (
        <>
          {item.Image.length > 0 ? (
            <div className="callout-item__wrapper">
              <img
                className="callout-item__image"
                src={
                  item.Image[0].Url
                    ? item.Image[0].Url
                    : `/images/default-source/default-album/profile-pic.png`
                }
                alt={item.Image[0].AlternativeText}
              />
            </div>
          ) : (
            ""
          )}
          <h3 className="callout-item__title">{item.Title}</h3>
        </>
      )}
    </>
  );
};
