import ReactDOM from "react-dom";
import { useDivInjector } from "hooks/useDivInjector";
import { useUserRoles } from "context/user-roles/user-roles-context";
import { ImpersonationUnavailable } from "components/impersonation-unavailable/impersonation-unavailable";
import { StudentPersonalDetail } from "components/studentProfile/studentPersonalDetail";

export default function ProfileDetailPersonal() {
  useDivInjector("portal-detail-personal");

  const container = document.getElementById(
    "portal-detail-personal"
  ) as HTMLElement;

  const dataModel = container?.dataset.model;
  const parsedData = dataModel && JSON.parse(dataModel);
  const { isStudent } = useUserRoles();
  return (
    container &&
    ReactDOM.createPortal(
      !isStudent ? (
        <></>
      ) : (
        <StudentPersonalDetail
          componentHeading={parsedData?.headerText}
          linkHtml={parsedData?.linkHtml}
          changeImageHtml={parsedData?.imageUploadHtml}
        />
      ),
      container
    )
  );
}
