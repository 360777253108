import React from "react";
import ReactDOM from "react-dom";
import AcademicPlan from "../../components/classes/academic-plan";
import BalanceDue from "../../components/classes/balance";
import Question from "../../components/classes/question";
import Graduate from "../../components/classes/graduate";
import { getAcademicPlanDynamicActionLinksFromContainerData } from "utilities/classes";
import { useUserRoles } from "context/user-roles/user-roles-context";

export default function ClassCards() {
  const container = document.getElementById("class-cards") as HTMLElement;
  const { isStudent } = useUserRoles();
  const academicPlanActionLinks =
    getAcademicPlanDynamicActionLinksFromContainerData(container);
  const requestToGraduateActionLink = container.dataset.requesttograduatelink!;
  const academicHeading = container.dataset.academicheading;
  const balanceHeading = container.dataset.balanceheading;
  const advisorHeading = container.dataset.advisorheading;
  const graduationHeading = container.dataset.graduationheading;

  return (
    container &&
    ReactDOM.createPortal(
      <div className="class-cards">
        <AcademicPlan
          componentHeading={academicHeading}
          actionLinks={academicPlanActionLinks}
        ></AcademicPlan>
        <BalanceDue componentHeading={balanceHeading}></BalanceDue>
        <Question componentHeading={advisorHeading}></Question>
        <Graduate
          componentHeading={graduationHeading}
          requestToGraduateActionLink={requestToGraduateActionLink}
        ></Graduate>
      </div>,
      container
    )
  );
}
