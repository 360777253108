import ReactDOM from "react-dom";
import FinancialAid from "../../components/financials/financial-aid";
import { useUserRoles } from "context/user-roles/user-roles-context";

export default function FinancialCardFinancialAid() {
  const container = document.getElementById(
    "financial-card-financial-aid"
  ) as HTMLElement;

  const heading = container.dataset.heading;
  const fafsaSubText = container.dataset.fafsasubtext;
  const fafsaSchoolCodeText = container.dataset.fafsaschoolcodetext;
  const fafsaSubTextLink = container.dataset.fafsasubtextlink;
  const completeFafsaLink = container.dataset.completefafsalink;
  const needToCompleteLink = container.dataset.needtocompletelink;
  const viewAwardsSummaryLink = container.dataset.viewawardsummarylink;
  const applyForVeteranLink = container.dataset.applyforveteranlink;

  return (
    container &&
    ReactDOM.createPortal(
      <div className="financial-cards">
        <>
          <FinancialAid
            componentHeading={heading}
            fafsaSchoolCodeText={fafsaSchoolCodeText}
            fafsaSubText={fafsaSubText}
            fafsaSubTextLink={fafsaSubTextLink}
            completeFafsaLink={completeFafsaLink}
            needToCompleteFafsaActionLink={needToCompleteLink}
            applyForVeteranLink={applyForVeteranLink}
            viewAwardsSummaryLink={viewAwardsSummaryLink}
          ></FinancialAid>
        </>
      </div>,
      container
    )
  );
}
