import ReactDOM from "react-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import CalendarWeekView from "../../components/calendar/calendar-week-list-view/calendar-week-list-view";
import CalendarMonthView from "../../components/calendar/calendar-month-view/calendar-month-view";
import CustomDropdown from "components/dropdowns/dropdown";
import { CalendarItemsTypeFilterOptions } from "types/calendar";
import {
  DropdownOptionTypes,
  FilterDropdownOption,
} from "types/sort-and-filter-options";
import { useState } from "react";
import { useUserRoles } from "context/user-roles/user-roles-context";

export default function CalendarView() {
  const container = document.getElementById("calendar-view") as HTMLElement;
  const today = new Date(container.dataset.today!);

  const componentHeading = container.dataset.heading;
  const personalReminderLink = container.dataset.personalreminderlink;

  const [activeFilter, setActiveFilter] = useState<FilterDropdownOption>({
    Value: CalendarItemsTypeFilterOptions[0].Value,
    Label: CalendarItemsTypeFilterOptions[0].Label,
  });
  const { isStudent } = useUserRoles();

  const url = new URLSearchParams(window.location.search);
  const selectListView = url.get("isListViewSelected");

  const onFilter = (value: string, label?: string) => {
    setActiveFilter((previousState) => {
      return { Value: value, Label: !label ? previousState.Label : label };
    });
  };
  return (
    container &&
    ReactDOM.createPortal(
      !isStudent ? (
        <></>
      ) : (
        <section className="calendar-listing">
          <div className="calendar-listing__header">
            <h1 className="calendar-listing__title">
              {componentHeading || "To-Do's"}
            </h1>
            <CustomDropdown
              type="tertiary"
              optionsType={DropdownOptionTypes.Filters}
              toggleTitle={
                !activeFilter?.Label ? "All Types" : activeFilter.Label
              }
              options={CalendarItemsTypeFilterOptions}
              onFilter={onFilter}
            ></CustomDropdown>
            <a href={personalReminderLink ?? "#"} className="plus-link">
              Add Personal Reminder
            </a>
            <Tabs
              className="react-tabs react-tabs--tertiary"
              forceRenderTabPanel
              defaultIndex={selectListView ? 1 : 0}
            >
              <TabList>
                <Tab>
                  <svg className="react-tabs__icon" aria-hidden="true">
                    <use href="#calendar"></use>
                  </svg>
                  <span className="visually-hidden">Calendar Month View</span>
                </Tab>
                <Tab>
                  <svg className="react-tabs__icon" aria-hidden="true">
                    <use href="#list"></use>
                  </svg>
                  <span className="visually-hidden">Calendar Week View</span>
                </Tab>
              </TabList>
              <TabPanel>
                <CalendarMonthView
                  today={today}
                  activeCalendarFilter={activeFilter}
                ></CalendarMonthView>
              </TabPanel>
              <TabPanel>
                <CalendarWeekView
                  today={today}
                  activeCalendarFilter={activeFilter}
                ></CalendarWeekView>
              </TabPanel>
            </Tabs>
          </div>
        </section>
      ),
      container
    )
  );
}
