import axios from "axios";
export const useFetcher = <T,>(
  methodName: string,
  data?: any,
  accessToken?: string
) => {
  const fetcher = async (url: string) => {
    const resp = await axios({
      method: methodName,
      url: url,
      data: data,
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    if (resp?.data?.errors?.validation_errors["StudentIdError"]) {
      throw new Error(
        "Error: No Student ID detected. Please use a Student Account. If you already are, please try relogging using the Azure AD Process"
      );
    } else if (resp?.data?.errors?.message) {
      throw new Error("Error: Something went wrong, please check back later.");
    }

    return resp.data;
  };
  return { fetcher };
};
