import CalendarCard from "components/calendar/common/calendar-card";
import React, { useEffect, useState } from "react";
import ReactFocusLock from "react-focus-lock";
import { CalendarDayInfo, CalendarItemDisplayTypes } from "types/calendar";

interface Props {
  activeDate: CalendarDayInfo;
  modalStatus: boolean;
  modalCloseRef: React.MutableRefObject<null>;
  setModalStatus: React.Dispatch<React.SetStateAction<boolean>>;
}

/* Show all tasks for specific day in a modal on desktop */
function CalendarViewDesktopResultsModal({
  activeDate,
  modalStatus,
  modalCloseRef,
  setModalStatus,
}: Props) {
  const closeModal = () => {
    setModalStatus(false);
    document.body.classList.remove("modal-active");
  };

  useEffect(() => {
    document.addEventListener("keyup", onEscapeKey);
    return () => {
      document.removeEventListener("keyup", onEscapeKey);
    };
  }, []);

  const onEscapeKey = (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      closeModal();
    }
  };

  return (
    <div className={`calendar-modal ${modalStatus ? "active" : ""}`}>
      <ReactFocusLock disabled={!modalStatus}>
        <div className="calendar-modal__dialog">
          <button
            type="button"
            className="calendar-modal__close button-unstyled"
            aria-label="close calendar modal"
            onClick={closeModal}
            ref={modalCloseRef}
          ></button>
          <h2 className="calendar-modal__heading">
            <span className="calendar-modal__sub-heading">
              {activeDate?.DayName}
            </span>
            <span className="calendar-modal__sub-heading calendar-modal__sub-heading--secondary">
              {activeDate?.DayIndex}
            </span>
          </h2>
          <ul className="calendar-modal__list">
            {activeDate?.CalendarItems.map((task, index) => {
              return (
                <CalendarCard
                  displayType={CalendarItemDisplayTypes.Simple}
                  calendarItem={task}
                  key={index}
                ></CalendarCard>
              );
            })}
          </ul>
        </div>
      </ReactFocusLock>
    </div>
  );
}

export default CalendarViewDesktopResultsModal;
