import React, { useState } from "react";
import { ToDoItem } from "types/integrations";
import TaskModal from "./modal";

interface Props {
  toDoItem: ToDoItem;
  cardType: string;
  markTaskAsCompleted?: (id: string) => {};
}

export default function TodoCard({
  toDoItem,
  cardType,
  markTaskAsCompleted,
}: Props) {
  const [dropdownStatus, setDropdownStatus] = useState(false);

  const cardTypeClass =
    cardType === "upcoming"
      ? "todo-card__container--upcoming"
      : cardType === "recommended"
      ? "todo-card__container--recommended"
      : "";
  return (
    <div className="todo-card">
      <div className={`todo-card__container ${cardTypeClass}`}>
        <div className="todo-card__header">
          {toDoItem.NintexTaskStep && (
            <>
              <h3 className="todo-card__text todo-card__text--inline">
                Step {toDoItem.NintexTaskStep.current} of{" "}
                {toDoItem.NintexTaskStep.steps.length}
              </h3>
              <div className="todo-card__steps">
                <span className="todo-card__step"></span>
                <span className="todo-card__step"></span>
              </div>
              <TaskModal NintexTaskStep={toDoItem.NintexTaskStep}></TaskModal>
            </>
          )}
        </div>
        <div className="todo-card__content">
          {toDoItem.Priority && (
            <>
              {" "}
              <span className="todo-card__label">Priority:</span>
              <span
                className={
                  "todo-card__value todo-card__value--" +
                  toDoItem.Priority.toLowerCase()
                }
              >
                {toDoItem.Priority}
              </span>
            </>
          )}

          {toDoItem.Name && (
            <h3 className="todo-card__heading">
              <button
                type="button"
                className={`${
                  toDoItem.LongDescription
                    ? "todo-card__toggle"
                    : "todo-card__toggle-disabled"
                } ${dropdownStatus ? "active" : ""}`}
                aria-expanded={dropdownStatus}
                aria-controls="card-dropdown"
                onClick={() => setDropdownStatus(!dropdownStatus)}
              >
                {toDoItem.Name}
              </button>
            </h3>
          )}

          {toDoItem.ShortDescription && (
            <span className="todo-card__label todo-card__label--secondary">
              {toDoItem.ShortDescription}
            </span>
          )}
          {toDoItem.LongDescription && (
            <div
              id="card-dropdown"
              className={`todo-card__dropdown ${
                dropdownStatus ? "active" : ""
              }`}
            >
              <div
                dangerouslySetInnerHTML={{ __html: toDoItem.LongDescription }}
              ></div>
            </div>
          )}
        </div>

        <div className="todo-card__footer">
          {toDoItem.ButtonUrl && toDoItem.ButtonText && (
            <a
              href={toDoItem.ButtonUrl}
              className="button todo-card__button"
              target="_blank"
            >
              {toDoItem.ButtonText}
            </a>
          )}
          {markTaskAsCompleted &&
            toDoItem.IsNintexTask === true &&
            toDoItem.IsCompleted === false && (
              <button
                type="button"
                className="todo-card__complete button-unstyled"
                onClick={() => markTaskAsCompleted(toDoItem.Id)}
              >
                <span className="todo-card__icon"></span>
              </button>
            )}
        </div>
      </div>
    </div>
  );
}
