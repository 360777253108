import ReactDOM from "react-dom";
import StudentOrganizationFavoriteCard from "../../components/student-organizations/student-organization-favorite-card";
import { useDivInjector } from "hooks/useDivInjector";
import { useDataModel } from "hooks/useDataModel";
import { Model } from "types/data-models";
import { StudentOrganization } from "types/student";
import { useStudentOrganizationContext } from "context/student-organizations/student-organizations-context";
import CardContainer from "components/containers/card-container";
import ErrorComponent from "components/common/errorComponent";
import Link from "components/links/link";
import { BusyIndicator } from "components/busyIndicator/busy-indicator";
import { useUserRoles } from "context/user-roles/user-roles-context";
import { FUNCTIONALITY_AVAILABLE_TO_STUDENTS_ONLY } from "context/common/constant";

export default function StudentOrganizationFavorites() {
  const { injected } = useDivInjector("student-organization-favorites");
  const { impersonating, isStudent } = useUserRoles();

  useDataModel(
    Model.OrganizationClub,
    injected,
    "student-organization-favorites"
  );
  const { studentFavoriteOrgs, studentFavoriteOrgsResp } =
    useStudentOrganizationContext();
  const { error, isLoading } = studentFavoriteOrgsResp;

  const container = document.getElementById(
    "student-organization-favorites"
  ) as HTMLElement;
  const componentHeading = container.dataset.heading;

  const data = container.dataset.model;
  const parsedData = (data && JSON.parse(data)) || {};

  return (
    container &&
    ReactDOM.createPortal(
      <CardContainer title={componentHeading || "Favorite Organizations"}>
        {!isStudent ? (
          <>{FUNCTIONALITY_AVAILABLE_TO_STUDENTS_ONLY}</>
        ) : impersonating ? (
          <div>
            <span>
              Warning: Proxy does not include favorite organizations access
            </span>
          </div>
        ) : error ? (
          <ErrorComponent
            message={error.message || "An unkown error has occured"}
          />
        ) : isLoading ? (
          <BusyIndicator />
        ) : (
          studentFavoriteOrgs && (
            <>
              {studentFavoriteOrgs.length > 0 ? (
                <div className="callout-listing">
                  <ul className="callout-listing__list">
                    {studentFavoriteOrgs.map((org: StudentOrganization) => {
                      return (
                        <div key={org.Id}>
                          <StudentOrganizationFavoriteCard
                            data={org}
                            linkUrl={
                              "/student-life/student-organization-detail"
                            }
                          />
                        </div>
                      );
                    })}
                  </ul>
                </div>
              ) : (
                <div className="callout-listing">
                  No favorite organizations selected
                </div>
              )}
              <div className="content-block">
                <Link
                  url={
                    parsedData.linkurl || "/student-life/student-organizations"
                  }
                  label={parsedData.linktext || "View All Organizations"}
                  type="arrow"
                />
              </div>
            </>
          )
        )}
      </CardContainer>,
      container
    )
  );
}
