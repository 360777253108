import { TwentyFiveLiveEventCustomField } from "./integrations";
import { FilterDropdownOption } from "./sort-and-filter-options";

export interface DayOfWeek {
  DayName: string;
  DayIndex: number;
}
export interface CalendarDayInfo {
  DayIndex: number;
  DayName: string;
  MonthNameAbbreviation: string;
  CalendarItems: CalendarItem[];
}

export interface CalendarGroup {
  CalendarDayInfoItems: CalendarDayInfo[];
}
``;
export interface CalendarDayInfoGroup {
  CalendarGroups: CalendarGroup[];
}

export interface CalendarDay {
  disabled: boolean;
  selected?: boolean;
  calendarItems: CalendarItem[];
  label: string;
  dayIndex: number;
}

export interface CalendarMonth {
  label: string;
  year: number;
  month: number;
}

export interface CalendarRow {
  id: number;
  days: CalendarDay[];
}

export interface CalendarWeek {
  dayName: string;
  dayNumber: number;
  monthName: string;
  calendarItems: CalendarItem[];
}
export interface CalendarItem {
  Id: string;
  Type: string;
  Display: string;
  IconName: string;
  StartDate: Date;
  EndDate: Date;
  Title: string;
  Location: string;
  Content: string;
  calendarcategories?: string[];
  DetailUrl?: string;
  ActionUrl?: string;
  CustomFields?: string | TwentyFiveLiveEventCustomField[];
  IsTimeless?: boolean;
}

export enum CalendarItemDisplayTypes {
  Simple = "simple",
  SimpleSecondary = "simple-secondary",
  Date = "date",
}

export const CalendarItemTypes = {
  ["Class"]: { typeName: "class", iconName: "school" },
  ["Event"]: { typeName: "event", iconName: "calendar" },
  ["Assignment"]: { typeName: "assignment", iconName: "pencil-ruler" },
  ["Task"]: { typeName: "task", iconName: "check-square" },
  ["Reminder"]: { typeName: "appointment", iconName: "alarm-exclamation" },
};

export const CalendarItemsTypeFilterOptions = [
  {
    Value: "",
    Label: "All Types",
  },
  {
    Value: "class",
    Label: "Classes",
  },
  {
    Value: "task",
    Label: "Tasks",
  },
  {
    Value: "event",
    Label: "Events",
  },
  {
    Value: "assignment",
    Label: "Assignments",
  },
  {
    Value: "appointment",
    Label: "Appointments",
  },
] as FilterDropdownOption[];
