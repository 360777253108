import React, { useCallback, useEffect, useRef, useState } from "react";
import CalendarDisplayItems from "../common/calendar-display-items";
import { BusyIndicator } from "../../busyIndicator/busy-indicator";
import {
  CalendarRow,
  CalendarDay,
  CalendarDayInfo,
  CalendarItem,
} from "../../../types/calendar";

import {
  daysOfWeekLongSundayFirst,
  getCalendarActiveDateRange,
  getCalendarBuildRelevantDates,
  monthNames,
  onFilterCalendarItemsByDate,
} from "../../../utilities/calendar";
import CalendarViewMobileResults from "./modal-results/calendar-month-view-mobile-results";
import { useHttpForCalendar } from "../../../hooks/useHttpForCalendarData";
import { useBusyIndicator } from "../../../hooks/useBusyIndicator";
import DaysOfWeek from "./calendar-slider/days-of-week";
import ErrorComponent from "components/common/errorComponent";
import MonthSlider from "./calendar-slider/months-slider";
import CalendarViewDesktopResultsModal from "./modal-results/calendar-month-view-desktop-results-modal";
import { FilterDropdownOption } from "types/sort-and-filter-options";
interface Props {
  activeCalendarFilter: FilterDropdownOption;
  today: Date;
}

export default function CalendarMonthView({
  today,
  activeCalendarFilter,
}: Props) {
  const [calendarRows, setCalendarRows] = useState(Array<CalendarRow>);
  const [calendarActiveDate, setCalendarActiveDate] = useState(
    getCalendarActiveDateRange(today)
  );
  const {
    loading: isBuildingCalendar,
    load: scheduleCalendarBuild,
    unload: finishCalendarBuild,
  } = useBusyIndicator();

  const [activeDay, setActiveDay] = useState<CalendarDayInfo | undefined>();
  const [modalStatus, setModalStatus] = useState(false);
  const [areEventsAdded, setAreEventsAdded] = useState(false);
  const modalCloseRef = useRef(null);

  const {
    data: calendarItems,
    isLoading,
    error,
  } = useHttpForCalendar(
    "CalendarView",
    calendarActiveDate.startDate,
    calendarActiveDate.endDate
  );
  const updateActiveDay = useCallback(
    (
      dayNameIndex: number,
      day: number,
      calendarDayItems: CalendarItem[],
      displayModal?: boolean
    ) => {
      setActiveDay({
        DayName:
          daysOfWeekLongSundayFirst.find((day) => day.DayIndex === dayNameIndex)
            ?.DayName ?? "",
        DayIndex: day,
        MonthNameAbbreviation: monthNames[
          calendarActiveDate.monthIndex
        ].substring(0, 3),
        CalendarItems: calendarDayItems,
      });
      if (displayModal) {
        document.body.classList.add("modal-active");
        setModalStatus(true);
      }
    },
    [calendarItems]
  );

  // build out calendar view to display the current month
  // each row must be filled out
  // includes previous and next month days to finish incomplete rows
  const buildCalendar = useCallback(() => {
    if (!calendarItems) return;
    let filteredCalendarItems = Array<CalendarItem>();
    if (activeCalendarFilter.Label === "All Types") {
      filteredCalendarItems = [...calendarItems];
    } else {
      calendarItems.map((calendarItem) => {
        if (calendarItem.Type === activeCalendarFilter.Value) {
          filteredCalendarItems.push(calendarItem);
        }
      });
    }

    // initial monthData array to hold calendar row days
    // once complete, will set this value as the calendarRows state
    const monthData = Array<CalendarRow>();
    const rowData = Array<CalendarDay>();

    const { prevDaysCount, firstDayIndex, daysCount } =
      getCalendarBuildRelevantDates(calendarActiveDate);

    let previousDayNumber = prevDaysCount - firstDayIndex + 1;
    let currentDayNumber = 1;
    let nextDayNumber = 1;
    let tempDayNumber: number;

    let rowIndex = 0;
    let columnIndex = 0;

    let calendarComplete = false;

    let counter = 0;
    try {
      while (!calendarComplete) {
        counter++;
        let thisDay: Date;
        let isRowDisabled: boolean;
        let monthIndex: number;

        /* Set to previous day */
        if (previousDayNumber <= prevDaysCount) {
          monthIndex = calendarActiveDate.monthIndex - 1;
          tempDayNumber = previousDayNumber;
          previousDayNumber++;
          isRowDisabled = true;
          /* Set to today */
        } else if (currentDayNumber <= daysCount) {
          monthIndex = calendarActiveDate.monthIndex;
          tempDayNumber = currentDayNumber;
          currentDayNumber++;
          isRowDisabled = false;
          /* Set to next day */
        } else if (columnIndex <= 6 && currentDayNumber >= daysCount) {
          monthIndex = calendarActiveDate.monthIndex + 1;
          tempDayNumber = nextDayNumber;
          nextDayNumber++;
          isRowDisabled = true;
        }

        thisDay = new Date(
          calendarActiveDate.year,
          monthIndex!,
          tempDayNumber!
        );

        const DayTasks = onFilterCalendarItemsByDate(
          filteredCalendarItems,
          thisDay.toISOString()
        );
        rowData.push({
          disabled: isRowDisabled!,
          calendarItems: DayTasks,
          label: tempDayNumber!.toString(),
          dayIndex: tempDayNumber!,
        });

        tempDayNumber!++;
        columnIndex++;
        // add the row data once we are at the end of a row
        if (columnIndex === 7) {
          // duplicate check while in dev mode
          const hasDuplicate = monthData.filter((item) => item.id === rowIndex);
          if (hasDuplicate.length === 0 && rowData.length === 7) {
            monthData.push({ id: rowIndex, days: [...rowData] });
          }
          // calendar is done once we have filled out the last row
          // and all of the current month days have been added
          calendarComplete = currentDayNumber > daysCount;
          calendarComplete = nextDayNumber >= 1 && currentDayNumber > daysCount;
          rowData.length = 0;
          rowIndex++;
          columnIndex = 0;
        }
        // exit the while loop failsafe
        if (counter > 50) {
          calendarComplete = true;
          console.error(
            "There was an error in the calendar build. Exit failsafe used."
          );
        }
      }
    } catch (error) {
      console.log(error);
    }

    setCalendarRows(monthData);
    finishCalendarBuild();
  }, [calendarItems, activeCalendarFilter]);

  useEffect(() => {
    if (!calendarItems) return;
    buildCalendar();
  }, [calendarItems, activeCalendarFilter]);
  // rebuild the calendar everytime the slider moves to a new month
  const onMonthChange = (newMonthIndex: number, newYear: number) => {
    // Update active calendar date
    setCalendarActiveDate(() => {
      scheduleCalendarBuild();
      return getCalendarActiveDateRange(new Date(newYear, newMonthIndex, 1));
    });
  };

  return (
    <div className="calendar">
      <MonthSlider onSlideChange={onMonthChange}></MonthSlider>
      {error ? (
        <ErrorComponent message={error.message} />
      ) : (
        <table className="calendar-table">
          <DaysOfWeek />
          <tbody className="calendar-table__body">
            {isLoading || isBuildingCalendar ? (
              <BusyIndicator />
            ) : (
              calendarRows.map((row, index) => {
                return (
                  <tr key={index} className="calendar-table__row">
                    {row.days.map((calendarDay, index) => {
                      return (
                        <td className="calendar-table__cell" key={index}>
                          <button
                            type="button"
                            onClick={() => {
                              updateActiveDay(
                                index,
                                calendarDay.dayIndex,
                                calendarDay.calendarItems
                              );
                            }}
                            className={`calendar-table__button ${
                              calendarDay.selected
                                ? "calendar-table__button--selected"
                                : ""
                            }`}
                            disabled={calendarDay.disabled}
                          >
                            <span className="calendar-table__day">
                              {calendarDay.label}
                            </span>
                          </button>

                          <div
                            className={`calendar-table__label ${
                              calendarDay.disabled
                                ? "calendar-table__label--disabled"
                                : ""
                            }`}
                          >
                            <span className="calendar-table__day">
                              {calendarDay.label}
                            </span>
                          </div>

                          {calendarDay.calendarItems.length > 0 && (
                            <CalendarDisplayItems
                              calendarItems={calendarDay.calendarItems}
                              updateActiveDay={updateActiveDay}
                              modalStatus={modalStatus}
                              dayNameIndex={index}
                              dayName={calendarDay.label}
                              dayIndex={calendarDay.dayIndex}
                            ></CalendarDisplayItems>
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      )}
      {activeDay && (
        <>
          <CalendarViewMobileResults activeDate={activeDay} />
          <CalendarViewDesktopResultsModal
            activeDate={activeDay}
            modalCloseRef={modalCloseRef}
            modalStatus={modalStatus}
            setModalStatus={setModalStatus}
          />
        </>
      )}
    </div>
  );
}
