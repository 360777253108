import ReactDOM from "react-dom";
import { useDivInjector } from "hooks/useDivInjector";
import { useUserRoles } from "context/user-roles/user-roles-context";
import { ImpersonationUnavailable } from "components/impersonation-unavailable/impersonation-unavailable";
import { StudentAddress } from "components/studentProfile/studentAddress";

export default function ProfileDetailAddress() {
  useDivInjector("portal-detail-address");

  const container = document.getElementById(
    "portal-detail-address"
  ) as HTMLElement;
  const dataModel = container?.dataset.model;
  const parsedData = dataModel && JSON.parse(dataModel);
  const { isStudent } = useUserRoles();
  return (
    container &&
    ReactDOM.createPortal(
      !isStudent ? (
        <></>
      ) : (
        <StudentAddress
          componentHeading={parsedData?.headerText}
          linkHtml={parsedData?.linkHtml}
        />
      ),
      container
    )
  );
}
