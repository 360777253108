import {
  ActionLinkDropdownOption,
  DropdownOptionDataTypes,
  DropdownOptionTypes,
  FilterDropdownOption,
} from "types/sort-and-filter-options";
import FilterOptions from "./content/filter-options";
import ActionLinkOptions from "./content/link-options";

interface Props {
  options: typeof DropdownOptionDataTypes;
  optionsType: string;
  handleFilterClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  activeFacet: string;
}

export default function DropdownContent({
  options,
  optionsType,
  handleFilterClick,
  activeFacet,
}: Props) {
  return (
    <ul className="select-dropdown__list">
      {optionsType === DropdownOptionTypes.Filters && handleFilterClick && (
        <FilterOptions
          options={options as FilterDropdownOption[]}
          handleFilterClick={handleFilterClick}
          activeFacet={activeFacet}
        />
      )}
      {optionsType === DropdownOptionTypes.ActionLinks && (
        <ActionLinkOptions options={options as ActionLinkDropdownOption[]} />
      )}
    </ul>
  );
}
