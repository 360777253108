import { ActionLinkDropdownOption } from "types/sort-and-filter-options";

interface Props {
  options: ActionLinkDropdownOption[];
}

export default function ActionLinkOptions({ options }: Props) {
  return (
    <>
      {options.map((option, index) => {
        return (
          <li
            className="select-dropdown__item"
            key={option.Label + "-" + index}
          >
            <a
              href={option.Url}
              target={option.IsExternal ? "_blank" : "_self"}
              className="select-dropdown__link"
            >
              {option.Label}
            </a>
          </li>
        );
      })}
    </>
  );
}
