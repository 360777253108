import React, { useEffect, useContext, useState } from "react";
import { useHttp } from "../../hooks/useHttp";
import {
  FavoriteItem,
  SitefinityTool,
  Types,
} from "types/tools-resources-callouts";
import { mapFavoritesFromTools } from "utilities/map-helper";
import { FilterDropdownOption, SortBy } from "types/sort-and-filter-options";
import { mapRelevantFiltersFromTools } from "utilities/map-helper";
import {
  addFavorite,
  removeFavorite,
  sortingBy,
} from "utilities/tools-resources-callouts";
import { useMemo } from "react";
import { HttpResponseWithMutator } from "types/http";

interface Props {
  children: React.ReactNode;
}

// Context state objects
export interface ITools {
  relevantFilters: FilterDropdownOption[];
  tools: SitefinityTool[] | undefined;
  favoriteTools: SitefinityTool[] | undefined;
  favsToolsResp: HttpResponseWithMutator<FavoriteItem[]>;
  allToolsResp: HttpResponseWithMutator<SitefinityTool[]>;
}

// Extends for methods
export interface IToolsContext extends ITools {
  addFavoriteTool: (toolId: string, type: string) => Promise<void>;
  removeFavoriteTool: (toolId: string) => Promise<void>;
  filterToolsBy: (filterOptionIds: string[]) => void;
  sortToolsBy: (key: "Title" | "LastModified", ascending: boolean) => void;
}

// Default Values
const defaultState: ITools = {
  relevantFilters: [],
  tools: undefined,
  favoriteTools: undefined,
  favsToolsResp: {} as HttpResponseWithMutator<FavoriteItem[]>,
  allToolsResp: {} as HttpResponseWithMutator<SitefinityTool[]>,
};

const defaultContext: IToolsContext = {
  ...defaultState,
  addFavoriteTool: async (toolId: string, type: string) => {},
  removeFavoriteTool: async (toolId: string) => {},
  filterToolsBy: (filterOptionIds: string[]) => {},
  sortToolsBy: (key: "Title" | "LastModified", ascending: boolean) => {},
};

export const ToolsContext = React.createContext<IToolsContext>(defaultContext);

export const ToolsContextProvider: React.FC<Props> = ({ children }) => {
  const [relevantFilters, setRelevantFiltersOptions] = useState<
    FilterDropdownOption[]
  >(defaultContext.relevantFilters);
  const [favoriteTools, setFavoriteTools] = useState<
    SitefinityTool[] | undefined
  >(undefined);
  const [nonFavoriteTools, setNonFavoriteTools] = useState<
    SitefinityTool[] | undefined
  >(undefined);
  const [toolsSortedBy, setToolsSortedBy] = useState<SortBy | undefined>(
    undefined
  );
  const [toolsFilteredBy, setToolsFilteredBy] = useState<string[] | undefined>(
    undefined
  );

  const [filteredAndSortedTools, setFilteredAndSortedTools] = useState<
    SitefinityTool[] | undefined
  >(undefined);

  // retrieve user favorites
  const favsToolsResp = useHttp<FavoriteItem[]>(
    "get",
    `/api/favorites/type/${Types.Tool}`,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    true
  );
  const { data: userFavorites, mutate } = favsToolsResp;

  // retrieve tools
  const allToolsResp = useHttp<SitefinityTool[]>(
    "get",
    `/sfapi/default/tools?$select=Id,Title,Link,Summary,LastModified&$orderby=DefaultOrdinal,Title&$expand=Image($select=Url,AlternativeText),tooltypes($select=Title,Id)`,
    true,
    undefined,
    undefined,
    undefined,
    undefined,
    true
  );
  const { data: allTools } = allToolsResp;

  const memoizedMapRelevantFiltersFromTools = useMemo(
    () => allTools && mapRelevantFiltersFromTools(allTools as SitefinityTool[]),
    [allTools]
  );

  useEffect(() => {
    if (userFavorites && allTools) {
      const {
        favoriteTools: favoriteToolsFromMap,
        nonFavoriteTools: nonFavoriteToolsFromMap,
      } = mapFavoritesFromTools(allTools, userFavorites);
      const relevantFilterOptions = memoizedMapRelevantFiltersFromTools;

      setRelevantFiltersOptions(
        relevantFilterOptions as FilterDropdownOption[]
      );

      setFavoriteTools(favoriteToolsFromMap);
      setNonFavoriteTools(nonFavoriteToolsFromMap);
      if (toolsFilteredBy && toolsFilteredBy.length !== 0) {
        shouldFilterAndThenSortTools(nonFavoriteToolsFromMap); // Will not run Sort if no filters are passed
      } else {
        shouldSortTools(nonFavoriteToolsFromMap);
      }
    }
  }, [userFavorites, allTools]);

  const shouldFilterAndThenSortTools = (listToBeSorted?: SitefinityTool[]) => {
    if (toolsFilteredBy && toolsFilteredBy.length !== 0) {
      const filterIds = Array<string>();
      relevantFilters.map((filter) => {
        if (toolsFilteredBy.includes(filter.Value))
          return filterIds.push(filter.Value);
      });
      filterToolsBy(filterIds, listToBeSorted);
    }
  };

  const shouldSortTools = (listToBeSorted?: SitefinityTool[]) => {
    if (toolsSortedBy) {
      sortToolsBy(
        toolsSortedBy.Field as "Title" | "LastModified",
        toolsSortedBy.Ascending,
        listToBeSorted as SitefinityTool[]
      );
    } else if (listToBeSorted) {
      // Fallback for filtered but not sorted items because filter function doesn't update state
      setFilteredAndSortedTools(listToBeSorted);
    }
  };

  /**
   * Add Tool to favorites
   * @param toolId
   * @param toolType
   */
  const addFavoriteTool = async (toolId: string, type: string) => {
    await addFavorite(toolId, type ? type : Types.Tool);
    mutate();
  };

  /**
   * Delete tool from favorites
   * @param toolId
   */
  const removeFavoriteTool = async (toolId: string) => {
    await removeFavorite(toolId);
    mutate();
  };

  /**
   * Filters LIst
   *
   * @param {boolean} ascending
   * @param {SitefinityTool[]} listToBeFiltered
   * @return {*}
   */
  const filteringBy = (
    filterIds: string[],
    listToBeFiltered: SitefinityTool[]
  ) => {
    if (filterIds.length === 0) return listToBeFiltered;

    const filteredList = listToBeFiltered.filter((item) => {
      // Get all toolType Ids for this item
      const itemToolTypeIds = item.tooltypes.map((toolType) => {
        return toolType.Id;
      });
      // If it has any ID in the filter IDs then it's included
      if (
        itemToolTypeIds.some((toolTypeId) => {
          return filterIds.includes(toolTypeId);
        })
      ) {
        return item;
      }
    });
    return filteredList;
  };

  /**
   *
   *
   * @param {string[]} toolTypeIds
   * @param {SitefinityTool[]} [listToBeFiltered]
   */
  const filterToolsBy = (
    selectedFilterOptionIds: string[],
    listToBeFiltered?: SitefinityTool[]
  ) => {
    const filteredList = filteringBy(
      selectedFilterOptionIds,
      listToBeFiltered
        ? listToBeFiltered
        : (nonFavoriteTools as SitefinityTool[])
    );
    setToolsFilteredBy(selectedFilterOptionIds);
    shouldSortTools(filteredList);
  };

  /**
   * Sort Tools list
   * @param key
   * @param ascending
   */
  const sortToolsBy = (
    key: "Title" | "LastModified",
    ascending: boolean,
    listToBeSorted?: SitefinityTool[]
  ) => {
    const listToSort = listToBeSorted ? listToBeSorted : filteredAndSortedTools;
    const sortedList = sortingBy(
      key,
      ascending,
      listToSort as SitefinityTool[]
    );
    setToolsSortedBy({ Field: key, Ascending: ascending });
    setFilteredAndSortedTools(sortedList as SitefinityTool[]);
  };

  return (
    <ToolsContext.Provider
      value={{
        relevantFilters: relevantFilters ?? defaultContext.relevantFilters,
        tools: filteredAndSortedTools ?? defaultContext.tools,
        favoriteTools: favoriteTools ?? defaultContext.favoriteTools,
        allToolsResp: allToolsResp ?? defaultContext.allToolsResp,
        favsToolsResp: favsToolsResp ?? defaultContext.favsToolsResp,
        addFavoriteTool,
        removeFavoriteTool,
        filterToolsBy,
        sortToolsBy,
      }}
    >
      {children}
    </ToolsContext.Provider>
  );
};

export const useToolsContext = () => useContext(ToolsContext);
