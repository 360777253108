import React, { useEffect, useContext, useState } from "react";
import { useHttp } from "../../hooks/useHttp";
import {
  FavoriteItem,
  SitefinityPage,
  Types,
} from "types/tools-resources-callouts";
import {
  addFavorite,
  removeFavorite,
} from "utilities/tools-resources-callouts";

interface Props {
  children: React.ReactNode;
}

// Context state objects
export interface IResources {
  isLoading: boolean;
  error: Error | undefined;
  favoriteResources: SitefinityPage[];
}

// Extends for methods
export interface IResourcesContext extends IResources {
  addFavoriteResource: (resourceId: string, type: string) => Promise<void>;
  removeFavoriteResource: (resourceId: string) => Promise<void>;
}

// Default Values
const defaultState: IResources = {
  isLoading: false,
  error: undefined,
  favoriteResources: [],
};

const defaultContext: IResourcesContext = {
  ...defaultState,
  addFavoriteResource: async (resourceId: string, type: string) => {},
  removeFavoriteResource: async (resourceId: string) => {},
};

export const ResourcesContext =
  React.createContext<IResourcesContext>(defaultContext);

export const ResourcesContextProvider: React.FC<Props> = ({ children }) => {
  const [isLoading, setIsLoading] = useState<boolean>(defaultContext.isLoading);
  const [error, setError] = useState<Error | undefined>(defaultContext.error);
  // Get user favorites
  const {
    data: userFavorites,
    isLoading: isUserFavoritesLoading,
    error: userFavoriteError,
    mutate,
  } = useHttp<FavoriteItem[]>(
    "get",
    `/api/favorites/type/${Types.Resource}`,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    true
  );

  // Get resources
  let {
    data: favoriteResources,
    isLoading: areFavoriteResourcesLoading,
    error: favoriteResourcesError,
  } = useHttp<SitefinityPage[]>(
    "get",
    `/sfapi/default/pages?$select=Id,Title,UrlName,ViewUrl,types,Description,Summary,LastModified,Image&$expand=Image($select=Url,AlternativeText)&$filter=Id in(${
      userFavorites && userFavorites.length > 0
        ? userFavorites.map((item) => item.id).join(",")
        : "00000000-0000-0000-0000-000000000000"
    })`,
    true,
    undefined,
    undefined,
    undefined,
    undefined,
    true
  );

  useEffect(() => {
    if (
      isUserFavoritesLoading !== undefined &&
      areFavoriteResourcesLoading !== undefined
    ) {
      setIsLoading(isUserFavoritesLoading || areFavoriteResourcesLoading);
    }
  }, [isUserFavoritesLoading, areFavoriteResourcesLoading]);

  useEffect(() => {
    if (favoriteResourcesError) {
      setError(favoriteResourcesError);
      return;
    }
    if (userFavoriteError) {
      setError(userFavoriteError);
      return;
    }
  }, [favoriteResourcesError, userFavoriteError]);

  /**
   * Add Resource to favorites
   * @param resourceId
   * @param type
   */
  const addFavoriteResource = async (resourceId: string, type: string) => {
    await addFavorite(resourceId, type ? type : Types.Resource);
    mutate();
  };

  /**
   * Delete Resource from favorites
   * @param resourceId
   */
  const removeFavoriteResource = async (resourceId: string) => {
    await removeFavorite(resourceId);
    mutate();
  };

  return (
    <ResourcesContext.Provider
      value={{
        error: error ?? defaultContext.error,
        isLoading: isLoading ?? defaultContext.isLoading,
        favoriteResources:
          favoriteResources ?? defaultContext.favoriteResources,
        addFavoriteResource,
        removeFavoriteResource,
      }}
    >
      {children}
    </ResourcesContext.Provider>
  );
};

export const useResourcesContext = () => useContext(ResourcesContext);
