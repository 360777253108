import ReactDOM from "react-dom";
import { useState, FormEvent, MouseEvent, useRef, useEffect } from "react";
import { Modal } from "components/modal/modal";
import { useUserRoles } from "context/user-roles/user-roles-context";
import { initial } from "lodash";

export default function ImpersonateRoles() {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [empId, setEmpId] = useState<string>("");
  const container = document.getElementById("impersonate-roles");
  const { impersonate, impersonating, resetImpersonation } = useUserRoles();

  const openModalRef = useRef<HTMLButtonElement>(null);
  const initialUpdate = useRef(true);
  const onCloseModal = () => {
    setIsModalOpen(false);
    document.body.classList.remove("modal-active");
  };

  const onOpenModal = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setIsModalOpen(true);
    document.body.classList.add("modal-active");
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // Call API to impersonate the given user id
    onCloseModal();
    setEmpId("");
    await impersonate(empId);
  };

  const endProxyOnClick = async () => {
    await resetImpersonation();
  };

  useEffect(() => {
    // only apply focus after initial render
    if (!isModalOpen) {
      if (initialUpdate.current) {
        initialUpdate.current = false;
        return;
      }
      if (openModalRef.current && !initialUpdate.current) {
        openModalRef.current.focus();
      }
    }
  }, [isModalOpen]);

  return (
    container &&
    ReactDOM.createPortal(
      <>
        {impersonating ? (
          <button className="button" type="button" onClick={endProxyOnClick}>
            End Proxy
          </button>
        ) : (
          <>
            <button
              ref={openModalRef}
              className="button"
              type="button"
              onClick={onOpenModal}
            >
              Proxy
            </button>
            {isModalOpen && (
              <Modal
                onClose={onCloseModal}
                modalContent={
                  <form
                    className="form"
                    onSubmit={handleSubmit}
                    title="Proxy Dialog"
                  >
                    <div className="form__group">
                      <label className="form__label">
                        Enter student ID to proxy:
                        <input
                          className="form__text-input"
                          id="username"
                          type="text"
                          value={empId}
                          required
                          onChange={(event) => setEmpId(event.target.value)}
                        />
                      </label>
                    </div>
                    <button type="submit" className="form__submit button">
                      Proxy
                    </button>
                  </form>
                }
              />
            )}
          </>
        )}
      </>,
      container
    )
  );
}
