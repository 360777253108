import { Contacts, ContactTypes } from "types/integrations";
import { useMemo } from "react";
import CardContainer from "components/containers/card-container";
import { useStudentContext } from "context/student/student-context";
import { BusyIndicator } from "components/busyIndicator/busy-indicator";
import ErrorComponent from "components/common/errorComponent";

interface Props {
  componentHeading: string | undefined;
  linkHtml: string | undefined;
}

export const StudentContactDetails = ({
  componentHeading,
  linkHtml,
}: Props) => {
  const { studentProfile } = useStudentContext();
  const { homeEmail, studentEmail, homePhone, mobilePhone } = useMemo(
    () => getHomeAndStudentEmailAndPhone(studentProfile.data?.Contacts),
    [studentProfile.data?.Contacts]
  );
  return (
    <CardContainer title={componentHeading || "Contact Details"}>
      {studentProfile.error ? (
        <ErrorComponent message={studentProfile.error.message} />
      ) : studentProfile.isLoading ? (
        <BusyIndicator />
      ) : (
        studentProfile.data && (
          <>
            <div className="card-container__section">
              <h3>Email:</h3>
              <div className="row">
                <div className="col-8">
                  <p>{homeEmail}</p>
                </div>
                <div className="col-4">
                  <p className=" align-right">Home</p>
                </div>
              </div>
              {studentEmail && (
                <div className="row">
                  <div className="col-8">
                    <p>{studentEmail}</p>
                  </div>
                  <div className="col-4">
                    <p className=" align-right">Student</p>
                  </div>
                </div>
              )}
            </div>
            <div className="card-container__section">
              <h3>Phone:</h3>
              <div className="row">
                <div className="col-8">
                  <p>{homePhone}</p>
                </div>
                <div className="col-4">
                  <p className=" align-right">Home</p>
                </div>
              </div>
            </div>
            {mobilePhone && (
              <div className="row">
                <div className="col-8">
                  <p>{homePhone}</p>
                </div>
                <div className="col-4">
                  <p className=" align-right">Mobile</p>
                </div>
              </div>
            )}
            <div className="card-container__section">
              <div dangerouslySetInnerHTML={{ __html: linkHtml ?? "" }} />
              {/* <Link
                url={linkUrl ? linkUrl : "#"}
                label={linkText ? linkText : "Update My Contact Details"}
                type="arrow"
                openNewTab={openNewTab}
              /> */}
            </div>
          </>
        )
      )}
    </CardContainer>
  );
};

const getHomeAndStudentEmailAndPhone = (contacts: Contacts | undefined) => {
  if (!contacts)
    return { homeEmail: "", studentEmail: "", homePhone: "", mobilePhone: "" };
  let homeEmail = "",
    studentEmail = "",
    homePhone = "",
    mobilePhone = "";
  for (let i = 0; i < contacts.Emails.length; i++) {
    if (contacts.Emails[i].Type === ContactTypes.Home) {
      homeEmail = contacts.Emails[i].Email;
    } else {
      studentEmail = contacts.Emails[i].Email;
    }
  }
  for (let j = 0; j < contacts.Phones.length; j++) {
    if (contacts.Phones[j].Type === ContactTypes.Home) {
      homePhone = contacts.Phones[j].Phone;
    } else {
      mobilePhone = contacts.Phones[j].Phone;
    }
  }
  return { homeEmail, studentEmail, homePhone, mobilePhone };
};
