import {
  Callout,
  SitefinityPage,
  SitefinityTool,
  Types,
} from "../../../types/tools-resources-callouts";
import ToolsCard from "../../tools/tools-card";
import { CalloutCard } from "../../callouts/callout-card";
import { ResourceCard } from "components/resources/resources-card";

interface Props {
  data: SitefinityTool[] | Callout[] | SitefinityPage[];
  type: string;
  displayType: string;
}
export const SitefinityItemList = ({ data, type, displayType }: Props) => {
  return (
    <div className="tools-listing__main">
      <ul className="tools-listing__list" aria-live="polite">
        {data?.map((item, index) => {
          return type === Types.Tool ? (
            <ToolsCard
              data={item as SitefinityTool}
              key={index}
              type={type}
              displayType={displayType}
            ></ToolsCard>
          ) : type === Types.Callout ? (
            <CalloutCard
              callout={item as Callout}
              key={index}
              type={Types.Callout}
              displayType={displayType}
            />
          ) : (
            <ResourceCard
              resource={item as SitefinityPage}
              type={Types.Resource}
              key={index}
              displayType={displayType}
            />
          );
        })}
        {data?.length === 0 && <span>No Results Found.</span>}
      </ul>
    </div>
  );
};
