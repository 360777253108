import { ActionLinkDataModel } from "types/data-models";

interface Props {
  firstFourActions: ActionLinkDataModel[];
}

export default function ClassesHeaderActions({ firstFourActions }: Props) {
  return (
    <ul className="class-schedule__list class-schedule__list--secondary">
      {firstFourActions.map((classAction, index) => {
        return (
          <a
            href={classAction.Url}
            target={classAction.IsExternal ? "_blank" : "_self"}
            className="button class-schedule__button"
          >
            {classAction.Label}
          </a>
        );
      })}
    </ul>
  );
}
