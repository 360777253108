import { BusyIndicator } from "components/busyIndicator/busy-indicator";
import ErrorComponent from "components/common/errorComponent";
import { useStudentContext } from "context/student/student-context";
import { ActionLinkDataModel } from "types/data-models";
import { formatCurrency } from "../../utilities/currency-parser";
import CardContainer from "../containers/card-container";
import { useUserRoles } from "context/user-roles/user-roles-context";
import { FUNCTIONALITY_AVAILABLE_TO_STUDENTS_ONLY } from "context/common/constant";

interface Props {
  makePaymentActionLink: string | undefined;
  otherFinancialActionLinks: ActionLinkDataModel[];
  componentHeading: string | undefined;
  form1098Link: string | undefined;
  setupDirectDepositLink: string | undefined;
  paymentAndBillingActionLinks: ActionLinkDataModel[];
}

export default function FinancialPayments({
  makePaymentActionLink,
  otherFinancialActionLinks,
  componentHeading,
  form1098Link,
  setupDirectDepositLink,
  paymentAndBillingActionLinks,
}: Props) {
  const { overallBalance } = useStudentContext();
  const { isStudent } = useUserRoles();
  return (
    <CardContainer title={componentHeading || "Amount Due"}>
      {!isStudent ? (
        <>{FUNCTIONALITY_AVAILABLE_TO_STUDENTS_ONLY}</>
      ) : overallBalance.error ? (
        <ErrorComponent message={overallBalance.error.message} />
      ) : overallBalance.isLoading ? (
        <BusyIndicator />
      ) : (
        overallBalance.data && (
          <>
            <div className="card-container__section">
              <div className="row">
                <div className="col-lg-7">
                  <p className="text-size-large">
                    $
                    {formatCurrency(
                      +overallBalance.data.OverallBalance.replaceAll(",", "")
                    )}
                  </p>
                  {/* {studentFinancials.data.AccountBalance.length > 0 &&
                    earliestDueDate && (
                      <p className="text-size-medium text-weight-bold">
                        Due on {formatDate(new Date(earliestDueDate))}
                      </p>
                    )} */}
                  {/* {!financialAidDisbursementDate ? (
                    <p className="text-size-medium">
                      Financial aid disubrsement date is unavailable at this
                      time.
                    </p>
                  ) : (
                    <p className="text-size-medium">
                      Financial aid will begin disbursing on{" "}
                      {parseDate(
                        new Date(financialAidDisbursementDate),
                        "monthAndDay"
                      )}
                    </p>
                  )}
                  <p className="text-size-medium">
                    You are eligible for deferred payment plan.
                      </p>*/}
                </div>
                <div className="col-lg-5">
                  <p
                    className="financials__button--small"
                    dangerouslySetInnerHTML={{
                      __html: makePaymentActionLink ?? "",
                    }}
                  ></p>
                  <p>
                    {otherFinancialActionLinks.map((actionLink) => {
                      return (
                        <a
                          href={actionLink.Url}
                          className="text-size-small"
                          target={actionLink.IsExternal ? "_blank" : "_self"}
                        >
                          {actionLink.Label}
                        </a>
                      );
                    })}
                  </p>
                </div>
              </div>
            </div>
            {paymentAndBillingActionLinks.length > 0 ||
            (form1098Link &&
              form1098Link !== `<a href="http://"></a>` &&
              form1098Link !== `<a href=""></a>`) ||
            (setupDirectDepositLink &&
              setupDirectDepositLink !== `<a href="http://"></a>` &&
              setupDirectDepositLink !== `<a href=""></a>`) ? (
              <div className="card-container__section">
                <div className="row">
                  <div className="col-lg-7">
                    {paymentAndBillingActionLinks.length > 0 && (
                      <>
                        <h3>Payment &amp; Billing</h3>
                        {paymentAndBillingActionLinks.map((actionLink) => {
                          return (
                            <p className="text-size-medium">
                              <a
                                href={actionLink.Url}
                                target={
                                  actionLink.IsExternal ? "_blank" : "_self"
                                }
                              >
                                {actionLink.Label}
                              </a>
                            </p>
                          );
                        })}
                      </>
                    )}
                  </div>
                  <div className="col-lg-5">
                    <p
                      dangerouslySetInnerHTML={{ __html: form1098Link ?? "" }}
                    ></p>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: setupDirectDepositLink ?? "",
                      }}
                    ></p>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </>
        )
      )}
    </CardContainer>
  );
}
