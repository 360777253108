import { useState, useEffect, useCallback, MouseEvent } from "react";

interface Props {
  content: string;
  className?: string;
  isHtmlContent?: boolean;
}

export const TrimText = ({
  content,
  className,
  isHtmlContent = false,
}: Props) => {
  const [showContent, setShowContent] = useState<string>("");
  const [isShowAll, setIsShowAll] = useState<boolean>(true);
  const [isContentLong, setIsContentLong] = useState<boolean>(false);

  const trimContent = () => {
    if (!content || content === "") return "";
    if (content.length <= 250) return content;
    const splittedContent = content.split(" ");
    const end = splittedContent.length > 35 ? 35 : splittedContent.length;
    if (end === splittedContent.length) return content;
    setIsShowAll(false);
    setIsContentLong(true);
    return splittedContent.slice(0, end).join(" ");
  };
  const memoizedTrimContent = useCallback(trimContent, []);
  useEffect(() => {
    setShowContent(memoizedTrimContent());
  }, []);

  const viewMore = (event: MouseEvent<HTMLAnchorElement>) => {
    setIsShowAll(true);
    setShowContent(content);
    event.preventDefault();
  };

  const viewLess = (event: MouseEvent<HTMLAnchorElement>) => {
    setShowContent(memoizedTrimContent());
    event.preventDefault();
  };

  const showMoreOrLess = !isShowAll ? (
    <a href="#" className="arrow-git bra" onClick={viewMore}>
      Show More
    </a>
  ) : isContentLong ? (
    <a href="#" className="arrow-link" onClick={viewLess}>
      Show Less
    </a>
  ) : (
    ""
  );

  return (
    <>
      {isHtmlContent ? (
        <div className={className ? className : ""}>
          <div
            dangerouslySetInnerHTML={{
              __html: showContent,
            }}
          />
          <span>{showMoreOrLess}</span>
        </div>
      ) : (
        <div className={className ? className : ""}>
          {showContent}&nbsp;
          {showMoreOrLess}
        </div>
      )}
    </>
  );
};
