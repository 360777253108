import React, { useEffect, useContext, useState } from "react";
import { useHttp } from "../../hooks/useHttp";
import { Callout, FavoriteItem, Types } from "types/tools-resources-callouts";
import { mapFavoritesFromCallouts } from "utilities/map-helper";
import { SortBy } from "types/sort-and-filter-options";
import {
  addFavorite,
  removeFavorite,
  sortingBy,
} from "utilities/tools-resources-callouts";

interface Props {
  children: React.ReactNode;
}

// Context state objects
export interface ICallouts {
  callouts: Callout[];
  favoriteCallouts: Callout[];
}

// Extends for methods
export interface ICalloutsContext extends ICallouts {
  addFavoriteCallout: (calloutId: string, type: string) => Promise<void>;
  removeFavoriteCallout: (calloutId: string) => Promise<void>;
  sortCalloutsBy: (key: "Title" | "LastModified", ascending: boolean) => void;
}

// Default Values
const defaultState: ICallouts = {
  callouts: [],
  favoriteCallouts: [],
};

const defaultContext: ICalloutsContext = {
  ...defaultState,
  addFavoriteCallout: async (calloutId: string, type: string) => {},
  removeFavoriteCallout: async (calloutId: string) => {},
  sortCalloutsBy: (key: "Title" | "LastModified", ascending: boolean) => {},
};

export const CalloutsContext =
  React.createContext<ICalloutsContext>(defaultContext);

export const CalloutsContextProvider: React.FC<Props> = ({ children }) => {
  const [favoriteCallouts, setFavoriteCallouts] = useState<Callout[]>(
    defaultContext.favoriteCallouts
  );
  const [calloutsSortedBy, setCalloutsSortedBy] = useState<SortBy | undefined>(
    undefined
  );

  const [filteredAndSortedCallouts, setFilteredAndSortedCallouts] = useState<
    Callout[]
  >([]);

  // retrieve user favorites
  const { data: userFavorites } = useHttp<FavoriteItem[]>(
    "get",
    `/api/favorites/type/${Types.Callout}`
  );

  // retrieve callouts
  const { data: allCallouts } = useHttp<Callout[]>(
    "get",
    `/sfapi/default/pages?$expand=Image($select=Url,AlternativeText)`,
    true
  );

  useEffect(() => {
    if (userFavorites && allCallouts) {
      const { favoriteCallouts, nonFavoriteCallouts } =
        mapFavoritesFromCallouts(allCallouts, userFavorites);

      setFavoriteCallouts(favoriteCallouts);
      shouldSortCallouts();
    }
  }, [userFavorites, allCallouts]);

  const shouldSortCallouts = (listToBeSorted?: Callout[]) => {
    if (calloutsSortedBy) {
      sortCalloutsBy(
        calloutsSortedBy.Field as "Title" | "LastModified",
        calloutsSortedBy.Ascending,
        listToBeSorted as Callout[]
      );
    }
  };

  /**
   * Add Callout to favorites
   * @param calloutId
   * @param type
   */
  const addFavoriteCallout = async (calloutId: string, type: string) => {
    const resp = await addFavorite(calloutId, type ? type : Types.Callout);
    const { favoriteCallouts, nonFavoriteCallouts } = mapFavoritesFromCallouts(
      allCallouts as Callout[],
      resp?.data
    );
    setFavoriteCallouts(favoriteCallouts);
    shouldSortCallouts(nonFavoriteCallouts);
  };

  /**
   * Remove Callout from favorites
   * @param calloutId
   */
  const removeFavoriteCallout = async (calloutId: string) => {
    const resp = await removeFavorite(calloutId);
    const { favoriteCallouts, nonFavoriteCallouts } = mapFavoritesFromCallouts(
      allCallouts as Callout[],
      resp?.data
    );
    setFavoriteCallouts(favoriteCallouts);
    shouldSortCallouts(nonFavoriteCallouts);
  };

  /**
   * Sort callouts list
   * @param key
   * @param ascending
   */
  const sortCalloutsBy = (
    key: "Title" | "LastModified",
    ascending: boolean,
    listToBeSorted?: Callout[]
  ) => {
    const sortedList = sortingBy(
      key,
      ascending,
      listToBeSorted ? listToBeSorted : filteredAndSortedCallouts
    );
    setCalloutsSortedBy({ Field: key, Ascending: ascending });
    setFilteredAndSortedCallouts(sortedList as Callout[]);
  };

  return (
    <CalloutsContext.Provider
      value={{
        favoriteCallouts: favoriteCallouts ?? defaultContext.favoriteCallouts,
        callouts: filteredAndSortedCallouts ?? defaultContext.callouts,
        removeFavoriteCallout: removeFavoriteCallout,
        addFavoriteCallout: addFavoriteCallout,
        sortCalloutsBy: sortCalloutsBy,
      }}
    >
      {children}
    </CalloutsContext.Provider>
  );
};

export const useCalloutsContext = () => useContext(CalloutsContext);
