import { SitefinityTool } from "../../types/tools-resources-callouts";
import { useToolsContext } from "../../context/tools/tools-context";
import { ShowDashboardFavoriteActionButton } from "components/dashboard-favorite-action-button/dashboard-favorite-action-button";
import { useUserRoles } from "context/user-roles/user-roles-context";

interface Props {
  data: SitefinityTool;
  type: string;
  displayType: string;
  onFavoriteChange?: Function;
}

const ToolsCard = ({ data, type, displayType, onFavoriteChange }: Props) => {
  const { addFavoriteTool, removeFavoriteTool } = useToolsContext();
  const { impersonating, isStudent } = useUserRoles();
  const addFavoriteClick = async () => {
    addFavoriteTool(data.Id, type);
    if (onFavoriteChange) {
      onFavoriteChange();
    }
  };
  const removeFavoriteClick = () => {
    removeFavoriteTool(data.Id);
    if (onFavoriteChange) {
      onFavoriteChange();
    }
  };

  return (
    <li className="tools-card">
      {data.Image && data.Image.length > 0 ? (
        <img
          className="tools-card__image"
          src={
            data.Image[0].Url
              ? data.Image[0].Url
              : `/images/default-source/default-album/profile-pic.png`
          }
          alt={data.Image[0].AlternativeText}
        />
      ) : (
        <img
          className="tools-card__image"
          src={`/images/default-source/default-album/profile-pic.png`}
          alt=""
        />
      )}
      <div className="tools-card__content">
        {data.tooltypes && data.tooltypes.length > 0 && (
          <span className="tools-card__tag">{data.tooltypes[0].Title}</span>
        )}
        {data.Link ? (
          <h3 className="tools-card__title">
            <a
              href={data.Link}
              className="tools-card__link"
              target="_blank"
              aria-label={`${data.Title} will open in a new window`}
            >
              {data.Title}
            </a>
          </h3>
        ) : (
          <h3 className="tools-card__title">{data.Title}</h3>
        )}
        {data.Summary && (
          <div
            className="tools-card__summary"
            dangerouslySetInnerHTML={{ __html: data.Summary }}
          ></div>
        )}
      </div>
      <div className="tools-card__aside">
        {data.Link && (
          <a
            href={data.Link}
            target="_blank"
            className="button button--solid tools-card__button"
          >
            Launch
            <span className="visually-hidden">
              {data.Title} will open a new window
            </span>
          </a>
        )}
        {!isStudent || impersonating ? (
          <></>
        ) : displayType === "listing" ? (
          <ShowDashboardFavoriteActionButton
            onClick={addFavoriteClick}
            text={"Show on Dashboard"}
          />
        ) : (
          <ShowDashboardFavoriteActionButton
            onClick={removeFavoriteClick}
            text={"Remove from Dashboard"}
            isFavorite={true}
          />
        )}
      </div>
    </li>
  );
};
export default ToolsCard;
