import { AccountDetail, ActiveTerm, AnticipatedAid } from "types/integrations";

export const getEarliestDueDate = (accountDetails: AccountDetail[]) => {
  const validAccountDetails = accountDetails.filter((item) => {
    if (item.DueDate !== "" && item.DueDate !== null) return item;
  });

  if (validAccountDetails.length === 0) return "";
  else if (validAccountDetails.length === 1) return undefined;
  else {
    const sortedAccountDetailList =
      sortAccountDetailsByDate(validAccountDetails);
    return sortedAccountDetailList[0].DueDate;
  }
};

// Sorts from oldest to soonest dates
export const sortAccountDetailsByDate = (accountDetails: AccountDetail[]) => {
  const sortedList = accountDetails.sort((item1, item2) => {
    const item1Date = new Date(item1.DueDate).toISOString();
    const item2Date = new Date(item2.DueDate).toISOString();
    return item1Date < item2Date ? -1 : 1;
  });
  return sortedList;
};

export const getAnticipatedAidTotal = (
  anticipatedAid: AnticipatedAid[],
  activeTerm: string
) => {
  let total = 0;
  anticipatedAid.forEach((aid) => {
    if (aid.Term === activeTerm) total += +aid.Amount;
  });

  return total;
};

export const getActiveFinancialAidYearsFromTerm = (
  selectedTermValue: string,
  studentTerms: ActiveTerm[]
) => {
  const currentTermInformation = studentTerms.find(
    (term) => term.Term === selectedTermValue
  );
  if (!currentTermInformation) return undefined;

  const selectedTermDate = new Date(currentTermInformation.StartDate);
  if (currentTermInformation.Description.includes("Fall")) {
    return (
      selectedTermDate.getFullYear() +
      "-" +
      (selectedTermDate.getFullYear() + 1).toString()
    );
  } else {
    return (
      (selectedTermDate.getFullYear() - 1).toString() +
      "-" +
      selectedTermDate.getFullYear().toString()
    );
  }
};
