import ReactFocusLock from "react-focus-lock";
interface Props {
  modalContent: React.ReactNode;
  title?: string;
  onClose: () => void;
}
// TODO: Alex make generic Modal styles, so we can re-use this modal in future as common component
export const Modal = ({ title, modalContent, onClose }: Props) => {
  return (
    <ReactFocusLock>
      <div className="modal" role="dialog" tabIndex={-1}>
        <div className="modal__dialog" role="document">
          <button className="modal__close button" onClick={onClose}>
            <span className="visually-hidden">Close Dialog</span>
          </button>
          <div className="modal__header">{title}</div>
          <div className="modal__content">{modalContent}</div>
        </div>
      </div>
    </ReactFocusLock>
  );
};
