import ReactDOM from "react-dom";
import StudentOrganizationsListing from "../../components/student-organizations/student-organizations-listing";
import { useStudentOrganizationContext } from "../../context/student-organizations/student-organizations-context";
import { useDivInjector } from "hooks/useDivInjector";
import { BusyIndicator } from "components/busyIndicator/busy-indicator";
import ErrorComponent from "components/common/errorComponent";
import { Types } from "types/tools-resources-callouts";
import { useDataModel } from "hooks/useDataModel";
import { Model } from "types/data-models";

export default function StudentOrganizationsList() {
  const { injected } = useDivInjector("student-organization-list");
  useDataModel(Model.OrganizationClub, injected, "student-organization-list");
  const container = document.getElementById(
    "student-organization-list"
  ) as HTMLElement;
  const data = container.dataset.model;
  const parsedData = (data && JSON.parse(data)) || {};
  const {
    studentFavoriteOrgs,
    nonStudentFavoriteOrgs,
    studentFavoriteOrgsResp,
    allOrgsResp,
  } = useStudentOrganizationContext();
  const { isLoading: studentFavsLoading, error: studentFavsError } =
    studentFavoriteOrgsResp;
  const { isLoading: allOrgsLoading, error: allOrgsError } = allOrgsResp;
  return ReactDOM.createPortal(
    <>
      {studentFavsError || allOrgsError ? (
        <ErrorComponent
          message={studentFavsError?.message || allOrgsError?.message || ""}
        />
      ) : studentFavsLoading || allOrgsLoading ? (
        <BusyIndicator />
      ) : (
        studentFavoriteOrgs &&
        nonStudentFavoriteOrgs && (
          <>
            <section className="tools-listing">
              <div className="tools-listing__header">
                <h2 className="tools-listing__title">On Dashboard</h2>
              </div>
              <StudentOrganizationsListing
                data={studentFavoriteOrgs}
                type={Types.Organization}
                displayType="favorite"
                url={
                  parsedData?.linkurl ||
                  "/student-life/student-organization-detail"
                }
              />
            </section>
            <section className="tools-listing">
              <div className="tools-listing__header">
                <h2 className="tools-listing__title">More Organizations</h2>
              </div>
              <StudentOrganizationsListing
                data={nonStudentFavoriteOrgs}
                type={Types.Organization}
                displayType="listing"
                url={
                  parsedData?.linkurl ||
                  "/student-life/student-organization-detail"
                }
              />
            </section>
          </>
        )
      )}
    </>,
    container
  );
}
