import { isNaN } from "lodash";
import React from "react";
import {
  CalendarItem,
  CalendarItemDisplayTypes,
} from "../../../types/calendar";
import { parseDate } from "../../../utilities/date-parser";

interface Props {
  calendarItem: CalendarItem;
  displayType: string;
  isToday?: boolean;
}
export default function CalendarCard({
  calendarItem,
  displayType,
  isToday,
}: Props) {
  return (
    <li className={`calendar-card calendar-card--${displayType}`}>
      <div
        className={`calendar-card__date calendar-card__date--${calendarItem.Type}`}
      >
        {calendarItem && (
          <>
            {calendarItem.StartDate && !isNaN(calendarItem.StartDate) && (
              <>
                {calendarItem.IsTimeless ? (
                  <span className="calendar-card__text ">All Day</span>
                ) : (
                  <span className="calendar-card__text ">
                    {parseDate(calendarItem.StartDate, "startTime")}
                  </span>
                )}
              </>
            )}
          </>
        )}
        {displayType === CalendarItemDisplayTypes.Date && isToday && (
          <span className="calendar-card__text">Due</span>
        )}
      </div>
      <div className="calendar-card__content">
        <div className="calendar-card__row">
          <svg
            className={`calendar-card__icon calendar-card__icon--${calendarItem.Type}`}
          >
            <use href={`#${calendarItem.IconName}`}></use>
          </svg>
          {displayType === CalendarItemDisplayTypes.Date && (
            <span
              className={`calendar-card__value text-weight-medium calendar-card__value--${calendarItem.Type}`}
            >
              {calendarItem.Type}
            </span>
          )}
        </div>
        <h3 className="calendar-card__title">
          <div className="calendar-card__link-inactive">
            {calendarItem && calendarItem.Title}
          </div>
        </h3>
        {displayType === CalendarItemDisplayTypes.Date &&
          calendarItem &&
          calendarItem.Location && (
            <div className="calendar-card__row">
              <svg className="calendar-card__icon calendar-card__icon--online">
                <use href="#map-marker-alt"></use>
              </svg>
              <span className="calendar-card__value">
                {calendarItem.Location}
              </span>
            </div>
          )}
      </div>
    </li>
  );
}
