import {
  Course,
  Instructor,
  InstructorEducation,
} from "types/staff-and-instructors";

interface Props {
  instructor: Instructor;
}
export const InsturctorDetailLeftCard = ({ instructor }: Props) => {
  const instructorEducationParsed = instructor.Education
    ? (JSON.parse(instructor.Education) as InstructorEducation)
    : undefined;

  return (
    <div className="instructor-aside">
      {instructor.Education && (
        <div className="instructor-aside__section">
          <h2 className="instructor-aside__heading">Education</h2>
          {instructorEducationParsed && (
            <>
              {instructorEducationParsed.Degrees.map((degree) => {
                return (
                  <>
                    <p>
                      {degree.DegreeType +
                        " in " +
                        degree.Subject +
                        ", " +
                        degree.School +
                        " at " +
                        degree.Location +
                        ";"}
                    </p>
                  </>
                );
              })}
              {instructorEducationParsed.Certificates.map((certificate) => {
                return (
                  <>
                    <p>{certificate.Description + "."}</p>
                  </>
                );
              })}
            </>
          )}
        </div>
      )}
      {instructor.Affiliations && (
        <div className="instructor-aside__section">
          <h2 className="instructor-aside__heading">
            Professional Affiliations
          </h2>
          <div
            className="instructor-aside__content"
            dangerouslySetInnerHTML={{ __html: instructor.Affiliations }}
          />
        </div>
      )}
      {instructor.Courses && (
        <div className="instructor-aside__section">
          <h2 className="instructor-aside__heading">Course I Teach</h2>
          {instructor.Courses.map((course: Course) => (
            <div key={course.Id} className="instructor-aside__content">
              <a href={course.ItemDefaultUrl}>{course.Title}</a>
            </div>
          ))}
        </div>
      )}
      {instructor.Links && (
        <div className="instructor-aside__section">
          <h2 className="instructor-aside__heading">Links</h2>
          <div
            className="instructor-aside__content"
            dangerouslySetInnerHTML={{ __html: instructor.Links }}
          />
        </div>
      )}
    </div>
  );
};
