import React, { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import ReactDOM from "react-dom";
import FacultyCard from "../../components/faculty/faculty-card";
import CardContainer from "../../components/containers/card-container";
import { useHttp } from "hooks/useHttp";
import {
  EabStudentProfileApiResponse,
  PeoplesoftStudentClassApiResponse,
} from "types/integrations";
import {
  getEabStudentProfileUrl,
  getPeopleSoftUserClassesUrl,
  getSitefinityInstructorForFacultyTabsUrl,
  getSitefinityStaffForFacultyTabsUrl,
} from "utilities/urls";
import {
  InstructorForDashboard,
  StaffForDashboard,
} from "types/staff-and-instructors";
import ErrorComponent from "components/common/errorComponent";
import { BusyIndicator } from "components/busyIndicator/busy-indicator";
import { useUserRoles } from "context/user-roles/user-roles-context";
import { FUNCTIONALITY_AVAILABLE_TO_STUDENTS_ONLY } from "context/common/constant";
import { useStudentContext } from "context/student/student-context";
import { getCurrentTermFromActiveTerms } from "utilities/student";

// InstructorIds and FacultyIds are synonymous
export default function FacultyTabs() {
  const container = document.getElementById("faculty-tabs") as HTMLElement;
  const componentHeading = container.dataset.heading;
  const academicAdvisorLink = container.dataset.academicadvisorlink;
  const [facultyIds, setFacultyIds] = useState<string[]>([]);
  const [advisorIds, setAdvisorIds] = useState<string[]>([]);
  const { isStudent } = useUserRoles();

  const {
    data: studentProfileData,
    isLoading: studentProfileLoading,
    error: studentProfileError,
  } = useHttp<EabStudentProfileApiResponse>("get", getEabStudentProfileUrl());

  const {
    data: classesData,
    isLoading: classesDataLoading,
    error: classesError,
  } = useHttp<PeoplesoftStudentClassApiResponse>(
    "get",
    getPeopleSoftUserClassesUrl()
  );
  const { studentTerms } = useStudentContext();
  useEffect(() => {
    if (
      studentProfileData &&
      studentProfileData.StudentProfile &&
      studentProfileData.StudentProfile.relationships
    ) {
      let advisorIdsFromStudentProfile = Array<string>();

      studentProfileData.StudentProfile.relationships.map((item) => {
        if (item.id !== "" && item.id !== undefined) {
          advisorIdsFromStudentProfile.push(item.id);
        }
      });
      if (advisorIdsFromStudentProfile)
        setAdvisorIds(advisorIdsFromStudentProfile);
    }
  }, [studentProfileData]);

  useEffect(() => {
    if (classesData && classesData.Classes && !classesDataLoading) {
      const instructorIdsSet = new Set<string>();
      classesData.Classes.map((studentClass) => {
        if (
          studentClass.InstructorID !== "" &&
          studentClass.InstructorID !== undefined
        ) {
          instructorIdsSet.add(studentClass.InstructorID);
        }
      });
      let instructorIdsArray = Array<string>();
      instructorIdsSet.forEach((id) => {
        instructorIdsArray.push(id);
      });

      setFacultyIds(instructorIdsArray);
    }
  }, [classesData]);

  const {
    data: facultyData,
    isLoading: facultyDataLoading,
    error: facultyError,
  } = useHttp<InstructorForDashboard[]>(
    "get",
    () =>
      facultyIds.length > 0 &&
      getSitefinityInstructorForFacultyTabsUrl(facultyIds),
    true
  );

  const {
    data: facultyDataFallback,
    isLoading: facultyFallbackLoading,
    error: facultyFallbackError,
  } = useHttp<StaffForDashboard[]>(
    "get",
    () =>
      facultyIds.length > 0 && getSitefinityStaffForFacultyTabsUrl(facultyIds),
    true
  );
  const {
    data: advisorsData,
    isLoading: advisorsLoading,
    error: advisorsError,
  } = useHttp<InstructorForDashboard[]>(
    "get",
    () =>
      advisorIds.length > 0 &&
      getSitefinityInstructorForFacultyTabsUrl(advisorIds),
    true
  );
  const {
    data: advisorsDataFallback,
    isLoading: advisorsFallbackLoading,
    error: advisorsFallbackError,
  } = useHttp<StaffForDashboard[]>(
    "get",
    () =>
      advisorIds.length > 0 && getSitefinityStaffForFacultyTabsUrl(advisorIds),
    true
  );

  return (
    container &&
    ReactDOM.createPortal(
      <>
        <CardContainer title={componentHeading || "Success Team"}>
          {!isStudent ? (
            <>{FUNCTIONALITY_AVAILABLE_TO_STUDENTS_ONLY}</>
          ) : (
            <div className="faculty-tabs">
              <Tabs
                className="react-tabs react-tabs--secondary"
                forceRenderTabPanel
              >
                <TabList>
                  <Tab>Advisors</Tab>
                  <Tab>Faculty</Tab>
                </TabList>
                <TabPanel>
                  {studentProfileError ||
                  advisorsError ||
                  advisorsFallbackError ? (
                    <ErrorComponent
                      message={
                        (studentProfileError && studentProfileError.message) ||
                        (advisorsError && advisorsError.message) ||
                        (advisorsFallbackError &&
                          advisorsFallbackError.message) ||
                        ""
                      }
                    />
                  ) : studentProfileLoading ||
                    advisorsLoading ||
                    advisorsFallbackLoading ? (
                    <BusyIndicator />
                  ) : (
                    advisorsData &&
                    advisorsDataFallback &&
                    studentProfileData && (
                      <>
                        {advisorsData.length === 0 &&
                        advisorsDataFallback.length === 0 ? (
                          <p>No Advisors were found at this time.</p>
                        ) : (
                          <ul className="faculty-tabs__listing">
                            {advisorsData.length === 0
                              ? advisorsDataFallback.map((faculty) => {
                                  const newFaculty = {
                                    Staff: [faculty],
                                  } as InstructorForDashboard;
                                  return (
                                    <FacultyCard
                                      faculty={newFaculty}
                                      detailPageUrl={
                                        "/dashboard/staff-detail/" +
                                        faculty.UrlName
                                      }
                                      isAcademicAdvisor={faculty.JobTitle.toLowerCase().includes(
                                        "academic success advisor"
                                      )}
                                      academicAdvisorLink={academicAdvisorLink}
                                    ></FacultyCard>
                                  );
                                })
                              : advisorsData.map((faculty) => {
                                  return (
                                    <FacultyCard
                                      faculty={faculty}
                                      detailPageUrl={
                                        "/dashboard/instructor-detail?instructorId=" +
                                        faculty.Id
                                      }
                                      isAcademicAdvisor={
                                        faculty.Staff[0] &&
                                        faculty.Staff[0].JobTitle.toLowerCase().includes(
                                          "academic success advisor"
                                        )
                                      }
                                      academicAdvisorLink={academicAdvisorLink}
                                    ></FacultyCard>
                                  );
                                })}
                          </ul>
                        )}
                      </>
                    )
                  )}
                </TabPanel>
                <TabPanel>
                  {classesError || facultyError || facultyFallbackError ? (
                    <ErrorComponent
                      message={
                        (classesError && classesError.message) ||
                        (facultyError && facultyError.message) ||
                        (studentTerms.error && studentTerms.error.message) ||
                        (facultyFallbackError &&
                          facultyFallbackError.message) ||
                        ""
                      }
                    />
                  ) : classesDataLoading ||
                    facultyDataLoading ||
                    (studentTerms.isLoading && facultyFallbackLoading) ? (
                    <BusyIndicator />
                  ) : facultyData && facultyDataFallback && classesData ? (
                    <>
                      {facultyData.length === 0 &&
                      facultyDataFallback.length === 0 ? (
                        <p>No Faculty Members were found at this time.</p>
                      ) : (
                        <>
                          {studentTerms.data && (
                            <p>
                              Term:{" "}
                              {
                                getCurrentTermFromActiveTerms(
                                  studentTerms.data.ActiveTerms
                                ).currentTerm
                              }
                            </p>
                          )}
                          <ul className="faculty-tabs__listing">
                            {facultyData.length === 0
                              ? facultyDataFallback.map((faculty) => {
                                  const newFaculty = {
                                    Staff: [faculty],
                                  } as InstructorForDashboard;
                                  return (
                                    <FacultyCard
                                      faculty={newFaculty}
                                      detailPageUrl={
                                        "/dashboard/staff-detail/" +
                                        faculty.UrlName
                                      }
                                    ></FacultyCard>
                                  );
                                })
                              : facultyData.map((faculty) => {
                                  return (
                                    <FacultyCard
                                      faculty={faculty}
                                      detailPageUrl={
                                        "/dashboard/instructor-detail?instructorId=" +
                                        faculty.Id
                                      }
                                    ></FacultyCard>
                                  );
                                })}
                          </ul>
                        </>
                      )}
                    </>
                  ) : (
                    <p>No Faculty Members were found at this time.</p>
                  )}
                </TabPanel>
              </Tabs>
            </div>
          )}
        </CardContainer>
      </>,
      container
    )
  );
}
