import { useCallback, useEffect, useRef, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import ReactDOM from "react-dom";
import CardContainer from "../../components/containers/card-container";
import Link from "../../components/links/link";
import A11YSlider from "a11y-slider";
import {
  getDashboardGroupItems,
  getDayNameFromDate,
  onFilterCalendarItemsOnDashboard,
} from "../../utilities/calendar";
import {
  CalendarDayInfo,
  CalendarGroup,
  CalendarItem,
  CalendarItemDisplayTypes,
  CalendarItemsTypeFilterOptions,
} from "../../types/calendar";
import CalendarCard from "../../components/calendar/common/calendar-card";
import { useHttpForCalendar } from "../../hooks/useHttpForCalendarData";
import { BusyIndicator } from "../../components/busyIndicator/busy-indicator";
import ErrorComponent from "components/common/errorComponent";
import CustomDropdown from "components/dropdowns/dropdown";
import {
  DropdownOptionTypes,
  FilterDropdownOption,
} from "types/sort-and-filter-options";
import { useUserRoles } from "context/user-roles/user-roles-context";
import { FUNCTIONALITY_AVAILABLE_TO_STUDENTS_ONLY } from "context/common/constant";

const initialTodayGroupInfo = {
  CalendarDayInfoItems: [],
} as CalendarGroup;

const initialUpcomingInfo = {
  CalendarDayInfoItems: [],
} as CalendarGroup;

const itemsPerPage = 8;
let todaySlider: A11YSlider;
let upcomingSlider: A11YSlider;

export default function CalendarFeatured() {
  const container = document.getElementById("calendar-featured") as HTMLElement;
  const componentHeading = container.dataset.heading;
  const personalReminderLink = container.dataset.personalreminderlink;
  const today = new Date(container.dataset.today!);
  const upcomingEndDate = new Date(today);
  upcomingEndDate.setDate(upcomingEndDate.getDate() + 7);

  const todaySliderRef = useRef(null) as
    | React.MutableRefObject<HTMLDivElement>
    | React.MutableRefObject<null>;
  const upcomingSliderRef = useRef(null) as
    | React.MutableRefObject<HTMLDivElement>
    | React.MutableRefObject<null>;
  const [activeFilter, setActiveFilter] = useState<FilterDropdownOption>({
    Value: CalendarItemsTypeFilterOptions[0].Value,
    Label: CalendarItemsTypeFilterOptions[0].Label,
  });
  const [todayInfoGroup, setTodayInfoGroup] = useState(initialTodayGroupInfo);
  const [upcomingInfo, setUpcomingInfo] = useState(initialUpcomingInfo);
  const { isStudent } = useUserRoles();

  const {
    data: calendarItems,
    isLoading,
    isValidating,
    error,
  } = useHttpForCalendar(
    "CalendarFeatured",
    new Date(today),
    new Date(upcomingEndDate)
  );

  const updateUpcomingCalendarGroupItems = useCallback(
    (items: CalendarDayInfo[], type: string) => {
      if (type === "today") {
        setTodayInfoGroup((previousState) => {
          return {
            ...previousState,
            CalendarDayInfoItems: items,
          };
        });
      } else if (type === "upcoming") {
        setUpcomingInfo((previousState) => {
          return {
            ...previousState,
            CalendarDayInfoItems: items,
          };
        });
      }
    },
    [todayInfoGroup.CalendarDayInfoItems, upcomingInfo.CalendarDayInfoItems]
  );

  const updateCalendarItems = useCallback(
    (items: CalendarItem[], type: string) => {
      if (calendarItems) {
        if (type === "upcoming") {
          const upcomingWeekInfo = getDashboardGroupItems(items, itemsPerPage);
          updateUpcomingCalendarGroupItems(upcomingWeekInfo, type);
        } else if (type === "today") {
          const dayInfo = getDashboardGroupItems(items, itemsPerPage);
          updateUpcomingCalendarGroupItems(dayInfo, type);
        }
      }
    },
    [calendarItems, activeFilter]
  );

  useEffect(() => {
    if (isLoading || isValidating || !calendarItems) return;

    const todayCalendarItems = onFilterCalendarItemsOnDashboard(
      calendarItems,
      today.toISOString(),
      undefined,
      activeFilter?.Value
    );
    const todayDate = new Date(today);
    const upcomingStartDate = new Date(
      todayDate.setDate(todayDate.getDate() + 1)
    );

    const upcomingCalendarItems = onFilterCalendarItemsOnDashboard(
      calendarItems,
      upcomingStartDate.toISOString(),
      upcomingEndDate.toISOString(),
      activeFilter?.Value
    );

    updateCalendarItems(upcomingCalendarItems, "upcoming");
    updateCalendarItems(todayCalendarItems, "today");
  }, [calendarItems, activeFilter]);

  useEffect(() => {
    const slideSettings = {
      slidesToShow: 1,
      arrows: true,
      dots: false,
      infinite: false,
    };
    if (todaySliderRef.current) {
      if (todaySliderRef.current.classList.contains("a11y-slider")) {
        todaySlider.destroy();
      }
      if (todayInfoGroup.CalendarDayInfoItems.length > 1) {
        todaySlider = new A11YSlider(
          (todaySliderRef as React.MutableRefObject<HTMLDivElement>).current,
          slideSettings
        );
      }
    }

    if (upcomingSliderRef.current) {
      if (upcomingSliderRef.current.classList.contains("a11y-slider")) {
        upcomingSlider.destroy();
      }
      if (upcomingInfo.CalendarDayInfoItems.length > 1) {
        upcomingSlider = new A11YSlider(
          (upcomingSliderRef as React.MutableRefObject<HTMLDivElement>).current,
          slideSettings
        );
      }
    }
  }, [todayInfoGroup, upcomingInfo]);

  const onFilter = useCallback(
    (value: string, label?: string) => {
      setActiveFilter((previousState) => {
        return { Value: value, Label: !label ? previousState.Label : label };
      });
    },
    [activeFilter]
  );

  // render groups of items for a11y slider slides
  // adding date header for each group and when each item changes date
  const renderCalendarItems = (items: CalendarDayInfo[]) => {
    return items.map((dayInfoItem) => {
      let currentDay = dayInfoItem.DayName;
      let showNewDate = false;
      return (
        <div className="calendar-group">
          {dayInfoItem.CalendarItems.map((item, itemIndex) => {
            const startDate = new Date(item.StartDate);
            const dayName: string = getDayNameFromDate(
              new Date(item.StartDate)
            )!;
            const month = startDate.toLocaleDateString("en-US", {
              month: "short",
            });
            const day = startDate.getDate();
            const dateHeading = `${dayName}, ${day} ${month}`;
            if (currentDay !== dayName) {
              currentDay = dayName;
              showNewDate = true;
            } else {
              showNewDate = false;
            }
            return (
              <>
                {itemIndex % 8 === 0 || showNewDate ? (
                  <h3 className="calendar-tabs__heading">{dateHeading}</h3>
                ) : (
                  ""
                )}
                <CalendarCard
                  displayType={CalendarItemDisplayTypes.Date}
                  key={itemIndex}
                  calendarItem={item}
                  isToday={false}
                />
              </>
            );
          })}
        </div>
      );
    });
  };

  return (
    container &&
    ReactDOM.createPortal(
      <CardContainer
        title={componentHeading || "My Calendar"}
        utility={
          isStudent ? (
            <CustomDropdown
              type="tertiary"
              optionsType={DropdownOptionTypes.Filters}
              toggleTitle={
                !activeFilter?.Label ? "All Types" : activeFilter.Label
              }
              options={CalendarItemsTypeFilterOptions}
              onFilter={onFilter}
            ></CustomDropdown>
          ) : (
            <></>
          )
        }
      >
        {!isStudent ? (
          <>{FUNCTIONALITY_AVAILABLE_TO_STUDENTS_ONLY}</>
        ) : error ? (
          <ErrorComponent message={error.message} />
        ) : isLoading || isValidating ? (
          <BusyIndicator />
        ) : (
          <Tabs
            className="react-tabs react-tabs--secondary react-tabs--calendar"
            forceRenderTabPanel
          >
            <TabList>
              <Tab>Today</Tab>
              <Tab>Upcoming Week</Tab>
            </TabList>

            <TabPanel>
              <div className="calendar-tabs__list" ref={todaySliderRef}>
                {todayInfoGroup.CalendarDayInfoItems.length === 0 ? (
                  <div className="calendar-tabs__no-items">
                    You have no events today.{" "}
                  </div>
                ) : (
                  renderCalendarItems(todayInfoGroup.CalendarDayInfoItems)
                )}
              </div>
            </TabPanel>
            <TabPanel>
              {/* each group needs  */}
              <div className="calendar-tabs__list" ref={upcomingSliderRef}>
                {upcomingInfo.CalendarDayInfoItems.length === 0 ? (
                  <div className="calendar-tabs__no-items">
                    {" "}
                    You have no events this week.{" "}
                  </div>
                ) : (
                  renderCalendarItems(upcomingInfo.CalendarDayInfoItems)
                )}
              </div>
            </TabPanel>
          </Tabs>
        )}
        <div className="card-container__footer">
          <Link
            url={personalReminderLink ?? "#"}
            openNewTab={true}
            label="Add Personal Reminder"
            type="plus"
          ></Link>
          <Link
            url="/dashboard/calendar"
            label="View Calendar"
            type="arrow"
          ></Link>
        </div>
      </CardContainer>,
      container
    )
  );
}
