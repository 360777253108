import { FilterDropdownOption } from "types/sort-and-filter-options";
import { FavoriteOrganizations, StudentOrganization } from "types/student";
import {
  Callout,
  FavoriteItem,
  SitefinityPage,
  SitefinityTool,
} from "types/tools-resources-callouts";

export const mapFavoritesFromTools = (
  tools: SitefinityTool[],
  favorites: FavoriteItem[]
) => {
  const favoriteTools: SitefinityTool[] = tools.filter((tol) =>
    favorites.some((fav) => fav.id === tol.Id)
  );
  const nonFavoriteTools: SitefinityTool[] = tools.filter(
    (tol) => !favorites.some((fav) => fav.id === tol.Id)
  );

  return { favoriteTools, nonFavoriteTools };
};

export const mapFavoriteOrganizationsFromOrgs = (
  organizations: StudentOrganization[],
  favoriteOrgs: FavoriteOrganizations[]
) => {
  const favoriteOrganizations: StudentOrganization[] = organizations.filter(
    (org) => favoriteOrgs.some((fav) => fav.id === org.Id)
  );
  const nonFavoriteOrganizations: StudentOrganization[] = organizations.filter(
    (org) => !favoriteOrgs.some((fav) => fav.id === org.Id)
  );
  return { favoriteOrganizations, nonFavoriteOrganizations };
};

export const mapFavoritesFromCallouts = (
  callouts: Callout[],
  favorites: FavoriteItem[]
) => {
  const favoriteCallouts: Callout[] = callouts
    .filter((res) => favorites.some((fav) => fav.id === res.Id))
    .sort((callout1, callout2) => {
      return callout1.Title < callout2.Title ? -1 : 1;
    });
  const nonFavoriteCallouts: Callout[] = callouts.filter(
    (res) => !favorites.some((fav) => fav.id === res.Id)
  );

  return { favoriteCallouts, nonFavoriteCallouts };
};

// Filter and then sort by Title Ascending
export const mapFavoritesFromResources = (
  resources: SitefinityPage[],
  favorites: FavoriteItem[]
) => {
  const favoriteResources: SitefinityPage[] = resources

    .filter((res) => favorites.some((fav) => fav.id === res.Id))
    .sort((resource1, resource2) => {
      return resource1.Title < resource2.Title ? -1 : 1;
    });

  const nonFavoriteResources: SitefinityPage[] = resources
    .filter((res) => !favorites.some((fav) => fav.id === res.Id))
    .sort((resource1, resource2) => {
      return resource1.Title < resource2.Title ? -1 : 1;
    });

  return { favoriteResources, nonFavoriteResources };
};

export const mapRelevantFiltersFromTools = (tools: SitefinityTool[]) => {
  /* Get a list of all tool types from all tools */
  const relevantFilters = Array<FilterDropdownOption>();
  tools.filter((tool) => {
    tool.tooltypes.filter((toolType) => {
      if (
        relevantFilters.findIndex(
          (relevantFilter) => relevantFilter.Value === toolType.Id
        ) === -1
      )
        relevantFilters.push({ Label: toolType.Title, Value: toolType.Id });
    });
  });

  return relevantFilters;
};
