import React, { useEffect, useState } from "react";
import {
  CalendarItem,
  CalendarItemDisplayTypes,
  CalendarWeek as CalendarWeekDay,
} from "../../../types/calendar";
import {
  buildWeekFromStartDate,
  daysOfWeekLongMondayFirst,
  getCurrentWeekFirstDay,
  getNextWeek,
  getPreviousWeek,
  onFilterCalendarItemsByDate,
} from "../../../utilities/calendar";
import CalendarCard from "../common/calendar-card";

import CalendarWeekListPagination from "./calendar-week-list-pagination";
import { useHttpForCalendar } from "../../../hooks/useHttpForCalendarData";
import ErrorComponent from "components/common/errorComponent";
import { FilterDropdownOption } from "types/sort-and-filter-options";
import { BusyIndicator } from "components/busyIndicator/busy-indicator";
interface Props {
  activeCalendarFilter: FilterDropdownOption;
  today: Date;
}

export default function CalendarWeekView({
  today,
  activeCalendarFilter,
}: Props) {
  const originalCalendarStartDate = getCurrentWeekFirstDay(today);
  const originalCalendarEndDate = buildWeekFromStartDate(
    originalCalendarStartDate
  );

  const [calendarDates, setCalendarDates] = useState({
    startDate: new Date(originalCalendarStartDate),
    endDate: new Date(originalCalendarEndDate),
  });
  const [calendarWeekDays, setCalendarWeekDays] = useState(
    Array<CalendarWeekDay>()
  );
  const [areEventsAdded, setAreEventsAdded] = useState(false);
  const {
    data: calendarItems,
    isLoading,
    isValidating,
    error,
  } = useHttpForCalendar(
    "CalendarList",
    calendarDates.startDate,
    calendarDates.endDate
  );

  const changeWeek = (direction: string) => {
    if (direction === undefined || direction === "") return;

    const newStartDate = new Date(calendarDates.startDate);
    newStartDate.setDate(newStartDate.getDate() + 7);

    if (direction === "Next") {
      setCalendarDates(getNextWeek(newStartDate));
    } else if (direction === "Previous") {
      setCalendarDates(getPreviousWeek(newStartDate));
    }
  };

  const goToWeek = (weekToGoTo: string) => {
    if (weekToGoTo === "This") {
      setCalendarDates({
        startDate: new Date(originalCalendarStartDate),
        endDate: new Date(originalCalendarEndDate),
      });
    } else {
      const newStartDate = new Date(originalCalendarStartDate);
      newStartDate.setDate(newStartDate.getDate() + 7);
      if (weekToGoTo === "Upcoming") {
        setCalendarDates(getNextWeek(newStartDate));
      } else if (weekToGoTo === "Last") {
        setCalendarDates(getPreviousWeek(newStartDate));
      }
    }
  };
  useEffect(() => {
    if (isLoading || isValidating || !calendarItems) return;

    if (calendarItems) {
      let filteredCalendarItems = Array<CalendarItem>();
      if (activeCalendarFilter.Label === "All Types") {
        filteredCalendarItems = [...calendarItems];
      } else {
        calendarItems.map((calendarItem) => {
          if (calendarItem.Type === activeCalendarFilter.Value) {
            filteredCalendarItems.push(calendarItem);
          }
        });
      }
      let newCalendarWeekDays = Array<CalendarWeekDay>();
      let date = new Date(calendarDates.startDate);

      daysOfWeekLongMondayFirst.forEach((day) => {
        const weekCalendarItems = onFilterCalendarItemsByDate(
          filteredCalendarItems,
          date.toISOString()
        );

        const weekData = {
          dayName: day.DayName,
          dayNumber: date.getDate(),
          monthName: date.toLocaleString("default", { month: "short" }),
          calendarItems: weekCalendarItems,
        } as CalendarWeekDay;

        // set date to next day of the week
        date.setDate(date.getDate() + 1);
        newCalendarWeekDays.push(weekData);
      });
      setCalendarWeekDays(newCalendarWeekDays);
    }
  }, [calendarItems, activeCalendarFilter]);

  return (
    <div className="calendar-list">
      {error ? (
        <ErrorComponent message={error.message} />
      ) : isLoading || isValidating ? (
        <BusyIndicator />
      ) : (
        <>
          <div className="calendar-controls">
            <button
              className="button calendar-controls__button"
              onClick={() => goToWeek("Last")}
            >
              Last Week
            </button>
            <button
              className="button calendar-controls__button"
              onClick={() => goToWeek("This")}
            >
              This Week
            </button>
            <button
              className="button calendar-controls__button"
              onClick={() => goToWeek("Upcoming")}
            >
              Upcoming Week
            </button>
          </div>
          {!calendarWeekDays ? (
            <></>
          ) : (
            <ul className="calendar-list__list">
              {calendarWeekDays.map((item) => {
                return (
                  <li className="calendar-list__item" key={item.dayName}>
                    <div className="calendar-list__date">
                      <h2 className="calendar-list__heading">
                        <span className="calendar-list__sub-heading">
                          {item.dayName}
                        </span>
                        <span className="calendar-list__sub-heading calendar-list__sub-heading--secondary">
                          {item.dayNumber} {item.monthName}
                        </span>
                      </h2>
                    </div>
                    <ul className="calendar-list__list calendar-list__list--secondary">
                      {item.calendarItems.map((calendarItem, index) => {
                        return (
                          <CalendarCard
                            displayType={
                              CalendarItemDisplayTypes.SimpleSecondary
                            }
                            calendarItem={calendarItem}
                            key={index}
                          ></CalendarCard>
                        );
                      })}
                    </ul>
                  </li>
                );
              })}
            </ul>
          )}

          <CalendarWeekListPagination
            isLoading={isLoading!}
            isValidating={isValidating!}
            changeWeek={changeWeek}
          />
        </>
      )}
    </div>
  );
}
