import axios from "axios";

export const useBatchFetcher = <T,>(
  methodName: string,
  baseUrl: string,
  accessToken: string
) => {
  const fetcher = async (url: string) => {
    const messageIds = url.split(",");
    const urls: string[] = messageIds.map(
      (messageId: string, index: number) => {
        if (index === 0) {
          return messageId;
        }
        return baseUrl + `${messageId}`;
      }
    );
    const resp = await axios.all(
      urls.map((url: string) =>
        axios({
          method: methodName,
          url: url,
          headers: { Authorization: `Bearer ${accessToken}` },
        })
      )
    );
    return resp.map((response) => response.data);
  };
  return { fetcher };
};
