import { BusyIndicator } from "components/busyIndicator/busy-indicator";
import ErrorComponent from "components/common/errorComponent";
import { useHttp } from "hooks/useHttp";
import { useEffect, useState } from "react";
import { EabStudentProfileApiResponse } from "types/integrations";
import { StaffResp } from "types/staff-and-instructors";
import { getEabStudentProfileUrl, getSitefinityStaffUrl } from "utilities/urls";
import CardContainer from "../containers/card-container";
import { useUserRoles } from "context/user-roles/user-roles-context";
import { FUNCTIONALITY_AVAILABLE_TO_STUDENTS_ONLY } from "context/common/constant";
interface Props {
  componentHeading: string | undefined;
}

export default function Question({ componentHeading }: Props) {
  const [staffId, setStaffId] = useState<string[]>([]);
  const { isStudent } = useUserRoles();

  const studentProfile = useHttp<EabStudentProfileApiResponse>(
    "get",
    getEabStudentProfileUrl()
  );
  useEffect(() => {
    if (
      studentProfile.data &&
      studentProfile.data.StudentProfile &&
      studentProfile.data.StudentProfile.relationships
    ) {
      const staffMatch = studentProfile.data.StudentProfile.relationships.find(
        (item) => item.relationship_name === "Academic Success Advisor"
      );
      if (staffMatch) setStaffId([staffMatch.id]);
    }
  }, [studentProfile.data]);
  const studentAdvisor = useHttp<StaffResp>(
    "get",
    getSitefinityStaffUrl(staffId)
  );

  const studentAdvisorData =
    studentAdvisor.data && studentAdvisor.data.value.length > 0
      ? studentAdvisor.data.value[0]
      : undefined;
  return (
    <CardContainer title={componentHeading || "Have a Question?"}>
      {!isStudent ? (
        <>{FUNCTIONALITY_AVAILABLE_TO_STUDENTS_ONLY}</>
      ) : studentProfile.error || studentAdvisor.error ? (
        <ErrorComponent
          message={
            studentProfile.error?.message || studentAdvisor.error?.message || ""
          }
        />
      ) : studentProfile.isLoading || studentAdvisor.isLoading ? (
        <BusyIndicator />
      ) : studentProfile.data && studentAdvisorData ? (
        <>
          <p className="text-weight-semibold">{studentAdvisorData.Name}</p>
          <p className="text-size-small text-weight-semibold">
            Academic Success Advisor
          </p>
          <p className="text-size-small">
            Room: {studentAdvisorData.OfficeLocation}
          </p>
          <p className="text-size-small">
            <a href={"tel:" + studentAdvisorData.WorkPhone}>
              {studentAdvisorData.WorkPhone}
            </a>
          </p>
          <p className="text-size-small text-margin-bottom">
            <a href={"mailto:" + studentAdvisorData.Email}>
              {studentAdvisorData.Email}
            </a>
          </p>
          <a
            href="https://clcillinois.navigate.eab.com/app/#/my/appointment-new/"
            className="button"
          >
            Schedule Appointment
          </a>
        </>
      ) : (
        <p>No Academic Success Advisor was found at this time.</p>
      )}
    </CardContainer>
  );
}
