import React, { useContext, useEffect, useState } from "react";
import { StudentOrganization, FavoriteOrganizations } from "types/student";
import { Types } from "types/tools-resources-callouts";
import { useHttp } from "hooks/useHttp";
import { mapFavoriteOrganizationsFromOrgs } from "utilities/map-helper";
import { HttpResponseWithMutator } from "types/http";
import {
  addFavorite,
  removeFavorite,
} from "utilities/tools-resources-callouts";

interface Props {
  children: React.ReactNode;
}

export interface IStudentOrganization {
  studentFavoriteOrgs: StudentOrganization[] | undefined;
  nonStudentFavoriteOrgs: StudentOrganization[] | undefined;
  studentFavoriteOrgsResp: HttpResponseWithMutator<FavoriteOrganizations[]>;
  allOrgsResp: HttpResponseWithMutator<StudentOrganization[]>;
}

export interface IStudentOrganizationContext extends IStudentOrganization {
  addFavoriteOrg: (orgId: string, type: string) => Promise<void>;
  removeFavoriteOrg: (orgId: string) => Promise<void>;
}

const defaultState: IStudentOrganization = {
  studentFavoriteOrgs: undefined,
  nonStudentFavoriteOrgs: undefined,
  studentFavoriteOrgsResp: {} as HttpResponseWithMutator<
    FavoriteOrganizations[]
  >,
  allOrgsResp: {} as HttpResponseWithMutator<StudentOrganization[]>,
};

const defaultContext: IStudentOrganizationContext = {
  ...defaultState,
  addFavoriteOrg: async (orgId: string, type: string) => {},
  removeFavoriteOrg: async (orgId: string) => {},
};
export let StudentOrganizationContext =
  React.createContext<IStudentOrganizationContext>(defaultContext);

export const StudentOrganizationProvider: React.FC<Props> = ({ children }) => {
  const [nonFavoriteOrganizations, setNonFavoriteOrganizations] = useState<
    StudentOrganization[] | undefined
  >(defaultContext.studentFavoriteOrgs);
  const [favoriteOrganizations, setFavoriteOrganizations] = useState<
    StudentOrganization[] | undefined
  >(defaultContext.nonStudentFavoriteOrgs);
  const studentFavoriteOrgsResp = useHttp<FavoriteOrganizations[]>(
    "get",
    `/api/favorites/type/${Types.Organization}`,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    true
  );
  const { mutate } = studentFavoriteOrgsResp;
  const allOrgsResp = useHttp<StudentOrganization[]>(
    "get",
    `/sfapi/default/organizations?$select=*&$expand=Image($select=Url,AlternativeText),Staff($select=Id,WorkPhone,Name,Email)&$orderby=Title`,
    true,
    undefined,
    undefined,
    undefined,
    undefined,
    true
  );
  useEffect(() => {
    const { data: studentFavoriteOrgs } = studentFavoriteOrgsResp;
    const { data: allOrganizations } = allOrgsResp;
    if (studentFavoriteOrgs && allOrganizations) {
      const {
        favoriteOrganizations: favoriteOrgsFromMap,
        nonFavoriteOrganizations: nonFavoriteOrgsFromMap,
      } = mapFavoriteOrganizationsFromOrgs(
        allOrganizations,
        studentFavoriteOrgs
      );
      setNonFavoriteOrganizations(nonFavoriteOrgsFromMap);
      setFavoriteOrganizations(favoriteOrgsFromMap);
    }
  }, [studentFavoriteOrgsResp.data, allOrgsResp.data]);

  /**
   * Add organization to favorites
   * @param orgId
   * @param type
   */
  const addFavoriteOrg = async (orgId: string, type: string) => {
    await addFavorite(orgId, type ? type : Types.Organization);
    mutate();
  };

  /**
   * Delete organization from favorites
   * @param orgId
   */
  const removeFavoriteOrg = async (orgId: string) => {
    await removeFavorite(orgId);
    mutate();
  };
  return (
    <StudentOrganizationContext.Provider
      value={{
        studentFavoriteOrgs:
          favoriteOrganizations ?? defaultContext.studentFavoriteOrgs,
        nonStudentFavoriteOrgs:
          nonFavoriteOrganizations ?? defaultContext.nonStudentFavoriteOrgs,
        studentFavoriteOrgsResp:
          studentFavoriteOrgsResp ?? defaultContext.studentFavoriteOrgsResp,
        allOrgsResp: allOrgsResp ?? defaultContext.allOrgsResp,
        addFavoriteOrg,
        removeFavoriteOrg,
      }}
    >
      {children}
    </StudentOrganizationContext.Provider>
  );
};

export const useStudentOrganizationContext = () =>
  useContext(StudentOrganizationContext);
