import { useDivInjector } from "hooks/useDivInjector";
import { useHttp } from "hooks/useHttp";
import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import {
  DepartmentResp,
  Instructor,
  SubjectResp,
} from "types/staff-and-instructors";
import {
  InstructorDetailMainCard,
  InstructorHeader,
  InsturctorDetailLeftCard,
} from "components/instructor/";
import { BusyIndicator } from "components/busyIndicator/busy-indicator";
import ErrorComponent from "components/common/errorComponent";
import { useUserRoles } from "context/user-roles/user-roles-context";
import { FUNCTIONALITY_AVAILABLE_TO_STUDENTS_ONLY } from "context/common/constant";

export default function InstructorDetail() {
  useDivInjector("instructor-details");
  const container = document.getElementById(
    "instructor-details"
  ) as HTMLElement;
  const [instructorId, setInstructorId] = useState<string>("");
  const { isStudent } = useUserRoles();
  const {
    data: instructor,
    isLoading,
    error,
  } = useHttp<Instructor>(
    "get",
    () =>
      instructorId &&
      `/sfapi/default/instructors(${instructorId})?$expand=Photo,PrimaryVideo($expand=Thumbnail($select=ThumbnailUrl,AlternativeText)),RelatedVideos($expand=Thumbnail($select=ThumbnailUrl,AlternativeText)),Staff,Courses`
  );
  const { data: departments, isLoading: isDepartmentsLoading } =
    useHttp<DepartmentResp>(
      "get",
      () =>
        instructor &&
        instructor.Staff &&
        instructor.Staff[0] &&
        instructor.Staff[0].departments &&
        instructor.Staff[0].departments.length > 0 &&
        `/sfapi/default/hierarchy-taxa?$select=Id,Title,FullUrl&$filter=Id in(${instructor.Staff[0].departments.map(
          (deptId) => {
            return '"' + deptId + '"';
          }
        )})`
    );
  if (instructor && instructor.Staff && instructor.Staff[0] && departments) {
    instructor.Departments = [...departments.value];
  }

  useEffect(() => {
    if (window.location.href.includes("instructorId")) {
      const url = window.location.href;
      const insturctorIdentifier = url.substring(
        url.indexOf("instructorId=") + 13
      );
      setInstructorId(insturctorIdentifier);
    }
  }, []);

  return (
    container &&
    ReactDOM.createPortal(
      <>
        {!isStudent ? (
          <>{FUNCTIONALITY_AVAILABLE_TO_STUDENTS_ONLY}</>
        ) : isLoading || isDepartmentsLoading ? (
          <BusyIndicator />
        ) : error ? (
          <ErrorComponent
            message={error.message || "An unknown error occurred"}
          />
        ) : (
          instructor &&
          !isLoading &&
          !isDepartmentsLoading && (
            <article className="instructor-detail">
              <InstructorHeader instructor={instructor} />
              <div className="instructor-detail__main">
                <InsturctorDetailLeftCard instructor={instructor} />
                <InstructorDetailMainCard instructor={instructor} />
              </div>
            </article>
          )
        )}
      </>,
      container
    )
  );
}
