import { HttpResponse } from "../types/http";
import useSWR from "swr";
import { CalendarItem } from "../types/calendar";
import { useFetcherForCalendarData } from "./useFetcherForCalendarData";

export const useHttpForCalendar = (
  componentName: string,
  startDate: Date,
  endDate: Date
): HttpResponse<CalendarItem[]> => {
  const { fetcher } = useFetcherForCalendarData(startDate, endDate);

  const key =
    componentName +
    document.location.pathname +
    startDate.toLocaleString() +
    endDate.toLocaleString();

  const { data, error, isLoading, isValidating } = useSWR<CalendarItem[]>(
    key,
    fetcher,
    {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    }
  );

  return { data, error, isLoading, isValidating };
};
