import { Instructor } from "types/staff-and-instructors";

interface Props {
  instructor: Instructor;
}
export const InstructorHeader = ({ instructor }: Props) => {
  const staff = instructor.Staff && instructor.Staff[0];
  const subjectsSet: Set<string> = new Set<string>();
  instructor.Courses.map((course) => {
    subjectsSet.add(course.Subject);
  });
  const subjects = Array<string>();
  subjectsSet.forEach((subject) => {
    subjects.push(subject);
  });
  return (
    <>
      {staff && (
        <header className="instructor-header">
          <div className="instructor-header__section">
            <div className="instructor-header__profile-container">
              {instructor.Photo && instructor.Photo.length > 0 && (
                <img
                  src={instructor.Photo[0].Url}
                  alt={instructor.Photo[0].AlternativeText}
                  className="instructor-header__image"
                />
              )}

              <div className="instructor-header__content-section">
                <h1 className="instructor-header__title">{staff.Name}</h1>
                {instructor.Departments && instructor.Departments.length > 0 && (
                  <ul className="instructor-header__list">
                    {instructor.Departments.map((department) => (
                      <li
                        key={department.Id}
                        className="instructor-header__item"
                      >
                        {department.Title}
                      </li>
                    ))}
                  </ul>
                )}
                <span className="instructor-header__info">
                  {instructor.IsDepartmentChair === true &&
                    "Department Chair | "}
                  {staff.JobTitle}
                </span>
                <div className="instructor-header__sub-section">
                  <h2 className="instructor-header__heading">
                    Subjects I Teach
                  </h2>

                  {subjects &&
                    subjects.length > 0 &&
                    subjects.map((subject) => (
                      <>
                        <a href={"#"}>{subject}</a>
                        <>&nbsp;&nbsp;</>
                      </>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className="instructor-header__section">
            <h2 className="instructor-header__heading">Get in Touch</h2>
            <ul className="instructor-contact">
              <li className="instructor-contact__item">
                <h3 className="instructor-contact__heading">Office Location</h3>
                <span className="instructor-contact__info">
                  {staff.OfficeLocation}
                </span>
              </li>
              {staff.WorkPhone && (
                <li className="instructor-contact__item">
                  <h3 className="instructor-contact__heading">Work</h3>
                  <a
                    className="instructor-contact__info"
                    href={"tel:" + staff.WorkPhone}
                  >
                    {staff.WorkPhone}
                  </a>
                </li>
              )}
              {staff.Email && (
                <li className="instructor-contact__item">
                  <h3 className="instructor-contact__heading">Email</h3>
                  <a
                    className="instructor-contact__info"
                    href={"mailto:" + staff.Email}
                  >
                    {staff.Email}
                  </a>
                </li>
              )}
              {instructor.CellPhone && (
                <li className="instructor-contact__item">
                  <h3 className="instructor-contact__heading">Cell</h3>
                  <a
                    className="instructor-contact__info"
                    href={"tel:" + instructor.CellPhone}
                  >
                    {instructor.CellPhone}
                  </a>
                </li>
              )}
            </ul>
          </div>
          <div className="instructor-header__section">
            <h2 className="instructor-heading__heading">Meet with Me</h2>
            {instructor.MeetWithMe && (
              <div className="instructor-header__description">
                {instructor.MeetWithMe}
              </div>
            )}
            {instructor.SchedulingLink && (
              <a
                href={instructor.SchedulingLink}
                className="button button--solid"
              >
                {instructor.IsDocument
                  ? `View Open Office Hours`
                  : `Schedule Appointment`}
              </a>
            )}
          </div>
        </header>
      )}
    </>
  );
};
