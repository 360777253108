import { IsCalendarItemArray } from "utilities/calendar";
import { CalendarItem } from "../types/calendar";
import {
  IntegrationAxiosRequest,
  CalendarItemIntegrationDataTypes,
} from "../types/integrations";
import {
  getDataFromApiCall,
  mutateToCalendarItem,
} from "../utilities/integrations";
let property: keyof typeof CalendarItemIntegrationDataTypes;

export const useFetcherForCalendarData = (
  startDate: Date,
  endDate: Date,
  data?: any
) => {
  const fetcher = async () => {
    let requests = new Array<IntegrationAxiosRequest>();
    for (property in CalendarItemIntegrationDataTypes) {
      const dataUrl = CalendarItemIntegrationDataTypes[property].getApiUrl(
        startDate,
        endDate
      );
      // We don't want All 25 live events on the calendar.
      if (
        CalendarItemIntegrationDataTypes[property].name !==
        CalendarItemIntegrationDataTypes.TwentyFiveLiveEvents.name
      ) {
        requests.push({
          url: dataUrl,
          IntegrationTypeName: CalendarItemIntegrationDataTypes[property].name,
        });
      }
    }
    let calendarItems = Array<CalendarItem>();

    try {
      const response = await getDataFromApiCall(requests);
      response.forEach((data, dataType) => {
        const integrationDataType =
          CalendarItemIntegrationDataTypes[
            dataType as keyof typeof CalendarItemIntegrationDataTypes
          ];

        const dataArray = !integrationDataType.dataArrayName
          ? (data as Array<typeof integrationDataType.dataType>)
          : (data[integrationDataType.dataArrayName] as Array<
              typeof integrationDataType.dataType
            >);

        dataArray &&
          dataArray.forEach((item) => {
            const calendarData = mutateToCalendarItem(item, dataType);
            if (IsCalendarItemArray(calendarData)) {
              calendarItems.push(...calendarData);
            } else {
              calendarItems.push(calendarData);
            }
          });
      });
    } catch (error) {
      console.log(error);
      const errorMessage = error as Error;
      if (errorMessage) throw new Error(errorMessage.message);
    }

    return calendarItems;
  };
  return { fetcher };
};
