import CalendarCard from "./calendar-card";
import React, { useRef, useState, useEffect } from "react";
import {
  CalendarItem,
  CalendarItemDisplayTypes,
} from "../../../types/calendar";
interface Props {
  calendarItems: CalendarItem[];
  updateActiveDay: (
    dayNameIndex: number,
    dayIndex: number,
    calendarItems: CalendarItem[],
    showModal: true
  ) => void;
  modalStatus: boolean;
  dayNameIndex: number;
  dayName: string;
  dayIndex: number;
}

function CalendarDisplayItems({
  calendarItems: calendarItems,
  updateActiveDay,
  modalStatus,
  dayNameIndex,
  dayIndex,
}: Props) {
  const modalOpenRef = useRef(null) as
    | React.MutableRefObject<HTMLButtonElement>
    | React.MutableRefObject<null>;

  const [modalState, setModalState] = useState(false);

  const handleModalEvent = () => {
    setModalState(true);
    updateActiveDay(
      dayNameIndex,
      dayIndex,
      calendarItems.filter((item, index) => {
        if (index > 2) return item;
      }),
      true
    );
  };
  // when closing the modal, focus on the button that opened it
  useEffect(() => {
    if (modalState && !modalStatus) {
      (
        modalOpenRef as React.MutableRefObject<HTMLButtonElement>
      ).current.focus();
      setModalState(false);
    }
  }, [modalStatus]);

  return (
    <div className="calendar-task">
      {/* display dots on mobile */}
      <div className="calendar-task__content">
        {calendarItems.map((item, index) => {
          if (index < 3) {
            return <span key={index} className="calendar-task__dot"></span>;
          } else if (
            calendarItems.length > 3 &&
            index === calendarItems.length - 1
          ) {
            return (
              <span
                key={index}
                className="calendar-task__dot calendar-task__dot--plus"
              >
                +
              </span>
            );
          }
        })}
      </div>
      {/* display task cards on desktop */}
      <div className="calendar-task__content calendar-task__content--secondary">
        <ul className="calendar-task__list">
          {calendarItems.map((item, index) => {
            if (index < 3) {
              return (
                <li className="calendar-task__item" key={index}>
                  <CalendarCard
                    calendarItem={item}
                    key={index}
                    displayType={CalendarItemDisplayTypes.Simple}
                  ></CalendarCard>
                </li>
              );
            }
          })}
        </ul>
        {calendarItems.length - 3 > 0 && (
          <button
            type="button"
            className="calendar-task__button plus-link button-unstyled"
            onClick={handleModalEvent}
            ref={modalOpenRef}
            aria-label="view all items"
          >
            {calendarItems.length - 3} more
          </button>
        )}
      </div>
    </div>
  );
}
export default CalendarDisplayItems;
