import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import TodoCard from "../../components/tasks/card";
import TodoSlider from "../../components/tasks/slider";
import CardContainer from "../../components/containers/card-container";
import {
  NintexTasksResponse,
  PeoplesoftStudentClassApiResponse,
  ToDoItem,
} from "types/integrations";
import { useHttp } from "hooks/useHttp";
import {
  getNintexTasksForUserDashboard,
  getPeopleSoftUserClassesWithCanvasAssignmentsUrl,
} from "utilities/urls";
import { useUserRoles } from "context/user-roles/user-roles-context";
import { BusyIndicator } from "components/busyIndicator/busy-indicator";
import ErrorComponent from "components/common/errorComponent";
import { uuid } from "utilities/uuid";
import {
  mutateCanvasAssignmentsToNintexTask,
  mutateFinancialAidToDoToNintexTask,
  populateNintexTasksPriority,
} from "utilities/todos";
import { useStudentContext } from "context/student/student-context";
import { FUNCTIONALITY_AVAILABLE_TO_STUDENTS_ONLY } from "context/common/constant";
import CustomDropdown from "components/dropdowns/dropdown";
import { DropdownOptionTypes } from "types/sort-and-filter-options";
import { ToDoTypesOptions } from "types/tasks";

function Todos() {
  const container = document.getElementById("todo-tabs") as HTMLElement;
  const today = new Date(container.dataset.today!);

  const startDate = new Date(today);

  startDate.setDate(today.getDate() - 7);

  const endDate = new Date(today);
  endDate.setDate(today.getDate() + 30);

  const componentHeading = container.dataset.heading;
  const [tasks, setTasks] = useState({
    toDoTasks: Array<ToDoItem>(),
    upcomingTasks: Array<ToDoItem>(),
    inProgressTasks: Array<ToDoItem>(),
    completedTasks: Array<ToDoItem>(),
  });
  const [filteredTasks, setFilteredTasks] = useState({
    toDoTasks: Array<ToDoItem>(),
    upcomingTasks: Array<ToDoItem>(),
    inProgressTasks: Array<ToDoItem>(),
    completedTasks: Array<ToDoItem>(),
  });

  const { isStudent } = useUserRoles();
  const {
    data: nintexData,
    isLoading: isNintexLoading,
    error: nintexError,
    mutate,
  } = useHttp<NintexTasksResponse>("get", getNintexTasksForUserDashboard());

  const markTaskAsCompleted = async (id: string) => {
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
    };

    await fetch(`/api/MarkTaskAsCompleted?Id=${id}`, requestOptions);
    mutate();
  };
  const { studentFinancialAid } = useStudentContext();

  const {
    data: canvasData,
    isLoading: isCanvasLoading,
    error: canvasError,
  } = useHttp<PeoplesoftStudentClassApiResponse>(
    "get",
    getPeopleSoftUserClassesWithCanvasAssignmentsUrl(startDate, endDate)
  );

  useEffect(() => {
    if (
      isNintexLoading ||
      studentFinancialAid.isLoading ||
      isCanvasLoading ||
      !nintexData?.ToDoTasks ||
      !studentFinancialAid.data ||
      !canvasData
    )
      return;
    let mutatedNintexData = populateNintexTasksPriority(nintexData);
    let mutatedCanvasData = mutateCanvasAssignmentsToNintexTask(
      canvasData,
      today
    );

    const todoItems = [
      ...mutatedNintexData.ToDoTasks,
      ...mutatedCanvasData.ToDoTasks,
    ];

    if (studentFinancialAid.data.ToDos)
      todoItems.push(
        ...studentFinancialAid?.data?.ToDos?.map((todo) => {
          return mutateFinancialAidToDoToNintexTask(todo);
        })
      );

    const inProgressItems = [...mutatedNintexData.InProgressTasks];
    const upcomingItems = [
      ...mutatedNintexData.UpcomingTasks,
      ...mutatedCanvasData.UpcomingTasks,
    ];
    const completedItems = [
      ...mutatedNintexData.CompletedTasks,
      ...mutatedCanvasData.CompletedTasks,
    ];

    setTasks({
      toDoTasks: todoItems,
      completedTasks: completedItems,
      upcomingTasks: upcomingItems,
      inProgressTasks: inProgressItems,
    });
    setFilteredTasks({
      toDoTasks: todoItems,
      completedTasks: completedItems,
      upcomingTasks: upcomingItems,
      inProgressTasks: inProgressItems,
    });
  }, [nintexData, studentFinancialAid.data, canvasData]);
  const filterTasks = (value: string, label?: string) => {
    if (value === "All") {
      setFilteredTasks({
        toDoTasks: tasks.toDoTasks,
        completedTasks: tasks.completedTasks,
        inProgressTasks: tasks.inProgressTasks,
        upcomingTasks: tasks.upcomingTasks,
      });
    } else {
      setFilteredTasks({
        toDoTasks: tasks.toDoTasks.filter((item) => item.DataType === value),
        completedTasks: tasks.completedTasks.filter(
          (item) => item.DataType === value
        ),
        inProgressTasks: tasks.inProgressTasks.filter(
          (item) => item.DataType === value
        ),
        upcomingTasks: tasks.upcomingTasks.filter(
          (item) => item.DataType === value
        ),
      });
    }
  };
  return (
    container &&
    ReactDOM.createPortal(
      <CardContainer
        title={componentHeading || "To-do's"}
        utility={
          <CustomDropdown
            toggleTitle="Filters"
            dropdownTitle="Select Filter"
            type="secondary"
            optionsType={DropdownOptionTypes.Filters}
            options={ToDoTypesOptions}
            onFilter={filterTasks}
            alignment="left"
            defaultSelection="All"
          ></CustomDropdown>
        }
      >
        {!isStudent ? (
          <>{FUNCTIONALITY_AVAILABLE_TO_STUDENTS_ONLY}</>
        ) : isNintexLoading ||
          studentFinancialAid.isLoading ||
          isCanvasLoading ? (
          <BusyIndicator />
        ) : nintexError || studentFinancialAid.error || canvasError ? (
          <ErrorComponent
            message={
              nintexError?.message ||
              studentFinancialAid.error?.message ||
              canvasError?.message ||
              ""
            }
          />
        ) : (
          nintexData && (
            <Tabs forceRenderTabPanel>
              <TabList>
                <Tab>{filteredTasks.toDoTasks.length} To-do Tasks</Tab>
                <Tab>{filteredTasks.upcomingTasks.length} Upcoming Tasks</Tab>
                <Tab>
                  {filteredTasks.inProgressTasks.length} In-Progress Tasks
                </Tab>
                <Tab>{filteredTasks.completedTasks.length} Complete Tasks</Tab>
              </TabList>

              <TabPanel>
                <TodoSlider>
                  {filteredTasks &&
                    filteredTasks.toDoTasks.map((toDoItem) => {
                      return (
                        toDoItem.CardType && (
                          <TodoCard
                            key={uuid()}
                            markTaskAsCompleted={markTaskAsCompleted}
                            toDoItem={toDoItem}
                            cardType={
                              toDoItem.CardType &&
                              toDoItem.CardType.toLowerCase()
                            }
                          />
                        )
                      );
                    })}
                </TodoSlider>
              </TabPanel>
              <TabPanel>
                <TodoSlider>
                  {filteredTasks &&
                    filteredTasks.upcomingTasks.map((toDoItem) => {
                      return (
                        toDoItem.CardType && (
                          <TodoCard
                            key={uuid()}
                            markTaskAsCompleted={markTaskAsCompleted}
                            toDoItem={toDoItem}
                            cardType={
                              toDoItem.CardType &&
                              toDoItem.CardType.toLowerCase()
                            }
                          />
                        )
                      );
                    })}
                </TodoSlider>
              </TabPanel>
              <TabPanel>
                <TodoSlider>
                  {filteredTasks &&
                    filteredTasks.inProgressTasks.map((toDoItem) => {
                      return (
                        toDoItem.CardType && (
                          <TodoCard
                            key={uuid()}
                            markTaskAsCompleted={markTaskAsCompleted}
                            toDoItem={toDoItem}
                            cardType={
                              toDoItem.CardType &&
                              toDoItem.CardType.toLowerCase()
                            }
                          />
                        )
                      );
                    })}
                </TodoSlider>
              </TabPanel>
              <TabPanel>
                <TodoSlider>
                  {filteredTasks &&
                    filteredTasks.completedTasks.map((toDoItem) => {
                      return (
                        toDoItem.CardType && (
                          <TodoCard
                            toDoItem={toDoItem}
                            markTaskAsCompleted={markTaskAsCompleted}
                            cardType={toDoItem.CardType.toLowerCase()}
                          />
                        )
                      );
                    })}
                </TodoSlider>
              </TabPanel>
            </Tabs>
          )
        )}
      </CardContainer>,
      container
    )
  );
}
export default Todos;
