import React from "react";

export default function Link({
  url,
  label,
  type,
  openNewTab = false,
}: {
  url: string;
  label: string;
  type: string;
  openNewTab?: boolean;
}) {
  return (
    <a
      href={url}
      className={`${type}-link`}
      target={openNewTab ? "_blank" : "_self"}
    >
      {label}
    </a>
  );
}
