const getDataFromUrl = async (url: string): Promise<any> => {
  let resultData: any;
  await fetch(url)
    .then(async (response) => {
      return await response.json();
    })
    .then((result) => (resultData = result));

  return resultData;
};

export { getDataFromUrl };
