/*Use this on any portal to show in dev envrionment*/
export const useDivInjector = (id: string) => {
  let injected = false;
  if (process.env.NODE_ENV !== "development") return { injected };
  const root = document.getElementById("root") as HTMLElement;
  appendDiv(root, id);
  return { injected: true };
};

export const appendDiv = (root: HTMLElement, id: string) => {
  const div = getDivElement(id);
  root.appendChild(div);
};
const getDivElement = (id: string) => {
  const div = document.createElement("div") as HTMLDivElement;
  div.id = id;
  return div;
};
