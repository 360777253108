import React from "react";
import { InstructorForDashboard } from "types/staff-and-instructors";

interface Props {
  faculty: InstructorForDashboard;
  detailPageUrl: string;
  isAcademicAdvisor?: boolean;
  academicAdvisorLink?: string | undefined;
}

export default function FacultyCard({
  faculty,
  detailPageUrl,
  isAcademicAdvisor,
  academicAdvisorLink,
}: Props) {
  const facultyPhoto =
    faculty.Photo && faculty.Photo.length > 0 ? faculty.Photo[0] : undefined;

  const staff = faculty.Staff && faculty.Staff[0] && faculty.Staff[0];

  return (
    <>
      {staff && (
        <li className="faculty-card">
          <h3 className="faculty-card__title">{staff.JobTitle}</h3>
          <img
            src={
              facultyPhoto
                ? facultyPhoto.Url
                  ? facultyPhoto.Url
                  : `/images/default-source/default-album/profile-pic.png`
                : `/images/default-source/default-album/profile-pic.png`
            }
            alt="profile picture"
            className="faculty-card__image"
          />
          <div className="faculty-card__content">
            <h4 className="faculty-card__name">
              <a
                href={detailPageUrl}
                className="faculty-card__link faculty-card__link--secondary"
              >
                {!staff.Name || staff.Name === "" ? <>&nbsp;</> : staff.Name}
              </a>
            </h4>
            <span className="faculty-card__text">
              {!staff.OfficeLocation || staff.OfficeLocation === "" ? (
                <>&nbsp;</>
              ) : (
                staff.OfficeLocation
              )}
            </span>
            <a
              className="faculty-card__link"
              href={"tel:" + staff.WorkPhone}
              style={
                staff.WorkPhone
                  ? {}
                  : { pointerEvents: "none", visibility: "hidden" }
              }
            >
              {!staff.WorkPhone || staff.WorkPhone === "" ? (
                <>&nbsp;</>
              ) : (
                staff.WorkPhone
              )}
            </a>
            <a
              href={"mailto:" + staff.Email}
              className="faculty-card__link"
              style={
                staff.Email
                  ? {}
                  : { pointerEvents: "none", visibility: "hidden" }
              }
            >
              {!staff.Email || staff.Email === "" ? <>&nbsp;</> : staff.Email}
            </a>
            {isAcademicAdvisor ? (
              <a
                href={academicAdvisorLink}
                target={"_blank"}
                className="button faculty-card__button"
              >
                Schedule Appointment
              </a>
            ) : (
              <a
                href={faculty.SchedulingLink ?? "#"}
                className="button faculty-card__button"
                target={"_blank"}
                style={
                  faculty.SchedulingLink
                    ? {}
                    : { pointerEvents: "none", visibility: "hidden" }
                }
              >
                Schedule Appointment
              </a>
            )}
          </div>
        </li>
      )}
    </>
  );
}
