import React from "react";

interface Props {
  isLoading: boolean;
  isValidating: boolean;
  changeWeek: (direction: string) => void;
}

function CalendarWeekListPagination({
  isLoading,
  isValidating,
  changeWeek,
}: Props) {
  return (
    <div className="pagination">
      <button
        disabled={isLoading || isValidating}
        id="calendar-listing-next-previous"
        type="button"
        className="pagination__button pagination__button--prev button-unstyled"
        onClick={() => changeWeek("Previous")}
      >
        Previous
      </button>
      <button
        disabled={isLoading || isValidating}
        type="button"
        className="pagination__button pagination__button--next button-unstyled"
        onClick={() => changeWeek("Next")}
      >
        Next
      </button>
    </div>
  );
}

export default CalendarWeekListPagination;
