import {
  FacultyDataModel,
  FavoriteToolDataModel,
  OrganizationClubDataModel,
} from "../types/data-models";

export const favoriteToolDataModel = (): FavoriteToolDataModel => {
  return {
    heading: "Favorite Tools",
    linkText: "View More Tools",
    linkUrl: "/tool",
    numberOfDaysToShowNewTool: 1,
  };
};

export const organizationClubModel = (): OrganizationClubDataModel => {
  return {
    type: "Organization",
    heading: "Favorite Organizations",
    linktext: "View All Organizations",
    linkurl: "/student-life/student-organizations",
  };
};

export const facultyTabsDataModel = (): FacultyDataModel => {
  return {
    type: "Faculty",
    heading: "Faculty Heading",
  };
};
