import axios from "axios";
import {
  CalendarItem,
  CalendarItemDisplayTypes,
  CalendarItemTypes,
} from "../types/calendar";
import {
  TwentyFiveLiveEventCustomField,
  TwentyFiveLiveEvent,
  CanvasAssignment,
  CalendarItemIntegrationDataTypes,
  IntegrationAxiosRequest,
  StudentClass,
  EabAppointment,
  FinancialAidToDo,
  FinancialAidToDoItem,
  GoogleCalendarItem,
  ToDoItem,
} from "../types/integrations";
import { daysOfWeekLongMondayFirst, getCurrentWeekFirstDay } from "./calendar";
import { getSitefinityEventDetailPageUrl } from "./urls";
import { isNaN } from "lodash";

export const isEventCustomField = (
  obj: any
): obj is TwentyFiveLiveEventCustomField | TwentyFiveLiveEventCustomField[] => {
  const object: any = Array.isArray(obj) ? obj[0] : obj;
  return "filedId" in object && "label" in object;
};

export const mutateEvent = (
  event: TwentyFiveLiveEvent
): TwentyFiveLiveEvent => {
  return {
    ...event,
    EventStart: new Date(event.EventStart),
    EventEnd: new Date(event.EventEnd),
    CustomFields:
      event.CustomFields &&
      (JSON.parse(
        event.CustomFields as string
      ) as TwentyFiveLiveEventCustomField[]),
  };
};

export const mutateToCalendarItem = (
  itemToBeConverted: any,
  integartionType: string
) => {
  switch (integartionType) {
    case CalendarItemIntegrationDataTypes.TwentyFiveLiveEvents.name:
      return populateTwentyFiveLiveEvent(itemToBeConverted);
    case CalendarItemIntegrationDataTypes.PeoplesoftClassesAndCanvasAssignments
      .name:
      return populatePeoplesoftCalendarEvent(itemToBeConverted);
    case CalendarItemIntegrationDataTypes.EabAppointments.name:
      return populateEabAppointment(itemToBeConverted);
    case CalendarItemIntegrationDataTypes.PeoplesoftFinancialToDos.name:
      return populatePeoplesoftFinancialAidToDoCalendarEvent(itemToBeConverted);
    case CalendarItemIntegrationDataTypes.NintexTasksToDo.name:
      return populateNintexTaskCalendarEvent(itemToBeConverted);
    case CalendarItemIntegrationDataTypes.GoogleCalendarItems.name:
      return populateGoogleCalendarItem(itemToBeConverted);
    default:
      return {} as CalendarItem;
      break;
  }
};

const populateTwentyFiveLiveEvent = (
  twentyFiveLiveEvent: TwentyFiveLiveEvent
) => {
  return {
    StartDate: new Date(twentyFiveLiveEvent.EventStart),
    EndDate: new Date(twentyFiveLiveEvent.EventEnd),
    ActionUrl: twentyFiveLiveEvent.EventActionUrl,
    Content: twentyFiveLiveEvent.Content,
    Id: twentyFiveLiveEvent.EventId,
    CustomItemId: twentyFiveLiveEvent.EventId,
    DetailUrl: getSitefinityEventDetailPageUrl() + twentyFiveLiveEvent.Id,
    Location: twentyFiveLiveEvent.CustomLocation,
    Title: twentyFiveLiveEvent.Title,
    Type: CalendarItemTypes.Event.typeName,
    Display: CalendarItemDisplayTypes.Date,
    IconName: CalendarItemTypes.Event.iconName,
    calendarcategories: twentyFiveLiveEvent.calendarcategories,
    CustomFields:
      twentyFiveLiveEvent.CustomFields &&
      (JSON.parse(
        twentyFiveLiveEvent.CustomFields as string
      ) as TwentyFiveLiveEventCustomField[]),
  };
};
const populateEabAppointment = (eabAppointment: EabAppointment) => {
  let calendarItem = {} as CalendarItem;
  calendarItem.StartDate = new Date(eabAppointment.start_time);
  calendarItem.EndDate = new Date(eabAppointment.end_time);
  calendarItem.Title = eabAppointment.scheduled_student_services;
  calendarItem.Id = eabAppointment.id.toString();
  calendarItem.Location = eabAppointment.location;
  calendarItem.Display = CalendarItemDisplayTypes.Date;
  calendarItem.Type = CalendarItemTypes.Reminder.typeName;
  calendarItem.IconName = CalendarItemTypes.Reminder.iconName;
  calendarItem.DetailUrl = eabAppointment.detail_url;
  calendarItem.Content = eabAppointment.type;

  return calendarItem;
};
const populateCanvasCalendarEvent = (canvasCalendarEvent: CanvasAssignment) => {
  let calendarItem = {} as CalendarItem;
  if (canvasCalendarEvent.due_at) {
    calendarItem.StartDate = new Date(canvasCalendarEvent.due_at);
  }
  calendarItem.Title = canvasCalendarEvent.name;
  calendarItem.Id = canvasCalendarEvent.id;
  calendarItem.Display = CalendarItemDisplayTypes.Date;
  calendarItem.Type = CalendarItemTypes.Assignment.typeName;
  calendarItem.IconName = CalendarItemTypes.Assignment.iconName;
  calendarItem.DetailUrl = canvasCalendarEvent.html_url;

  return calendarItem;
};

const populatePeoplesoftCalendarEvent = (
  peoplesoftStudentClass: StudentClass
) => {
  let calendarItems = Array<CalendarItem>();

  const classStartDate = new Date(peoplesoftStudentClass.Dates.Start);
  const classEndDate = new Date(peoplesoftStudentClass.Dates.End);

  peoplesoftStudentClass.DaysInfo?.map((dayInfo) => {
    const meetingDays =
      dayInfo.Meeting.Days !== "TBD"
        ? dayInfo.Meeting.Days.replaceAll(" ", "").split("&")
        : [];
    const dateCounter = getCurrentWeekFirstDay(classStartDate, false);

    do {
      meetingDays.map((meetingDay) => {
        const meetingDayIndex = daysOfWeekLongMondayFirst.find(
          (day) => day.DayName.toLowerCase() === meetingDay.toLowerCase()
        )?.DayIndex!;
        if (meetingDayIndex) {
          const meetingDayDate = new Date(dateCounter);
          meetingDayDate.setDate(meetingDayDate.getDate() + meetingDayIndex);

          if (
            !isNaN(meetingDayDate) &&
            meetingDayDate.toISOString() >= classStartDate.toISOString() &&
            meetingDayDate.toISOString() <= classEndDate.toISOString()
          ) {
            let newCalendarItem = {} as CalendarItem;

            newCalendarItem.StartDate = new Date(meetingDayDate);
            newCalendarItem.EndDate = new Date(meetingDayDate);
            newCalendarItem.Title =
              peoplesoftStudentClass.Title + " - " + dayInfo.Topic;
            newCalendarItem.Id = peoplesoftStudentClass.CourseID;
            newCalendarItem.Location = dayInfo.Room;
            newCalendarItem.Display = CalendarItemDisplayTypes.Date;
            newCalendarItem.Type = CalendarItemTypes.Class.typeName;
            newCalendarItem.IconName = CalendarItemTypes.Class.iconName;
            newCalendarItem.Content = dayInfo.Topic;

            calendarItems.push(newCalendarItem);
          }
        }
      });

      dateCounter.setDate(dateCounter.getDate() + 7);
    } while (dateCounter < classEndDate);
  });
  peoplesoftStudentClass.Assignments?.filter((a) => a.due_at !== undefined).map(
    (assignment, index) => {
      calendarItems.push(populateCanvasCalendarEvent(assignment));
    }
  );

  return calendarItems;
};
const populatePeoplesoftFinancialAidToDoCalendarEvent = (
  financialAidToDo: FinancialAidToDo
) => {
  let calendarItem = {} as CalendarItem;
  if (financialAidToDo.ItemDueDate) {
    calendarItem.StartDate = new Date(
      financialAidToDo.ItemDueDate + " 00:00:00"
    );
  }

  calendarItem.Title = financialAidToDo.ItemDescription;
  calendarItem.Id = financialAidToDo.ItemCode;
  calendarItem.Display = CalendarItemDisplayTypes.Date;
  calendarItem.Type = CalendarItemTypes.Task.typeName;
  calendarItem.IconName = CalendarItemTypes.Task.iconName;

  return calendarItem;
};
const populateNintexTaskCalendarEvent = (nintexTask: ToDoItem) => {
  let calendarItem = {} as CalendarItem;
  if (nintexTask.TaskDate) {
    calendarItem.StartDate = new Date(nintexTask.TaskDate);
  }

  calendarItem.Title = nintexTask.Name;
  calendarItem.Id = nintexTask.Id;
  calendarItem.Display = CalendarItemDisplayTypes.Date;
  calendarItem.Type = CalendarItemTypes.Task.typeName;
  calendarItem.IconName = CalendarItemTypes.Task.iconName;
  calendarItem.DetailUrl = nintexTask.ButtonUrl;

  return calendarItem;
};

const populateGoogleCalendarItem = (googleCalendarItem: GoogleCalendarItem) => {
  let calendarItem = {} as CalendarItem;
  calendarItem.StartDate = new Date(googleCalendarItem.StartDate);
  calendarItem.EndDate = new Date(googleCalendarItem.EndDate);
  calendarItem.Title = googleCalendarItem.Title;
  calendarItem.Id = googleCalendarItem.Id;
  calendarItem.Location = googleCalendarItem.Location;
  calendarItem.Display = CalendarItemDisplayTypes.Date;
  calendarItem.Type = CalendarItemTypes.Event.typeName;
  calendarItem.IconName = CalendarItemTypes.Event.iconName;
  calendarItem.Content = googleCalendarItem.Content;
  calendarItem.IsTimeless = googleCalendarItem.IsAllDayEvent;

  return calendarItem;
};
export const mutateFinancialAidToDo = (financialAidToDo: FinancialAidToDo) => {
  let todoItem = {} as FinancialAidToDoItem;
  if (financialAidToDo.ItemDueDate) {
    todoItem.Date = new Date(financialAidToDo.ItemDueDate);
  }
  todoItem.Title = financialAidToDo.ItemDescription;
  todoItem.Type = CalendarItemTypes.Task.typeName;
  todoItem.IconName = CalendarItemTypes.Task.iconName;

  return todoItem;
};
export const getDataFromApiCall = async (
  requests: Array<IntegrationAxiosRequest>
) => {
  const dataMap = new Map();
  do {
    await axios
      .all(
        requests.map((request) => {
          return axios.get(request.url, {
            headers: {
              "Content-Type": "application/json",
              "Data-Type": request.IntegrationTypeName,
            },
          });
        })
      )
      .then((dataCollection) => {
        requests = [];

        for (let index = 0; index < dataCollection.length; index++) {
          const data = dataCollection[index];
          console.log(data?.data?.errors?.message);
          if (
            data?.data?.errors?.message ===
            "Error: No Student ID detected. Please use a Student Account. If you already are, please try relogging using the Azure AD Process"
          ) {
            throw new Error(
              "Error: No Student ID detected. Please use a Student Account. If you already are, please try relogging using the Azure AD Process."
            );
          }

          const dataType = data.config.headers!["Data-Type"];

          const isOdata = data.data["@odata.context"];
          if (isOdata) {
            const possibleNextOdataLink = data.data["@odata.nextLink"];

            if (possibleNextOdataLink) {
              const newOdataRequest = {
                IntegrationTypeName: dataType,
                url: possibleNextOdataLink,
              } as IntegrationAxiosRequest;
              requests.push(newOdataRequest);
            }

            if (dataMap.has(dataType)) {
              dataMap.set(dataType, [
                ...dataMap.get(dataType),
                ...data.data.value,
              ]);
            } else {
              dataMap.set(dataType, data.data.value);
            }
          } else {
            dataMap.set(dataType, data.data);
          }
        }
      });
  } while (requests.length > 0);
  return dataMap;
};
