import axios, { AxiosResponse } from "axios";

export const get = async <T>(
  url: string,
  headers: any
): Promise<AxiosResponse<T> | undefined> => {
  try {
    const resp = await axios.get<T>(url, headers);
    return resp;
  } catch (err: any) {
    logError(url, err);
    return {} as Promise<AxiosResponse<T>>;
  }
};

export const post = async <T>(
  url: string,
  payload: any,
  headers: any
): Promise<AxiosResponse<T>> => {
  try {
    const resp = await axios.post<T>(url, payload, headers);
    return resp;
  } catch (err: any) {
    logError(url, err);
    return {} as Promise<AxiosResponse<T>>;
  }
};

export const put = async <T>(
  url: string,
  payload: any,
  headers: any
): Promise<AxiosResponse<T>> => {
  try {
    const resp = await axios.put<T>(url, payload, headers);
    return resp;
  } catch (err: any) {
    logError(url, err);
    return {} as Promise<AxiosResponse<T>>;
  }
};

export const deleteHttp = async <T>(
  url: string,
  headers: any
): Promise<AxiosResponse<T>> => {
  try {
    const resp = await axios.delete<T>(url, headers);
    return resp;
  } catch (err: any) {
    logError(url, err);
    return {} as Promise<AxiosResponse<T>>;
  }
};

const logError = (url: string, error: any) => {
  console.error(`error http request ${url}, error: `, error);
};
