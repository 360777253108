import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import A11YSlider from "a11y-slider";
interface Props {
  children: React.ReactNode;
}

export default function TodoSlider({ children }: Props) {
  const sliderRef = useRef(null) as
    | React.MutableRefObject<HTMLDivElement>
    | React.MutableRefObject<null>;
  let slider: A11YSlider;

  const [a11ySlider, setA11ySlider] = useState<A11YSlider | undefined>();

  const updateSlider = (slideCount: number, type: string) => {
    // a11y slider was erroring out when more slides to show
    // than the actual slide count
    // splitting up responsive settings depending on slide count
    const settings = {
      slidesToShow: 1,
      infinite: false,
      arrows: false,
      dots: false,
      responsive: {
        768: {
          slidesToShow: slideCount === 1 ? 1 : 2,
          disable: slideCount <= 2,
          dots: slideCount > 2,
        },
        960: {
          disable: slideCount <= 3,
          arrows: slideCount > 3,
          slidesToShow: slideCount > 3 ? 3 : slideCount,
        },
      },
    };
    if (type === "init") {
      slider = new A11YSlider(
        (sliderRef as React.MutableRefObject<HTMLDivElement>).current,
        settings
      );
      slider.scrollToSlide(0);
      setA11ySlider(slider);
    } else if (type === "update") {
      a11ySlider?.destroy();
      if (slideCount > 0) {
        slider = new A11YSlider(
          (sliderRef as React.MutableRefObject<HTMLDivElement>).current,
          settings
        );
        slider.scrollToSlide(0);
        setA11ySlider(slider);
      }
    }
  };
  useEffect(() => {
    // when children get updated during filtering, we have to re init / destroy the slider
    const slideCount = sliderRef.current?.children.length as number;
    if (
      (
        sliderRef as React.MutableRefObject<HTMLDivElement>
      ).current.classList.contains("a11y-slider")
    ) {
      updateSlider(slideCount, "update");
    } else if (slideCount > 1) {
      updateSlider(slideCount, "init");
    }
  }, [children]);

  useEffect(() => {
    const slideCount = sliderRef.current?.children.length as number;
    if (
      slideCount > 1 &&
      !(
        sliderRef as React.MutableRefObject<HTMLDivElement>
      ).current.classList.contains("a11y-slider")
    ) {
      updateSlider(slideCount, "init");
    }
  }, []);

  return (
    <div className="todo-slider" ref={sliderRef}>
      {children}
    </div>
  );
}
