import { FilterDropdownOption } from "types/sort-and-filter-options";

export const sortOptions: FilterDropdownOption[] = [
  {
    Value: "Title:asc",
    Label: "A-Z",
  },
  {
    Value: "Title:desc",
    Label: "Z-A",
  },
  {
    Value: "LastModified:asc",
    Label: "Oldest-Newest",
  },
  {
    Value: "LastModified:desc",
    Label: "Newest-Oldest",
  },
];

export const mapSortingOption = (sortingOption: string) => {
  const splitOptionValue = sortingOption.split(":");
  return {
    field: splitOptionValue[0],
    direction: splitOptionValue[1],
  };
};

export const findSortOption = (label: string) => {
  return sortOptions.find((option: FilterDropdownOption) => {
    option.Label === label;
  })?.Value;
};
