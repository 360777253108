import ReactDOM from "react-dom";
import { useDivInjector } from "hooks/useDivInjector";
import { StudentContactDetails } from "components/studentProfile/studentContactDetail";
import { useUserRoles } from "context/user-roles/user-roles-context";
import { ImpersonationUnavailable } from "components/impersonation-unavailable/impersonation-unavailable";

export default function ProfileDetailContact() {
  useDivInjector("portal-detail-contact");

  const container = document.getElementById(
    "portal-detail-contact"
  ) as HTMLElement;
  const dataModel = container?.dataset.model;
  const parsedData = dataModel && JSON.parse(dataModel);
  const { isStudent } = useUserRoles();
  return (
    container &&
    ReactDOM.createPortal(
      !isStudent ? (
        <></>
      ) : (
        <StudentContactDetails
          componentHeading={parsedData?.headerText}
          linkHtml={parsedData?.linkHtml}
        />
      ),
      container
    )
  );
}
