import { FilterDropdownOption } from "./sort-and-filter-options";

export const ToDoTypes = {
  Onboarding: "Nintex",
  Assignment: "Assignment",
  Financial: "Financial",
};

export const ToDoTypesOptions: FilterDropdownOption[] = [
  {
    Value: "All",
    Label: "All",
  },
  {
    Value: ToDoTypes.Onboarding,
    Label: "Onboarding",
  },
  {
    Value: ToDoTypes.Assignment,
    Label: "Assignment",
  },
  {
    Value: ToDoTypes.Financial,
    Label: "Financial",
  },
];
