import { Model } from "../types/data-models";
import {
  favoriteToolDataModel,
  organizationClubModel,
} from "../utilities/data-models";
export const useDataModel = (
  modelName: string,
  injected: boolean,
  id: string
) => {
  if (process.env.NODE_ENV !== "development" || !injected) return;
  const element = document.getElementById(id) as HTMLDivElement;
  switch (modelName) {
    case Model.FavoriteTools:
      element.dataset.model = JSON.stringify(favoriteToolDataModel());
      break;
    case Model.OrganizationClub:
      element.dataset.model = JSON.stringify(organizationClubModel());
      break;
    default:
      break;
  }
};
