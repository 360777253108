import {
  get25LiveEventsFromSitefinityUrl,
  getEabAppointmentsUrl,
  getGoogleCalendarItemsForUserCalendar,
  getNintexTasksForUserCalendar,
  getPeopleSoftFinancialAidToDosUrl,
  getPeopleSoftUserClassesWithCanvasAssignmentsUrl,
} from "../utilities/urls";

// #region Twentyfivelive
export interface TwentyFiveLiveEventCustomField {
  fieldId: string;
  label: string;
  value: string;
  type: string;
}

export interface TwentyFiveLiveEvent {
  Id: string;
  EventStart: Date;
  EventEnd: Date;
  CustomLocation: string;
  EventId: string;
  Title: string;
  CustomFields: string | TwentyFiveLiveEventCustomField[];
  EventActionUrl: string;
  Content: string;
  calendarcategories: string[];
}

export interface CalendarCategoryTag {
  Id: string;
  Title: string;
  TaxonomyId: string;
  ParentId: string;
}

export interface TwentyFiveLiveCalendarTag {
  value: CalendarCategoryTag[];
}

export interface TwentyFiveLiveRelatedEvents {
  value: TwentyFiveLiveEvent[];
}

// #endregion

// #region Canvas

// Could be an Event or an Item
// See https://canvas.instructure.com/doc/api/calendar_events.html
export interface CanvasAssignment {
  id: string;
  due_at?: Date;
  name: string;
  html_url: string;
  has_submitted_submissions: boolean;
}

// #endregion

export interface ApiErrors {
  code: string;
  message: string;
  raw_message: string;
  validation_errors: { [key: string]: string };
}

export const CalendarItemIntegrationDataTypes = {
  ["TwentyFiveLiveEvents"]: {
    name: "TwentyFiveLiveEvents",
    dataType: {} as TwentyFiveLiveEvent,
    dataArrayName: undefined,
    isOdata: true,
    getApiUrl: get25LiveEventsFromSitefinityUrl,
  },
  ["PeoplesoftClassesAndCanvasAssignments"]: {
    name: "PeoplesoftClassesAndCanvasAssignments",
    dataType: {} as StudentClass,
    dataArrayName: "Classes",
    isOdata: false,
    getApiUrl: getPeopleSoftUserClassesWithCanvasAssignmentsUrl,
  },
  ["EabAppointments"]: {
    name: "EabAppointments",
    dataType: {} as EabAppointment,
    dataArrayName: "Appointments",
    isOdata: false,
    getApiUrl: getEabAppointmentsUrl,
  },
  ["PeoplesoftFinancialToDos"]: {
    name: "PeoplesoftFinancialToDos",
    dataType: {} as FinancialAidToDo,
    dataArrayName: "ToDos",
    getApiUrl: getPeopleSoftFinancialAidToDosUrl,
  },
  ["NintexTasksToDo"]: {
    name: "NintexTasksToDo",
    dataType: {} as ToDoItem,
    dataArrayName: "tasks",
    getApiUrl: getNintexTasksForUserCalendar,
  },
  ["GoogleCalendarItems"]: {
    name: "GoogleCalendarItems",
    dataType: {} as GoogleCalendarItem,
    dataArrayName: "CalendarItems",
    getApiUrl: getGoogleCalendarItemsForUserCalendar,
  },
};

export interface IntegrationAxiosRequest {
  url: string;
  IntegrationTypeName: string;
}

// #region Peoplesoft
// Student Academic Plan
export interface PeoplesoftStudentAcademicPlanApiResponse {
  Programs: Program[];
  AcademicProgress: AcademicProgress;
  GPA: number;
  GraduationRequests: GraduationRequest[];
  Success: boolean;
  status: string;
  errors: ApiErrors;
}

export interface Program {
  Program: string;
  Plan: string;
  Description: string;
}

export interface AcademicProgress {
  HasReport: string;
  Units: Units;
  Percentages: Percentages;
}

export interface Units {
  Taken: number;
  Used: number;
  Required: number;
}

export interface Percentages {
  Complete: number;
  InProgress: number;
  Incomplete: number;
}

export interface GraduationRequest {
  ExpectedGraduationTerm: string;
  RequestStatus: string;
  StatusDate: string;
}

export interface ProgressChartPath {
  data: string;
  fillColor: string;
}

// Student Class

export interface StudentClassSchedule {
  day: string;
  time: string;
}
export interface StudentClassShoppingCartItem {
  CourseID: string;
  Subject: string;
  CatalogNumber: string;
  Section: string;
  Title: string;
}
export interface StudentClass {
  CourseID: string;
  Subject: string;
  CatalogNumber: string;
  Section: string;
  Title: string;
  Campus: string;
  Room: string;
  Topic: string;
  InstructorName: string;
  InstructorID: string;
  Meeting: StudentClassMeeting;
  Dates: StudentClassDates;
  Exam: StudentClassExam;
  Grade: string;
  CreditHours: number;
  Assignments?: CanvasAssignment[];
  DaysInfo?: StudentClassDaysInfo[];
}
export interface StudentClassDaysInfo {
  Topic: string;
  Campus: string;
  Room: string;
  Meeting: StudentClassMeeting;
}
export interface StudentClassMeeting {
  Days: string;
  StartTime: string;
  EndTime: string;
}
export interface StudentClassDates {
  Start: string;
  End: string;
  Withdraw: string;
  Drop: string;
}

export interface StudentClassExam {
  Date?: string;
  StartTime?: string;
  EndTime?: string;
}
export interface PeoplesoftStudentClassApiResponse {
  Classes: StudentClass[];
  InProgressHours: string;
  PriorityRegistration: string;
  OpenEnrollment: string;
  ShoppingCart: StudentClassShoppingCartItem[];
  Success: boolean;
  status: string;
  errors: ApiErrors;
}

// Student Terms
export interface PeoplesoftStudentTermsApiResponse {
  ActiveTerms: ActiveTerm[];
  PastTerms: PastTerm[];
  Success: boolean;
  status: string;
  errors: ApiErrors;
}

export interface ActiveTerm {
  Term: string;
  Description: string;
  StartDate: string;
  EndDate: string;
  IsCurrentTerm: string;
}

export interface PastTerm {
  Term: string;
  Description: string;
  StartDate: string;
  EndDate: string;
}

// Student Financials
export interface PeoplesoftStudentFinancialsApiResponse {
  AccountBalance: string;
  AccountDetails: AccountDetail[];
  PaymentPlan: PaymentPlan;
  Success: boolean;
  status: string;
  errors: ApiErrors;
}

export interface AccountDetail {
  Description: string;
  Balance: string;
  DueDate: string;
}

export interface PaymentPlan {
  EligibleForDeferred: string;
  Enrolled: string;
}

// Overall Balance

export interface PeoplesoftOverallBalanceResponse {
  OverallBalance: string;
}

// Student Financial Aid
export interface PeoplesoftStudentFinancialAidApiResponse {
  ToDos: FinancialAidToDo[];
  Holds: Hold[];
  AnticipatedAid: AnticipatedAid[];
  FAFSAStatus: string;
  Awards: Award[];
  DisbursementDates: DisbursementDate[];
  Success: boolean;
  status: string;
  errors: ApiErrors;
}

export interface Name {
  Name: string;
  Type: string;
}

export interface Address {
  Address: string;
  City: string;
  State: string;
  Postal: string;
  Type: string;
}

export interface Email {
  Email: string;
  Type: string;
}

export interface Phone {
  Phone: string;
  Type: string;
}

export interface Contacts {
  Emails: Email[];
  Phones: Phone[];
}

export interface TuitionRate {
  TuitionRate: string;
  Description: string;
}

export interface Program {
  Program: string;
  Plan: string;
  SubPlan: string;
  Description: string;
}

export interface PeopleSoftStudentProfile {
  Names: Name[];
  Addresses: Address[];
  Contacts: Contacts;
  TuitionRates: TuitionRate[];
  Programs: Program[];
  StudentId: string;
  Success: boolean;
  errors: ApiErrors;
  AvatarUrl: string;
}

export enum NameTypes {
  Alias = "ALI",
  Primary = "PRI",
}

export enum AddressTypes {
  Home = "HOME",
  Mailing = "MAILING",
}

export enum ContactTypes {
  Home = "HOME",
  Student = "STDT",
  Mobile = "MOBILE",
}

export enum TuitionRateTypes {
  OUT_DIST = "Out-of-Distict Student",
  IN_DIST = "In-District Student",
}

export interface FinancialAidToDo {
  Code: string;
  Description: string;
  Status: string;
  ItemCode: string;
  ItemDescription: string;
  ItemDueDate: string;
  ItemStatus: string;
}
export interface FinancialAidToDoItem {
  Title: string;
  SubText: string;
  Date: Date;
  Type: string;
  IconName: string;
}

export interface AnticipatedAid {
  Term: string;
  Type: string;
  Amount: string;
}

export interface Award {
  Term: string;
  Description: string;
  Amount: string;
}

export interface DisbursementDate {
  Term: string;
  "Grant-Scholarship": string;
  Loan: string;
}
export interface Hold {
  Term: string;
  Code: string;
  Description: string;
  Reason: Reason;
}
export interface Reason {
  Description: string;
  Code: string;
}
// #endregion

// #region Eab
export interface EabAppointment {
  id: number;
  location: string;
  organizer: Organizer;
  type: string;
  attendees: Attendee[];
  start_time: string;
  end_time: string;
  start_time_utc: string;
  end_time_utc: string;
  scheduled_student_services: string;
  reported_student_services: any;
  report_filed: string;
  is_no_show: string;
  is_cancelled: string;
  detail_url: string;
}

export interface Attendee {
  name: string;
  primary_id: string;
  categories: string;
}

export interface Organizer {
  name: string;
  primary_id: string;
}

export interface EabStudentProfileApiResponse {
  success: boolean;
  status: string;
  errors: ApiErrors;
  StudentProfile: EabStudentProfile;
}
export interface EabStudentProfile {
  transaction_id: string;
  event_type: string;
  student: Student;
  success: boolean;
  courses: Course[];
  activity: Activity;
  relationships: Relationship[];
}
export interface Attendee {
  name: string;
  primary_id: string;
  categories: string;
}

export interface Organizer {
  name: string;
  primary_id: string;
}

export interface Activity {
  next_appointments: any[];
  recent_appointments: any[];
  recent_notes: RecentNote[];
}

export interface RecentNote {
  datetime: string;
  reasons: string;
  body: string;
}

export interface Course {
  name: string;
  section_name: string;
  unexcused_absences: string;
  at_risk_progress_reports: string;
}

export interface Relationship {
  id: string;
  first_name: string;
  last_name: string;
  relationship_name: string;
  last_updated_at: string;
}

export interface Student {
  address_1: string;
  address_2: any;
  alternate_id: any;
  cell_phone: string;
  city: string;
  created_at: string;
  current_classification: any;
  date_of_birth: string;
  email: string;
  enrollment_goal: any;
  enrollment_status: any;
  ethnicity: string;
  first_name: string;
  gender: string;
  home_phone: string;
  id: number;
  is_active: boolean;
  is_at_risk: boolean;
  last_name: string;
  login: string;
  primary_id: string;
  sso_id: any;
  state: string;
  url: string;
  work_phone: string;
  zip: string;
}
// #endregion

// #region Nintex
export interface NintexTasksResponse {
  ToDoTasks: ToDoItem[];
  UpcomingTasks: ToDoItem[];
  InProgressTasks: ToDoItem[];
  CompletedTasks: ToDoItem[];
}
export interface ToDoItem {
  Id: string;
  UserId: string;
  SourceId: string;
  TaskId: string;
  Priority: string;
  Category: string;
  Name: string;
  TaskDate: string;
  ShortDescription: string;
  LongDescription: string;
  ButtonUrl: string;
  ButtonText: string;
  IsCompleted: boolean;
  InProgress: boolean;
  CardType: string;
  DataType: string;
  IsNintexTask: boolean;
  Steps: string | undefined;
  NintexTaskStep: NintexTaskStep;
}
export interface NintexTaskStep {
  steps: string[];
  current: number | undefined;
  text: string | undefined;
}
export interface FilterOptions {
  UserId: string;
  SourceId: string;
  TaskId: string;
  Priority: string;
  Category: string;
  Name: string;
  TaskDate: string | null;
  IsCompleted: boolean | null;
  InProgress: boolean | null;
}
export interface PaginationOptions {
  Take: string;
  Skip: string;
}
export interface NintexGetRequest {
  Filter: FilterOptions;
  Pagination: PaginationOptions;
}
// #endregion

// #region Google

// Calnedar Items
export interface GoogleCalendarItemResponse {
  CalendarItems: GoogleCalendarItem[];
  Success: boolean;
  errors: ApiErrors;
}

export interface GoogleCalendarItem {
  StartDate: string;
  EndDate: string;
  Title: string;
  Id: string;
  Location: string;
  Content: string;
  IsAllDayEvent?: boolean;
}

// Google Mail
export interface GoogleMaileResponse {
  EmailMessages: EmailMessage[];
  Success: boolean;
  errors: ApiErrors;
}

export interface EmailMessage {
  Id: string;
  Date?: Date;
  IsRead: boolean;
  SenderName: string;
  Subject: string;
}
// #endregion
