import React, { useEffect, useRef } from "react";
import A11YSlider from "a11y-slider";
import { getSliderMonths } from "utilities/calendar";
interface Props {
  onSlideChange: (month: number, currentYear: number) => void;
}

const { months, previousMonthToShow } = getSliderMonths();
let activeSlideNumber = previousMonthToShow;
let oldSlideNumber = +activeSlideNumber;
let slider: A11YSlider;

const MonthSlider = React.memo(function MonthSlider({ onSlideChange }: Props) {
  const calendarMonthsRef = useRef(null) as
    | React.MutableRefObject<HTMLDivElement>
    | React.MutableRefObject<null>;
  let initialLoad = true;
  let didDataChangeFromSwipe = false;

  const changeSlide = (slideNumber: number) => {
    activeSlideNumber = slideNumber;
    slider.scrollToSlide(slideNumber);
  };
  useEffect(() => {
    if (
      !(
        calendarMonthsRef as React.MutableRefObject<HTMLDivElement>
      ).current.classList.contains("a11y-slider")
    ) {
      (
        calendarMonthsRef as React.MutableRefObject<HTMLDivElement>
      ).current.addEventListener("afterChange", function (e: any) {
        const slide = e.detail.currentSlide;

        // Make an exception if user swiped to change active month
        if (didDataChangeFromSwipe === true) {
          // Only update the active slide number if the user completes the swipe to another month
          if (slide.dataset.slidenumber != activeSlideNumber) {
            activeSlideNumber = slide.dataset.slidenumber;
            didDataChangeFromSwipe = false;
          }
        }
        if (slide.dataset.slidenumber == activeSlideNumber) {
          if (initialLoad) {
            initialLoad = false;
            return;
          }
          if (activeSlideNumber !== oldSlideNumber) {
            const monthIndex = Number(slide.dataset.month);
            const year = Number(slide.dataset.year);

            onSlideChange(monthIndex, year);
            oldSlideNumber = +activeSlideNumber;
          }
        } else {
          slider.scrollToSlide(activeSlideNumber);
        }
        didDataChangeFromSwipe = false;
      });
      slider = new A11YSlider(
        (calendarMonthsRef as React.MutableRefObject<HTMLDivElement>).current,
        {
          slidesToShow: 1,
          arrows: true,
          dots: false,
          infinite: false,
        }
      );

      const calendarReference = document
        .getElementsByClassName("a11y-slider")
        .item(0);
      var observer = new MutationObserver((event) => {
        const isCalendarScrolling = document.getElementsByClassName(
          "a11y-slider-scrolling"
        );
        if (
          isCalendarScrolling.length > 0 &&
          didDataChangeFromSwipe === false
        ) {
          didDataChangeFromSwipe = true;
        }
      });

      observer.observe(calendarReference!, {
        attributes: true,
        attributeFilter: ["class"],
        childList: true,
        characterData: false,
        subtree: false,
      });
      // slide didnt scroll correctly without a delay
      setTimeout(() => {
        slider.scrollToSlide(activeSlideNumber);
        (
          calendarMonthsRef as React.MutableRefObject<HTMLDivElement>
        ).current.classList.add("active");
        // When the window is resized, the slider "afterChange" will be triggered and
        // will cause it to change months. We keep track of activeMonthNumber
        // to force the slider to display the correct month whenever "afterChange" is called
        const nextButton = document
          .getElementsByClassName("a11y-slider-next")
          .item(0) as HTMLButtonElement;

        const prevButton = document
          .getElementsByClassName("a11y-slider-prev")
          .item(0) as HTMLButtonElement;

        const swipeButton = document
          .getElementsByClassName("a11y-slider active")
          .item(0) as HTMLButtonElement;

        nextButton.onclick = () => {
          if (activeSlideNumber < 18) ++activeSlideNumber;
        };
        prevButton.onclick = () => {
          if (activeSlideNumber > 0) --activeSlideNumber;
        };
      }, 250);

      return () => {
        observer.disconnect();
      };
    }
  }, []);

  return (
    <>
      <div className="calendar-controls">
        <button
          className="button calendar-controls__button"
          onClick={() => changeSlide(5)}
        >
          Last Month
        </button>
        <button
          className="button calendar-controls__button"
          onClick={() => changeSlide(6)}
        >
          This Month
        </button>
        <button
          className="button calendar-controls__button"
          onClick={() => changeSlide(7)}
        >
          Upcoming Month
        </button>
      </div>
      <div className="month-slider" ref={calendarMonthsRef}>
        {months.map((item, index) => {
          return (
            <div
              className="month-slider__item"
              key={index}
              data-month={item.month}
              data-year={item.year}
              data-slidenumber={index}
            >
              <h2 className="month-slider__title">{item.label}</h2>
            </div>
          );
        })}
      </div>
    </>
  );
});
export default MonthSlider;
