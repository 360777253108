import { useState, useRef, useEffect } from "react";
import { DropdownOptionDataTypes } from "types/sort-and-filter-options";
import FocusLock from "react-focus-lock";
import DropdownContentContainer from "./common/dropdown-content-container";
import DropdownContent from "./common/dropdown-content";

interface Props {
  toggleTitle: string;
  dropdownTitle?: string;
  type: string;
  optionsType: string;
  onFilter?: (value: string, label?: string) => void;
  alignment?: string;
  options: typeof DropdownOptionDataTypes;
  defaultSelection?: string;
}

export default function CustomDropdown({
  toggleTitle,
  dropdownTitle,
  type,
  optionsType,
  onFilter,
  alignment,
  options,
  defaultSelection,
}: Props) {
  const [dropdownActive, setDropdownActive] = useState("");
  const [activeFacet, setActiveFacet] = useState(defaultSelection ?? "");

  const closeButtonRef = useRef(null) as React.MutableRefObject<any>;
  const toggleButtonRef = useRef(null) as React.MutableRefObject<any>;

  const openDropdown = function (e: any) {
    document.body.classList.add("modal-active");
    setDropdownActive("active");
    closeButtonRef.current.focus();
  };

  const closeDropdown = function (e: any) {
    document.body.classList.remove("modal-active");
    setDropdownActive("inactive");
    toggleButtonRef.current.focus();
  };

  const handleFilterClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const buttonElement = e.target as HTMLButtonElement;
    closeDropdown(e);

    if (onFilter !== undefined && buttonElement) {
      setActiveFacet(buttonElement.value);
      onFilter(buttonElement.value, buttonElement.innerText);
    }
  };

  useEffect(() => {
    if (dropdownActive === "active") {
      closeButtonRef.current.focus();
    } else if (dropdownActive === "inactive") {
      toggleButtonRef.current.focus();
    }
  }, [dropdownActive]);

  return (
    <>
      <div
        className={`select-overlay ${dropdownActive}`}
        onClick={closeDropdown}
      ></div>
      <div className={`select-dropdown select-dropdown--${type}`}>
        <button
          type="button"
          className="select-dropdown__toggle"
          onClick={openDropdown}
          ref={toggleButtonRef}
        >
          {toggleTitle}
        </button>
        <FocusLock disabled={!dropdownActive}>
          <DropdownContentContainer
            dropdownActive={dropdownActive}
            closeButtonRef={closeButtonRef}
            closeDropdown={closeDropdown}
            dropdownTitle={dropdownTitle}
            alignment={alignment}
            dropdownContent={
              <DropdownContent
                activeFacet={activeFacet}
                options={options}
                optionsType={optionsType}
                handleFilterClick={handleFilterClick}
              />
            }
          />
        </FocusLock>
      </div>
    </>
  );
}
