import { ShowDashboardFavoriteActionButton } from "components/dashboard-favorite-action-button/dashboard-favorite-action-button";
import { useCalloutsContext } from "context/callouts/callouts-context";
import { useUserRoles } from "context/user-roles/user-roles-context";
import { Callout } from "types/tools-resources-callouts";

interface Props {
  callout: Callout;
  type: string;
  displayType: string;
}
export const CalloutCard = ({ callout, type, displayType }: Props) => {
  const { addFavoriteCallout, removeFavoriteCallout } = useCalloutsContext();
  const { impersonating, isStudent } = useUserRoles();

  const addFavoriteClick = () => {
    addFavoriteCallout(callout.Id, type);
  };
  const removeFavoriteClick = () => {
    removeFavoriteCallout(callout.Id);
  };

  return (
    <li className="tools-card">
      {callout.Image && callout.Image.length > 0 ? (
        <img
          className="tools-card__image"
          src={
            callout.Image[0].Url
              ? callout.Image[0].Url
              : `/images/default-source/default-album/profile-pic.png`
          }
          alt={callout.Image[0].AlternativeText}
        />
      ) : (
        <img
          className="tools-card__image"
          src={`/images/default-source/default-album/profile-pic.png`}
          alt=""
        />
      )}
      <div className="tools-card__content">
        {/* {callout.tooltypes && callout.tooltypes.length > 0 && (
          <span className="tools-card__tag">{callout.tooltypes[0].Title}</span>
        )} */}
        {callout.ItemDefaultUrl ? (
          <h3 className="tools-card__title">
            <a
              href={callout.ItemDefaultUrl}
              className="tools-card__link"
              target="_blank"
              aria-label={`${callout.Title} will open in a new window`}
            >
              {callout.Title}
            </a>
          </h3>
        ) : (
          <h3 className="tools-card__title">{callout.Title}</h3>
        )}
        {/* {resource.Summary && (
          <div className="tools-card__summary">{resource.Summary}</div>
        )} */}
        <div className="tools-card__summary">
          This summary is only for display, no Summary field being returned in
          api yet
        </div>
      </div>
      <div className="tools-card__aside">
        {callout.ItemDefaultUrl && (
          <a
            href={callout.ItemDefaultUrl}
            target="_blank"
            className="button button--solid tools-card__button"
          >
            Launch
            <span className="visually-hidden">
              {callout.Title} will open a new window
            </span>
          </a>
        )}
        {!isStudent || impersonating ? (
          <></>
        ) : displayType === "listing" ? (
          <ShowDashboardFavoriteActionButton
            onClick={addFavoriteClick}
            text={"Show on Dashboard"}
          />
        ) : (
          <ShowDashboardFavoriteActionButton
            onClick={removeFavoriteClick}
            text={"Remove from Dashboard"}
            isFavorite={true}
          />
        )}
      </div>
    </li>
  );
};
