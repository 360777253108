import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import {
  SocialNetworkTypes,
  StudentOrganization,
  StudentOrganizationOfficer,
} from "../../types/student";
import { useStudentOrganizationContext } from "../../context/student-organizations/student-organizations-context";
import { BusyIndicator } from "components/busyIndicator/busy-indicator";
import ErrorComponent from "components/common/errorComponent";
import { useDivInjector } from "hooks/useDivInjector";
import { SocialMedialLinks } from "components/social-media-links/social-media-links";

export default function StudentOrganizationDetail() {
  useDivInjector("student-organization-detail");
  const container = document.getElementById(
    "student-organization-detail"
  ) as HTMLElement;
  const {
    studentFavoriteOrgs,
    nonStudentFavoriteOrgs,
    allOrgsResp,
    studentFavoriteOrgsResp,
  } = useStudentOrganizationContext();
  const [studentOrganization, setStudentOrganization] = useState<
    StudentOrganization | undefined
  >(undefined);
  const { isLoading: isStudentFavsLoading, error: studentFavsError } =
    studentFavoriteOrgsResp;
  const { isLoading: isAllOrgsLoading, error: allOrgsError } = allOrgsResp;

  useEffect(() => {
    if (studentFavoriteOrgs || nonStudentFavoriteOrgs) {
      if (window.location.href.includes("studentOrganizationId")) {
        const url = window.location.href;
        const studentOrganizationId = url.substring(
          url.indexOf("studentOrganizationId=") + 22
        );
        setStudentOrganization(
          findOrgsById(
            studentFavoriteOrgs,
            nonStudentFavoriteOrgs,
            studentOrganizationId
          )
        );
      }
    }
  }, [studentFavoriteOrgs, nonStudentFavoriteOrgs]);

  return (
    container &&
    ReactDOM.createPortal(
      <>
        {studentFavsError || allOrgsError ? (
          <ErrorComponent
            message={studentFavsError?.message || allOrgsError?.message || ""}
          />
        ) : isStudentFavsLoading || isAllOrgsLoading ? (
          <BusyIndicator />
        ) : (
          studentOrganization && (
            <section className="organization-detail">
              <h1 className="organization-detail__title">
                {studentOrganization.Title}
              </h1>
              <div className="organization-detail__main">
                {studentOrganization.Image?.length >= 1 ? (
                  <img
                    src={
                      studentOrganization.Image[0].Url
                        ? studentOrganization.Image[0].Url
                        : `/images/default-source/default-album/profile-pic.png`
                    }
                    className="organization-detail__image"
                    alt={studentOrganization.Image[0].AlternativeText}
                  />
                ) : (
                  <></>
                )}
                <div className="organization-detail__content">
                  <h2 className="organization-detail__sub-heading">Mission</h2>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: studentOrganization.Content,
                    }}
                  />
                </div>
              </div>
              <div className="organization-detail__rail">
                <div className="organization-detail__social">
                  <div className="organization-links">
                    {studentOrganization.TwitterURL && (
                      <SocialMedialLinks
                        link={studentOrganization.TwitterURL}
                        type={SocialNetworkTypes.Twitter}
                      />
                    )}
                    {studentOrganization.FacebookURL && (
                      <SocialMedialLinks
                        link={studentOrganization.FacebookURL}
                        type={SocialNetworkTypes.Facebook}
                      />
                    )}
                    {studentOrganization.InstagramURL && (
                      <SocialMedialLinks
                        link={studentOrganization.InstagramURL}
                        type={SocialNetworkTypes.Instagram}
                      />
                    )}
                  </div>
                </div>
                <div className="organization-detail__section">
                  <h2 className="organization-detail__sub-heading">Officers</h2>
                  {studentOrganization.Officers &&
                    JSON.parse(studentOrganization.Officers as string).map(
                      (officer: StudentOrganizationOfficer) => (
                        <ul key={officer.Id} className="officers-list">
                          <li className="officers-list__item">
                            <h3 className="officers-list__detail">
                              {officer.JobTitle}: {officer.Name}
                            </h3>
                            <a
                              href={`mailto:${officer.Email}`}
                              className="officers-list__link"
                            >
                              {officer.Email}
                            </a>
                          </li>
                        </ul>
                      )
                    )}
                </div>
                <div className="organization-detail__officers">
                  <h2 className="organization-detail__sub-heading">Advisors</h2>
                  {studentOrganization.Staff.map(
                    (staff) =>
                      staff && (
                        <ul key={staff.Id} className="officers-list">
                          <li className="officers-list__item">
                            <span className="officers-list__detail">
                              {staff.Name}
                            </span>
                            <span className="officers-list__detail">
                              {staff.WorkPhone}
                            </span>
                            <a
                              href={`mailto:${staff.Email}`}
                              className="officers-list__link"
                            >
                              {staff.Email}
                            </a>
                          </li>
                        </ul>
                      )
                  )}
                </div>
              </div>
            </section>
          )
        )}
      </>,
      container
    )
  );
}

const findOrgsById = (
  favOrgs: StudentOrganization[] | undefined,
  nonFavOrgs: StudentOrganization[] | undefined,
  id: string
) => {
  if (!favOrgs && !nonFavOrgs) return undefined;
  if (favOrgs && favOrgs.length <= 0 && nonFavOrgs && nonFavOrgs.length <= 0)
    return undefined;
  let org =
    favOrgs && favOrgs.length > 0
      ? favOrgs.find((org) => org.Id === id)
      : undefined;
  if (org) {
    return org;
  }
  org =
    nonFavOrgs && nonFavOrgs.length > 0
      ? nonFavOrgs.find((org) => org.Id === id)
      : undefined;
  return org;
};
