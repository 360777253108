import { ShowDashboardFavoriteActionButton } from "components/dashboard-favorite-action-button/dashboard-favorite-action-button";
import { useResourcesContext } from "context/resources/resources-context";
import { useUserRoles } from "context/user-roles/user-roles-context";
import { useHttp } from "hooks/useHttp";
import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { FavoriteItem, Types } from "types/tools-resources-callouts";
import { getDataFromUrl } from "utilities/get-data-from-url";

import { getSitefinityPageByUrlNameUrl } from "utilities/urls";

export default function ResourceFavoriteButton() {
  const container = document.getElementById(
    "resource-favorite-button"
  ) as HTMLElement;
  const { addFavoriteResource, removeFavoriteResource } = useResourcesContext();
  const [favoriteStatus, setFavoriteStatus] = useState<boolean>(false);
  const pageRoute = window.location.pathname;
  const pageUrlName = pageRoute.substring(pageRoute.lastIndexOf("/") + 1);
  const { impersonating, isStudent } = useUserRoles();

  const {
    data: resourceIds,
    isLoading,
    error,
  } = useHttp<{ Id: string }[]>(
    "get",
    getSitefinityPageByUrlNameUrl(pageUrlName),
    true
  );
  useEffect(() => {
    if (resourceIds && resourceIds.length > 0 && !isLoading && !error) {
      const url = `/api/favorites/`;
      const dataCall = getDataFromUrl(url + resourceIds[0].Id) as Promise<
        FavoriteItem[]
      >;
      dataCall.then((data) => {
        if (data.length > 0) {
          setFavoriteStatus(true);
        } else {
          setFavoriteStatus(false);
        }
      });
    }
  }, [resourceIds]);

  const toggleFavorite = () => {
    if (resourceIds && resourceIds.length > 0 && !error) {
      setFavoriteStatus((previousState) => {
        const newState = !previousState;
        if (newState === true) {
          addFavoriteResource(resourceIds[0].Id, Types.Resource);
          return true;
        } else {
          removeFavoriteResource(resourceIds[0].Id);
          return false;
        }
      });
    }
  };
  return (
    container &&
    ReactDOM.createPortal(
      <>
        {!isStudent || impersonating ? (
          <></>
        ) : (
          favoriteStatus !== undefined &&
          !isLoading && (
            <ShowDashboardFavoriteActionButton
              onClick={toggleFavorite}
              text={
                favoriteStatus ? "Remove From Dashboard" : "Show on Dashboard"
              }
              isFavorite={favoriteStatus}
            />
          )
        )}
      </>,
      container
    )
  );
}
