import React, { useState } from "react";
import ReactDOM from "react-dom";
import CardContainer from "../../components/containers/card-container";
import Link from "../../components/links/link";
import { useToolsContext } from "../../context/tools/tools-context";
import { SitefinityFavoritesCard } from "components/common/tools-resources-callouts/sitefinity-favorites-card";
import { useHttp } from "hooks/useHttp";
import {
  SitefinityTool,
  ToolsApiResponse,
  Types,
} from "types/tools-resources-callouts";
import { getLatestToolUrl } from "utilities/urls";
import ErrorComponent from "components/common/errorComponent";
import { BusyIndicator } from "components/busyIndicator/busy-indicator";
import { useUserRoles } from "context/user-roles/user-roles-context";
import { FUNCTIONALITY_AVAILABLE_TO_STUDENTS_ONLY } from "context/common/constant";

// Dashboard Favorites Widget
const FavoriteTools = () => {
  const [showNewToolAlert, setNewToolAlert] = useState(false);
  const { favoriteTools, addFavoriteTool } = useToolsContext();
  const { impersonating, isStudent } = useUserRoles();

  const container = document.getElementById("favorite-tools");
  const data = container?.dataset.model;
  const parsedData = (data && JSON.parse(data)) || null;

  const {
    data: tools,
    error,
    isLoading,
  } = useHttp<SitefinityTool[]>(
    "get",
    getLatestToolUrl(parsedData?.NumberOfDaysToShowNewTool),
    true
  );
  const latestTool = tools === undefined ? undefined : tools[0];

  if (
    latestTool &&
    !error &&
    !showNewToolAlert &&
    favoriteTools &&
    !favoriteTools.find((tools) => {
      /* The Favorites array is initialized as an empty array, so a backup check is also used below */
      return tools.Id === latestTool.Id;
    })
  ) {
    const dataFromStorage = localStorage.getItem("NewFavoriteToolData");

    if (dataFromStorage) {
      const parsedDataFromStorage = JSON.parse(dataFromStorage) as {
        toolId: string;
      };
      if (parsedDataFromStorage.toolId !== latestTool.Id) {
        if (!showNewToolAlert) setNewToolAlert(true);
      }
    } else {
      if (!showNewToolAlert) setNewToolAlert(true);
    }
  }

  const handleNewToolToFavorite = (
    e: React.MouseEvent<HTMLButtonElement>,
    isFavorite: boolean
  ) => {
    e.currentTarget.disabled = true;
    const newFavoriteToolStorage = {
      toolId: latestTool!.Id,
    };
    const jsonData = JSON.stringify(newFavoriteToolStorage);

    localStorage.setItem("NewFavoriteToolData", jsonData);
    if (isFavorite) {
      addFavoriteTool(latestTool!.Id, Types.Tool);
    }
    setNewToolAlert(false);
  };

  // If item is already favorite then hide
  if (showNewToolAlert) {
    if (
      favoriteTools &&
      favoriteTools.find((tools) => {
        return tools.Id === latestTool?.Id;
      })
    )
      setNewToolAlert(false);
  }

  return (
    container &&
    ReactDOM.createPortal(
      <CardContainer title={parsedData?.heading || "Favorite Tools"}>
        {!isStudent ? (
          <>{FUNCTIONALITY_AVAILABLE_TO_STUDENTS_ONLY}</>
        ) : impersonating ? (
          <div>
            <span>Warning: Proxy does not include favorite tools access</span>
          </div>
        ) : (
          <>
            {error ? (
              <ErrorComponent message={error.message} />
            ) : isLoading ? (
              <BusyIndicator />
            ) : (
              <>
                {showNewToolAlert === true && latestTool && (
                  <div className="callout-new-tool__alert">
                    <h3 className="callout-item__title">
                      {latestTool.Title} is now available.
                    </h3>
                    <div className="callout-new-tool__actions">
                      <button
                        type="button"
                        className="plus-link callout-new-tool__add-to-favorites"
                        onClick={(event) =>
                          handleNewToolToFavorite(event, true)
                        }
                      >
                        Add To Favorites
                      </button>
                      <Link
                        url={latestTool.Link}
                        label="More Info"
                        type="arrow"
                      ></Link>
                      <button
                        type="button"
                        className="callout-new-tool__close button-unstyled"
                        aria-label="dismiss new tool alert"
                        onClick={(event) =>
                          handleNewToolToFavorite(event, false)
                        }
                      ></button>
                    </div>
                  </div>
                )}
                {favoriteTools &&
                  (favoriteTools.length > 0 && favoriteTools.length < 9 ? (
                    <SitefinityFavoritesCard
                      favorites={favoriteTools.slice(0, 6)}
                    />
                  ) : favoriteTools.length >= 9 ? (
                    <SitefinityFavoritesCard
                      favorites={favoriteTools.slice(0, 9)}
                    />
                  ) : (
                    <div className="callout-listing">
                      No favorite tools selected
                    </div>
                  ))}
                {parsedData && (
                  <Link
                    url={parsedData.linkUrl}
                    label={parsedData.linkText || "View More Tools"}
                    type="arrow"
                  ></Link>
                )}
              </>
            )}
          </>
        )}
      </CardContainer>,
      container
    )
  );
};
export default FavoriteTools;
