import React from "react";
import { CalendarDayInfo, CalendarItemDisplayTypes } from "types/calendar";
import CalendarCard from "../../common/calendar-card";

interface Props {
  activeDate: CalendarDayInfo;
}
/* show all tasks for specific day in a list on mobile */
function CalendarViewMobileResults({ activeDate }: Props) {
  return (
    <div className="calendar-results">
      <h2 className="calendar-results__heading">
        <span className="calendar-results__sub-heading">
          {activeDate?.DayName}
        </span>
        <span className="calendar-results__sub-heading calendar-results__sub-heading--secondary">
          {activeDate?.DayIndex}
        </span>
        <span className="calendar-results__sub-heading calendar-results__sub-heading--secondary">
          {activeDate?.MonthNameAbbreviation}
        </span>
      </h2>
      <ul className="calendar-results__listing">
        {activeDate?.CalendarItems.map((task, index) => {
          return (
            <CalendarCard
              displayType={CalendarItemDisplayTypes.Simple}
              calendarItem={task}
              key={index}
            ></CalendarCard>
          );
        })}
      </ul>
    </div>
  );
}

export default CalendarViewMobileResults;
