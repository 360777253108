import { AssignmentDayInfo } from "types/classes";
import { ActionLinkDataModel, Term } from "types/data-models";
import {
  ActiveTerm,
  CanvasAssignment,
  GraduationRequest,
  PastTerm,
  StudentClass,
  StudentClassShoppingCartItem,
} from "types/integrations";
import { FilterDropdownOption } from "types/sort-and-filter-options";
import { getDayNameFromDate, monthNames } from "./calendar";
import { sortTermsByDate } from "./student";

export const getSemesterGpa = (semesterClasses: StudentClass[]) => {
  if (
    !semesterClasses ||
    semesterClasses == undefined ||
    semesterClasses.length === 0
  )
    return "N/A";

  let totalGpa = 0.0;
  let validClassesToCountAgainst = semesterClasses.length;

  semesterClasses.forEach((semesterClass) => {
    switch (semesterClass.Grade) {
      case "A":
        totalGpa += 4;
        break;
      case "B":
        totalGpa += 3;
        break;
      case "C":
        totalGpa += 2;
        break;
      case "D":
        totalGpa += 1;
        break;
      case "F":
        break;
      default:
        validClassesToCountAgainst--;
        break;
    }
  });
  if (validClassesToCountAgainst === 0) {
    return "N/A";
  }
  totalGpa = totalGpa / validClassesToCountAgainst;
  return totalGpa.toFixed(2);
};

// ToDo: Add Open Enrollment date fallback if Priority Registration date does not exist. -Missing from API, waiting on CLC.
export const getRelevantClassActions = (
  classActionLinksCollection: { [key: string]: ActionLinkDataModel[] },
  classesInCart: StudentClassShoppingCartItem[],
  enrolledClasses: StudentClass[],
  priorityRegistrationDate: Date,
  currentTermTimeState: string,
  todayDate: Date
) => {
  const relevantOptions = Array<ActionLinkDataModel>();

  if (currentTermTimeState === "Past") {
    // Past Term Options
    relevantOptions.push(...classActionLinksCollection["pastTermActionLinks"]);
  } else {
    if (classesInCart.length === 0 || enrolledClasses.length === 0) {
      // No Classes in Cart, No Enrolled Classes, Date doesn't matter
      relevantOptions.push(
        ...classActionLinksCollection[
          "noClassesEnrolledOrNoClassesInCartActionLinks"
        ]
      );
    }
    if (
      classesInCart.length > 0 &&
      !isNaN(priorityRegistrationDate.valueOf()) &&
      todayDate.toISOString() < priorityRegistrationDate.toISOString()
    ) {
      // Has Classes in Cart, before priority/open registration date
      relevantOptions.push(
        ...classActionLinksCollection[
          "classesInCartAndRegistrationClosedActionLinks"
        ]
      );
    }
    if (
      !isNaN(priorityRegistrationDate.valueOf()) &&
      todayDate.toISOString() >= priorityRegistrationDate.toISOString()
    ) {
      // if student is both enrolled and has classes in cart, just display the enrolled actions
      if (enrolledClasses.length > 0) {
        // Has Classes Enrolled, at/after priority/open registration date
        relevantOptions.push(
          ...classActionLinksCollection[
            "classesEnrolledAndRegistrationOpenActionLinks"
          ]
        );
      } else if (classesInCart.length > 0) {
        // Has Classes in Cart, at/after priority/open registration date
        relevantOptions.push(
          ...classActionLinksCollection[
            "classesInCartAndRegistrationOpenActionLinks"
          ]
        );
      }
    }
  }

  return relevantOptions;
};

export const getSemesterCreditHours = (classes: StudentClass[]) => {
  if (!classes || classes == undefined) return 0;
  return classes.reduce((accumulator: number, studentClass) => {
    const creditHours: number = +studentClass.CreditHours;
    return accumulator + creditHours;
  }, 0);
};

export const getScheduleDynamicActionLinksFromContainerData = (
  container: HTMLElement
) => {
  const pastTermActionLinksViewData = container.dataset.first;

  const noClassesEnrolledOrNoClassesInCartActionLinksViewData =
    container.dataset.second;

  const classesInCartAndRegistrationClosedActionLinksViewData =
    container.dataset.third;

  const classesInCartAndRegistrationOpenActionLinksViewData =
    container.dataset.fourth;

  const classesEnrolledAndRegistrationOpenActionLinksViewData =
    container.dataset.fifth;

  const pastTermActionLinks = !pastTermActionLinksViewData
    ? []
    : (JSON.parse(pastTermActionLinksViewData) as ActionLinkDataModel[]);

  const noClassesEnrolledOrNoClassesInCartActionLinks =
    !noClassesEnrolledOrNoClassesInCartActionLinksViewData
      ? []
      : (JSON.parse(
          noClassesEnrolledOrNoClassesInCartActionLinksViewData
        ) as ActionLinkDataModel[]);

  const classesInCartAndRegistrationClosedActionLinks =
    !classesInCartAndRegistrationClosedActionLinksViewData
      ? []
      : (JSON.parse(
          classesInCartAndRegistrationClosedActionLinksViewData
        ) as ActionLinkDataModel[]);

  const classesInCartAndRegistrationOpenActionLinks =
    !classesInCartAndRegistrationOpenActionLinksViewData
      ? []
      : (JSON.parse(
          classesInCartAndRegistrationOpenActionLinksViewData
        ) as ActionLinkDataModel[]);

  const classesEnrolledAndRegistrationOpenActionLinks =
    !classesEnrolledAndRegistrationOpenActionLinksViewData
      ? []
      : (JSON.parse(
          classesEnrolledAndRegistrationOpenActionLinksViewData
        ) as ActionLinkDataModel[]);

  return {
    pastTermActionLinks: pastTermActionLinks,

    noClassesEnrolledOrNoClassesInCartActionLinks:
      noClassesEnrolledOrNoClassesInCartActionLinks,

    classesInCartAndRegistrationClosedActionLinks:
      classesInCartAndRegistrationClosedActionLinks,

    classesInCartAndRegistrationOpenActionLinks:
      classesInCartAndRegistrationOpenActionLinks,

    classesEnrolledAndRegistrationOpenActionLinks:
      classesEnrolledAndRegistrationOpenActionLinks,
  };
};
export const getScheduleStaticActionLinksFromContainerData = (
  container: HTMLElement
) => {
  const searchClassesActionLinkViewData = container.dataset.sixth;

  const enrollActionLinkViewData = container.dataset.seventh;

  const viewHoldsActionLinkViewData = container.dataset.eighth;

  const searchClassesActionLink = !searchClassesActionLinkViewData
    ? []
    : JSON.parse(searchClassesActionLinkViewData);

  const enrollActionLink = !enrollActionLinkViewData
    ? []
    : JSON.parse(enrollActionLinkViewData);

  const viewHoldsActionLink = !viewHoldsActionLinkViewData
    ? []
    : JSON.parse(viewHoldsActionLinkViewData);

  return {
    searchClassesActionLink: searchClassesActionLink,

    enrollActionLink: enrollActionLink,

    viewHoldsActionLink: viewHoldsActionLink,
  };
};

export const getAcademicPlanDynamicActionLinksFromContainerData = (
  container: HTMLElement
) => {
  const academicPlanActionLinksViewData =
    container.dataset.academicplanactionlinks;

  const academicPlanActionLinks = !academicPlanActionLinksViewData
    ? []
    : (JSON.parse(academicPlanActionLinksViewData) as ActionLinkDataModel[]);

  return academicPlanActionLinks;
};

export const getSelectOptionsFromTerms = (
  initialTerm: Term,
  pastTerms: PastTerm[],
  activeTerms: ActiveTerm[]
) => {
  const sortedPastTerms = sortTermsByDate(pastTerms, true);
  const sortedActiveTerms = sortTermsByDate(activeTerms, true);

  let selectOptions = Array<FilterDropdownOption>();
  sortedActiveTerms.map((term) => {
    let termState = "Future";
    let termLabel = term.Description;
    if (
      initialTerm.timeState === "Current" &&
      term.Term === initialTerm.value
    ) {
      termState = "Current";
      termLabel = initialTerm.label;
    }

    selectOptions.push({
      Label: termLabel,
      Value: term.Term,
      SecondaryValue: termState,
    });
  });

  sortedPastTerms.map((term) => {
    selectOptions.push({
      Label: term.Description,
      Value: term.Term,
      SecondaryValue: "Past",
    });
  });

  return selectOptions;
};

// Returns the closest/newest date
export const getMostRecentGraduationRequest = (
  graduationRequests: GraduationRequest[]
) => {
  const sortedRequests = graduationRequests.sort((item1, item2) => {
    const item1Date = new Date(item1.StatusDate).toISOString();
    const item2Date = new Date(item2.StatusDate).toISOString();
    return item1Date > item2Date ? -1 : 1;
  });
  return sortedRequests[0];
};

export const onFilterAssignments = (
  canvasAssignments: CanvasAssignment[],
  startDateIsoString: string,
  endDateIsoString?: string
) => {
  return canvasAssignments.filter((assignment) => {
    if (!assignment.due_at) return false;
    let assignmentDate = new Date(assignment.due_at);
    if (isNaN(assignmentDate.valueOf())) return false;

    assignmentDate.setHours(0, 0, 0, 0); // sets time to 0
    const assignmentDateIsoString = assignmentDate.toISOString();

    let isMatch = false;
    if (!endDateIsoString)
      isMatch = assignmentDateIsoString == startDateIsoString;
    else {
      isMatch =
        assignmentDateIsoString >= startDateIsoString &&
        assignmentDateIsoString <= endDateIsoString;
    }
    return isMatch;
  });
};
export const getAssignmentsInfo = (
  startDate: Date,
  endDate: Date,
  canvasAssignments: CanvasAssignment[]
) => {
  const start = new Date(startDate);
  let assignmentInfo = Array<AssignmentDayInfo>();
  let assignments = canvasAssignments.filter(
    (assignment) => assignment.due_at !== undefined
  );
  do {
    const dayInfo = {
      Assignments: assignments.filter(
        (assignment) =>
          new Date(assignment.due_at!).getDate() === start.getDate()
      ),
      DayIndex: start.getDate(),
      DayName: getDayNameFromDate(start),
      MonthNameAbbreviation: monthNames[start.getMonth()].substring(0, 3),
    } as AssignmentDayInfo;

    assignmentInfo.push(dayInfo);
    start.setDate(start.getDate() + 1); // go to next day
  } while (start.toISOString() !== endDate.toISOString());

  return assignmentInfo;
};
