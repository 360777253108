import { put, deleteHttp } from "services/api-service";
import {
  Callout,
  FavoriteItem,
  SitefinityPage,
  SitefinityTool,
} from "types/tools-resources-callouts";

export const isResource = (obj: any): obj is SitefinityPage => {
  return "ViewUrl" in obj;
};
export const isCallout = (obj: any): obj is Callout => {
  return "ItemDefaultUrl" in obj;
};

// POST to sitefinity to add a favorite
export const addFavorite = async (id: string, type: string) => {
  return put<FavoriteItem[]>(
    `/api/favorites`,
    {
      id,
      type,
    },
    {}
  );
};

// POST to sitefinity to remove a favorite
export const removeFavorite = async (id: string) => {
  return deleteHttp<FavoriteItem[]>(`/api/favorites/` + id, {});
};

/**
 * Sort list
 * @param key key field for Entity Type
 * @param ascending boolean that is true if sort by ascending
 * @param listToBeSorted unsorted list to sort
 */
export const sortingBy = (
  key: "Title" | "LastModified",
  ascending: boolean,
  listToBeSorted: Callout[] | SitefinityTool[]
) => {
  let sortedList: Callout[] | SitefinityTool[];
  if (!ascending) {
    sortedList = listToBeSorted.sort((item1, item2) =>
      item1[key] > item2[key] ? -1 : 1
    );
  } else {
    sortedList = listToBeSorted.sort((item1, item2) =>
      item1[key] < item2[key] ? -1 : 1
    );
  }
  return sortedList;
};
