import { BusyIndicator } from "components/busyIndicator/busy-indicator";
import ErrorComponent from "components/common/errorComponent";
import { useStudentContext } from "context/student/student-context";
import React from "react";
import { getEarliestDueDate } from "utilities/financials";
import { formatDate } from "../../utilities/date-parser";
import CardContainer from "../containers/card-container";
import { useUserRoles } from "context/user-roles/user-roles-context";
import { FUNCTIONALITY_AVAILABLE_TO_STUDENTS_ONLY } from "context/common/constant";

interface Props {
  componentHeading: string | undefined;
}

export default function BalanceDue({ componentHeading }: Props) {
  const { studentFinancials, selectedTermName } = useStudentContext();
  const earliestDueDate =
    studentFinancials.data &&
    getEarliestDueDate(studentFinancials.data.AccountDetails);
  const { isStudent } = useUserRoles();
  return (
    <>
      <CardContainer title={componentHeading || "You have a Balance"}>
        {!isStudent ? (
          <>{FUNCTIONALITY_AVAILABLE_TO_STUDENTS_ONLY}</>
        ) : studentFinancials.error ? (
          <ErrorComponent message={studentFinancials.error.message} />
        ) : studentFinancials.isLoading ? (
          <BusyIndicator />
        ) : (
          <>
            {studentFinancials.data &&
            studentFinancials.data.AccountDetails.length > 0 &&
            earliestDueDate ? (
              <>
                <p className="text-weight-semibold">
                  You have a balance for {selectedTermName} due on{" "}
                  {formatDate(new Date(earliestDueDate))}
                </p>
                <p className="text-size-small text-margin-bottom">
                  Make payment arragements to avoid being dropped.
                </p>
              </>
            ) : (
              <p>You have no balance for {selectedTermName}.</p>
            )}

            <a href="/my-financials" className="button">
              View My Financials
            </a>
          </>
        )}
      </CardContainer>
    </>
  );
}
