import { MouseEventHandler } from "react";
interface Props {
  onClick: MouseEventHandler<HTMLButtonElement>;
  text: string;
  isFavorite?: boolean;
}
export const ShowDashboardFavoriteActionButton = ({
  onClick,
  text,
  isFavorite = false,
}: Props) => {
  return (
    <button
      type="button"
      className={"button-unstyled tools-card__favorite-button"}
      onClick={onClick}
    >
      <svg
        className="tools-card__icon"
        aria-hidden="true"
        viewBox="0 0 384 512"
      >
        <use
          href={isFavorite ? "#icon-bookmark-solid" : "#icon-bookmark"}
        ></use>
      </svg>
      <span className="tools-card__button-text">{text}</span>
    </button>
  );
};
