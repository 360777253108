export interface FilterTool {
  filterType: string;
  filterValue: string;
  isChecked: boolean;
}
export interface FilterOptions {
  Title: string;
  Id: string;
}
export interface FavoriteItem {
  id: string;
  type: string;
}

export interface SitefinityFavoriteTool {
  Id: string;
  type: string;
}

export interface SitefinityImage {
  Url: string;
  AlternativeText: string;
}

export interface SitefinityTool {
  Id: string;
  Title: string;
  Link: string;
  Summary: string;
  LastModified: string;
  Image: SitefinityImage[];
  tooltypes: SitefinityTag[];
  DefaultOrdinal: number;
}

export interface SitefinityTag {
  Title: string;
  Id: string;
}
// AKA Resource
export interface SitefinityPage {
  Id: string;
  Title: string;
  UrlName: string;
  ViewUrl: string;
  Link: string;
  types: string[];
  Description: string;
  Summary: string;
  LastModified: string;
  Image: SitefinityImage[];
  ResourceTypeTitle: string;
}

export interface ToolsApiResponse {
  "@odata.nextLink": string;
  "@odata.context": string;
  value: SitefinityTool[];
}

export enum Types {
  Tool = "Telerik.Sitefinity.DynamicTypes.Model.Tools.Tool",
  Organization = "Organization",
  Callout = "Callout",
  Resource = "Resource",
}

export interface Callout {
  Id: string;
  Title: string;
  PublicationDate: string;
  ItemDefaultUrl: string;
  Link: string;
  Provider: string;
  LastModified: string;
  IncludeInSitemap: boolean;
  Image: SitefinityImage[];
}
export interface SitefinityApiResponse {
  "@odata.nextLink": string;
  "@odata.context": string;
  value: Callout[] | SitefinityPage[] | SitefinityTool[];
}
