import { Video, Photo } from "types/staff-and-instructors";
import { useState } from "react";
import { VideoModal } from "components/video-modal/video-modal";
interface Props {
  video: Video;
  photo: Photo;
  isSecondary?: boolean;
  className?: string;
}

export const VideoCard = ({
  video,
  photo,
  className,
  isSecondary = false,
}: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const onCloseModal = () => {
    setOpen(false);
  };
  const onOpenModal = () => {
    setOpen(true);
  };
  return (
    <>
      {photo.ThumbnailUrl && !isSecondary ? (
        <img
          src={video.Thumbnail[0] ? video.Thumbnail[0].ThumbnailUrl : ""}
          className={className ? className + "__thumbnail" : ""}
          alt={
            video.Thumbnail[0]
              ? video.Thumbnail[0].AlternativeText
              : "Thumbnail"
          }
        />
      ) : (
        <img
          src={video.Thumbnail[0] ? video.Thumbnail[0].ThumbnailUrl : ""}
          alt={
            video.Thumbnail[0]
              ? video.Thumbnail[0].AlternativeText
              : "Thumbnail"
          }
          className={
            className + "__thumbnail " + className + "__thumbnail--secondary"
          }
        />
      )}

      {video.Source && (
        <>
          <button
            type="button"
            className={className ? className + "__button" : ""}
            onClick={onOpenModal}
          >
            <svg
              className={
                className && isSecondary
                  ? className + "__icon " + className + "__icon--secondary"
                  : className
                  ? className + "__icon"
                  : ""
              }
              aria-hidden="true"
            >
              <use
                href={`/ResourcePackages/Talon/assets/dist/sprites/svgdefs.svg#icon-video-button`}
              ></use>
            </svg>
            <span className="visually-hidden">Play Video {video.Title}</span>
          </button>
          <VideoModal
            content={
              <iframe className="video-modal__iframe" src={video.Source}>
                <img
                  src={photo.ThumbnailUrl}
                  className={className ? className + "__thumbnail" : ""}
                  alt={photo.AlternativeText}
                />
              </iframe>
            }
            open={open}
            onClose={onCloseModal}
          />
        </>
      )}
    </>
  );
};
