export interface Staff {
  Id: string;
  LastModified: string;
  PublicationDate: string;
  DateCreated: string;
  IncludeInSitemap: boolean;
  UrlName: string;
  ItemDefaultUrl: string;
  StaffId: string;
  WorkPhone: string;
  JobTitle: string;
  OfficeLocation: string;
  Email: string;
  DepartmentId: string;
  departments: string[];
  Fax: string;
  Name: string;
  JobFamily: any;
  Title: string;
  Provider: string;
}

export enum InstructorAndStafftypes {
  Staff = "Staff",
  Instructor = "Instructor",
}

export interface StaffForDashboard {
  WorkPhone: string;
  JobTitle: string;
  OfficeLocation: string;
  Email: string;
  Name: string;
  UrlName: string;
}
export interface InstructorForDashboard {
  Id: string;
  UrlName: string;
  SchedulingLink: string;
  Staff: StaffForDashboard[];
  Photo: InstructorPhotoForDashboard[];
}

export interface InstructorPhotoForDashboard {
  Url: string;
  AlternativeText: string;
}

export interface Department {
  Id: string;
  Title: string;
  FullUrl: string;
}
export interface StaffResp {
  "@odata.nextLink": string;
  "@odata.context": string;
  value: Staff[];
}
export interface DepartmentResp {
  "@odata.context": string;
  value: Department[];
}
export interface Subject {
  Id: string;
  Title: string;
  UrlName: string;
}

export interface SubjectResp {
  "@odata.context": string;
  value: Subject[];
}

export interface Instructor {
  "@odata.context": string;
  Id: string;
  LastModified: string;
  PublicationDate: string;
  DateCreated: string;
  IncludeInSitemap: boolean;
  UrlName: string;
  ItemDefaultUrl: string;
  CellPhone: string;
  Links: string;
  Question5Answer: string;
  Question4Answer: string;
  Question2Answer: string;
  Question3Answer: string;
  IsDocument: boolean;
  IsDepartmentChair: boolean;
  Affiliations: string;
  InstructorId: string;
  Title: string;
  Education: string;
  SchedulingLink: any;
  MeetWithMe: string;
  Question1Answer: string;
  Bio: string;
  Provider: string;
  Photo: Photo[];
  PrimaryVideo: Video[];
  RelatedVideos: Video[];
  Staff: Staff[];
  Departments: Department[];
  Courses: Course[];
}

export interface Photo {
  Id: string;
  LastModified: string;
  PublicationDate: string;
  Title: string;
  Description: string;
  DateCreated: string;
  IncludeInSitemap: boolean;
  Ordinal: number;
  UrlName: string;
  ItemDefaultUrl: string;
  Author: string;
  Extension: string;
  MimeType: string;
  TotalSize: number;
  Category: any[];
  Tags: any[];
  Width: number;
  Height: number;
  AlternativeText: string;
  FolderId: any;
  ParentId: string;
  Provider: string;
  Url: string;
  ThumbnailUrl: string;
  IsDamMedia: boolean;
  Thumbnails: Thumbnail[];
}
export interface Video {
  Id: string;
  LastModified: string;
  PublicationDate: string;
  DateCreated: string;
  IncludeInSitemap: boolean;
  UrlName: string;
  ItemDefaultUrl: string;
  Source: string;
  Title: string;
  Provider: string;
  Thumbnail: Thumbnail[];
}

export interface Thumbnail {
  AlternativeText: string;
  ThumbnailUrl: string;
}

export enum InstructorQuestions {
  "one" = "Question 1...",
  "two" = "Question 2...",
  "three" = "Question 3...",
  "four" = "Question 4...",
  "five" = "Question 5...",
}

export interface InstructorEducation {
  Degrees: InstructorDegree[];
  Certificates: InstructorCertificate[];
}

export interface InstructorCertificate {
  Description: string;
}
export interface InstructorDegree {
  DegreeType: string;
  Subject: string;
  School: string;
  Location: string;
}

export interface Course {
  Id: string;
  LastModified: string;
  PublicationDate: string;
  DateCreated: string;
  IncludeInSitemap: boolean;
  UrlName: string;
  ItemDefaultUrl: string;
  CourseTitle: string;
  AcademicOrg: string;
  Title: string;
  CourseId: string;
  CourseOfferNumber: string;
  CatalogNumber: string;
  subjects: string[];
  Subject: string;
  Provider: string;
}
