import React, { useEffect, useRef, useState } from "react";
import { FilterDropdownOption } from "types/sort-and-filter-options";

interface Props {
  classValues?: string;
  options: Array<FilterDropdownOption>;
  icon: string;
  label: string;
  activeDropdown: string;
  setActiveDropdown: (name: string) => void;
  filterTools: (
    label: string,
    filterValue: string,
    isChecked?: boolean
  ) => void;
}

const ToolsActionDropdown = ({
  classValues,
  options,
  icon,
  label,
  activeDropdown,
  setActiveDropdown,
  filterTools,
}: Props) => {
  const [dropdown, setCurrentDropdown] = useState(activeDropdown);
  const [activeFacet, setActiveFacet] = useState("");

  const toggleRef = useRef(null) as
    | React.MutableRefObject<HTMLButtonElement>
    | React.MutableRefObject<null>;

  const dropdownState = useRef(dropdown);
  const setDropdownState = (value: string) => {
    dropdownState.current = value;
    setCurrentDropdown(value);
  };

  const toggleDropdown = (e: React.MouseEvent<HTMLButtonElement>) => {
    const target = e.target as HTMLElement;
    const name = target.getAttribute("data-name") as string;
    if (activeDropdown === name) {
      setActiveDropdown("");
      setDropdownState("");
    } else {
      setActiveDropdown(name);
      setDropdownState(name);
    }
  };

  // when selecting a sort order
  const onSortClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const target = e.target as HTMLButtonElement;
    const filterValue = target.getAttribute("data-value") as string;
    filterTools(label, filterValue);
    setActiveFacet(filterValue);
  };

  // when filtering on tool type classification
  // can select multiple tags
  const onFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    const value = target?.value;
    const isChecked = target?.checked;
    filterTools(label, value, isChecked);
  };

  const onEscapeKey = (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      if (dropdownState.current === label) {
        setActiveDropdown("");
        setDropdownState("");
        toggleRef.current?.focus();
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keyup", onEscapeKey);

    setActiveFacet("Title:asc");
    filterTools(label, "Title:asc");

    return () => {
      document.removeEventListener("keyup", onEscapeKey);
    };
  }, []);

  return (
    <div className={`tools-filter ${classValues}`}>
      <button
        type="button"
        className="tools-filter__toggle"
        aria-expanded={activeDropdown === label}
        onClick={toggleDropdown}
        data-name={label}
        ref={toggleRef}
      >
        <svg className="tools-filter__icon" aria-hidden="true">
          <use href={`#${icon}`}></use>
        </svg>
        {label}
        <span className="visually-hidden">
          {label === "Sort"
            ? "select a sort option in the expanded list to sort tools results"
            : "select one or more tool type categories in the expanded list to filter tools results"}
        </span>
      </button>
      <ul
        className={`tools-filter__dropdown ${
          activeDropdown === label ? "active" : ""
        }`}
      >
        {options.map((item, index) => {
          return (
            <li
              key={index}
              className={`tools-filter__item ${
                activeFacet === item.Value ? "active" : ""
              } ${label === "Filter" ? "checkbox-item" : ""}`}
            >
              {label === "Filter" ? (
                <label className="checkbox-item__label">
                  <input
                    type="checkbox"
                    className="checkbox-item__input"
                    value={item.Value}
                    onChange={onFilterChange}
                  />
                  <span className="checkbox-item__pseudo-checkbox"></span>
                  {item.Label}
                </label>
              ) : (
                <button
                  type="button"
                  className={`tools-filter__facet ${
                    activeFacet === item.Value ? "active" : ""
                  }`}
                  data-value={item.Value}
                  onClick={onSortClick}
                >
                  {item.Label}
                </button>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};
export default ToolsActionDropdown;
