import ReactDOM from "react-dom";
import { ActionLinkDataModel } from "types/data-models";
import FinancialPayments from "../../components/financials/financial-payments";

export default function FinancialCardAmountDue() {
  const container = document.getElementById(
    "financial-card-amount-due"
  ) as HTMLElement;

  const heading = container.dataset.heading;
  const makeAPaymentLink = container.dataset.makeapaymentlink;
  const form1098Link = container.dataset.form1098link;
  const directDepositLink = container.dataset.directdepositlink;
  const makePaymentSubActionLinksViewData =
    container.dataset.makepaymentsubactionlinks;
  const paymentAndBillingActionLinksViewData =
    container.dataset.paymentandbillinglinks;

  const financialActionLinks = !makePaymentSubActionLinksViewData
    ? []
    : (JSON.parse(makePaymentSubActionLinksViewData) as ActionLinkDataModel[]);
  const paymentAndBillingActionLinks = !paymentAndBillingActionLinksViewData
    ? []
    : (JSON.parse(
        paymentAndBillingActionLinksViewData
      ) as ActionLinkDataModel[]);

  return (
    container &&
    ReactDOM.createPortal(
      <div className="financial-cards">
        <>
          <FinancialPayments
            componentHeading={heading}
            makePaymentActionLink={makeAPaymentLink}
            form1098Link={form1098Link}
            setupDirectDepositLink={directDepositLink}
            otherFinancialActionLinks={financialActionLinks}
            paymentAndBillingActionLinks={paymentAndBillingActionLinks}
          ></FinancialPayments>
        </>
      </div>,
      container
    )
  );
}
