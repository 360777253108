import { Video, Photo } from "types/staff-and-instructors";
import { VideoCard } from "components/video-card/video-card";
import { useEffect, useRef } from "react";
import A11YSlider from "a11y-slider";

let slider: A11YSlider;
let activeSlideNumber = 1;

interface Props {
  video: Video | Video[] | undefined;
  photo: Photo;
}
// TODO: thubmnail for video & not photo. Data not coming back as part of the video object
export const InstructorVideoCard = ({ video, photo }: Props) => {
  const isArray = Array.isArray(video);
  const slideShowClass = isArray
    ? video.length <= 3
      ? "video-slideshow--hidden"
      : ""
    : "";
  const videoSlideRef = useRef(null) as
    | React.MutableRefObject<HTMLDivElement>
    | React.MutableRefObject<null>;

  useEffect(() => {
    if (
      videoSlideRef.current &&
      !(
        videoSlideRef as React.MutableRefObject<HTMLDivElement>
      ).current.classList.contains("a11y-slider")
    ) {
      slider = new A11YSlider(
        (videoSlideRef as React.MutableRefObject<HTMLDivElement>).current,
        {
          slidesToShow: 3,
          arrows: true,
          dots: false,
          infinite: false,
        }
      );
      slider.scrollToSlide(activeSlideNumber);
      (
        videoSlideRef as React.MutableRefObject<HTMLDivElement>
      ).current.classList.add("a11y-slider");
    }
  }, []);

  return !video ? (
    <></>
  ) : !isArray ? (
    <div className="instructor-video">
      <div className="instructor-video__content">
        <VideoCard video={video} photo={photo} className="instructor-video" />
      </div>
    </div>
  ) : (
    <div className="instructor-detail__section">
      <h2>Video</h2>
      <div className={`video-slideshow` + slideShowClass}>
        <div className="a11y-slider-container">
          <div
            className="video-slideshow__list"
            ref={videoSlideRef}
            style={{ display: "flex" }}
            data-video-slider
          >
            {video.map((vid, index) => (
              <div
                key={vid.Id}
                className={`instructor-video instructor-video--secondary a11y-slider-visible a11y-slider-active`}
                style={{ width: "33.3333%", flex: "0 0 auto" }}
                data-slidenumber={index}
              >
                <div className="instructor-video__content">
                  <VideoCard
                    video={vid}
                    photo={photo}
                    className="instructor-video"
                    isSecondary={true}
                  />
                </div>
                <h3 className="insturctor-video__title">{vid.Title}</h3>
              </div>
            ))}
          </div>
        </div>
        <div className="a11y-slider-sr-only" tabIndex={-1}>
          End of slider carousel
        </div>
      </div>
    </div>
  );
};
