import { isNaN } from "lodash";
import React from "react";
import { FinancialAidToDoItem } from "types/integrations";
import { parseDate } from "utilities/date-parser";

interface Props {
  todoItem: FinancialAidToDoItem;
  displayType: string;
}
export default function ToDoItemCard({ todoItem, displayType }: Props) {
  return (
    <li className={`calendar-card calendar-card--${displayType}`}>
      <div
        className={`calendar-card__date calendar-card__date--${todoItem.Type}`}
      >
        {todoItem && todoItem.Date && !isNaN(todoItem.Date) && (
          <span className="calendar-card__text">
            {parseDate(todoItem.Date, "dayNumber")}
            <br />
            {parseDate(todoItem.Date, "monthShort")}
            <br />
          </span>
        )}
      </div>
      <div className="calendar-card__content">
        <div className="calendar-card__row">
          <svg
            className={`calendar-card__icon calendar-card__icon--${todoItem.Type}`}
          >
            <use href={`#${todoItem.IconName}`}></use>
          </svg>

          <span
            className={`calendar-card__value text-weight-medium calendar-card__value--${todoItem.Type}`}
          >
            {todoItem.Type}
          </span>
        </div>
        <h3 className="calendar-card__title">{todoItem.Title}</h3>
        {todoItem.SubText && (
          <h5 className="calendar-card__title">{todoItem.SubText}</h5>
        )}
      </div>
    </li>
  );
}
