import { BusyIndicator } from "components/busyIndicator/busy-indicator";
import ErrorComponent from "components/common/errorComponent";
import { useEventsContext } from "context/events/events-context";
import { useStudentOrganizationContext } from "context/student-organizations/student-organizations-context";
import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { SitefinityTagForRelatedEvents } from "types/event";
import { StudentOrganization } from "types/student";
import EventListing from "../../components/events/event-listing";
import { useHttp } from "../../hooks/useHttp";
import { CalendarItem } from "../../types/calendar";
import {
  CalendarItemIntegrationDataTypes,
  TwentyFiveLiveEvent,
  TwentyFiveLiveRelatedEvents,
} from "../../types/integrations";
import { mutateToCalendarItem } from "../../utilities/integrations";
import { get25LiveOrganizationEventsFromSitefinityUrl } from "../../utilities/urls";

export default function OrganizationEvents() {
  const { allOrgsResp } = useStudentOrganizationContext();
  const { eventTagResponse, setRequestedEventTagId } = useEventsContext();
  const [calendarTags, setCalendarTags] = useState<
    SitefinityTagForRelatedEvents[] | undefined
  >(undefined);
  const [studentOrganization, setStudentOrganization] = useState<
    StudentOrganization | undefined
  >(undefined);
  const [calendarItems, setCurrentCalendarItems] = useState(
    Array<CalendarItem>()
  );
  const container = document.getElementById(
    "organization-events"
  ) as HTMLElement;

  const calendarTagId = container.dataset.categoryid!;
  const today = new Date(container.dataset.today!);

  calendarTagId && setRequestedEventTagId(calendarTagId);

  // const today = new Date(container.dataset.today!);
  // const eventDateRange = getCalendarActiveDateRange(today);
  const [eventTag, setEventTag] = useState(
    "00000000-0000-0000-0000-000000000000"
  );
  // Gets the active student organization data
  useEffect(() => {
    if (allOrgsResp.data) {
      const url = window.location.href;
      const studentOrganizationId = url.substring(
        url.indexOf("studentOrganizationId=") + 22
      );
      const studentOrganization = allOrgsResp.data.find(
        (x) => x.Id == studentOrganizationId
      );
      setStudentOrganization(studentOrganization);
    }
  }, [allOrgsResp]);

  // Create an array of all tags applicable to this event based on their level in hierarchy
  // After the Event Tag API Call has finished
  useEffect(() => {
    if (
      eventTagResponse.data &&
      !eventTagResponse.isLoading &&
      !eventTagResponse.isValidating
    ) {
      // traverse through the tags to determine what level in the hierarchy they are
      const getTagLevel: any = (
        tagData: any,
        parentId: string,
        level: number,
        tagTitle: string
      ) => {
        const rootId = "00000000-0000-0000-0000-000000000000";
        if (parentId !== rootId) {
          const parentItem = tagData.find((x: any) => x.Id === parentId);
          level++;
          if (parentItem && parentItem.ParentId !== rootId) {
            return getTagLevel(tagData, parentItem.ParentId, level, tagTitle);
          } else {
            return level;
          }
        } else {
          return level;
        }
      };
      const calnedarTagArray = Array<any>();
      eventTagResponse.data.value.forEach((item) => {
        calnedarTagArray.push({
          Title: item.Title,
          Id: item.Id,
          Level: getTagLevel(
            eventTagResponse.data?.value,
            item.ParentId,
            0,
            item.Title
          ),
        });
      });
      setCalendarTags(calnedarTagArray);
    }
  }, [eventTagResponse]);
  // When you have 1) Current Event 2) Calendar Tags Array
  // Then 3) Get the deepest level in the hierarchy of tags
  useEffect(() => {
    if (
      studentOrganization &&
      eventTagResponse.data &&
      studentOrganization.calendarcategories &&
      calendarTags
    ) {
      let tagLevel = 0;
      let tag = "";
      studentOrganization.calendarcategories.forEach((itemId) => {
        const tagInfo = calendarTags.find((x: any) => x.Id === itemId);
        if (tagInfo) {
          if (tagInfo.Level > tagLevel) {
            tagLevel = tagInfo.Level;
            tag = itemId;
          } else if (tag === "" || tagLevel === 0) {
            tag = itemId;
          }
        }
      });
      setEventTag(tag);
    }
  }, [studentOrganization, calendarTags]);

  const { data, isLoading, isValidating, error } =
    useHttp<TwentyFiveLiveRelatedEvents>(
      "get",
      get25LiveOrganizationEventsFromSitefinityUrl(today, eventTag)
    );

  useEffect(() => {
    setCurrentCalendarItems(
      !error && data && data.value.length > 0 && studentOrganization
        ? (data.value.map((item) => {
            return mutateToCalendarItem(
              item,
              CalendarItemIntegrationDataTypes.TwentyFiveLiveEvents.name
            );
          }) as CalendarItem[])
        : []
    );
  }, [data]);
  return ReactDOM.createPortal(
    <>
      {error ? (
        <ErrorComponent message={error.message} />
      ) : isLoading ? (
        <BusyIndicator />
      ) : (
        calendarItems &&
        calendarItems.length > 0 && (
          <>
            <h2 className="event-detail__heading">
              Upcoming Organization Events
            </h2>

            <EventListing data={calendarItems}></EventListing>
          </>
        )
      )}
    </>,
    container
  );
}
