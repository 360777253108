import ReactFocusLock from "react-focus-lock";
interface Props {
  content: React.ReactNode;
  open: boolean;
  onClose: () => void;
}
export const VideoModal = ({ content, open, onClose }: Props) => {
  return (
    <>
      {open && (
        <ReactFocusLock>
          <div
            className="video-modal active"
            role="dialog"
            aria-modal="true"
            tabIndex={-1}
            aria-labelledby="video-title"
          >
            <div className="video-modal__container">
              <button
                type="button"
                className="video-modal__close"
                onClick={onClose}
              >
                <span className="visually-hidden">Close Modal</span>
              </button>
              <div className="video-modal__content">{content}</div>
              <h2
                id="video-title"
                className="visually-hidden"
                data-modal-title
                tabIndex={0}
              ></h2>
            </div>
          </div>
        </ReactFocusLock>
      )}
    </>
  );
};
