import axios from "axios";
export const useFetcherWithOdata = <T,>(methodName: string, data?: any) => {
  const fetcher = async (url: string) => {
    let data: T[] = [];
    let nextUrl: string | undefined = url;
    do {
      const response: any = await axios({
        method: methodName,
        url: nextUrl,
        data: data,
      });
      if (response?.data?.errors?.validation_errors["StudentIdError"]) {
        throw new Error(
          "Error: No Student ID detected. Please use a Student Account. If you already are, please try relogging using the Azure AD Process"
        );
      } else if (response?.data?.errors?.message) {
        throw new Error(
          "Error: Something went wrong, please check back later."
        );
      }

      nextUrl = response.data["@odata.nextLink"];

      response.data.value.map((item: T) => {
        data.push(item);
      });
    } while (nextUrl);
    return data;
  };
  return { fetcher };
};
