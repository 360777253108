import { ShowDashboardFavoriteActionButton } from "components/dashboard-favorite-action-button/dashboard-favorite-action-button";
import { useResourcesContext } from "context/resources/resources-context";
import { useUserRoles } from "context/user-roles/user-roles-context";
import { SitefinityPage } from "types/tools-resources-callouts";

interface Props {
  resource: SitefinityPage;
  type: string;
  displayType: string;
}
export const ResourceCard = ({ resource, type, displayType }: Props) => {
  const { addFavoriteResource, removeFavoriteResource } = useResourcesContext();
  const { impersonating, isStudent } = useUserRoles();
  const addFavoriteClick = () => {
    addFavoriteResource(resource.Id, type);
  };
  const removeFavoriteClick = () => {
    removeFavoriteResource(resource.Id);
  };

  return (
    <li className="tools-card">
      {resource.Image && resource.Image.length > 0 ? (
        <img
          className="tools-card__image"
          src={
            resource.Image[0].Url
              ? resource.Image[0].Url
              : `/images/default-source/default-album/profile-pic.png`
          }
          alt={resource.Image[0].AlternativeText}
        />
      ) : (
        <img
          className="tools-card__image"
          src={`/images/default-source/default-album/profile-pic.png`}
          alt=""
        />
      )}
      <div className="tools-card__content">
        {resource.types && resource.types.length > 0 && (
          <span className="tools-card__tag">{resource.ResourceTypeTitle}</span>
        )}
        {resource.ViewUrl ? (
          <h3 className="tools-card__title">
            <a
              href={resource.ViewUrl}
              className="tools-card__link"
              target="_blank"
              aria-label={`${resource.Title} will open in a new window`}
            >
              {resource.Title}
            </a>
          </h3>
        ) : (
          <h3 className="tools-card__title">{resource.Title}</h3>
        )}
        {resource.Summary && (
          <div className="tools-card__summary">{resource.Summary}</div>
        )}
      </div>
      <div className="tools-card__aside">
        {!isStudent || impersonating ? (
          <></>
        ) : displayType === "listing" ? (
          <ShowDashboardFavoriteActionButton
            onClick={addFavoriteClick}
            text={"Show on Dashboard"}
          />
        ) : (
          <ShowDashboardFavoriteActionButton
            onClick={removeFavoriteClick}
            text={"Remove from Dashboard"}
            isFavorite={true}
          />
        )}
      </div>
    </li>
  );
};
