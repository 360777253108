import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import CardContainer from "../../components/containers/card-container";
import StudentClassWidget from "../../components/classes/student-class";
import { useHttp } from "hooks/useHttp";
import { PeoplesoftStudentClassApiResponse } from "types/integrations";
import { getPeopleSoftUserClassesWithCanvasAssignmentsUrl } from "utilities/urls";
import {
  getCurrentWeekFirstDay,
  buildWeekFromStartDate,
} from "utilities/calendar";
import {
  getRelevantClassActions,
  getScheduleDynamicActionLinksFromContainerData,
  getScheduleStaticActionLinksFromContainerData,
  getSelectOptionsFromTerms,
  getSemesterCreditHours,
  getSemesterGpa,
} from "utilities/classes";
import {
  ActionLinkDropdownOption,
  DropdownOptionTypes,
  FilterDropdownOption,
} from "types/sort-and-filter-options";
import { BusyIndicator } from "components/busyIndicator/busy-indicator";
import {
  getCurrentTermFromActiveTerms,
  getTermTimeState,
} from "utilities/student";
import { useStudentContext } from "context/student/student-context";
import { ActionLinkDataModel, Term } from "types/data-models";
import ErrorComponent from "components/common/errorComponent";
import CustomDropdown from "components/dropdowns/dropdown";
import ClassesHeaderActions from "components/classes/schedule-components/classes-header-actions";
import ClassesScheduleAlert from "components/classes/schedule-components/classes-schedule-alert";
import { useUserRoles } from "context/user-roles/user-roles-context";
import { FUNCTIONALITY_AVAILABLE_TO_STUDENTS_ONLY } from "context/common/constant";

export default function ClassSchedule() {
  const container = document.getElementById("class-schedule") as HTMLElement;
  const today = new Date(container.dataset.today!);

  const weekStartDate = getCurrentWeekFirstDay(today);
  const weekEndDate = buildWeekFromStartDate(weekStartDate);

  const studentSchedule = container.dataset.scheduleheading;

  const allPossibleDynamicActionLinks =
    getScheduleDynamicActionLinksFromContainerData(container);
  const allPossibleStaticActionLinks =
    getScheduleStaticActionLinksFromContainerData(container);

  const [termActions, setTermActions] = useState<{
    firstFourActions: ActionLinkDataModel[];
    moreActions: ActionLinkDropdownOption[];
  }>({ firstFourActions: [], moreActions: [] });

  const {
    studentTerms,
    studentFinancialAid,
    setSelectedTermNumber,
    setSelectedTermName,
  } = useStudentContext();

  const [selectedTerm, setSelectedTerm] = useState<Term>({
    label: "",
    value: "0000",
    timeState: "N/A",
  });

  const [termOptions, setTermOptions] = useState<FilterDropdownOption[]>([]);

  const [isUserTermsDataReady, setIsUserTermsDataReady] =
    useState<boolean>(false);

  const [shouldDisplayData, setShouldDsiplayData] = useState<boolean>(false);
  const { isStudent } = useUserRoles();

  const {
    data: classesResponse,
    isLoading: isClassesLoading,
    isValidating: isClassesValidating,
    error: userClassesError,
  } = useHttp<PeoplesoftStudentClassApiResponse>(
    "get",
    getPeopleSoftUserClassesWithCanvasAssignmentsUrl(
      weekStartDate,
      weekEndDate,
      selectedTerm.value
    )
  );
  useEffect(() => {
    if (studentTerms.data) {
      const initialTerm = getCurrentTermFromActiveTerms(
        studentTerms.data.ActiveTerms
      );

      setSelectedTerm(initialTerm);
      if (initialTerm.timeState === "Current")
        setSelectedTermName(
          initialTerm.label.substring(0, initialTerm.label.indexOf("-") - 1)
        );
      else setSelectedTermName(initialTerm.label);

      const selectOptions = getSelectOptionsFromTerms(
        initialTerm,
        studentTerms.data.PastTerms,
        studentTerms.data.ActiveTerms
      );

      setTermOptions(selectOptions);
      setIsUserTermsDataReady(true);
    }
  }, [studentTerms.data]);

  useEffect(() => {
    if (
      isUserTermsDataReady &&
      !isClassesLoading &&
      !isClassesValidating &&
      classesResponse
    ) {
      const termActionsToUpdate =
        !classesResponse.ShoppingCart || !classesResponse.Classes
          ? []
          : getRelevantClassActions(
              allPossibleDynamicActionLinks,
              classesResponse.ShoppingCart,
              classesResponse.Classes,
              new Date(classesResponse.PriorityRegistration),
              selectedTerm.timeState,
              new Date()
            );

      setTermActions({
        firstFourActions: termActionsToUpdate.slice(0, 4),
        moreActions: termActionsToUpdate.slice(4).map((classAction) => {
          return {
            Label: classAction.Label,
            Url: classAction.Url,
            IsExternal: classAction.IsExternal,
          };
        }),
      });

      setShouldDsiplayData(true);
    }
  }, [classesResponse, isUserTermsDataReady]);

  const onTermChange = (value: string, label?: string) => {
    if (classesResponse) {
      const termState = getTermTimeState(value, termOptions);
      const term = {
        label: !label ? "" : label,
        value: value,
        timeState: !termState ? "" : termState,
      };
      setSelectedTerm(term);
      setSelectedTermNumber(term.value);
      if (termState === "Current")
        setSelectedTermName(
          term.label.substring(0, term.label.indexOf("-") - 1)
        );
      else setSelectedTermName(term.label);

      if (studentTerms.data) {
        const termActionsToUpdate =
          !classesResponse.ShoppingCart || !classesResponse.Classes
            ? []
            : getRelevantClassActions(
                allPossibleDynamicActionLinks,
                classesResponse.ShoppingCart,
                classesResponse.Classes,
                new Date(classesResponse.PriorityRegistration),
                term.timeState,
                new Date()
              );

        setTermActions({
          firstFourActions: termActionsToUpdate.splice(0, 4),
          moreActions: termActionsToUpdate.splice(4).map((classAction) => {
            return {
              Label: classAction.Label,
              Url: classAction.Url,
              IsExternal: classAction.IsExternal,
            };
          }),
        });
      }
    }
  };

  const apiError =
    studentTerms.error || userClassesError || studentFinancialAid.error;

  return (
    container &&
    ReactDOM.createPortal(
      <CardContainer title={studentSchedule || "Class Schedule"}>
        <div className="class-schedule">
          {!isStudent ? (
            <>{FUNCTIONALITY_AVAILABLE_TO_STUDENTS_ONLY}</>
          ) : apiError ? (
            <ErrorComponent message={apiError.message} />
          ) : !shouldDisplayData ? (
            <BusyIndicator />
          ) : (
            classesResponse &&
            studentFinancialAid.data &&
            studentTerms.data && (
              <>
                <div className="class-schedule__header">
                  <div className="class-schedule__section">
                    <CustomDropdown
                      toggleTitle={selectedTerm.label}
                      dropdownTitle="Select Term"
                      type="secondary"
                      optionsType={DropdownOptionTypes.Filters}
                      options={termOptions}
                      onFilter={onTermChange}
                      alignment="left"
                    ></CustomDropdown>
                    <span className="class-schedule__credits">
                      Total credit hours:
                      {" " + getSemesterCreditHours(classesResponse.Classes)}
                    </span>
                    {selectedTerm.timeState !== "Future" ? (
                      <span className="class-schedule__credits">
                        GPA for this semester:
                        {" " + getSemesterGpa(classesResponse.Classes)}
                      </span>
                    ) : (
                      <></>
                    )}
                  </div>
                  <ClassesHeaderActions
                    firstFourActions={termActions.firstFourActions}
                  />

                  {termActions.moreActions.length > 0 ? (
                    <div className="class-schedule__actions">
                      <CustomDropdown
                        toggleTitle="More Actions"
                        type="primary"
                        optionsType={DropdownOptionTypes.ActionLinks}
                        dropdownTitle=""
                        options={termActions.moreActions}
                        alignment="right"
                      ></CustomDropdown>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                {classesResponse.Classes && classesResponse.ShoppingCart && (
                  <ClassesScheduleAlert
                    classesEnrolled={classesResponse.Classes}
                    classesInShoppingCart={classesResponse.ShoppingCart}
                    openEnrollmentDate={classesResponse.OpenEnrollment}
                    studentHolds={studentFinancialAid.data.Holds}
                    selectedTerm={selectedTerm}
                    staticActionLinks={allPossibleStaticActionLinks}
                    priorityRegistrationDate={
                      classesResponse.PriorityRegistration
                    }
                    today={today}
                  />
                )}
                {classesResponse.ShoppingCart &&
                classesResponse.ShoppingCart.length > 0 ? (
                  <>
                    <h3>In cart - Not enrolled</h3>
                    <ul className="class-schedule__list">
                      {classesResponse.ShoppingCart.map((item, index) => {
                        return (
                          <StudentClassWidget
                            key={index}
                            studentClass={item}
                            termTimeState={selectedTerm.timeState}
                            isClassEnrolled={false}
                            today={today}
                          ></StudentClassWidget>
                        );
                      })}
                    </ul>
                  </>
                ) : (
                  <></>
                )}

                {classesResponse.ShoppingCart &&
                  classesResponse.ShoppingCart.length > 0 &&
                  classesResponse.Classes.length > 0 && (
                    <h3>Enrolled Courses</h3>
                  )}
                {classesResponse.Classes && (
                  <ul className="class-schedule__list">
                    {classesResponse.Classes.map((item, index) => {
                      return (
                        <StudentClassWidget
                          key={index}
                          studentClass={item}
                          termTimeState={selectedTerm.timeState}
                          isClassEnrolled={true}
                          today={today}
                        />
                      );
                    })}
                  </ul>
                )}
              </>
            )
          )}
        </div>
      </CardContainer>,

      container
    )
  );
}
