import { APP_CACHE, CACHE_EXPIRED, CLEAR_CACHE } from "context/common/constant";
export const sessionStorageProvider = () => {
  // Check if cache has not expired
  const now = new Date();
  const isCacheExpired = sessionStorage.getItem(CACHE_EXPIRED);

  if (isCacheExpired) {
    const expireDate = new Date(isCacheExpired);
    if (now.toISOString() > expireDate.toISOString()) {
      sessionStorage.removeItem(APP_CACHE);

      // Set new expiry date
      now.setMinutes(now.getMinutes() + 5);
      sessionStorage.setItem(CACHE_EXPIRED, now.toString());
    }
  } else {
    now.setMinutes(now.getMinutes() + 5);
    sessionStorage.setItem(CACHE_EXPIRED, now.toString());
  }

  // When initializing, we restore the data from `sessionStorage` into a map.
  const map: Map<any, any> = new Map(
    JSON.parse(sessionStorage.getItem(APP_CACHE) || "[]")
  );

  // Before unloading the app, we write back all the data into `sessionStorage`.
  window.addEventListener("beforeunload", () => {
    const isClearCache = JSON.parse(
      sessionStorage.getItem(CLEAR_CACHE) || "false"
    );
    if (!isClearCache) {
      const appCache = JSON.stringify(Array.from(map.entries()));
      sessionStorage.setItem(APP_CACHE, appCache);
    } else {
      sessionStorage.removeItem(CLEAR_CACHE);
    }
  });

  // We still use the map for write & read for performance.
  return map;
};
