import { Name, NameTypes } from "types/integrations";
export const getPrimaryAndAliasNames = (names: Name[] | undefined) => {
  if (!names || names.length === 0)
    return { primaryName: "", aliasName: "", firstName: "" };
  let primaryName = "",
    aliasName = "",
    firstName = "";
  for (let i = 0; i < names.length; i++) {
    if (names[i].Type === NameTypes.Alias) {
      aliasName = names[i].Name;
    } else {
      primaryName = names[i].Name;
    }
  }
  let firstAndLast = primaryName.split(",");
  primaryName = `${firstAndLast[1]} ${firstAndLast[0]}`;
  firstName = `${firstAndLast[1].split(" ")[0]}`;
  firstAndLast = aliasName.split(",");
  aliasName = `${firstAndLast[1]} ${firstAndLast[0]}`;
  return { primaryName, aliasName, firstName };
};
