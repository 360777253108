import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { SitefinityItemList } from "../../components/common/tools-resources-callouts/sitefinity-item-list";
import ToolsActionDropdown from "../../components/tools/tools-action-dropdown";
import { useToolsContext } from "../../context/tools/tools-context";
import { useDivInjector } from "../../hooks/useDivInjector";
import { SitefinityTool, Types } from "../../types/tools-resources-callouts";
import { mapSortingOption, sortOptions } from "utilities/sorting-options";
import { BusyIndicator } from "components/busyIndicator/busy-indicator";
import ErrorComponent from "components/common/errorComponent";
import { useUserRoles } from "context/user-roles/user-roles-context";
import { FUNCTIONALITY_AVAILABLE_TO_STUDENTS_ONLY } from "context/common/constant";

// https://clc-dev.idevdesign.net/tools
const PortalTools = () => {
  useDivInjector("portal-tools");
  const container = document.getElementById("portal-tools");
  const {
    tools: noneFavoriteTools,
    favoriteTools,
    favsToolsResp,
    allToolsResp,
    sortToolsBy,
    filterToolsBy,
    relevantFilters,
  } = useToolsContext();
  const { isLoading: isFavsToolsLoading, error: favToolsError } = favsToolsResp;
  const { isLoading: isAllToolsLoading, error: allToolsError } = allToolsResp;

  const { impersonating, isStudent } = useUserRoles();
  const [activeDropdown, setActiveDropdown] = useState("");
  const [selectedFilters, setSelectedFilters] = useState([] as string[]);

  const updateTools = (
    filterType: string,
    filterValue: string,
    isChecked?: boolean
  ) => {
    if (filterType === "Filter") {
      if (isChecked) {
        const newSelectedFilters = [...selectedFilters, filterValue];
        filterToolsBy(newSelectedFilters);
        setSelectedFilters((filters) => [...filters, filterValue]);
      } else {
        const newSelectedFilters = selectedFilters.filter(
          (item) => item !== filterValue
        );
        filterToolsBy(newSelectedFilters);
        setSelectedFilters((filters) =>
          filters.filter((item) => item !== filterValue)
        );
      }
    } else if (filterType === "Sort") {
      const { field, direction } = mapSortingOption(filterValue);
      sortToolsBy(field as "Title" | "LastModified", direction === `asc`);
    }
  };

  useEffect(() => {
    if (noneFavoriteTools && favoriteTools) {
      if (!isStudent || impersonating)
        noneFavoriteTools?.push(...favoriteTools);
    }
  }, [favoriteTools, noneFavoriteTools]);

  return (
    container &&
    ReactDOM.createPortal(
      <>
        {favToolsError || allToolsError ? (
          <ErrorComponent
            message={favToolsError?.message || allToolsError?.message || ""}
          />
        ) : isFavsToolsLoading || isAllToolsLoading ? (
          <BusyIndicator />
        ) : (
          favoriteTools &&
          noneFavoriteTools && (
            <>
              {!isStudent ? (
                <>{FUNCTIONALITY_AVAILABLE_TO_STUDENTS_ONLY}</>
              ) : impersonating ? (
                <div>
                  <span>
                    Warning: Proxy does not include favorite tools access
                  </span>
                </div>
              ) : (
                /* Favorites */
                <section className="tools-listing">
                  <div className="tools-listing__header">
                    <h2 className="tools-listing__title">On Dashboard</h2>
                  </div>
                  {favoriteTools && (
                    <SitefinityItemList
                      data={favoriteTools as SitefinityTool[]}
                      type={Types.Tool}
                      displayType="favorite"
                    />
                  )}
                </section>
              )}
              {/* Full Listing */}
              <section className="tools-listing">
                <div className="tools-listing__header">
                  <h2 className="tools-listing__title">More Tools</h2>

                  <ToolsActionDropdown
                    options={sortOptions}
                    icon="icon-sort"
                    label="Sort"
                    activeDropdown={activeDropdown}
                    setActiveDropdown={setActiveDropdown}
                    filterTools={updateTools}
                  ></ToolsActionDropdown>

                  <ToolsActionDropdown
                    options={relevantFilters}
                    icon="icon-filter"
                    label="Filter"
                    activeDropdown={activeDropdown}
                    setActiveDropdown={setActiveDropdown}
                    filterTools={updateTools}
                  ></ToolsActionDropdown>
                </div>
                {noneFavoriteTools && (
                  <SitefinityItemList
                    data={noneFavoriteTools as SitefinityTool[]}
                    type={Types.Tool}
                    displayType="listing"
                  />
                )}
              </section>
            </>
          )
        )}
      </>,
      container
    )
  );
};

export default PortalTools;
