import CardContainer from "../containers/card-container";
import { formatCurrency } from "../../utilities/currency-parser";
import { formatDate } from "../../utilities/date-parser";
import {
  getActiveFinancialAidYearsFromTerm,
  getAnticipatedAidTotal,
} from "utilities/financials";
import ErrorComponent from "components/common/errorComponent";
import { BusyIndicator } from "components/busyIndicator/busy-indicator";
import { useStudentContext } from "context/student/student-context";
import { useUserRoles } from "context/user-roles/user-roles-context";
import { FUNCTIONALITY_AVAILABLE_TO_STUDENTS_ONLY } from "context/common/constant";
interface Props {
  fafsaSchoolCodeText: string | undefined;
  componentHeading: string | undefined;
  fafsaSubText: string | undefined;
  fafsaSubTextLink: string | undefined;
  completeFafsaLink: string | undefined;
  needToCompleteFafsaActionLink: string | undefined;
  applyForVeteranLink: string | undefined;
  viewAwardsSummaryLink: string | undefined;
}
export default function FinancialAid({
  fafsaSchoolCodeText,
  componentHeading,
  fafsaSubText,
  fafsaSubTextLink,
  completeFafsaLink,
  needToCompleteFafsaActionLink,
  applyForVeteranLink,
  viewAwardsSummaryLink,
}: Props) {
  const {
    studentFinancialAid,
    currentTermNumber: activeTerm,
    studentTerms,
    financialAidDisbursementDate,
  } = useStudentContext();
  const { isStudent } = useUserRoles();
  const total =
    studentFinancialAid.data &&
    activeTerm &&
    getAnticipatedAidTotal(studentFinancialAid.data.AnticipatedAid, activeTerm);

  const aidYears =
    studentTerms.data &&
    activeTerm &&
    getActiveFinancialAidYearsFromTerm(
      activeTerm,
      studentTerms.data.ActiveTerms
    );
  return (
    <CardContainer title={componentHeading || "Financial Aid"}>
      {!isStudent ? (
        <>{FUNCTIONALITY_AVAILABLE_TO_STUDENTS_ONLY}</>
      ) : studentFinancialAid.error ? (
        <ErrorComponent message={studentFinancialAid.error.message} />
      ) : studentFinancialAid.isLoading || studentTerms.isLoading ? (
        <BusyIndicator />
      ) : (
        studentFinancialAid.data &&
        studentTerms.data &&
        activeTerm && (
          <>
            {/* <div className="card-container__section">
              <h3>Anticipated Amount {aidYears}</h3>
              <p className="text-size-large">
                ${total ? formatCurrency(total) : 0}
              </p>
              {!financialAidDisbursementDate ? (
                <p className="text-size-small">
                  Disbursement date is unavailable at this time
                </p>
              ) : (
                <p className="text-size-small">
                  Disbursement begins on{" "}
                  {formatDate(new Date(financialAidDisbursementDate))}
                </p>
              )}
            </div> */}
            <div className="card-container__section">
              <div className="row">
                <div className="col-lg-7">
                  <p>
                    My FAFSA status:
                    <span className="text-weight-bold">
                      {" " + studentFinancialAid.data.FAFSAStatus}
                    </span>
                  </p>

                  <div>
                    <p className="text-size-medium">{fafsaSubText}</p>
                    {fafsaSubTextLink && (
                      <p
                        className="text-size-medium"
                        dangerouslySetInnerHTML={{ __html: fafsaSubTextLink }}
                      ></p>
                    )}
                  </div>
                </div>
                <div className="col-lg-5">
                  <p
                    className="financials__button--small"
                    dangerouslySetInnerHTML={{
                      __html: completeFafsaLink ?? "",
                    }}
                  ></p>
                  <p className="text-size-small">{fafsaSchoolCodeText}</p>
                  <p
                    className="text-size-medium"
                    dangerouslySetInnerHTML={{
                      __html: needToCompleteFafsaActionLink ?? "",
                    }}
                  ></p>
                </div>
              </div>
            </div>
            {total && total > 0 ? (
              <div className="card-container__section">
                <div className="row">
                  <div className="col-lg-7">
                    <h3>My Award</h3>
                    <p className="text-size-medium">
                      Congratulations, you have been awarded aid for the{" "}
                      {aidYears} financial aid year.
                    </p>
                  </div>
                  <div className="col-lg-5">
                    <p
                      className="financials__button--small"
                      dangerouslySetInnerHTML={{
                        __html: viewAwardsSummaryLink ?? "",
                      }}
                    ></p>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
            {studentFinancialAid.data.Holds.length > 0 && (
              <div className="card-container__section">
                <h3>My Financial Aid Holds</h3>
                {studentFinancialAid.data.Holds.map((hold) => {
                  return (
                    <p>
                      <a href="#">{hold.Description}</a>
                    </p>
                  );
                })}
              </div>
            )}
            <div className="card-container__section">
              <p
                className="financials__button--small"
                dangerouslySetInnerHTML={{
                  __html: applyForVeteranLink ?? "",
                }}
              ></p>
            </div>
          </>
        )
      )}
    </CardContainer>
  );
}
