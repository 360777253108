import { BusyIndicator } from "components/busyIndicator/busy-indicator";
import ErrorComponent from "components/common/errorComponent";
import { useStudentAcademicPlanContext } from "context/student/student-academic-plan-context";
import React, { useEffect, useState } from "react";
import { ActionLinkDataModel } from "types/data-models";
import { ProgressChartPath } from "../../types/integrations";
import CardContainer from "../containers/card-container";
import { useUserRoles } from "context/user-roles/user-roles-context";
import { FUNCTIONALITY_AVAILABLE_TO_STUDENTS_ONLY } from "context/common/constant";

interface Props {
  actionLinks: ActionLinkDataModel[];
  componentHeading: string | undefined;
}

export default function AcademicPlan({ componentHeading, actionLinks }: Props) {
  const [chartPaths, setChartPaths] = useState(Array<ProgressChartPath>);
  const { studentAcademicPlan } = useStudentAcademicPlanContext();
  const { isStudent } = useUserRoles();

  const completeColor = "#49A425";
  const progressColor = "#005374";
  const incompleteColor = "#D4D4D5";

  let cumulativePercent = 0;

  const getCoordinatesForPercent = (percent: number) => {
    const x = Math.cos(2 * Math.PI * percent);
    const y = Math.sin(2 * Math.PI * percent);
    return [x, y];
  };

  // create an svg for the chart
  const buildProgressChart = () => {
    if (studentAcademicPlan && studentAcademicPlan.data) {
      const progressChart = Array<ProgressChartPath>();
      const slices = [
        {
          percent:
            +studentAcademicPlan.data?.AcademicProgress.Percentages.Complete /
            100.0,
          color: completeColor,
        },
        {
          percent:
            +studentAcademicPlan.data?.AcademicProgress.Percentages.InProgress /
            100.0,
          color: progressColor,
        },
        {
          percent:
            +studentAcademicPlan.data?.AcademicProgress.Percentages.Incomplete /
            100.0,
          color: incompleteColor,
        },
      ];
      slices.forEach((slice) => {
        const [startX, startY] = getCoordinatesForPercent(cumulativePercent);

        // each slice starts where the last slice ended, so keep a cumulative percent
        cumulativePercent += +slice.percent;
        const [endX, endY] = getCoordinatesForPercent(cumulativePercent);

        // if the slice is more than 50%, take the large arc (the long way around)
        const largeArcFlag = +slice.percent > 0.5 ? 1 : 0;

        const pathData = [
          `M ${startX} ${startY}`, // Move
          `A 1 1 0 ${largeArcFlag} 1 ${endX} ${endY}`, // Arc
          `L 0 0`, // Line
        ].join(" ");

        const path = {
          data: pathData,
          fillColor: slice.color,
        };
        progressChart.push(path);
      });
      setChartPaths(progressChart);
    }
  };

  useEffect(() => {
    buildProgressChart();
  }, [studentAcademicPlan]);

  return (
    <CardContainer title={componentHeading || "Academic Plan"}>
      {!isStudent ? (
        <>{FUNCTIONALITY_AVAILABLE_TO_STUDENTS_ONLY}</>
      ) : (
        <div className="academic-plan">
          {studentAcademicPlan.error ? (
            <ErrorComponent message={studentAcademicPlan.error.message} />
          ) : studentAcademicPlan.isLoading ? (
            <BusyIndicator />
          ) : (
            studentAcademicPlan.data && (
              <>
                {/* <h3 className="academic-plan__heading">
                You are well on your way!
              </h3> */}
                <div className="academic-plan__content academic-plan__content--secondary">
                  {studentAcademicPlan.data.AcademicProgress.HasReport ===
                    "Y" && (
                    <div className="academic-plan__chart">
                      <svg
                        viewBox="-1 -1 2 2"
                        className="academic-plan__chart-icon"
                        aria-hidden="true"
                      >
                        {chartPaths.map((path, index) => {
                          return (
                            <path
                              key={index}
                              d={path.data}
                              fill={path.fillColor}
                            ></path>
                          );
                        })}
                      </svg>
                      <span className="academic-plan__chart-label">
                        {studentAcademicPlan.data.AcademicProgress.Percentages
                          .Complete +
                          studentAcademicPlan.data.AcademicProgress.Percentages
                            .InProgress +
                          "%"}
                      </span>
                    </div>
                  )}
                  <span className="academic-plan__label">
                    Gpa: {studentAcademicPlan.data.GPA.toFixed(1)}
                  </span>
                </div>
                <div className="academic-plan__content">
                  <p className="academic-plan__text">
                    Currently pursuing (a) degree(s) in{" "}
                    {studentAcademicPlan.data.Programs.map((program) => {
                      return (
                        <span className="academic-plan__value">
                          {program.Description}
                          <br />
                          Plan Code: {program.Plan}
                        </span>
                      );
                    })}
                  </p>
                  <ul className="academic-plan__list">
                    {studentAcademicPlan.data.AcademicProgress.HasReport ===
                      "Y" && (
                      <>
                        <li className="academic-plan__item academic-plan__item--complete">
                          <span className="text-weight-bold">
                            {studentAcademicPlan.data.AcademicProgress
                              .Percentages.Complete + "% "}
                          </span>
                          <span>complete</span>
                        </li>
                        <li className="academic-plan__item academic-plan__item--progress">
                          <span className="text-weight-bold">
                            {studentAcademicPlan.data.AcademicProgress
                              .Percentages.InProgress + "% "}
                          </span>
                          <span>in progress</span>
                        </li>
                        <li className="academic-plan__item academic-plan__item--incomplete">
                          <span className="text-weight-bold">
                            {studentAcademicPlan.data.AcademicProgress
                              .Percentages.Incomplete + "% "}
                          </span>
                          <span>incomplete</span>
                        </li>
                      </>
                    )}
                  </ul>
                </div>
                <ul className="academic-plan__list academic-plan__list--secondary">
                  {actionLinks.map((actionLink) => {
                    return (
                      <li
                        className="academic-plan__item"
                        key={actionLink.Label}
                      >
                        <a
                          href={actionLink.Url}
                          className="arrow-link arrow-link--secondary"
                          target={actionLink.IsExternal ? "_blank" : "_self"}
                        >
                          {actionLink.Label}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </>
            )
          )}
        </div>
      )}
    </CardContainer>
  );
}
