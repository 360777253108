import { useEffect } from "react";
import { useUserRoles } from "context/user-roles/user-roles-context";
import { APP_CACHE, CLEAR_CACHE, PROXY_USER } from "context/common/constant";

export const WindowLocationObserver = () => {
  const sitefintityLogin = `/sitefinity/login`;
  const sitefinityLogout = `/sitefinity/signout`;
  const login = `/login`;
  const { resetImpersonation } = useUserRoles();
  const resetAppState = (pathname: string) => {
    if (
      pathname === sitefinityLogout ||
      pathname === sitefintityLogin ||
      pathname === login
    ) {
      sessionStorage.setItem(CLEAR_CACHE, "true");
      sessionStorage.removeItem(APP_CACHE);
      const impersonation = JSON.parse(
        localStorage.getItem(PROXY_USER) || "false"
      );
      if (impersonation && impersonation.impersonating) {
        localStorage.removeItem(PROXY_USER);
        resetImpersonation();
      }
    }
  };
  useEffect(() => {
    const pathname = window.location.pathname.toLocaleLowerCase();
    resetAppState(pathname);
    return () => {
      resetAppState(pathname);
    };
  }, [window.location.pathname]);

  // Don't want render anything
  return <></>;
};
