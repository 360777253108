import { ActionLinkDataModel } from "./data-models";

export interface FilterDropdownOption {
  Label: string;
  Value: string;
  SecondaryValue?: string;
}
export interface ActionLinkDropdownOption extends ActionLinkDataModel {}

export const DropdownOptionDataTypes = [] as
  | Array<FilterDropdownOption>
  | Array<ActionLinkDropdownOption>;

export enum DropdownOptionTypes {
  Filters = "Filters",
  ActionLinks = "ActionLinks",
}
export interface SortBy {
  Field: "Title" | "LastModified";
  Ascending: boolean;
}
