interface Props {
  dropdownActive: string;
  closeButtonRef: React.MutableRefObject<any>;
  closeDropdown: (e: any) => void;
  dropdownContent: JSX.Element;
  dropdownTitle?: string;
  alignment?: string;
}

export default function DropdownContentContainer({
  dropdownActive,
  closeButtonRef,
  closeDropdown,
  dropdownContent,
  dropdownTitle,
  alignment,
}: Props) {
  return (
    <>
      <div
        className={`select-dropdown__dropdown select-dropdown__dropdown--${alignment} ${dropdownActive}`}
      >
        {dropdownTitle && (
          <h3 className="select-dropdown__heading">{dropdownTitle}</h3>
        )}

        <button
          type="button"
          className="select-dropdown__close"
          onClick={closeDropdown}
          ref={closeButtonRef}
        >
          <span className="visually-hidden">close dropdown</span>
        </button>
        {dropdownContent}
      </div>
    </>
  );
}
