import { TrimText } from "components/trimText/trimText";
import { StudentOrganization } from "types/student";
import { useStudentOrganizationContext } from "context/student-organizations/student-organizations-context";
import { ShowDashboardFavoriteActionButton } from "components/dashboard-favorite-action-button/dashboard-favorite-action-button";

interface Props {
  data: StudentOrganization;
  type: string;
  displayType: string;
  url: string;
}
export const StudentOrganizationListingCard = ({
  data,
  type,
  displayType,
  url,
}: Props) => {
  const { addFavoriteOrg, removeFavoriteOrg } = useStudentOrganizationContext();
  const addFavoriteClick = async () => {
    await addFavoriteOrg(data.Id, type);
  };

  const removeFavoriteClick = async () => {
    await removeFavoriteOrg(data.Id);
  };
  return (
    <li className="tools-card">
      {data.Image && data.Image.length > 0 ? (
        <img
          className="tools-card__image"
          src={
            data.Image[0].Url
              ? data.Image[0].Url
              : `/images/default-source/default-album/profile-pic.png`
          }
          alt={data.Image[0].AlternativeText}
        />
      ) : (
        <img
          src={
            data.Image[0] && data.Image[0].Url
              ? data.Image[0].Url
              : `/images/default-source/callouts/clc-alumni-association.png`
          }
          alt=""
          className="tools-card__image"
        />
      )}
      <div className="tools-card__content">
        {data.Title && <span className="tools-card__tag">{data.Title}</span>}
        {data.Id ? (
          <h3 className="tools-card__title">
            <a
              href={url + `?studentOrganizationId=${data.Id}`}
              className="tools-card__link"
              aria-label={`${data.Title} will open in a new window`}
            >
              {data.Title}
            </a>
          </h3>
        ) : (
          <h3 className="tools-card__title">{data.Title}</h3>
        )}
        {data.Content && (
          <TrimText
            content={data.Content}
            className={"tools-card__summary"}
            isHtmlContent={true}
          />
        )}
      </div>
      <div className="tools-card__aside">
        {displayType === "listing" ? (
          <ShowDashboardFavoriteActionButton
            onClick={addFavoriteClick}
            text={"Show on Dashboard"}
          />
        ) : (
          <ShowDashboardFavoriteActionButton
            onClick={removeFavoriteClick}
            text={"Remove from Dashboard"}
            isFavorite={true}
          />
        )}
      </div>
    </li>
  );
};
