import { Impersonator, UserRoles, UserRolesResp } from "types/user-roles";
import React, { useState, useEffect, useContext } from "react";
import { useHttp } from "hooks/useHttp";
import { post } from "services/api-service";
import { APP_CACHE, CLEAR_CACHE, PROXY_USER } from "context/common/constant";

export interface IUserRoles {
  impersonator: boolean;
  impersonating: boolean;
  isStudent: boolean;
}

export interface IUserRolesContext extends IUserRoles {
  impersonate: (empId: string) => Promise<void>;
  resetImpersonation: () => Promise<void>;
}

const defaultState: IUserRoles = {
  impersonator: false,
  isStudent: false,
  impersonating: false,
};

const defaultContext: IUserRolesContext = {
  ...defaultState,
  impersonate: async () => {},
  resetImpersonation: async () => {},
};

export interface UserRolesProps {
  children?: React.ReactNode;
}

export const UserRolesContext =
  React.createContext<IUserRolesContext>(defaultContext);

export const UserRolesProvider: React.FC<UserRolesProps> = ({ children }) => {
  const [impersonator, setImpersonator] = useState<boolean>(
    defaultState.impersonator
  );
  const [impersonating, setImpersonating] = useState<boolean>(
    defaultContext.impersonating
  );

  const [isStudent, setIsStudent] = useState<boolean>(defaultContext.isStudent);
  const { data: userRoles, mutate } = useHttp<UserRolesResp>(
    "get",
    `/api/user/roles`
  );

  useEffect(() => {
    if (userRoles && userRoles.Roles && userRoles.Roles.length > 0) {
      if (isImpersonator(userRoles.Roles)) {
        setImpersonator(true);
      }
      const impersonation = JSON.parse(
        localStorage.getItem(PROXY_USER) || "false"
      );
      if (impersonation) {
        setImpersonating(impersonation.impersonating);
        setIsStudent(impersonation.isStudent);
        return;
      }

      setIsStudent(userRoles.IsStudent);
    }
  }, [userRoles]);

  const isImpersonator = (roles: UserRoles[]) => {
    return roles.find((role) => role.Name === "Proxy User");
  };

  const impersonate = async (empId: string) => {
    const resp = await post<Impersonator>(
      `/api/user/proxy?empId=${empId}`,
      {},
      {}
    );
    setImpersonating(true);
    setIsStudent(resp.data.IsStudent);
    localStorage.setItem(
      PROXY_USER,
      JSON.stringify({ impersonating: true, isStudent: resp.data.IsStudent })
    );
    // reset swr cache to retrieve apis once impersonated
    sessionStorage.removeItem(APP_CACHE);

    location.reload();
  };

  const resetImpersonation = async () => {
    const resp = await post<Impersonator>(`/api/user/reset`, {}, {});
    setImpersonating(false);
    setIsStudent(resp.data.IsStudent);
    localStorage.removeItem(PROXY_USER);
    // reset swr cache to retrieve apis once reset impersonation
    sessionStorage.removeItem(APP_CACHE);
    sessionStorage.setItem(CLEAR_CACHE, "true");
    location.reload();
  };

  return (
    <UserRolesContext.Provider
      value={{
        impersonator: impersonator,
        impersonate,
        resetImpersonation,
        impersonating: impersonating,
        isStudent: isStudent,
      }}
    >
      {children}
    </UserRolesContext.Provider>
  );
};

export const useUserRoles = () => useContext(UserRolesContext);
