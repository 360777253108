import CardContainer from "../containers/card-container";
import { getPrimaryAndAliasNames } from "./utilities";
import { useMemo } from "react";
import { useStudentContext } from "context/student/student-context";
import ErrorComponent from "components/common/errorComponent";
import { BusyIndicator } from "components/busyIndicator/busy-indicator";

interface Props {
  linkHtml: string | null;
  changeImageHtml: string | null;
  componentHeading: string | null;
}
export const StudentPersonalDetail = ({
  linkHtml,
  changeImageHtml,
  componentHeading,
}: Props) => {
  const { studentProfile } = useStudentContext();
  const { primaryName, aliasName } = useMemo(
    () => getPrimaryAndAliasNames(studentProfile.data?.Names),
    [studentProfile.data?.Names]
  );

  return (
    <CardContainer title={componentHeading || "Personal Detail"}>
      {studentProfile.error ? (
        <ErrorComponent message={studentProfile.error.message} />
      ) : studentProfile.isLoading ? (
        <BusyIndicator />
      ) : (
        studentProfile.data && (
          <>
            <div className="card-container__section">
              <div className="row align-items-center">
                <div className="col-4">
                  <img
                    src={
                      studentProfile.data.AvatarUrl
                        ? studentProfile.data.AvatarUrl
                        : `/images/default-source/default-album/profile-pic.png`
                    }
                    alt="profile picture"
                    className="portal-profile__image"
                  />
                </div>
                <div className="col-8">
                  <div
                    dangerouslySetInnerHTML={{ __html: changeImageHtml ?? "" }}
                  />

                  {/* <Link
                    url={changeImageUrl ? changeImageUrl : "#"}
                    label="Change My Profile Picture"
                    type="arrow"
                    openNewTab={true}
                  /> */}
                </div>
              </div>
            </div>
            <div className="card-container__section">
              <h3>Student ID:</h3>
              <div className="row">
                <div className="col-8">
                  <p>{studentProfile.data.StudentId}</p>
                </div>
              </div>
            </div>
            <div className="card-container__section">
              <h3>Names:</h3>
              <div className="row">
                <div className="col-8">
                  <p>{primaryName}</p>
                </div>
                <div className="col-4">
                  <p>Primary</p>
                </div>
              </div>
              <div className="row">
                <div className="col-8">
                  <p>{aliasName}</p>
                </div>
                <div className="col-4">
                  <p>Alias</p>
                </div>
              </div>
            </div>

            <div className="card-container__section">
              <div dangerouslySetInnerHTML={{ __html: linkHtml ?? "" }} />
              {/* <Link
                url={linkUrl ? linkUrl : "#"}
                label={linkText ? linkText : "Update My Personal Information"}
                type="arrow"
                openNewTab={openNewTab}
              /> */}
            </div>
          </>
        )
      )}
    </CardContainer>
  );
};
