import ReactDOM from "react-dom";
import CardContainer from "../../components/containers/card-container";
import Link from "../../components/links/link";
import { SitefinityFavoritesCard } from "components/common/tools-resources-callouts/sitefinity-favorites-card";
import { useResourcesContext } from "context/resources/resources-context";
import ErrorComponent from "components/common/errorComponent";
import { BusyIndicator } from "components/busyIndicator/busy-indicator";
import { useUserRoles } from "context/user-roles/user-roles-context";
import { FUNCTIONALITY_AVAILABLE_TO_STUDENTS_ONLY } from "context/common/constant";

// Dashboard Favorites Widget
export const FavoriteResources = () => {
  const container = document.getElementById("favorite-resources");
  const { impersonating, isStudent } = useUserRoles();

  const componentHeading = container?.dataset.heading;
  const data = container?.dataset.model;
  const parsedData = (data && JSON.parse(data)) || null;

  const { favoriteResources, isLoading, error } = useResourcesContext(); // Dashboard Resource Favorites Widget
  return (
    container &&
    ReactDOM.createPortal(
      <CardContainer title={componentHeading || "Favorite Resources"}>
        {!isStudent ? (
          <>{FUNCTIONALITY_AVAILABLE_TO_STUDENTS_ONLY}</>
        ) : impersonating ? (
          <div>
            <span>
              Warning: Proxy does not include favorite resources access
            </span>
          </div>
        ) : (
          <>
            {error ? (
              <ErrorComponent message={error.message} />
            ) : isLoading ? (
              <BusyIndicator />
            ) : favoriteResources && favoriteResources.length > 0 ? (
              <SitefinityFavoritesCard favorites={favoriteResources} />
            ) : (
              <div className="callout-listing">
                No favorite resources selected
              </div>
            )}
            <Link
              url={parsedData.linkUrl || "/resources"}
              label={parsedData.linkText || "View All Resources"}
              type="arrow"
            ></Link>
          </>
        )}
      </CardContainer>,
      container
    )
  );
};
