import ReactDOM from "react-dom";
import EventListing from "../../components/events/event-listing";
import CardContainer from "../../components/containers/card-container";
import {
  CalendarItemIntegrationDataTypes,
  TwentyFiveLiveEvent,
} from "../../types/integrations";
import { mutateToCalendarItem } from "../../utilities/integrations";
import ErrorComponent from "components/common/errorComponent";
import { CalendarItem } from "types/calendar";
import { BusyIndicator } from "components/busyIndicator/busy-indicator";
import { useHttp } from "hooks/useHttp";
import { getFeatured25LiveEventsFromSitefinityUrl } from "utilities/urls";

export default function FeaturedEvents() {
  const container = document.getElementById("featured-events") as HTMLElement;
  const componentHeading = container.dataset.heading;
  const today = new Date(container.dataset.today!);
  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);
  const nextMonth = new Date(today);
  nextMonth.setMonth(today.getMonth() + 1);
  const actionLink = container.dataset?.actionlink;

  const {
    data: calendarData,
    isLoading,
    error,
  } = useHttp<TwentyFiveLiveEvent[]>(
    "get",
    getFeatured25LiveEventsFromSitefinityUrl(today, nextMonth),
    true
  );
  const getMutatedEvents = () => {
    const result: CalendarItem[] = [];
    if (calendarData) {
      calendarData.map((item) => {
        result.push(
          mutateToCalendarItem(
            item,
            CalendarItemIntegrationDataTypes.TwentyFiveLiveEvents.name
          ) as CalendarItem
        );
      });
    }
    return result;
  };

  const calendarItems =
    !error && calendarData && !isLoading ? getMutatedEvents() : [];

  return ReactDOM.createPortal(
    <>
      <CardContainer title={componentHeading || "Campus Events"}>
        {error ? (
          <ErrorComponent message={error.message} />
        ) : !calendarItems ? (
          <BusyIndicator />
        ) : (
          <>
            <EventListing data={calendarItems}></EventListing>
            <div
              dangerouslySetInnerHTML={{
                __html: actionLink ?? "",
              }}
              className="arrow-link"
            ></div>
          </>
        )}
      </CardContainer>
    </>,
    container
  );
}
