import { BusyIndicator } from "components/busyIndicator/busy-indicator";
import ErrorComponent from "components/common/errorComponent";
import CardContainer from "components/containers/card-container";
import EventListing from "components/events/event-listing";
import { useEventsContext } from "context/events/events-context";
import { useState } from "react";
import ReactDOM from "react-dom";
import ReactPaginate from "react-paginate";
import { CalendarItem } from "types/calendar";
import { CalendarItemIntegrationDataTypes } from "types/integrations";
import { mutateToCalendarItem } from "utilities/integrations";

export default function EventsListing() {
  const container = document.getElementById("events-list") as HTMLElement;
  const componentHeading = container.dataset.heading;

  const { twentyFiveEventsResponse } = useEventsContext();
  const { data: calendarData, error, isLoading } = twentyFiveEventsResponse;

  const getCalendarItemEvents = () => {
    if (!calendarData) return [];
    const result: CalendarItem[] = [];
    for (let i = 0; i < calendarData.length; i++) {
      result.push(
        mutateToCalendarItem(
          calendarData[i],
          CalendarItemIntegrationDataTypes.TwentyFiveLiveEvents.name
        ) as CalendarItem
      );
    }
    return result;
  };

  const calendarItems =
    !error && calendarData && !isLoading ? getCalendarItemEvents() : [];

  return ReactDOM.createPortal(
    <>
      <CardContainer title={componentHeading || "Campus Events"}>
        {error ? (
          <ErrorComponent message={error.message} />
        ) : !calendarItems ? (
          <BusyIndicator />
        ) : (
          <>
            <PaginatedItems items={calendarItems} />
          </>
        )}
      </CardContainer>
    </>,
    container
  );
}

interface Props {
  items: any;
}
function PaginatedItems({ items }: Props) {
  const [itemOffset, setItemOffset] = useState(0);

  const endOffset = itemOffset + 5;

  const currentItems = items.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(items.length / 5);

  const handlePageClick = (event: { selected: number }) => {
    const newOffset = (event.selected * 5) % items.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      <EventListing data={currentItems}></EventListing>
      <ReactPaginate
        breakLabel="..."
        nextLabel="next >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="< previous"
        className="events-listing__pagination"
        renderOnZeroPageCount={null}
      />
    </>
  );
}
