/* Tools */
export const getToolsUrl = () => {
  return "/sfapi/default/tools";
};
export const getLatestToolUrl = (numberOfDaysToShowNewTool: number) => {
  const earliestDateToShowNewTool = new Date();
  earliestDateToShowNewTool.setDate(
    earliestDateToShowNewTool.getDate() - numberOfDaysToShowNewTool
  );
  const dateFormatted =
    earliestDateToShowNewTool.getFullYear() +
    "-" +
    (earliestDateToShowNewTool.getMonth() + 1) +
    "-" +
    earliestDateToShowNewTool.getDate();

  const filterQuery = `$filter=DateCreated ge ${dateFormatted}`;
  const orderByAndTopQuery = "$orderby=DateCreated desc&$top=1";
  return `/sfapi/default/tools?${filterQuery}&${orderByAndTopQuery}`;
};
export const getFavoritesUrl = (typeName?: string) => {
  if (typeName) {
    return `/api/favorites/type/${typeName}`;
  }
  return "/api/favorites/";
};
export const getFavoritesDeleteUrl = (toolId: string) => {
  return "/api/favorites/" + toolId;
};

/* Sutdent Organizations */
export const getStudentOrganizationsFromSitefinityUrl = () => {
  const expandQuery = "$Expand=Image,Instructors";
  return `/sfapi/default/organizations?${expandQuery}`;
};
export const getStudentOrganizationFromSitefinityUrl = (
  studentOrganizationId: string
) => {
  const expandQuery = "$Expand=Image,Instructors";
  return `/sfapi/default/organizations(${studentOrganizationId})?${expandQuery}`;
};

/* Canvas */
export const getCanvasUserCoursesUrl = (enrollmentState?: string) => {
  return `/api/Courses` + enrollmentState && "/" + enrollmentState;
};
export const getCanvasCourseUrl = (courseId: string) => {
  return `/api/Course/${courseId}`;
};
export const getCanvasUserCalendarEventsUrl = (
  startDate: Date,
  endDate: Date,
  eventType?: string
) => {
  const startDateFormatted =
    startDate.getFullYear() +
    "-" +
    (startDate.getMonth() + 1) +
    "-" +
    startDate.getDate();
  const endDateFormatted =
    endDate.getFullYear() +
    "-" +
    (endDate.getMonth() + 1) +
    "-" +
    endDate.getDate();

  let url = `/api/CalendarEvents?startDate=${startDateFormatted}&endDate=${endDateFormatted}`;
  if (eventType) url += "&eventType=" + eventType;

  return url;
};
export const getCanvasUserCalendarEventUrl = (calendarEventId: string) => {
  return `/api/CalendarEvent/${calendarEventId}`;
};

/* 25 Live*/
export const get25LiveCalendarTagsFromSitefinityUrl = (
  calendarTagId: string
) => {
  const selectQuery = `$select=Id,Title,ParentId,TaxonomyId`;
  return `/sfapi/default/hierarchy-taxa?${selectQuery}&$filter=(TaxonomyId eq ${calendarTagId})`;
};
export const get25LiveEventFromSitefinityUrl = (eventId: string) => {
  const selectQuery = `$Select=Id,EventStart,EventEnd,CustomLocation,EventId,Title,CustomFields,EventActionUrl,Content,calendarcategories`;
  return `/sfapi/default/events/${eventId}?${selectQuery}`;
};
export const getFeatured25LiveEventsFromSitefinityUrl = (
  startDate: Date,
  endDate: Date
) => {
  const startDateFormatted = formatDate(startDate);
  const endDateFormatted = formatDate(endDate);

  const filterQuery = `$Filter=EventStart ge ${startDateFormatted} AND EventEnd le ${endDateFormatted}`;
  const selectQuery = `$Select=Id,EventStart,EventEnd,CustomLocation,EventId,Title,CustomFields,EventActionUrl,Content`;
  const orderByQuery = "$OrderBy=EventStart";
  const topQuery = "$Top=3";

  return `/sfapi/default/events?${filterQuery} &${selectQuery}&${orderByQuery}&${topQuery}`;
};
export const get25LiveEventsFromSitefinityUrl = (
  startDate: Date,
  endDate: Date
) => {
  const startDateFormatted = formatDate(startDate);
  const endDateFormatted = formatDate(endDate);

  const filterQuery = `$Filter=EventStart ge ${startDateFormatted} AND EventEnd le ${endDateFormatted}`;
  const selectQuery = `$Select=Id,EventStart,EventEnd,CustomLocation,EventId,Title,CustomFields,EventActionUrl,Content`;
  const orderByQuery = "$OrderBy=EventStart";
  return `/sfapi/default/events?${filterQuery} &${selectQuery}&${orderByQuery}`;
};

export const get25LiveOrganizationEventsFromSitefinityUrl = (
  today: Date,
  calendarTag: string
) => {
  if (
    calendarTag === undefined ||
    calendarTag === null ||
    calendarTag === "" ||
    calendarTag === " "
  ) {
    calendarTag = "00000000-0000-0000-0000-000000000000";
  }
  const startDateFormatted = formatDate(today);

  const filterQuery = `$Filter=(calendarcategories/any(x:x eq ${calendarTag})) and EventStart ge ${startDateFormatted}`;
  const selectQuery = `$Select=Id,EventStart,EventEnd,CustomLocation,EventId,Title,CustomFields,EventActionUrl,Content,calendarcategories`;
  const orderByQuery = "$OrderBy=EventStart";
  const limitByQuery = "$Top=3";

  return `/sfapi/default/events?${filterQuery} &${selectQuery}&${orderByQuery}&${limitByQuery}`;
};

export const get25LiveRelatedEventsFromSitefinityUrl = (
  today: Date,
  calendarTag: string,
  eventId: string
) => {
  if (
    calendarTag === undefined ||
    calendarTag === null ||
    calendarTag === "" ||
    calendarTag === " "
  ) {
    calendarTag = "00000000-0000-0000-0000-000000000000";
  }
  const startDateFormatted = formatDate(today);

  const filterQuery = `$Filter=(calendarcategories/any(x:x eq ${calendarTag}) and Id ne ${eventId}) and EventStart ge ${startDateFormatted}`;
  const selectQuery = `$Select=Id,EventStart,EventEnd,CustomLocation,EventId,Title,CustomFields,EventActionUrl,Content,calendarcategories`;
  const orderByQuery = "$OrderBy=EventStart";
  const limitByQuery = "$Top=3";
  return `/sfapi/default/events?${filterQuery} &${selectQuery}&${orderByQuery}&${limitByQuery}`;
};

export const getSitefinityEventDetailPageUrl = () => {
  return `/student-life/event-detail?eventId=`;
};
export const getSitefinityStaffUrl = (ids: string[]) => {
  let filterQuery = ``;
  if (!ids || ids.length === 0) {
    filterQuery = `$Filter=StaffId eq '00000000'`;
  } else {
    filterQuery = `$Filter=StaffId in (${ids.map(
      (id, index) => '"' + id + '"'
    )})`;
  }

  return `/sfapi/default/staffs?${filterQuery}`;
};
export const getSitefinityStaffForFacultyTabsUrl = (ids: string[]) => {
  let filterQuery = ``;
  const selectQuery = `$Select=JobTitle,Name,WorkPhone,Email,OfficeLocation,UrlName`;
  if (!ids || ids.length === 0) {
    filterQuery = `$Filter=StaffId eq '00000000'`;
  } else {
    filterQuery = `$Filter=StaffId in (${ids.map(
      (id, index) => '"' + id + '"'
    )})`;
  }

  return `/sfapi/default/staffs?${filterQuery}&${selectQuery}`;
};
export const getSitefinityInstructorForFacultyTabsUrl = (ids: string[]) => {
  let filterQuery = ``;
  const selectQuery = `$Select=SchedulingLink,UrlName,Id`;
  const expandQuery = `$expand=Photo($Select=Url),Staff($select=JobTitle,Name,WorkPhone,Email,OfficeLocation,UrlName)`;
  if (!ids || ids.length === 0) {
    filterQuery = `$Filter=StaffId eq '00000000'`;
  } else {
    filterQuery = `$filter=staff/any(s: s/StaffId in (${ids.map(
      (id, index) => '"' + id + '"'
    )}))`;
  }

  return `/sfapi/default/Instructors?${filterQuery}&${selectQuery}&${expandQuery}`;
};
export const getSitefinityPageByUrlNameUrl = (urlName?: string) => {
  if (!urlName) {
    return `/sfapi/default/pages?$filter=Id eq 00000000-0000-0000-0000-000000000000`;
  }
  const filterQuery = `$filter=UrlName eq \'${urlName}\'`;
  const selectQuery = "$select=Id";
  return `/sfapi/default/pages?${filterQuery}&${selectQuery}`;
};

/* Peoplesoft */
export const getPeopleSoftUserProfileUrl = (term?: string) => {
  let termQuery = "";
  if (term) {
    termQuery = `?term=${term}`;
  }
  return `/api/PeoplesoftUserProfile${termQuery}`;
};
export const getPeopleSoftUserProfileWithAvatarUrl = (term?: string) => {
  let termQuery = "";
  if (term) {
    termQuery = `?term=${term}`;
  }
  return `/api/PeoplesoftUserProfileWithAvatar${termQuery}`;
};
export const getPeopleSoftServiceIndicatorsUrl = (
  pos_srvc_indicator: string,
  term: string = "",
  srvc_ind_cd: string = ""
) => {
  return `api/ServiceIndicators?pos_srvc_indicator=${pos_srvc_indicator}&term=${term}&srvc_ind_cd=${srvc_ind_cd}`;
};
export const getPeopleSoftUserTermsUrl = () => {
  return `/api/UserTerms`;
};
export const getPeopleSoftUserClassesUrl = (term?: string) => {
  let termQuery = "";
  if (term) {
    termQuery = `?term=${term}`;
  }
  return `/api/UserClasses${termQuery}`;
};
export const getPeopleSoftUserClassesWithCanvasAssignmentsUrl = (
  startDate: Date,
  endDate: Date,
  term?: string
) => {
  const startDateFormatted = formatDate(startDate);
  const endDateFormatted = formatDate(endDate);
  let termQuery = "";
  if (term) {
    termQuery = `&term=${term}`;
  }
  return `/api/UserClassesWithCanvasAssignments?startDate=${startDateFormatted}&endDate=${endDateFormatted}${termQuery}`;
};
export const getPeopleSoftAcademicPlanUrl = () => {
  return `/api/AcademicPlan`;
};
export const getPeopleSoftAidYearsUrl = () => {
  return `/api/AidYears`;
};
export const getPeopleSoftFinancialAidUrl = () => {
  return `/api/FinancialAid`;
};
export const getPeopleSoftFinancialAidToDosUrl = (
  startDate: Date,
  endDate: Date
) => {
  const startDateFormatted = formatDate(startDate);
  const endDateFormatted = formatDate(endDate);

  return `/api/FinancialAid?startDate=${startDateFormatted}&endDate=${endDateFormatted}`;
};
export const getPeopleSoftStudentFinancials = (term?: string) => {
  let termQuery = "";
  if (term) {
    termQuery = `?term=${term}`;
  }
  return `/api/StudentFinancials${termQuery}`;
};

export const getPeopleSoftStudentOverallBalance = () => {
  return `/api/OverallBalance`;
};
/* EAB */
export const getEabAppointmentsUrl = (startDate: Date, endDate: Date) => {
  const startDateFormatted = formatDate(startDate);
  const endDateFormatted = formatDate(endDate);
  return `/api/Appointments?startDate=${startDateFormatted}&endDate=${endDateFormatted}`;
};

export const getEabStudentProfileUrl = () => {
  return `/api/StudentProfile`;
};
const formatDate = (date: Date) => {
  return (
    date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
  );
};

/* Nintex */
export const getNintexTasksForUserDashboard = (
  startDate?: Date,
  endDate?: Date
) => {
  let startDateFormatted = "null";
  let endDateFormatted = "null";

  if (startDate) startDateFormatted = formatDate(startDate);
  if (endDate) endDateFormatted = formatDate(endDate);

  return `/api/GetNintexTasksForUserDashboard?startDate=${startDateFormatted}&endDate=${endDateFormatted}`;
};

export const getNintexTasksForUserCalendar = (
  startDate?: Date,
  endDate?: Date
) => {
  let startDateFormatted = "null";
  let endDateFormatted = "null";

  if (startDate) startDateFormatted = formatDate(startDate);
  if (endDate) endDateFormatted = formatDate(endDate);

  return `/api/GetNintexTasksForUserCalendar?startDate=${startDateFormatted}&endDate=${endDateFormatted}`;
};

/* Google */
export const getGoogleCalendarItemsForUserCalendar = (
  startDate?: Date,
  endDate?: Date
) => {
  let startDateFormatted = "null";
  let endDateFormatted = "null";

  if (startDate) startDateFormatted = formatDate(startDate);
  if (endDate) endDateFormatted = formatDate(endDate);

  return `/api/GoogleCalendarItems?startDate=${startDateFormatted}&endDate=${endDateFormatted}`;
};
export const getGmailForUser = () => {
  return `/api/GoogleMail`;
};
