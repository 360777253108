import { useFetcher } from "./useFetcher";
import { HttpResponseWithMutator } from "../types/http";
import useSWR from "swr";
import { useFetcherWithOdata } from "./useFetcherWithOdata";
import { useBatchFetcher } from "./useBatchFetcher";

export const useHttp = <T,>(
  methodName: string,
  url: string | (() => any),
  isOdata?: boolean,
  payload?: any,
  accessToken?: string,
  isBatch?: boolean,
  baseUrl?: string,
  shouldRevalidateOnMount?: boolean
): HttpResponseWithMutator<T> => {
  const { fetcher } = isOdata
    ? useFetcherWithOdata<T>(methodName, payload)
    : isBatch
    ? useBatchFetcher<T>(methodName, baseUrl as string, accessToken as string)
    : useFetcher<T>(methodName, payload, accessToken);

  const { data, error, isLoading, isValidating, mutate } = useSWR<
    T | undefined
  >(url, fetcher, {
    revalidateOnFocus: false,
    revalidateIfStale: false,
    shouldRetryOnError: false,
    revalidateOnMount: shouldRevalidateOnMount,
  });

  return { data, error, isLoading, isValidating, mutate };
};
