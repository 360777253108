import { useEffect, useState } from "react";
import { parseDate } from "../../utilities/date-parser";
import ReactDOM from "react-dom";
import {
  isEventCustomField,
  mutateToCalendarItem,
} from "../../utilities/integrations";
import { useHttp } from "../../hooks/useHttp";
import {
  CalendarItemIntegrationDataTypes,
  TwentyFiveLiveEvent,
  TwentyFiveLiveRelatedEvents,
} from "../../types/integrations";
import {
  get25LiveEventFromSitefinityUrl,
  get25LiveRelatedEventsFromSitefinityUrl,
} from "../../utilities/urls";
import ErrorComponent from "components/common/errorComponent";
import { CalendarItem } from "types/calendar";
import EventListing from "components/events/event-listing";
import { useEventsContext } from "context/events/events-context";
import { SitefinityTagForRelatedEvents } from "types/event";
import { BusyIndicator } from "components/busyIndicator/busy-indicator";
import { useUserRoles } from "context/user-roles/user-roles-context";
import { ImpersonationUnavailable } from "components/impersonation-unavailable/impersonation-unavailable";
import { FUNCTIONALITY_AVAILABLE_TO_STUDENTS_ONLY } from "context/common/constant";

// Call to get related event based on event tags
export default function EventDetail() {
  // Call to get event tags
  const { eventTagResponse, setRequestedEventTagId } = useEventsContext();

  const container = document.getElementById("event-detail") as HTMLElement;
  const today = new Date(container.dataset.today!);
  const calendarTagId = container.dataset.categoryid!;
  setRequestedEventTagId(calendarTagId);

  const [eventTag, setEventTag] = useState(
    "00000000-0000-0000-0000-000000000000"
  );
  const [calendarTags, setCalendarTags] = useState<
    SitefinityTagForRelatedEvents[] | undefined
  >(undefined);
  const { isStudent } = useUserRoles();

  const [currentEvent, setCurrentEvent] = useState<CalendarItem | undefined>();
  const [calendarItems, setCurrentCalendarItems] = useState(
    Array<CalendarItem>()
  );

  const url = window.location.href;
  const eventId = url.substring(url.indexOf("eventId=") + 8);

  // EventId is a custom field, this will run an odata call that will always return an array.
  // Call to get event data
  const {
    data: eventData,
    isLoading: isEventDataLoading,
    isValidating: isEventDataValidating,
    error: eventDataError,
  } = useHttp<TwentyFiveLiveEvent>(
    "get",
    get25LiveEventFromSitefinityUrl(eventId)
  );

  // Set Current Event after data is available
  useEffect(() => {
    if (!isEventDataLoading && !isEventDataValidating && eventData) {
      const event = mutateToCalendarItem(
        eventData,
        CalendarItemIntegrationDataTypes.TwentyFiveLiveEvents.name
      ) as CalendarItem;
      if (event) {
        setCurrentEvent(event);
      }
    }
  }, [eventData]);

  // Create an array of all tags applicable to this event based on their level in hierarchy
  // After the Event Tag API Call has finished
  useEffect(() => {
    if (
      eventTagResponse.data &&
      !eventTagResponse.isLoading &&
      !eventTagResponse.isValidating
    ) {
      // traverse through the tags to determine what level in the hierarchy they are
      const getTagLevel: any = (
        tagData: any,
        parentId: string,
        level: number,
        tagTitle: string
      ) => {
        const rootId = "00000000-0000-0000-0000-000000000000";
        if (parentId !== rootId) {
          const parentItem = tagData.find((x: any) => x.Id === parentId);
          level++;
          if (parentItem && parentItem.ParentId !== rootId) {
            return getTagLevel(tagData, parentItem.ParentId, level, tagTitle);
          } else {
            return level;
          }
        } else {
          return level;
        }
      };
      const calnedarTagArray = Array<any>();
      eventTagResponse.data.value.forEach((item) => {
        calnedarTagArray.push({
          Title: item.Title,
          Id: item.Id,
          Level: getTagLevel(
            eventTagResponse.data?.value,
            item.ParentId,
            0,
            item.Title
          ),
        });
      });
      setCalendarTags(calnedarTagArray);
    }
  }, [eventTagResponse]);

  // When you have 1) Current Event 2) Calendar Tags Array
  // Then 3) Get the deepest level in the hierarchy of tags
  useEffect(() => {
    if (
      currentEvent &&
      eventTagResponse.data &&
      currentEvent.calendarcategories &&
      calendarTags
    ) {
      let tagLevel = 0;
      let tag = "";
      currentEvent.calendarcategories.forEach((itemId) => {
        const tagInfo = calendarTags.find((x: any) => x.Id === itemId);
        if (tagInfo) {
          if (tagInfo.Level > tagLevel) {
            tagLevel = tagInfo.Level;
            tag = itemId;
          } else if (tag === "" || tagLevel === 0) {
            tag = itemId;
          }
        }
      });
      setEventTag(tag);
    }
  }, [currentEvent, calendarTags]);

  // When you have the deepest level in the hierarchy, make a call
  // to get the related events
  const { data: relatedEventsData, error: relatedEventError } =
    useHttp<TwentyFiveLiveRelatedEvents>(
      "get",
      get25LiveRelatedEventsFromSitefinityUrl(today, eventTag, eventId)
    );

  // When you have the related events, mutate them into Calendar Items
  // For easier display
  useEffect(() => {
    setCurrentCalendarItems(
      !relatedEventError && relatedEventsData && currentEvent
        ? (relatedEventsData?.value.map((item) => {
            return mutateToCalendarItem(
              item,
              CalendarItemIntegrationDataTypes.TwentyFiveLiveEvents.name
            );
          }) as CalendarItem[])
        : []
    );
  }, [relatedEventsData]);

  return ReactDOM.createPortal(
    <>
      {!isStudent ? (
        <>{FUNCTIONALITY_AVAILABLE_TO_STUDENTS_ONLY}</>
      ) : eventDataError ? (
        <ErrorComponent message={eventDataError.message} />
      ) : isEventDataLoading || isEventDataValidating ? (
        <BusyIndicator />
      ) : (
        currentEvent && (
          <article className="event-detail" aria-labelledby="event=title">
            <section className="event-detail__main">
              <span className="event-detail__tag">
                {!currentEvent.CustomFields
                  ? ""
                  : isEventCustomField(currentEvent.CustomFields) &&
                    currentEvent.CustomFields[0].label}
              </span>
              <h1 className="event-detail__title" id="event-title">
                {currentEvent.Title}
              </h1>

              <div className="event-detail__info">
                <svg className="event-detail__icon" aria-hidden="true">
                  <use href="#calendar"></use>
                </svg>
                <span className="event-detail__value">
                  {parseDate(currentEvent.StartDate, "fullDate")}
                </span>
              </div>

              <div className="event-detail__info">
                <svg className="event-detail__icon" aria-hidden="true">
                  <use href="#clock"></use>
                </svg>
                <span className="event-detail__value">
                  {parseDate(currentEvent.StartDate, "startTime")}-
                  {parseDate(currentEvent.EndDate, "endTime")}
                </span>
              </div>
              {currentEvent.Location && (
                <div className="event-detail__info">
                  <svg className="event-detail__icon" aria-hidden="true">
                    <use href="#map-marker-alt"></use>
                  </svg>
                  <span className="event-detail__value">
                    {currentEvent.Location}
                  </span>
                </div>
              )}
              {currentEvent.ActionUrl && (
                <a
                  href={currentEvent.ActionUrl}
                  className="arrow-link arrow-link--icon"
                  target="_blank"
                >
                  <svg className="event-detail__icon" aria-hidden="true">
                    <use href="/ResourcePackages/Talon/assets/dist/sprites/solid.svg#calendar"></use>
                  </svg>
                  <span className="arrow-link__text">Add to Calendar</span>
                  <span className="visually-hidden">
                    will open in a new window
                  </span>
                </a>
              )}
              <div className="event-detail__main-content">
                <span
                  dangerouslySetInnerHTML={{ __html: currentEvent.Content }}
                />
              </div>
            </section>
            <section className="event-detail__rail">
              {calendarItems && calendarItems.length > 0 && (
                <>
                  <h2 className="event-detail__heading">
                    You may also be interested in
                  </h2>

                  <EventListing data={calendarItems}></EventListing>
                </>
              )}
            </section>
          </article>
        )
      )}
    </>,
    container
  );
}
