import React from "react";
import { daysOfWeek } from "../../../../utilities/calendar";

const DaysOfWeek = React.memo(function CalendarDaysOfWeek() {
  return (
    <thead className="calendar-table__head">
      <tr className="calendar-table__row">
        {daysOfWeek.map((day, index) => {
          return (
            <td
              key={index}
              className="calendar-table__cell calendar-table__cell--heading"
            >
              {day}
            </td>
          );
        })}
      </tr>
    </thead>
  );
});
export default DaysOfWeek;
