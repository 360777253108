interface Props {
  link: string;
  type: string;
}
export const SocialMedialLinks = ({ type, link }: Props) => {
  return (
    <li className="organization-links__item">
      <a
        className="organization-links__link"
        target="_blank"
        href={link}
        rel="noopener noreferrer"
      >
        <svg className="organization-links__icon" aria-hidden="true">
          <use
            href={`/ResourcePackages/Talon/assets/dist/sprites/brands.svg#${type.toLocaleLowerCase()}`}
          ></use>
        </svg>
      </a>
      <span className="visually-hidden">{type} will open in a new page</span>
    </li>
  );
};
