import { AssignmentDayInfo } from "types/classes";
import { getAssignmentsInfo } from "utilities/classes";
import {
  StudentClass,
  StudentClassShoppingCartItem,
} from "../../types/integrations";
import { parseDate } from "../../utilities/date-parser";
import StudentClassAssignmentWidget from "./student-class-assignment";

interface Props {
  studentClass: StudentClass | StudentClassShoppingCartItem;
  termTimeState: string;
  isClassEnrolled: boolean;
  today: Date;
}

export default function StudentClassWidget({
  studentClass,
  termTimeState,
  isClassEnrolled,
  today,
}: Props) {
  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);
  let enrolledClassInfo;
  if (isClassEnrolled) {
    enrolledClassInfo = studentClass as StudentClass;
    enrolledClassInfo.Assignments = enrolledClassInfo.Assignments?.filter(
      (item) =>
        item.due_at &&
        new Date(item.due_at).toISOString() >= today.toISOString()
    );
  }

  const nextWeek = new Date(today);
  nextWeek.setDate(nextWeek.getDate() + 7);
  const assignmentInfo =
    enrolledClassInfo?.Assignments === undefined
      ? Array<AssignmentDayInfo>()
      : getAssignmentsInfo(today, nextWeek, enrolledClassInfo?.Assignments);
  const courseClass =
    termTimeState === "Current"
      ? "course-item--current"
      : termTimeState === "Past"
      ? "course-item--past"
      : "";
  return (
    <li className={`course-item ${courseClass}`}>
      <div className="course-item__content">
        <h3 className="course-item__heading">
          {studentClass.Subject +
            " " +
            studentClass.CatalogNumber +
            "-" +
            studentClass.Section +
            " | " +
            studentClass.Title}
        </h3>
        {enrolledClassInfo && (
          <>
            <span className="course-item__credits">
              Credit hours: {enrolledClassInfo.CreditHours}
            </span>
            <ul className="course-item__info">
              {enrolledClassInfo.DaysInfo?.map((dayinfo) => {
                return (
                  <>
                    {termTimeState !== "Past" && (
                      <>
                        <li className="course-item__detail">
                          <svg aria-hidden="true" className="course-item__icon">
                            <use href="#book-open"></use>
                          </svg>
                          <span className="course-item__label">
                            <b>{dayinfo.Topic}</b>
                          </span>
                        </li>
                        <li className="course-item__detail">
                          <svg aria-hidden="true" className="course-item__icon">
                            <use href="#school"></use>
                          </svg>
                          <span className="course-item__label">
                            {dayinfo.Meeting.Days.replaceAll(" &", ",") +
                              " | " +
                              dayinfo.Meeting.StartTime.replaceAll(
                                "AM",
                                " AM"
                              ).replaceAll("PM", " PM") +
                              " - " +
                              dayinfo.Meeting.EndTime.replaceAll(
                                "AM",
                                " AM"
                              ).replaceAll("PM", " PM")}
                          </span>
                        </li>

                        <li className="course-item__detail">
                          <svg aria-hidden="true" className="course-item__icon">
                            <use href="#map-marker-alt"></use>
                          </svg>
                          <span className="course-item__label">
                            Location: {dayinfo.Campus}, Room: {dayinfo.Room}
                          </span>
                        </li>
                        <hr />
                      </>
                    )}
                  </>
                );
              })}
              <li className="course-item__detail">
                <svg aria-hidden="true" className="course-item__icon">
                  <use href="#user-tie"></use>
                </svg>
                <span className="course-item__label">
                  Instructor: {enrolledClassInfo.InstructorName}
                </span>
              </li>
              <li className="course-item__detail">
                <svg aria-hidden="true" className="course-item__icon">
                  <use href="#calendar"></use>
                </svg>
                <span className="course-item__label">
                  Start:{" "}
                  {parseDate(
                    new Date(enrolledClassInfo.Dates.Start),
                    "monthAndDay"
                  )}{" "}
                  | End:{" "}
                  {parseDate(
                    new Date(enrolledClassInfo.Dates.End),
                    "monthAndDay"
                  )}{" "}
                  | Final Exam:{" "}
                  {enrolledClassInfo.Exam.Date
                    ? parseDate(
                        new Date(enrolledClassInfo.Exam.Date),
                        "monthAndDay"
                      )
                    : "N/A"}
                </span>
              </li>
              {termTimeState !== "Past" && (
                <li className="course-item__detail">
                  <svg aria-hidden="true" className="course-item__icon">
                    <use href="#link"></use>
                  </svg>
                  <span className="course-item__label">
                    Drop Date:{" "}
                    {parseDate(
                      new Date(enrolledClassInfo.Dates.Drop),
                      "monthAndDay"
                    )}{" "}
                    | Withdrawal Date:{" "}
                    {parseDate(
                      new Date(enrolledClassInfo.Dates.Withdraw),
                      "monthAndDay"
                    )}
                  </span>
                </li>
              )}
            </ul>
          </>
        )}
      </div>
      {enrolledClassInfo && (
        <>
          {termTimeState === "Current" ? (
            <div className="course-item__assignments">
              <h3 className="course-item__heading course-item__heading--secondary">
                Assignments This Week (
                {!enrolledClassInfo.Assignments
                  ? "0"
                  : enrolledClassInfo.Assignments.length}
                )
              </h3>
              <ul className="course-item__list">
                {!enrolledClassInfo.Assignments ||
                enrolledClassInfo.Assignments.length === 0 ? (
                  <h3 className="course-item__heading course-item__heading--secondary">
                    Yay! No assignments due this week
                  </h3>
                ) : (
                  assignmentInfo.map((dayInfo, index) => {
                    return (
                      <>
                        {dayInfo.Assignments.length > 0 ? (
                          <h3 className="calendar-tabs__heading">
                            {dayInfo.DayName +
                              ", " +
                              dayInfo.DayIndex +
                              " " +
                              dayInfo.MonthNameAbbreviation}
                          </h3>
                        ) : (
                          <></>
                        )}

                        {dayInfo.Assignments.map((assignment) => {
                          return (
                            <StudentClassAssignmentWidget
                              key={index}
                              assignment={assignment}
                            ></StudentClassAssignmentWidget>
                          );
                        })}
                      </>
                    );
                  })
                )}
              </ul>
            </div>
          ) : termTimeState === "Past" ? (
            <div className="course-item__grade">
              <div className="course-item__grade-heading">Final Grade</div>
              <div className="course-item__grade-value">
                {!enrolledClassInfo.Grade || enrolledClassInfo.Grade === ""
                  ? "N/A"
                  : enrolledClassInfo.Grade}
              </div>
            </div>
          ) : (
            <></>
          )}
        </>
      )}
    </li>
  );
}
