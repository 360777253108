export enum Model {
  FavoriteTools = "favorite-tools",
  OrganizationClub = "organizationclub-dashboard",
}
export interface FavoriteToolDataModel {
  heading: string | null;
  linkText: string | null;
  linkUrl: string | null;
  numberOfDaysToShowNewTool: number | null;
}

export interface OrganizationClubDataModel {
  type: string | null;
  heading: string | null;
  linktext: string | null;
  linkurl: string | null;
}
export interface FacultyDataModel {
  type: string | null;
  heading: string | null;
}

export interface ActionLinkDataModel {
  Label: string;
  Url: string;
  IsExternal: boolean;
}

export interface Term {
  label: string;
  value: string;
  timeState: string;
}
