import { useHttp } from "hooks/useHttp";
import React, { useContext } from "react";
import { HttpResponse } from "types/http";
import { PeoplesoftStudentAcademicPlanApiResponse } from "types/integrations";
import { getPeopleSoftAcademicPlanUrl } from "utilities/urls";

interface Props {
  children: React.ReactNode;
}

export interface IStudentAcademicPlan {
  studentAcademicPlan: HttpResponse<PeoplesoftStudentAcademicPlanApiResponse>;
}

export interface IStudentAcademicPlanInfoContext extends IStudentAcademicPlan {}

const defaultState: IStudentAcademicPlan = {
  studentAcademicPlan:
    {} as HttpResponse<PeoplesoftStudentAcademicPlanApiResponse>,
};

const defaultContext: IStudentAcademicPlanInfoContext = {
  ...defaultState,
};

export const StudentAcademicPlanContext =
  React.createContext<IStudentAcademicPlanInfoContext>(defaultContext);

export const StudentAcademicPlanProvider: React.FC<Props> = ({ children }) => {
  const studentAcademicPlanResponse =
    useHttp<PeoplesoftStudentAcademicPlanApiResponse>(
      "get",
      getPeopleSoftAcademicPlanUrl()
    );

  return (
    <StudentAcademicPlanContext.Provider
      value={{
        studentAcademicPlan:
          studentAcademicPlanResponse ?? defaultContext.studentAcademicPlan,
      }}
    >
      {children}
    </StudentAcademicPlanContext.Provider>
  );
};

export const useStudentAcademicPlanContext = () =>
  useContext(StudentAcademicPlanContext);
