import { BusyIndicator } from "components/busyIndicator/busy-indicator";
import ErrorComponent from "components/common/errorComponent";
import { useStudentAcademicPlanContext } from "context/student/student-academic-plan-context";
import React from "react";
import { getMostRecentGraduationRequest } from "utilities/classes";
import CardContainer from "../containers/card-container";
import { useUserRoles } from "context/user-roles/user-roles-context";
import { FUNCTIONALITY_AVAILABLE_TO_STUDENTS_ONLY } from "context/common/constant";

interface Props {
  requestToGraduateActionLink: string | undefined;
  componentHeading: string | undefined;
}

export default function Graduate({
  componentHeading,
  requestToGraduateActionLink,
}: Props) {
  const { studentAcademicPlan } = useStudentAcademicPlanContext();
  const { isStudent } = useUserRoles();
  return (
    <CardContainer title={componentHeading || "Ready to Graduate?"}>
      {!isStudent ? (
        <>{FUNCTIONALITY_AVAILABLE_TO_STUDENTS_ONLY}</>
      ) : studentAcademicPlan.error ? (
        <ErrorComponent message={studentAcademicPlan.error.message} />
      ) : studentAcademicPlan.isLoading || studentAcademicPlan.isValidating ? (
        <BusyIndicator />
      ) : (
        studentAcademicPlan.data && (
          <>
            {studentAcademicPlan.data.GraduationRequests.length !== 0 ? (
              <p className="text-margin-bottom">
                <span className="text-weight-semibold">Status:</span>
                <span className="text-size-small">
                  {" " +
                    getMostRecentGraduationRequest(
                      studentAcademicPlan.data.GraduationRequests
                    ).RequestStatus}
                </span>
              </p>
            ) : (
              <p>No graduation requests were found.</p>
            )}
            {requestToGraduateActionLink && (
              <div
                className="graduate__button"
                dangerouslySetInnerHTML={{
                  __html: requestToGraduateActionLink,
                }}
              ></div>
            )}
          </>
        )
      )}
    </CardContainer>
  );
}
