import React from "react";
import ReactDOM from "react-dom";
import { useUserRoles } from "context/user-roles/user-roles-context";
import { GoogleMaileResponse } from "types/integrations";
import { useHttp } from "hooks/useHttp";
import { parseDateForGmail } from "utilities/date-parser";
import { BusyIndicator } from "components/busyIndicator/busy-indicator";
import ErrorComponent from "components/common/errorComponent";
import CardContainer from "components/containers/card-container";
import { FormatSender } from "utilities/inbox";
import { FUNCTIONALITY_AVAILABLE_TO_STUDENTS_ONLY } from "context/common/constant";

export default function GoogleInbox() {
  const { impersonating, isStudent } = useUserRoles();

  const container = document.getElementById("google-inbox") as HTMLElement;
  const today = new Date(container.dataset.today!);
  const heading = container.dataset.heading;

  const {
    data: gmailResponse,
    isLoading,
    error,
  } = useHttp<GoogleMaileResponse>("get", () => `/api/GoogleMail`);

  return (
    container &&
    ReactDOM.createPortal(
      <CardContainer
        title={heading || "Gmail Messages"}
        data-testid="inbox-div"
      >
        {!isStudent ? (
          <>{FUNCTIONALITY_AVAILABLE_TO_STUDENTS_ONLY}</>
        ) : impersonating ? (
          <div>
            <span>Warning: Proxy does not include Google Mail access</span>
          </div>
        ) : isLoading ? (
          <BusyIndicator />
        ) : error ? (
          <ErrorComponent
            message={error.message || "An unknown error occurred"}
          />
        ) : (
          <>
            <ul className="google-inbox">
              {gmailResponse?.EmailMessages?.map((email) => (
                <li
                  className={`google-inbox__item ${
                    !email.IsRead ? "google-inbox__item--unread" : ""
                  }`}
                  key={email.Id}
                >
                  <a
                    className="google-inbox__link"
                    target="_blank"
                    href={
                      "https://mail.google.com/a/stu.clcillinois.edu/mail/u/0/?pli=1#inbox/" +
                      email.Id
                    }
                  >
                    <div className="google-inbox__content">
                      <span className="google-inbox__date">
                        {parseDateForGmail(today, email.Date)}
                      </span>
                      <br />
                      <h3 className="google-inbox__heading">
                        {FormatSender(email.SenderName)}
                      </h3>

                      <p className="google-inbox__subject">{email.Subject}</p>
                    </div>
                  </a>
                </li>
              ))}
            </ul>
            <a
              href="https://mail.google.com/a/stu.clcillinois.edu"
              target="_blank"
              className="arrow-link"
            >
              Go to Inbox
            </a>
          </>
        )}
      </CardContainer>,
      container
    )
  );
}
