import { rest } from "msw";
import {
  userProfileData,
  userTermsData,
  favoriteTools,
  allTools,
  studentOrganizationFavrites,
  studentOrganizationListings,
  instructorDetails,
  departments,
  subjects,
} from "./mock-data";

let mutatedAllTools = [...allTools];
let mutatedFavorite = [...favoriteTools];
let mutatedOrgs = { ...studentOrganizationListings };
let mutatedFavOrgs = [...studentOrganizationFavrites];

const userProfileRest = rest.get(
  `/api/PeoplesoftUserProfile`,
  (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(userProfileData));
  }
);

const userTermsRest = rest.get(`/api/UserTerms`, (req, res, ctx) => {
  return res(ctx.status(200), ctx.json(userTermsData));
});

const userFavoriteToolsRest = rest.get(
  `/api/favorites/type/Tool`,
  (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mutatedFavorite));
  }
);

const allToolsRest = rest.get(
  `/sfapi/default/tools?$select=Id,Title,Link,Summary,LastModified&$expand=Image($select=Url,AlternativeText),tooltypes($select=Title,Id)`,
  (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mutatedAllTools));
  }
);

const addUserFavoriteToolRest = rest.put(`/api/favorites`, (req, res, ctx) => {
  const poppedElement = mutatedAllTools.pop();
  const poppedOrgItem = mutatedOrgs.value.pop();
  mutatedFavorite.push({ id: poppedElement?.Id as string, type: `Tool` });
  mutatedFavOrgs.push({
    id: poppedOrgItem?.Id as string,
    type: `Organization`,
  });
  return res(ctx.status(200), ctx.json(favoriteTools));
});

const deleteUserFavoriteToolRest = rest.delete(
  `/api/favorites/:id`,
  (req, res, ctx) => {
    mutatedAllTools = [...allTools];
    mutatedFavorite = [...favoriteTools];
    mutatedFavOrgs = [...studentOrganizationFavrites];
    mutatedOrgs = { ...studentOrganizationListings };
    return res(ctx.status(200), ctx.json(favoriteTools));
  }
);

const studentFavoriteOrganizations = rest.get(
  `/api/favorites/type/Organization`,
  (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mutatedFavOrgs));
  }
);

const studentOrganizationListingsResp = rest.get(
  `/sfapi/default/organizations?$expand=Image($select=Url,AlternativeText),Instructors($select=Id,WorkPhone,Name,Email)`,
  (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mutatedOrgs));
  }
);

const instructorDetailResp = rest.get(
  `/sfapi/default/instructors(:key)?$expand=Photo,PrimaryVideo,RelatedVideos`,
  (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(instructorDetails));
  }
);

const subjectsResp = rest.get(
  `/sfapi/default/hierarchy-taxa`,
  (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(subjects));
  }
);

const departmentResp = rest.get(`/sfapi/default/flat-taxa`, (req, res, ctx) => {
  return res(ctx.status(200), ctx.json(departments));
});

export const handlers = [
  userProfileRest,
  userTermsRest,
  userFavoriteToolsRest,
  allToolsRest,
  addUserFavoriteToolRest,
  deleteUserFavoriteToolRest,
  studentFavoriteOrganizations,
  studentOrganizationListingsResp,
  instructorDetailResp,
  subjectsResp,
  departmentResp,
];
