import {
  CalendarDayInfo,
  CalendarItem,
  CalendarMonth,
  DayOfWeek,
} from "../types/calendar";

/* First day can be sunday or monday depending on calendar view 
(calendar-view uses sunday vs calendar-list uses monday) */
export const getCurrentWeekFirstDay = (
  date: Date,
  isFirstDaySunday?: boolean
) => {
  let day = date.getDay();
  if (isFirstDaySunday) {
    day = day === 0 ? 0 : day;
  } else {
    day = day === 0 ? 6 : --day;
  }
  const dayDiff = date.getDate() - day;

  return new Date(date.setDate(dayDiff));
};
/* First day can be sunday or monday */
export const getCurrentWeekLastDay = (
  date: Date,
  isFirstDaySunday?: boolean
) => {
  let day = date.getDay();

  if (isFirstDaySunday) {
    day = day === 0 ? 6 : 6 - day;
  } else {
    day = day === 0 ? 0 : 7 - day;
  }
  const dayDiff = date.getDate() + day;

  return new Date(date.setDate(dayDiff));
};

// build out the week starting with the first day as Monday
export const buildWeekFromStartDate = (startDate?: Date) => {
  const firstDayOfWeek = startDate
    ? startDate
    : getCurrentWeekFirstDay(new Date());
  const endDate = new Date(firstDayOfWeek);
  endDate.setDate(endDate.getDate() + 7);
  endDate.setHours(0);
  endDate.setMinutes(0);
  endDate.setSeconds(0);
  return endDate;
};

// the current day is the next day of the current week
// the start of the previous week is 14 days prior
export const getPreviousWeek = (date: Date) => {
  const startDate = new Date(date);
  startDate.setDate(startDate.getDate() - 14);
  const endDate = buildWeekFromStartDate(startDate);

  return { startDate, endDate };
};

export const getNextWeek = (date: Date) => {
  const startDate = date;
  const endDate = buildWeekFromStartDate(startDate);
  return { startDate, endDate };
};

export const getDayNameFromDate = (date: Date, isFirstDaySunday?: boolean) => {
  if (isFirstDaySunday) {
    const dayNumber = date.getDay();
    return daysOfWeekLongSundayFirst.find((item) => item.DayIndex === dayNumber)
      ?.DayName;
  } else {
    const dayNumber = date.getDay() === 0 ? 6 : date.getDay() - 1;
    return daysOfWeekLongMondayFirst.find((item) => item.DayIndex === dayNumber)
      ?.DayName;
  }
};
export const daysOfWeekLongSundayFirst = [
  { DayIndex: 0, DayName: "Sunday" },
  { DayIndex: 1, DayName: "Monday" },
  { DayIndex: 2, DayName: "Tuesday" },
  { DayIndex: 3, DayName: "Wednesday" },
  { DayIndex: 4, DayName: "Thursday" },
  { DayIndex: 5, DayName: "Friday" },
  { DayIndex: 6, DayName: "Saturday" },
] as Array<DayOfWeek>;

export const daysOfWeekLongMondayFirst = [
  { DayIndex: 0, DayName: "Monday" },
  { DayIndex: 1, DayName: "Tuesday" },
  { DayIndex: 2, DayName: "Wednesday" },
  { DayIndex: 3, DayName: "Thursday" },
  { DayIndex: 4, DayName: "Friday" },
  { DayIndex: 5, DayName: "Saturday" },
  { DayIndex: 6, DayName: "Sunday" },
] as Array<DayOfWeek>;

export const daysOfWeek = ["S", "M", "T", "W", "T", "F", "S"];
export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const onFilterCalendarItemsByDate = (
  calendarItems: CalendarItem[],
  dateIsoString: string
) => {
  return calendarItems.filter((calendarItem) => {
    let calendarItemDate = new Date(calendarItem.StartDate);
    if (isNaN(calendarItemDate.valueOf())) return false;
    calendarItemDate.setHours(0, 0, 0, 0); // sets time to 0
    return calendarItemDate.toISOString() == dateIsoString;
  });
};
export const onFilterCalendarItemsOnDashboard = (
  calendarItems: CalendarItem[],
  startDateIsoString: string,
  endDateIsoString?: string,
  typeFilter?: string
) => {
  return calendarItems.filter((calendarItem) => {
    let calendarItemDate = new Date(calendarItem.StartDate);
    if (isNaN(calendarItemDate.valueOf())) return false;

    calendarItemDate.setHours(0, 0, 0, 0); // sets time to 0
    const calendarItemDateIsoString = calendarItemDate.toISOString();

    let isMatch = false;
    if (!endDateIsoString)
      isMatch = calendarItemDateIsoString == startDateIsoString;
    else {
      isMatch =
        calendarItemDateIsoString >= startDateIsoString &&
        calendarItemDateIsoString <= endDateIsoString;
    }

    isMatch =
      isMatch && (!typeFilter ? true : calendarItem.Type === typeFilter);

    return isMatch;
  });
};
export const onFilterCalendarItemsByDateRange = (
  calendarItems: CalendarItem[],
  startDateIsoString: string,
  endDateIsoString: string
) => {
  return calendarItems.filter((calendarItem) => {
    let calendarItemDate = new Date(calendarItem.StartDate);
    calendarItemDate.setHours(0, 0, 0, 0); // sets time to 0
    const calendarItemDateIsoString = calendarItemDate.toISOString();
    return (
      calendarItemDateIsoString >= startDateIsoString &&
      calendarItemDateIsoString <= endDateIsoString
    );
  });
};
export const getCalendarBuildRelevantDates = (calendarActiveDate: {
  startDate: Date;
  endDate: Date;
  year: number;
  monthIndex: number;
}) => {
  // get the first day so we know what day to start on
  const firstDayDate = new Date(
    calendarActiveDate.year,
    calendarActiveDate.monthIndex,
    1
  );
  const firstDayIndex = firstDayDate.getDay();

  // gets the total days in the month
  // Adds 1 to month index to get next month, and put 0 for day
  // which takes you back to the last day of previous month,
  // thus you get the days count
  const daysCount = new Date(
    calendarActiveDate.year,
    calendarActiveDate.monthIndex + 1,
    0
  ).getDate();

  // gets the total days in the previous month

  const prevDaysCount = new Date(
    calendarActiveDate.year,
    calendarActiveDate.monthIndex,
    0
  ).getDate();

  return { prevDaysCount, firstDayIndex, daysCount };
};

export const getSliderMonths = () => {
  // need to display 6 months prior and 12 months forward in the slider
  const previousMonthToShow = 6;
  const futureMonthsToShow = 12;
  const months = Array<CalendarMonth>();
  const date = new Date();
  const month = date.getMonth();
  const currentYear = date.getFullYear();
  let monthIndex =
    month < previousMonthToShow
      ? month + previousMonthToShow
      : month - previousMonthToShow;
  let year = month < previousMonthToShow ? currentYear - 1 : currentYear;
  const totalMonths = previousMonthToShow + futureMonthsToShow;
  for (let i = 0; i < totalMonths; i++) {
    if (monthIndex > 11) {
      year++;
      monthIndex = 0;
    }
    months.push({
      label: monthNames[monthIndex],
      year: year,
      month: monthIndex,
    });
    monthIndex++;
  }
  return { months, previousMonthToShow };
};

export const getCalendarActiveDateRange = (newStartDate: Date) => {
  let newCalendarDate = {
    startDate: new Date(newStartDate.getFullYear(), newStartDate.getMonth(), 1),
    endDate: new Date(newStartDate),
    year: newStartDate.getFullYear(),
    monthIndex: newStartDate.getMonth(),
  };

  newCalendarDate.endDate.setDate(newCalendarDate.startDate.getDate() + 26); // Get the 27th day because it will be in the last week of any month

  newCalendarDate.startDate = getCurrentWeekFirstDay(
    newCalendarDate.startDate,
    true
  );
  newCalendarDate.endDate = getCurrentWeekLastDay(
    newCalendarDate.endDate,
    true
  );

  return newCalendarDate;
};

export const IsCalendarItemArray = (obj: any): obj is CalendarItem[] => {
  return "length" in obj;
};

export const getDashboardGroupItems = (
  calendarItems: CalendarItem[],
  itemsPerPage: number
) => {
  const calendarGroup: CalendarDayInfo[] = [];
  let eventDateInfo: CalendarDayInfo = {
    DayIndex: 0,
    DayName: "",
    MonthNameAbbreviation: "",
    CalendarItems: [],
  };
  const sorted = calendarItems.sort(
    (a, b) => new Date(a.StartDate).valueOf() - new Date(b.StartDate).valueOf()
  );
  let counter = 0;
  sorted.forEach((item, index) => {
    counter++;
    eventDateInfo.CalendarItems.push(item);
    // set the date on the first item in the group
    // using this value to check when an event date changes
    if (counter % itemsPerPage === 1) {
      const startDate = new Date(item.StartDate);
      eventDateInfo.DayName = getDayNameFromDate(startDate) || "";
    }
    if (
      (counter % itemsPerPage === 0 && index > 0) ||
      (counter === sorted.length && sorted.length < itemsPerPage)
    ) {
      calendarGroup.push(eventDateInfo);
      eventDateInfo = {
        DayIndex: 0,
        DayName: "",
        MonthNameAbbreviation: "",
        CalendarItems: [],
      };
    }
    // push any remaining groups that didnt get filled
    if (
      counter === sorted.length &&
      counter % itemsPerPage > 0 &&
      counter > itemsPerPage
    ) {
      calendarGroup.push(eventDateInfo);
    }
  });
  return calendarGroup;
};
