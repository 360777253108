import { TuitionRate, TuitionRateTypes } from "types/integrations";
import { useMemo } from "react";
import CardContainer from "components/containers/card-container";
import { useStudentContext } from "context/student/student-context";
import { BusyIndicator } from "components/busyIndicator/busy-indicator";
import ErrorComponent from "components/common/errorComponent";
interface Props {
  componentHeading: string | undefined;
  linkHtml: string | undefined;
}

export const StudentTuitionRates = ({ componentHeading, linkHtml }: Props) => {
  const {
    studentProfile,
    currentTermName: currentTerm,
    studentTerms,
  } = useStudentContext();

  const { outOfDistTuition, inDistTuition } = useMemo(
    () => getInDistOrOutOfDistTuitionRates(studentProfile.data?.TuitionRates),
    [studentProfile.data?.TuitionRates]
  );
  return (
    <CardContainer title={componentHeading || "Tuition Rate"}>
      {studentProfile.error || studentTerms.error ? (
        <ErrorComponent
          message={
            studentProfile.error?.message || studentTerms.error?.message || ""
          }
        />
      ) : studentProfile.isLoading || studentTerms.isLoading ? (
        <BusyIndicator />
      ) : (
        studentProfile.data &&
        currentTerm &&
        studentTerms.data && (
          <>
            <div className="card-container__section">
              <h3>Current</h3>
              <div className="row">
                <div className="col-8">
                  <p>{currentTerm}</p>
                </div>
                {outOfDistTuition && (
                  <div className="col-4">
                    <p className=" align-right">{outOfDistTuition}</p>
                  </div>
                )}
                {inDistTuition && (
                  <div className="col-4">
                    <p className=" align-right">{inDistTuition}</p>
                  </div>
                )}
              </div>
            </div>
            <div className="card-container__section">
              <p>
                Tuition rate is based on your Residency status, and is
                determined by your home address. Residency changes submitted
                after the midterm of the semester will take affect for the next
                term.
              </p>
            </div>
            <div className="card-container__section">
              <div dangerouslySetInnerHTML={{ __html: linkHtml ?? "" }} />

              {/* <Link
                url={linkUrl ? linkUrl : "#"}
                label={
                  linkText
                    ? linkText
                    : "Learn More About Tuition And Special Circumstances"
                }
                type="arrow"
                openNewTab={openNewTab}
              /> */}
            </div>
          </>
        )
      )}
    </CardContainer>
  );
};

const getInDistOrOutOfDistTuitionRates = (
  tuitionRates: TuitionRate[] | undefined
) => {
  if (!tuitionRates) return { outOfDistTuition: "", inDistTuition: "" };
  let outOfDistTuition = "",
    inDistTuition = "";
  for (let i = 0; i < tuitionRates.length; i++) {
    if (tuitionRates[i].TuitionRate === TuitionRateTypes.OUT_DIST) {
      outOfDistTuition = tuitionRates[i].Description;
    } else {
      inDistTuition = tuitionRates[i].Description;
    }
  }
  return { outOfDistTuition, inDistTuition };
};
