import { StudentOrganization } from "types/student";

interface Props {
  data: StudentOrganization;
  linkUrl: string;
}

const StudentOrganizationFavoriteCard = ({ data, linkUrl }: Props) => {
  return (
    <li className="callout-item">
      {data.ItemDefaultUrl && (
        <a
          href={linkUrl + `?studentOrganizationId=` + data.Id}
          className="callout-item__link"
          aria-label={`${data.Title} will open in a new window`}
        >
          <div className="callout-item__wrapper">
            <img
              src={
                data.Image[0] && data.Image[0].Url
                  ? data.Image[0].Url
                  : `/images/default-source/callouts/clc-alumni-association.png`
              }
              alt=""
              className="callout-item__image"
            />
          </div>
          <h3 className="callout-item__title">{data.Title}</h3>
        </a>
      )}
    </li>
  );
};
export default StudentOrganizationFavoriteCard;
