import { CalendarItem } from "../../types/calendar";
import { parseDate } from "../../utilities/date-parser";
import { TrimText } from "components/trimText/trimText";
interface Props {
  data: CalendarItem[];
}

export default function EventListing({ data }: Props) {
  return (
    <div className="featured-events events-listing">
      <ul className="events-listing__list">
        {Array.isArray(data) ? (
          data.map((item) => {
            return (
              <li className="events-listing__item" key={item.Id}>
                <div className="events-listing__date">
                  <span className="events-listing__text">
                    {parseDate(item.StartDate, "dayNumber")}
                  </span>
                  <span className="events-listing__text events-listing__text--secondary">
                    {parseDate(item.StartDate, "monthShort")}
                  </span>
                  <span className="events-listing__text events-listing__text--tertiary">
                    {parseDate(item.StartDate, "day")}
                  </span>
                </div>
                <div className="events-listing__content">
                  <h3 className="events-listing__title">
                    <a href={item.DetailUrl} className="events-listing__link">
                      {item.Title}
                    </a>
                  </h3>
                  {item.StartDate && item.EndDate && (
                    <div className="events-listing__info">
                      <svg className="events-listing__icon" aria-hidden="true">
                        <use href="#clock"></use>
                      </svg>
                      <span className="events-listing__value events-listing__value--secondary">
                        {parseDate(item.StartDate, "startTime")}-
                        {parseDate(item.EndDate, "endTime")}
                      </span>
                    </div>
                  )}
                  {item.Location && (
                    <div className="events-listing__info">
                      <svg
                        className="events-listing__icon events-listing__icon--secondary"
                        aria-hidden="true"
                      >
                        <use href="#map-marker-alt"></use>
                      </svg>
                      <span className="events-listing__value">
                        {item.Location}
                      </span>
                    </div>
                  )}
                  {item.Content && (
                    <TrimText content={item.Content} isHtmlContent={true} />
                  )}
                </div>
              </li>
            );
          })
        ) : (
          <></>
        )}
      </ul>
    </div>
  );
}
