import React from "react";

interface Props {
  title: string;
  children: React.ReactNode;
  utility?: React.ReactElement;
}

export default function CardContainer({ title, children, utility }: Props) {
  return (
    <div className="card-container">
      <div className="card-container__header">
        <div className="card-container__title">
          <h2>{title}</h2>
        </div>
        {utility && <div className="card-container__link">{utility}</div>}
      </div>
      <div className="card-container__content">{children}</div>
    </div>
  );
}
