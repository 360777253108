import { SitefinityImage } from "./sitefinity";
import { Staff } from "./staff-and-instructors";

export interface StudentOrganizationOfficer {
  Id: string;
  JobTitle: string;
  Name: string;
  Email: string;
}

export interface StudentOrganization {
  Id: string;
  LastModified: Date;
  PublicationDate: Date;
  DateCreated: Date;
  IncludedInSitemap: boolean;
  UrlName: string;
  ItemDefaultUrl: string;
  FacebookURL: string;
  TwitterURL: string;
  InstagramURL: string;
  Officers: StudentOrganizationOfficer[] | string;
  Staff: Staff[];
  Title: string;
  Content: string;
  Provider: string;
  Image: SitefinityImage[];
  calendarcategories: string[];
}

export interface FavoriteOrganizations {
  id: string;
  type: string;
}

export interface StudentOrganizationApiResponse {
  "@odata.nextLink": string;
  "@odata.context": string;
  value: StudentOrganization[];
}

export enum SocialNetworkTypes {
  Instagram = "Instagram",
  Facebook = "Facebook",
  Twitter = "Twitter",
}
